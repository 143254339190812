import React,{useContext,useState,useEffect,useRef} from 'react';
import { MyContext } from '../contexts/MyContext';
import {Link } from "react-router-dom"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'react-loader-spinner';
import {toast} from "react-toastify";
import CoverPhotoCompany from './CoverPhotoCompany';
import CompanyPhoto from './CompanyPhoto';
import userphotos from '../Img/user_profile.jpg';
import covers from '../Img/mmm.jpg';
import {WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;



function CompanyPage(props) {
  const {rootState,companyviewsall} = useContext(MyContext);
  const {isAuth,theUser} = rootState;
  useEffect( () => {
    window.scrollTo(0, 0);
    companyviews(props.match.params.Addpreview);
   }, []); 
   
   const [iscompanyviews, setiscompanyviews] = useState([]);
   const companyviews = async(pageid) =>{
     try {
            const companyviewsss = await companyviewsall(pageid);
            if(companyviewsss.success === 1){
                   console.log('companyviewsss',companyviewsss.companyviewlistsss);
                   setiscompanyviews(companyviewsss.companyviewlistsss);
                   
            }
     } catch (error) { throw error;}      
} 


const [iscoverphotoss, setiscoverphotos] = useState(false);
const coverphotos = async() =>{
  setiscoverphotos(iscoverphotoss === false ? true : false);
  setisgroupphotoss(false);
} 
const [isgroupphotoss, setisgroupphotoss] = useState(false);
const groupphotos = async() =>{
       setisgroupphotoss(isgroupphotoss === false ? true : false);
       setiscoverphotos(false);
     }
const [isEditpage, setisEditpage] = useState(false);
const Editpage = ()=> {
       setisEditpage(true);
}
const externallinks = (url) => {
       if (!!url && !!url.trim()) { 
              url = url.trim(); 
              if (!/^(https?:)?\/\//i.test(url)) { 
                  url = 'http://' + url; 
                  window.open(url, '_blank');
              }else{
                     window.open(url, '_blank');
              }
          } 
     }
     const myRef = useRef(null)
     const executeScroll = () => { 
            myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })   
     } 

   return (
   <> 
       <div className="groupsallwww groupsallwwwmmm2">
              <div className="container">
                     <div className="row">
                     
{iscompanyviews.map((item, index) => ( 
       <div className="col-md-12" key={item.companypage_id} style={{padding:0}}>
              <div className="cover-image-container">
              
              <div className="header-cover-image" >
              {item.coverimage !== '' ?
                     <img src={BaseURLuploads+item.coverimage} alt="coverimg" className="yyy16" />
              : 
              <img src={covers} alt="coverimg" className="yyy16" />
              }   
			
                     {isAuth === true && theUser === item.createid ? <>
                            {item.coverimage === '' ? <>
                                  <p className="ccc11mmm2" onClick={()=>coverphotos()}> Add Cover Photo </p>
                                  {iscoverphotoss === true ? 
                                          <CoverPhotoCompany pageids={props.match.params.Addpreview} cancels={()=>coverphotos()} refreshsss ={()=>companyviews(props.match.params.Addpreview)}/>
                                   : null }
                            </>
                            : <>
                                   <p className="ccc11mmm2" onClick={()=>coverphotos()}> Edit Cover Photo </p>
                                   {iscoverphotoss === true ? 
                                          <CoverPhotoCompany pageids={props.match.params.Addpreview} cancels={()=>coverphotos()} refreshsss ={()=>companyviews(props.match.params.Addpreview)}/>
                                   : null }
                            </>
                            } 
                     </>: null }
                     
    

                     <div className="generic-button">
                     {isAuth === true && theUser !== '' && theUser != props.match.params.Addpreview ? <>
             

                     {/* <Followunfollow  storageid={theUser} profileids={props.match.params.Addpreview } refreshfollowcount={()=>followcount(props.match.params.Addpreview)}refreshfollowingcount={()=>followingcount(props.match.params.Addpreview)}/> */}

                     </> : null}
                            
                     </div>




              </div>

              <div className="col-md-3">
                   

              <div className="item-header-avatar">
                    
                    {item.image === '' ? <>
                    <p> &nbsp;</p>
                    {isAuth === true && theUser === item.createid ? <>
                          <span className="" onClick={()=>groupphotos()}> Add Photo </span>
                          {isgroupphotoss === true ? 
                                  <CompanyPhoto pageids={props.match.params.Addpreview} cancels={()=>groupphotos()} refreshsss ={()=>companyviews(props.match.params.Addpreview)}/>
                           : null }
                    </>: null }
                            <img src={userphotos} className="avatar group-4-avatar " alt="photo" />
                    </>
                    : <>
                    <p> &nbsp;</p>
                    {isAuth === true && theUser === item.createid ? <>
                           <span className="" onClick={()=>groupphotos()}> Edit Photo </span>
                           {isgroupphotoss === true ? 
                                  <CompanyPhoto pageids={props.match.params.Addpreview} cancels={()=>groupphotos()} refreshsss ={()=>companyviews(props.match.params.Addpreview)} />
                           : null }
                    </>: null }
                            <img src={BaseURLuploads+item.image} className="avatar group-4-avatar " alt="photo" />
                    </>
                    } 
           
           <h1 className="ccc10mmm2">{item.companyname}</h1>
                          
                    </div>
              </div>
      <div className="col-md-9">
                <ul className="profile-nav">	
                     <li  onClick={executeScroll} className="bp-groups-tab">
                     {/* <li  onClick={Editpage} className="bp-groups-tab"> */}
                            <span className="nav-link-text"> Edit </span>   
                     </li>
                    
              </ul> 
       </div>










              </div>
       </div>
))}


<div className="clearfix"></div>

              
       <div  ref={myRef} className="yyy">
              
              <div className="col-md-3 resss">
              <li  onClick={Editpage} className={isEditpage? "bp-groups-tab": "groupactives" } >
                           
                            <span className="nav-link-text"> About </span>   
                     </li>   
                     
              </div>
              <div className="col-md-6 resss cccmmm2">
              {isAuth === true && theUser !=='' && isEditpage === true ? <>
{iscompanyviews.map((item, index) => ( 
       <div className="col-md-12" key={item.companypage_id} style={{padding:0}}>
              <div className="col-md-12" style={{padding:0}}>
                     <p className="col-md-5" > Website </p>
                     <p className="col-md-7" onClick={() => externallinks(item.website)}> {item.website} </p>
              </div>
              <div className="col-md-12" style={{padding:0}}>
                     <p className="col-md-5" > Industry </p>
                     <p className="col-md-7" > {item.industry} </p>
              </div>
              <div className="col-md-12" style={{padding:0}}>
                     <p className="col-md-5" > Company size </p>
                     <p className="col-md-7" > {item.companysize} </p>
              </div>
              <div className="col-md-12" style={{padding:0}}>
                     <p className="col-md-5" > Type </p>
                     <p className="col-md-7" > {item.companytype} </p>
              </div>

                           
       </div>
))}
              </>: null } 
             
                     
                     
                      </div>
              <div className="col-md-3 resss"> </div>
       </div>













                     </div>
               </div>
       </div>
   
   
   
   
   
   
     </>
   );
};

export default CompanyPage;