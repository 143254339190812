import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';

import {Link } from "react-router-dom"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {toast} from "react-toastify";

function MyaccountBronze(props) {
  const {rootState,viewuserprofile} = useContext(MyContext);
  const {isAuth,theUser} = rootState;

  useEffect( () => {
     userdetailswww(props.storageid);
 }, []);

 const logsss =() =>{
       toast.info('Please Upgrade Your Membership  to access this page ',{
         position: "top-center"});  
     }

 const [ismember, setismember] = useState();
 const userdetailswww = async(storageid) =>{
       try {
              const viewuserwww = await viewuserprofile(storageid);
              if(viewuserwww.success === 1){
                     setismember(viewuserwww.memberlist[0].member);
              }
       } catch (error) { throw error;}      
}
  
  

   const Classified =()=>{
       props.Classifiedsss();
   }
   const Business =()=>{
       props.Businesss();
   }

    return (
    
    <>
                
                    {ismember === 'Bronze' ? <>

                            <li className="nav-item" onClick={logsss}><Link to="/Myaccount">    <FontAwesomeIcon icon={['fas','star']} className="iconsss2" /> Business Ads     </Link></li> 
                            <li className="nav-item" onClick={logsss}><Link to="/Myaccount"> <FontAwesomeIcon icon={['fas','star']} className="iconsss2" />   Business Directory      </Link></li> 

                    </>
                    
              
                     : <>
                            <li onClick={Classified} className={ props.isClassified ? "active": "nav-item" }>
                            <FontAwesomeIcon icon={['fas','star']} className="iconsss2" />
                             Business Ads </li> 
                            <li onClick={Business} className={ props.isBusiness ? "active": "nav-item" }>
                            <FontAwesomeIcon icon={['fas','star']} className="iconsss2" />
                             Business Directory   </li> 
                     
                     </>
                     }
                     
                 
    </>
    );
}

export default MyaccountBronze;