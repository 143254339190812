import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Classifiedsedits from './Classifiedsedits';

import userphotos from '../Img/user_profile.jpg';
import Moment from 'react-moment';
import {Link } from "react-router-dom"; 
import ContentLoader from "react-content-loader";
import { WEB_URL_UPLOAD } from '../contexts/Apilinks';

let BaseURLuploads = WEB_URL_UPLOAD;


function MemberAds(props) {

    const {Advertsviewslistbusiness,viewuserprofile} = useContext(MyContext);
 
   
    useEffect( () => {
       userdetails(props.match.params.Addpreview);
       Advertsviews(props.match.params.Addpreview);
       window.scrollTo(0, 0);
      }, []); 

      const [isname, setisname] = useState();
      const [ismemberimg, setismemberimg] = useState();
      const userdetails = async(storageid) =>{
             try {
                    const viewuser = await viewuserprofile(storageid);
                    if(viewuser.success === 1){
                           console.log(viewuser.memberlist);
                         
                           setisname(viewuser.memberlist[0].name);
                           setismemberimg(viewuser.memberlist[0].profile_image);
                    }
             } catch (error) { throw error;}      
      }

      const [isAdverts, setisAdverts] = useState([]);
      const Advertsviews = async (profileusers) => {
       try {
              const Advertsviewsss = await Advertsviewslistbusiness(profileusers);
                     if(Advertsviewsss.success === 1){
                            console.log('Adverts list',Advertsviewsss.Advertslist)
                            setisAdverts(Advertsviewsss.Advertslist);
                     }
              } catch (error) { throw error;}    
       }
     

        return(
               <>
               <div className="clearfix"></div>
       <div className="bookadspreview">
            
              <div className="container">
                     <div className="row">
                    
                     <div className="col-md-12 " style={{padding:0}}>
                            <div className="mnzxxx " >
                                   {ismemberimg !== '' ? <>
                                          <img src={BaseURLuploads+ismemberimg} alt="userprofile" className="" />
                                   </> :  <>
                                          <img src={userphotos} alt="userprofile" className=""/>
                                                 </>
                                   }
                                          <h2 className="usernamesss">{isname}</h2>
                            <Link to={`/ProfileView/${props.match.params.Addpreview}`} >
                                   <button className="buttons2" > View Profile </button> 
                            </Link>
                                   
                            </div>
                     </div>
                     <div className="col-md-12 aaatopsseconds" style={{padding:0}}> 
                   
                    
{isAdverts.map((item, index) => ( 
        <div className="col-md-3" key={item.business_id}>
        <div className="single-listing-item ">
            <div className="listing-image">
            <Link to={`/BusinessPreview/${item.business_id}`} className="link-btn">
                    <img src={BaseURLuploads+item.image} alt="Classifiedsads" />
                   
                    </Link>
             </div>

            <div className="col-md-12 Platinum2" >
               <div className="col-md-6 timqqqnnn2" style={{padding:0}}>
               <div className="col-md-4" style={{padding:0}}>
                  {item.profile_image !=='' ? 
                              <img src={BaseURLuploads+item.profile_image} alt="" className="rounded-circle mr-2" />
                              :
                              <img src={userphotos} alt="" className="rounded-circle mr-2"  />
                           }
                  </div>
                   <div className="col-md-8" style={{padding:0}}>
                     <span>by {item.name} </span>
                  </div>

               </div>
               <div className="col-md-6 timqqqnnn" style={{paddingRight:0}}>
                  <p className="timqqq"> <FontAwesomeIcon icon={['fas','clock']} className="iconsss2 " /> <Moment format="MMM D, YYYY" withTitle>
                                    {item.date} 
                                 </Moment></p>

               </div>
            </div>
           
                <div className="listing-content ">
                <Link to={`/BusinessPreview/${item.business_id}`} className="link-btn">  <h3> {item.title} </h3>     </Link>
                </div>
                 <div className="Platinum3">
                    <h4>  <Link to={`/BusinessPreview/${item.business_id}`} className="link-btn"> Read More     </Link>  <FontAwesomeIcon icon={['fas','plus-circle']} className="iconsss2 "/></h4>
                   
                </div>
         
                 
               
        </div>
    </div>    
       
))}


</div>



                   

             </div>
             </div>
             </div>
             </>
        );
    }

export default MemberAds;