
export const API_KEY_STRIPE = "pk_live_yn6RjQtCI6a8ITcRqjeSdLKv00FSgvPKl8";

 export const WEB_URL = "https://bizonio.com/";
 export const WEB_URL_UPLOAD = "https://bizonio.com/uploads/";


//export const WEB_URL_UPLOAD = "http://localhost/uploads/";
//export const WEB_URL = "http://192.168.0.129:8080/";




export const CAPTCHA_SITE_KEY = "6LceUOQZAAAAAIH4NcR9XZAtynEZS01dy3PjSzOm";


// Paypal Link
//export const CANCEL = "https://bizonio.com/10aec35353f9c4096a71c38654c3d402";

//export const SUCCESS = "https://bizonio.com/260ca9dd8a4577fc00b7bd5810298076";





//export const PAYMENTLISTNER = "https://bizonio.com/api/paypal/payment-listner.php";

// Live Account PayPal
       //export const PAYPAL_URL = 'https://www.paypal.com/cgi-bin/webscr'; 
       //export const PAYPAL_EMAIL = 'info@writers.support';

// Test Account PayPal
       //export const PAYPAL_URL = 'https://www.sandbox.paypal.com/cgi-bin/webscr'; 
       //export const PAYPAL_EMAIL = 'infooltobusines@gmail.com';

// react ---------------------------------------------------------------------

// npm install react-bootstrap bootstrap
// npm install --save react-router-dom
// npm install axios --save
// npm install --save react-toastify

//     npm i --save @fortawesome/fontawesome-svg-core  @fortawesome/free-solid-svg-icons @fortawesome/react-fontawesome

// npm i --save @fortawesome/free-brands-svg-icons
// npm install --save react-motion
// npm install react-content-loader --save
// npm install --save react-google-recaptcha
// npm install react-loader-spinner --save
// npm install --save react-draft-wysiwyg draft-js
// npm install html-to-draftjs --save
// npm install draftjs-to-html
// npm install react-select
// npm install --save react-stripe-checkout
// npm install react-date-picker
// npm install --save moment react-moment
// npm install @react-pdf/renderer
// npm install react-confirm-alert --save
// npm install reactjs-popup --save
// npm i bootstrap-switch-button-react --save
// npm i @brainhubeu/react-carousel
// npm i @react-pdf/renderer
// npm install --save react-router-hash-link
// npm i react-date-picker

// Node -------------------------------------------------------

// npm install stripe --save
// npm install bcrypt
// npm install body-parser
// npm install cors
// npm install express
// npm install --save multer
// npm install --save mysql2
// npm install nodemailer
// npm install -g nodemon
// npm install paypal-rest-sdk
// npm install strtotime
// npm install uuidv4
// npm install react-scripts


//---------------------------------------------------------------------
// groupsjoin 
//     3 ---create group (admin)
//     1 ---join group
//     0 ---leave group
//     4 ---permission group (sub admin)
//     super --- 1 --- Block From Group