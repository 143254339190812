import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import ProfileEdits from './ProfileEdits';
import Loader from 'react-loader-spinner';
import Moment from 'react-moment';

function Profileeditingall(props) {
       const {rootState,viewuserprofile} = useContext(MyContext);
       const {isAuth,theUser} = rootState;
       useEffect( () => {
              userdetails(props.match.params.Addpreview);
              window.scrollTo(0, 0);
       }, []);
       
       const [isEditProfile, setEditProfile] = useState(false);
       const EditProfile = () =>{
              setEditProfile(true);
       }

       const [isuserlist, setuserlist] = useState([]);
       const userdetails = async(storageid) =>{
              try {
                     const viewuser = await viewuserprofile(storageid);
                     if(viewuser.success === 1){
                            console.log(viewuser.memberlist);
                            setuserlist(viewuser.memberlist); 
                     }
              } catch (error) { throw error;}      
       }
      
       const editModepreviewchildedit =() =>{
              setEditProfile(false);
              window.scrollTo(0, 0);
       }
  
      


  return (
  
       <div className="notificationsss">
            <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1> About </h1> 
            <br />
            {isAuth === true && theUser == props.match.params.Addpreview && isEditProfile === false ? 
                   
                   <div className="col-md-12"> 
                   <p onClick={EditProfile} className="db-tit-btn2"> Edit Profile  </p>
                 </div>
              
              : null }
            <div className="col-md-2"> &nbsp;</div>
	<div className="col-md-8 ">
           <div class="ud-cen">
           <div class="log-bor">&nbsp;</div>
           <div class="ud-cen2">
                 
           
                  
                 
              {isEditProfile === true && isuserlist.length > 0 ? <>  
                   
		       <div className="bp-widget base">
                            <ProfileEdits storageid={props.match.params.Addpreview} profileviews={isuserlist} refresh={()=> userdetails(props.match.params.Addpreview)} editModepreviewchilds={editModepreviewchildedit }/>
                     </div>
                     </> :

       <div className="profile public">
            
             
		
		<div className=" bp-widget base">
			
{isuserlist.map((item, index) => ( 
       <ul key={item.register_id}className="profile-fields bp-tables-user">  
		<li  className="label"> <label className="font-weight-bolds col-md-3"> Name : </label> <span className="data">{item.name} </span></li>

		<li className="label"><label className="font-weight-bolds col-md-3"> Date of Birth : </label> <span className="data"> 
               {item.dob !== '' ?
                     <Moment format="MMM D, YYYY" withTitle className="timssqqqwww">
                     {item.dob}
                     </Moment> 
              : <>  {item.dob}  </>}
              </span></li>

		<li className="label"><label className="font-weight-bolds col-md-3"> Gender : </label>  <span className="data">{item.sex} </span></li>
		<li className="label"> <label className="font-weight-bolds col-md-3"> City : </label> <span className="data">{item.city} </span></li>
		<li className="label"> <label className="font-weight-bolds col-md-3"> Country : </label> <span className="data">{item.country} </span></li>
		<li className="label"> <label className="font-weight-bolds col-md-3"> Phone : </label> <span className="data">{item.phone} </span></li>
		<li className="label"> <label className="font-weight-bolds col-md-3"> Facebook : </label> <span className="data">{item.facebook} </span></li>
		<li className="label"> <label className="font-weight-bolds col-md-3"> Twitter  : </label> <span className="data">{item.twitter} </span></li>
		<li className="label"> <label className="font-weight-bolds col-md-3"> Linkedin  : </label> <span className="data">{item.linkedin} </span></li>
              <li className="label"> <label className="font-weight-bolds col-md-3"> About : </label> <span className="data">{item.about} </span></li>
				
       </ul>

))}
			
		</div>

       </div>

}






                     </div>
                     </div>
                     </div>
                     </div>
                     </div>
                     </div>

       </div>
   
  );
}

export default Profileeditingall;