import React,{useContext,useState,useEffect,} from 'react';
import { MyContext } from '../contexts/MyContext';
import {Link } from "react-router-dom"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'react-loader-spinner';
import {toast} from "react-toastify";
import { useHistory } from "react-router-dom";
import officebuilding from '../Img/office-building.png';
import officebuilding2 from '../Img/office-building2.png';
import responsive from '../Img/responsive.png';
import school from '../Img/school.png';

function Createcompany(props) {
  let history = useHistory();
  const {rootState,createpages} = useContext(MyContext);
  const {isAuth,theUser} = rootState;
  useEffect( () => {
    window.scrollTo(0, 0);
   }, []); 
  
  const [issmall, setsmall] = useState(false);
  const smallbusiness = () => {
    setsmall(true);
  }
  const [ismedium, setmedium] = useState(false);
  const mediumbusiness = () => {
      setmedium(true);
  }
  const [isshowcase, setshowcase] = useState(false);
  const showcasebusiness = () => {
      setshowcase(true);
  }
  const [iseducation, seteducation] = useState(false);
  const educationbusiness = () => {
      seteducation(true);
  } 
  const backsss = () => {
      setsmall(false);
      setmedium(false);
      setshowcase(false);
      seteducation(false);
  }
  const [loadingadd, setloadingadd] = useState(false);
  const [userInfo, setuserInfo] = useState({
    name: '',
    company: '',
    website:'',
    industry:'',
    companysize:'',
    companytype:'',
    tags:'',
});
const onChangeValue = (e) => {
         
  setuserInfo({
      ...userInfo,
          [e.target.name]:e.target.value
  });
} 

const booksAddbooks = async (event) => {
  try {
         setloadingadd(true);
                event.preventDefault();
                event.persist();
                if(invalidImage !== null){
                  toast.error(invalidImage, {
                    position: "top-center",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                  }); 
                  setloadingadd(false);
                  return;
                }
                const dataadd = await createpages(userInfo,theUser,userInfo.fileempty.value);
                console.log(dataadd);
                if(dataadd.success === 1){
                       console.log(dataadd);
                  
                       setloadingadd(false);
                       event.target.reset(); 
                       history.push(`CompanyPage/${dataadd.pageids}`);
                       
                       toast( 'Success', {
                        position: "top-center",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                      });
                }else{
                       setloadingadd(false);
                        toast.error( 'Please Try Again', {
                          position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                        });  
                }

  } catch (error) { throw error;}    
}

const resetFile = (event) => {
  event.preventDefault();
  setfiletemp(null)
}

let reader = new FileReader();

const handleChange = (event) => {
  const imageFile = event.target.files[0];
        const imageFilname = event.target.files[0].name;

        if (!imageFile) {
         setinvalidImage('Please select image.');
          return false;
        }
    
        if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif)$/)) {
         setinvalidImage('Please select valid image JPG,JPEG,PNG');
          return false;
        }
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {

//----------------------------- Resize img code -------------------------------------------------
           var canvas = document.createElement('canvas');
           var ctx = canvas.getContext("2d");
           ctx.drawImage(img, 0, 0);

           var MAX_WIDTH = 437;
           var MAX_HEIGHT = 437;
           var width = img.width;
           var height = img.height;

           if (width > height) {
             if (width > MAX_WIDTH) {
               height *= MAX_WIDTH / width;
               width = MAX_WIDTH;
             }
           } else {
             if (height > MAX_HEIGHT) {
               width *= MAX_HEIGHT / height;
               height = MAX_HEIGHT;
             }
           }
           canvas.width = width;
           canvas.height = height;
           var ctx = canvas.getContext("2d");
           ctx.drawImage(img, 0, 0, width, height);
           ctx.canvas.toBlob((blob) => {
             const file = new File([blob], imageFilname, {
                 type: 'image/jpeg',
                 lastModified: Date.now()
             });
             setuserInfo({
                ...userInfo,
                file:file,
           })
           }, 'image/jpeg', 1);


//-------------------------------------------------------------------------------------
         setfiletemp( URL.createObjectURL(imageFile))
         setfileempty(true);
         setinvalidImage(null)
         };
          img.onerror = () => {
                setinvalidImage('Invalid image content.');
            return false;
          };
          //debugger
          img.src = e.target.result;
        };
        reader.readAsDataURL(imageFile);

}; 

const [fileempty, setfileempty] = useState(false);
const [filetemp, setfiletemp] = useState(null);
const [invalidImage, setinvalidImage] = useState(null);

  return (
  
    <div className="createpagesss">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
         <h1> Create a Bizonio Page </h1>
{issmall === false && ismedium === false && isshowcase === false && iseducation === false  ? <> 
         <p> Connect with clients, employees, and the Bizonio community. To get started, choose a page type. </p>
         <div className="col-md-3">
              <div className="createpagesss2" onClick={smallbusiness}>
                     <img src={officebuilding2} />
                     <h3>Small business</h3>
                     <p>Fewer than 200 employees</p>
              </div>
         </div>
         <div className="col-md-3">
              <div className="createpagesss2" onClick={mediumbusiness}>
                     <img src={officebuilding} />
                     <h3>Medium to large business</h3>
                     <p>More than 200 employees</p>
              </div>
         </div>
        <div className="col-md-3">
              <div className="createpagesss2" onClick={showcasebusiness}>
                     <img src={responsive} />
                     <h3>Showcase page</h3>
                     <p>Sub-pages associated with an existing page</p>
              </div>
         </div>
        <div className="col-md-3">
              <div className="createpagesss2" onClick={educationbusiness}>
                     <img src={school} />
                     <h3>Educational institution</h3>
                     <p>Schools and universities</p>
              </div>
         </div>

</> : <>   

<div className="col-md-1"> &nbsp; </div>
<div className="col-md-8">
<form  onSubmit={booksAddbooks} className="createpagesss3">
<p onClick={backsss}> <FontAwesomeIcon icon={['fas','arrow-left']} /> Back </p>
            <div className="clearfix"></div>
          
            <div className="form-row">
            <h6> <span className="adverts-form-required"> * </span> &nbsp; indicates required </h6>
          <div className="createpagesss4">
          <div className="login-form"><h2> Page identity </h2></div>
            <div className="form-group col-md-12">
                    <label className="font-weight-bolds"> Name <span className="adverts-form-required"> * </span> </label>
                    <input type="text" name="name" value={userInfo.name} onChange={onChangeValue}  className="form-control" placeholder="" required />
            </div>
           
            <div className="form-group col-md-12">
<label className="font-weight-bolds"> Bizonio.com/{iseducation === true ? <>school/</> :<>company/</> } <span className="adverts-form-required"> * </span> </label>
                    <input type="text" name="company" value={userInfo.company} onChange={onChangeValue}   className="form-control" placeholder="" required />
            </div>     
            <div className="form-group col-md-12">
                    <label className="font-weight-bolds"> Website  </label>
                    <input type="text" name="website" value={userInfo.website} onChange={onChangeValue}   className="form-control" placeholder="Begin with http:// or https:// or www." />
                    <p> This is a link to your external website. </p>
            </div>     
          </div>     
         
        <div className="createpagesss4">
        <div className="login-form"><h2> Company details </h2></div>
                <div className="form-group col-md-12">
                    <label className="font-weight-bolds"> Industry  <span className="adverts-form-required"> * </span> </label>
                    <select  name="industry" value={userInfo.industry} onChange={onChangeValue} className="wqsss form-control"  required>
                            
                    <option value=""> Select Industry </option>
                     
                    <option>Accounting</option>
                    <option>Airlines/Aviation</option>
                    <option>Alternative Dispute Resolution</option>
                    <option>Alternative Medicine</option>
                    <option>Animation</option>
                    <option>Apparel &amp; Fashion</option>
                    <option>Architecture &amp; Planning</option>
                    <option>Arts &amp; Crafts</option>
                    <option>Automotive</option>
                    <option>Aviation &amp; Aerospace</option>
                    <option>Banking</option>
                    <option>Biotechnology</option>
                    <option>Broadcast Media</option>
                    <option>Building Materials</option>
                    <option>Business Supplies &amp; Equipment</option>
                    <option>Capital Markets</option>
                    <option>Chemicals</option>
                    <option>Civic &amp; Social Organization</option>
                    <option>Civil Engineering</option>
                    <option>Commercial Real Estate</option>
                    <option>Computer &amp; Network Security</option>
                    <option>Computer Games</option>
                    <option>Computer Hardware</option>
                    <option>Computer Networking</option>
                    <option>Computer Software</option>
                    <option>Construction</option>
                    <option>Consumer Electronics</option>
                    <option>Consumer Goods</option>
                    <option>Consumer Services</option>
                    <option>Cosmetics</option>
                    <option>Dairy</option>
                    <option>Defense &amp; Space</option>
                    <option>Design</option>
                    <option>E-learning</option>
                    <option>Education Management</option>
                    <option>Electrical &amp; Electronic Manufacturing</option>
                    <option>Entertainment</option>
                    <option>Environmental Services</option>
                    <option>Events Services</option>
                    <option>Executive Office</option>
                    <option>Facilities Services</option>
                    <option>Farming</option>
                    <option>Financial Services</option>
                    <option>Fine Art</option>
                    <option>Fishery</option>
                    <option>Food &amp; Beverages</option>
                    <option>Food Production</option>
                    <option>Fundraising</option>
                    <option>Furniture</option>
                    <option>Gambling &amp; Casinos</option>
                    <option>Glass, Ceramics &amp; Concrete</option>
                    <option>Government Administration</option>
                    <option>Government Relations</option>
                    <option>Graphic Design</option>
                    <option>Health, Wellness &amp; Fitness</option>
                    <option>Higher Education</option>
                    <option>Hospital &amp; Health Care</option>
                    <option>Hospitality</option>
                    <option>Human Resources</option>
                    <option>Import &amp; Export</option>
                    <option>Individual &amp; Family Services</option>
                    <option>Industrial Automation</option>
                    <option>Information Services</option>
                    <option>Information Technology &amp; Services</option>
                    <option>Insurance</option>
                    <option>International Affairs</option>
                    <option>International Trade &amp; Development</option>
                    <option>Internet</option>
                    <option>Investment Banking</option>
                    <option>Investment Management</option>
                    <option>Judiciary</option>
                    <option>Law Enforcement</option>
                    <option>Law Practice</option>
                    <option>Legal Services</option>
                    <option>Legislative Office</option>
                    <option>Leisure, Travel &amp; Tourism</option>
                    <option>Libraries</option>
                    <option>Logistics &amp; Supply Chain</option>
                    <option>Luxury Goods &amp; Jewelry</option>
                    <option>Machinery</option>
                    <option>Management Consulting</option>
                    <option>Maritime</option>
                    <option>Market Research</option>
                    <option>Marketing &amp; Advertising</option>
                    <option>Mechanical Or Industrial Engineering</option>
                    <option>Media Production</option>
                    <option>Medical Device</option>
                    <option>Medical Practice</option>
                    <option>Mental Health Care</option>
                    <option>Military</option>
                    <option>Mining &amp; Metals</option>
                    <option>Mobile Games</option>
                    <option>Motion Pictures &amp; Film</option>
                    <option>Museums &amp; Institutions</option>
                    <option>Music</option>
                    <option>Nanotechnology</option>
                    <option>Newspapers</option>
                    <option>Non-profit Organization Management</option>
                    <option>Oil &amp; Energy</option>
                    <option>Online Media</option>
                    <option>Outsourcing/Offshoring</option>
                    <option>Package/Freight Delivery</option>
                    <option>Packaging &amp; Containers</option>
                    <option>Paper &amp; Forest Products</option>
                    <option>Performing Arts</option>
                    <option>Pharmaceuticals</option>
                    <option>Philanthropy</option>
                    <option>Photography</option>
                    <option>Plastics</option>
                    <option>Political Organization</option>
                    <option>Primary/Secondary Education</option>
                    <option>Printing</option>
                    <option>Professional Training &amp; Coaching</option>
                    <option>Program Development</option>
                    <option>Public Policy</option>
                    <option>Public Relations &amp; Communications</option>
                    <option>Public Safety</option>
                    <option>Publishing</option>
                    <option>Railroad Manufacture</option>
                    <option>Ranching</option>
                    <option>Real Estate</option>
                    <option>Recreational Facilities &amp; Services</option>
                    <option>Religious Institutions</option>
                    <option>Renewables &amp; Environment</option>
                    <option>Research</option>
                    <option>Restaurants</option>
                    <option>Retail</option>
                    <option>Security &amp; Investigations</option>
                    <option>Semiconductors</option>
                    <option>Shipbuilding</option>
                    <option>Sporting Goods</option>
                    <option>Sports</option>
                    <option>Staffing &amp; Recruiting</option>
                    <option>Supermarkets</option>
                    <option>Telecommunications</option>
                    <option>Textiles</option>
                    <option>Think Tanks</option>
                    <option>Tobacco</option>
                    <option>Translation &amp; Localization</option>
                    <option>Transportation/Trucking/Railroad</option>
                    <option>Utilities</option>
                    <option>Venture Capital &amp; Private Equity</option>
                    <option>Veterinary</option>
                    <option>Warehousing</option>
                    <option>Wholesale</option>
                    <option>Wine &amp; Spirits</option>
                    <option>Wireless</option>
                    <option>Writing &amp; Editing</option>
                          </select>
                       
                </div> 
              
{isshowcase === true ? null :<>  
                <div className="form-group col-md-12">
                    <label className="font-weight-bolds"> Company size  <span className="adverts-form-required"> * </span> </label>
                    {ismedium === true ? 
                      <select  name="companysize" value={userInfo.companysize} onChange={onChangeValue} className="wqsss form-control"  required>
                        <option value=""> Select company size </option>
                        <option>201–500 employees</option>
                        <option>501–1,000 employees</option>
                        <option>1,001–5,000 employees</option>
                        <option>5,001–10,000 employees</option>
                        <option>10,000+ employees</option>
                      </select> 
                    : 
                      <select  name="companysize" value={userInfo.companysize} onChange={onChangeValue} className="wqsss form-control"  required>
                        <option value=""> Select company size </option>
                        <option>0–1 employees</option>
                        <option>2–10 employees</option>
                        <option>11–50 employees</option>
                        <option>51–200 employees</option>
                      </select>
                    } 
                </div>
                {iseducation === true ? null :<>               
                          
                 <div className="form-group col-md-12">
                    <label className="font-weight-bolds"> Company type <span className="adverts-form-required"> * </span> </label>
                    <select  name="companytype" value={userInfo.companytype} onChange={onChangeValue} className="wqsss form-control"  required>
                    <option value=""> Select company type </option>
                    <option>Public company</option>
                    <option>Self-employed</option>
                    <option>Nonprofit</option>
                    <option>Sole proprietorship</option>
                    <option>Privately held</option>
                    <option>Partnership</option>
                          </select>
                       
                </div>
                </> }   
              
</> }     

        </div>     
        <div className="createpagesss4">
          <div className="login-form"><h2> Profile details </h2></div>
            <div className="form-group col-md-12">
                    <label className="font-weight-bolds"> Logo </label>
                    <input type="file" name="file" className="form-control form-controlnnmmm2 " placeholder="Title"  onChange={handleChange}  />
                    {fileempty === true ? 

                    <input type="hidden" name="fileempty" ref={(val) => userInfo.fileempty = val} className="form-control" defaultValue="imgactive" />
                    : 
                    <input type="hidden" name="fileempty" ref={(val) => userInfo.fileempty = val} className="form-control" defaultValue="imgempty" />
                    }
            </div>
           
            <div className="form-group col-md-12">
              <label className="font-weight-bolds"> Tagline  </label>
                    <textarea type="text" name="tags" value={userInfo.tags} rows="5"  onChange={onChangeValue}   className="form-control" placeholder="Example: A family-run accounting firm that promises you won’t lose sleep over filing your taxes."  />
            </div>     
         
          </div>     
            
       
                {loadingadd ?
                          <>
                                 <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                          </>
                       : 
            <div className="form-group col-sm-12 text-right">
            
                    <button type="submit" className="btn btn-primary"> Create Page  </button>
                   
                </div> 
                 }
            
           
            </div>
            
        </form>
      </div>



 </>}    





          </div>


        </div>
      </div>
    </div>
  );
};

export default Createcompany;