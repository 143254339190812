
import React, { createContext,Component } from "react";
import axios from 'axios';
import { WEB_URL } from './Apilinks';
export const MyContext = createContext();

// Define the base URL
const Axios = axios.create({
    baseURL: WEB_URL,
});

class MyContextProvider extends Component{
  

    state = {
        isAuth:false,
        theUser:null,
    }
    componentDidMount(){
        this.isLoggedIn();
    }
    
        isLoggedIn = async () => {
            let userstorageid = JSON.parse(localStorage.getItem('loginToken'));
            if (localStorage.getItem('loginToken')) {
                this.setState({
                    ...this.state,
                    isAuth:true,
                    theUser:userstorageid
                });
                //console.log(this.state.isAuth);
            }
        }
        logoutUser = () => {
            localStorage.removeItem('loginToken');
            this.setState({
                ...this.state,
                isAuth:false
            })
            
            window.location.href = '/Login';
        }
        loginUser = async (user) => {
            const login = await Axios.post('login',{
                email:user.email,
                password:user.password
            });
            return login.data;
        }
        RegisterUser = async (user,recaptcha_response,price) => {
            const registerqqq = await Axios.post('Register',{
                user_name:user.name,
                user_email:user.email,
                password:user.password,
                recaptcha_response:recaptcha_response,
                plan:user.membership,
                price:price,
            });
            return registerqqq.data;
        }
        ForgetMailpassword = async (user) => {
            const loginForget = await Axios.post('forgetpassword',{
                email:user.useremail,
            });
            return loginForget.data;
        }
        pricesall = async () => {
            const pricesallwww = await Axios.get('pricesallsss');
            return pricesallwww.data;
        }
        memberlistall = async (memberid) => {
            const memberlistallwww = await Axios.post('memberlistallsss',{
                memberid:memberid,
            });
            return memberlistallwww.data;
        }
        changeplanlist = async (memberid,plan,price) => {
            const changeplanlistwww = await Axios.post('changeplanlistsss',{
                memberid:memberid,
                plan:plan,
                price:price,
            });
            return changeplanlistwww.data;
        }
        upgradeplanslist = async (memberid,plan,price) => {
            const upgradeplanslistwww = await Axios.post('upgradeplanslistsss',{
                memberid:memberid,
                plan:plan,
                price:price,
            });
            return upgradeplanslistwww.data;
        }
        memberstripe = async (token,memberid,plan,price) => {
            const memberstripewww = await Axios.post('memberstripesss',{
                token:token,
                memberid:memberid,
                plan:plan,
                price:price,
            });
            return memberstripewww.data;
        } 
        memberstripeupgrade = async (token,memberid,plan,price) => {
            const memberstripeupgradewww = await Axios.post('memberstripeupgradesss',{
                token:token,
                memberid:memberid,
                plan:plan,
                price:price,
            });
            return memberstripeupgradewww.data;
        }
        stripesuccesslist = async (stripeid) => {
            const stripesuccesslistwww = await Axios.post('stripesuccesslistsss',{
                stripeid:stripeid,
            });
            return stripesuccesslistwww.data;
        }
        paypalsuccessbook = async (paypalid) => {
            const paypalsuccessbookwww = await Axios.post('paypalsuccessbooksss',{
                paypalid:paypalid,
            });
            return paypalsuccessbookwww.data;
        }
        viewuserprofile = async (userid) => {
            const viewuserprofileqqq = await Axios.post('memberuser',{
                registerid:userid,
            });
            return viewuserprofileqqq.data;
        }
        loginUserimage = async (file,ids) => {
            const formData = new FormData();
            formData.append('avatar',file);
            formData.append('storageid',ids);
            const userimg = await Axios.post('profileupdatemember',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return userimg.data;
        }
        newbookadd = async (book,desc,userids) => {
            const formData = new FormData();
            formData.append('title',book.title);
            formData.append('description',desc);
            formData.append('avatar',book.file);
            formData.append('phone',book.phone);
            formData.append('email',book.email);
            formData.append('website',book.website);
            formData.append('category',book.category);
            formData.append('descothers',book.descothers);
            formData.append('userids',userids);
            const booksaddsss = await Axios.post('classifiedsadd',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return booksaddsss.data;
        }
        newbookupdate = async (book,desc,classifiedid,fileempty) => {
            const formData = new FormData();
            formData.append('title',book.title);
            formData.append('description',desc);
            formData.append('avatar',book.file);
            formData.append('phone',book.phone);
            formData.append('email',book.email);
            formData.append('website',book.website);
            formData.append('category',book.category);
            formData.append('descothers',book.descothers);
            formData.append('classifiedid',classifiedid);
            formData.append('fileempty',fileempty);
            const booksaddsssupdate = await Axios.post('classifiedsupdate',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return booksaddsssupdate.data;
        }
        newbusinessadd = async (book,desc,userids) => {
            const formData = new FormData();
            formData.append('title',book.title);
            formData.append('description',desc);
            formData.append('avatar',book.file);
            formData.append('phone',book.phone);
            formData.append('email',book.email);
            formData.append('website',book.website);
            formData.append('category',book.category);
            formData.append('descothers',book.descothers);
            formData.append('userids',userids);
            const booksaddsss = await Axios.post('businessadd',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return booksaddsss.data;
        }
        businessupdate = async (book,desc, businessid,fileempty) => {
            const formData = new FormData();
            formData.append('title',book.title);
            formData.append('description',desc);
            formData.append('avatar',book.file);
            formData.append('phone',book.phone);
            formData.append('email',book.email);
            formData.append('website',book.website);
            formData.append('category',book.category);
            formData.append('descothers',book.descothers);
            formData.append('businessid',businessid);
            formData.append('fileempty',fileempty);
            const booksaddsssupdate = await Axios.post('businessupdatesss',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return booksaddsssupdate.data;
        }
        classifiedspreview = async (classifiedids) => {
            const classifiedspreviewqqq = await Axios.post('classifiedspreviewsss',{
                classified_id:classifiedids,
            });
            return classifiedspreviewqqq.data;
        }
        Businesspreview = async (Businessids) => {
            const Businesspreviewqqq = await Axios.post('Businesspreviewsss',{
                Businessids:Businessids,
            });
            return Businesspreviewqqq.data;
        }
        PlatinumgoldAds = async () => {
            const PlatinumgoldAdsss = await Axios.get('PlatinumgoldAdsqqq');
            return PlatinumgoldAdsss.data;
        }
        GoldgoldAds = async () => {
            const GoldgoldAdsss = await Axios.get('GoldgoldAdsqqq');
            return GoldgoldAdsss.data;
        }
        paltinumrandAds = async () => {
            const paltinumrandAdsss = await Axios.get('paltinumrandAdsqqq');
            return paltinumrandAdsss.data;
        }
        PlatinumAds = async () => {
            const PlatinumAdsss = await Axios.get('PlatinumAdsqqq');
            return PlatinumAdsss.data;
        }
        GoldAds = async () => {
            const GoldAdsqqq = await Axios.get('GoldAdsss');
            return GoldAdsqqq.data;
        }
        GoldAdslatest = async () => {
            const GoldAdslatestqqq = await Axios.get('GoldAdslatestsss');
            return GoldAdslatestqqq.data;
        }  
        SilverAdslatest = async () => {
            const SilverAdslatestqqq = await Axios.get('SilverAdslatestsss');
            return SilverAdslatestqqq.data;
        }
        membersallAds = async () => {
            const membersallAdsqqq = await Axios.get('membersallAdsss');
            return membersallAdsqqq.data;
        }
        SilverAds = async () => {
            const SilverAdsqqq = await Axios.get('SilverAdsss');
            return SilverAdsqqq.data;
        }
        BronzeAds = async () => {
            const BronzeAdsqqq = await Axios.get('BronzeAdsss');
            return BronzeAdsqqq.data;
        }
        businessAds = async () => {
            const businessAdsqqq = await Axios.get('businessAdsss');
            return businessAdsqqq.data;
        }
        
        profileupdatelist = async (book, userid,fileempty,sdate) => {
            const formData = new FormData();
            formData.append('name',book.name);
            formData.append('password',book.password);
            formData.append('avatar',book.file);
            formData.append('about',book.about);
            formData.append('facebook',book.facebook);
            formData.append('twitter',book.twitter);
            formData.append('linkedin',book.linkedin);
            formData.append('dob',sdate);
            formData.append('sex',book.sex);
            formData.append('city',book.city);
            formData.append('country',book.country);
            formData.append('phone',book.phone);
            formData.append('userid',userid);
            formData.append('fileempty',fileempty);
            const profileupdatelistsss = await Axios.post('profileupdatesss',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return profileupdatelistsss.data;
        }
        newfeedsadd = async (newfeed, userid,fileempty) => {
            const formData = new FormData();
            formData.append('feeds',newfeed.feed);
            formData.append('avatar',newfeed.file);
            formData.append('url',newfeed.url);
            formData.append('youtube',newfeed.youtube);
            formData.append('userid',userid);
            formData.append('fileempty',fileempty);
            const newfeedsaddsss = await Axios.post('newfeedsaddqqq',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return newfeedsaddsss.data;
        }
        
        latestfeedslist  = async (pageNumber) => {
            const latestfeedslistqqq = await Axios.get(`latestfeedslistsss/${pageNumber}`);
            return latestfeedslistqqq.data;
        }
        LikesActiveAdd = async (feedid,likeuserid) => {
            const LikesActiveAddqqq = await Axios.post('LikesActiveAddsss',{
                feedid:feedid,
                likeuserid:likeuserid,
            });
            return LikesActiveAddqqq.data;
        }
        newcommentadd = async (newfeedcomment, userid,fileempty,postids) => {
            const formData = new FormData();
            formData.append('comment',newfeedcomment.comment);
            formData.append('avatar',newfeedcomment.file);
            formData.append('userid',userid);
            formData.append('fileempty',fileempty);
            formData.append('postids',postids);
            const newcommentaddqqq = await Axios.post('newcommentaddsss',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return newcommentaddqqq.data;
        }
        commentlistall = async (postids) => {
            const commentlistallqqq = await Axios.post('commentlistallsss',{
                postids:postids,
            });
            return commentlistallqqq.data;
        }
        Replayall = async (commentid,loginid,userInfo) => {
            const Replayallqqq = await Axios.post('Replayallsss',{
                commentid:commentid,
                loginid:loginid,
                replay:userInfo.replay,
            });
            return Replayallqqq.data;
        }
        
        Replayviewsall = async (postids) => {
            const Replayviewsallqqq = await Axios.post('Replayviewsallsss',{
                postids:postids,
            });
            return Replayviewsallqqq.data;
        }
        latestlikeslist  = async (storageid) => {
            const latestlikeslistqqq = await Axios.post('latestlikeslistsss',{
                loginid:storageid,
            });
            return latestlikeslistqqq.data;
        }
        newfeedsupdatelist = async (newfeed,fileempty,feedid,deleteimgsss) => {
            const formData = new FormData();
            formData.append('feeds',newfeed.feed);
            formData.append('avatar',newfeed.file);
            formData.append('url',newfeed.url);
            formData.append('youtube',newfeed.youtube);
            formData.append('fileempty',fileempty);
            formData.append('feedid',feedid);
            formData.append('deleteimgsss',deleteimgsss);
            const newfeedsupdatelist = await Axios.post('newfeedsupdatelistqqq',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return newfeedsupdatelist.data;
        }
        deletebooksssqqq  = async (deleteids) => {
            const deletebooksss = await Axios.post('deletebooks',{
                deleteids:deleteids,
            });
            return deletebooksss.data;
        }
        reportadds  = async (isreport,storageid,feedid) => {
            const reportaddsqqq = await Axios.post('reportaddsss',{
                report:isreport.reportpost,
                storageid:storageid,
                feedid:feedid,
            });
            return reportaddsqqq.data;
        }
        
        newcommentupdatelist = async (newfeed,fileempty,feedid,deleteimgsss) => {
            const formData = new FormData();
            formData.append('feeds',newfeed.comment);
            formData.append('avatar',newfeed.file);
            formData.append('fileempty',fileempty);
            formData.append('feedid',feedid);
            formData.append('deleteimgsss',deleteimgsss);
            const newfeedsupdatelistqqq = await Axios.post('newcommentupdatelistqqq',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return newfeedsupdatelistqqq.data;
        }
        deletecomments = async (deleteids,postids) => {
            const deletecommentsqqq = await Axios.post('deletecommentsss',{
                deleteids:deleteids,
                postids:postids,
            });
            return deletecommentsqqq.data;
        }
        newreplayupdateqqq = async (userInforeplay,postids) => {
            const newreplayupdate = await Axios.post('newreplayupdatesss',{
                replay:userInforeplay.replay,
                postids:postids,
            });
            return newreplayupdate.data;
        }
        deletesreplaylistall = async (deleteids) => {
            const deletesreplaylistallsss = await Axios.post('deletesreplaylistallqqq',{
                deleteids:deleteids,
            });
            return deletesreplaylistallsss.data;
        }
        getPhotoslist  = async (pageNumber) => {
            const getPhotoslistqqq = await Axios.get(`Allclassifieds/${pageNumber}`);
            return getPhotoslistqqq.data;
        }
        creategroups = async (userInfo,createids) => {
            const creategroupsqqq = await Axios.post('creategroupsss',{
                name:userInfo.name,
                description:userInfo.description,
                website:userInfo.website,
                createids:createids,
            });
            return creategroupsqqq.data;
        }
        allgroupslist  = async () => {
            const allgroupslistqqq = await Axios.get('allgroupslistsss');
            return allgroupslistqqq.data;
        }
        mygroupslist = async (ids) => {
            const mygroupslistqqq = await Axios.post('mygroupslistqqq',{
                ids:ids,
            });
            return mygroupslistqqq.data;
        }
        viewgroupslists = async (ids) => {
            const viewgroupslistsqqq = await Axios.post('viewgroupslistssss',{
                ids:ids,
            });
            return viewgroupslistsqqq.data;
        }
        coverphotoupdatelist = async (userInfo,groupids) => {
            const formData = new FormData();
            formData.append('avatar',userInfo.file);
            formData.append('groupids',groupids);
            const coverphotoupdatelistqqq = await Axios.post('coverphotoupdatelistsss',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return coverphotoupdatelistqqq.data;
        }
        groupphotoupdatelist = async (userInfo,groupids) => {
            const formData = new FormData();
            formData.append('avatar',userInfo.file);
            formData.append('groupids',groupids);
            const groupphotoupdatelistqqq = await Axios.post('groupphotoupdatelistsss',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return groupphotoupdatelistqqq.data;
        }
        joinsgroupslists = async (groupsids,userids) => {
            const joinsgroupslistsqqq = await Axios.post('joinsgroupslistsss',{
                groupsids:groupsids,
                userids:userids,
            });
            return joinsgroupslistsqqq.data;
        }
        joinsalllist = async (groupsids,userids) => {
            const joinsalllistqqq = await Axios.post('joinsalllistsss',{
                groupsids:groupsids,
                userids:userids,
            });
            return joinsalllistqqq.data;
        }
        leavegroupslist = async (groupsids,userids) => {
            const leavegroupslistqqq = await Axios.post('leavegroupslistsss',{
                groupsids:groupsids,
                userids:userids,
            });
            return leavegroupslistqqq.data;
        }
        newfeedsgroupadd = async (newfeed, userid,fileempty,groupid) => {
            const formData = new FormData();
            formData.append('feeds',newfeed.feed);
            formData.append('avatar',newfeed.file);
            formData.append('url',newfeed.url);
            formData.append('youtube',newfeed.youtube);
            formData.append('userid',userid);
            formData.append('fileempty',fileempty);
            formData.append('groupid',groupid);
            const newfeedsgroupaddsss = await Axios.post('newfeedsgroupaddqqq',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return newfeedsgroupaddsss.data;
        }
        groupannouncementadd = async (newfeed, userid,fileempty,groupid) => {
            const formData = new FormData();
            formData.append('feeds',newfeed.feed);
            formData.append('avatar',newfeed.file);
            formData.append('url',newfeed.url);
            formData.append('youtube',newfeed.youtube);
            formData.append('userid',userid);
            formData.append('fileempty',fileempty);
            formData.append('groupid',groupid);
            const groupannouncementaddsss = await Axios.post('groupannouncementaddqqq',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return groupannouncementaddsss.data;
        }
        announcmentslist = async (groupsids) => {
            const announcmentslistsss = await Axios.post('announcmentslistqqq',{
                groupsids:groupsids,
            });
            return announcmentslistsss.data;
        }
        gpostlist = async (pageNumber,groupsids) => {
            const gpostlistqqq = await Axios.get(`gpostlistsss/${pageNumber}/${groupsids}`);
            return gpostlistqqq.data;
        }
        groupfeedsupdatelist = async (newfeed,fileempty,feedid,deleteimgsss) => {
            const formData = new FormData();
            formData.append('feeds',newfeed.feed);
            formData.append('avatar',newfeed.file);
            formData.append('url',newfeed.url);
            formData.append('youtube',newfeed.youtube);
            formData.append('fileempty',fileempty);
            formData.append('feedid',feedid);
            formData.append('deleteimgsss',deleteimgsss);
            const groupfeedsupdatelistsss = await Axios.post('groupfeedsupdatelistqqq',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return groupfeedsupdatelistsss.data;
        }
        deletegrouppostsss  = async (deleteids) => {
            const deletegrouppostsssqqq = await Axios.post('deletegrouppostssswww',{
                deleteids:deleteids,
            });
            return deletegrouppostsssqqq.data;
        }
        reportpostupdatelist  = async (reports,userids,groupids,feedsids) => {
            const reportpostupdatelistqqq = await Axios.post('reportpostupdatelistsss',{
                reports:reports.reportpost,
                userids:userids,
                groupids:groupids,
                feedsids:feedsids,
            });
            return reportpostupdatelistqqq.data;
        }
        setfeaturedslist  = async (feedsids) => {
            const setfeaturedssswww = await Axios.post('setfeaturedsssqqq',{
                feedsids:feedsids,
            });
            return setfeaturedssswww.data;
        }
        latestlikeslistgroup  = async (storageid) => {
            const latestlikeslistqqq = await Axios.post('latestlikeslistgroupsss',{
                loginid:storageid,
            });
            return latestlikeslistqqq.data;
        }
        newcommentaddgroup = async (newfeedcomment, userid,fileempty,postids) => {
            const formData = new FormData();
            formData.append('comment',newfeedcomment.comment);
            formData.append('avatar',newfeedcomment.file);
            formData.append('userid',userid);
            formData.append('fileempty',fileempty);
            formData.append('postids',postids);
            const newcommentaddqqq = await Axios.post('newcommentaddgroupsss',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return newcommentaddqqq.data;
        }
        commentlistallgroup = async (postids) => {
            const commentlistallqqq = await Axios.post('commentlistallgroupsss',{
                postids:postids,
            });
            return commentlistallqqq.data;
        }
        Replayallgroup = async (commentid,loginid,userInfo) => {
            const Replayallqqq = await Axios.post('Replayallgroupsss',{
                commentid:commentid,
                loginid:loginid,
                replay:userInfo.replay,
            });
            return Replayallqqq.data;
        }
        Replayviewsallgroups = async (postids) => {
            const Replayviewsallqqq = await Axios.post('Replayviewsallgroupsss',{
                postids:postids,
            });
            return Replayviewsallqqq.data;
        } 
        LikesActiveAddgroup = async (feedid,likeuserid) => {
            const LikesActiveAddqqq = await Axios.post('LikesActiveAddgroup',{
                feedid:feedid,
                likeuserid:likeuserid,
            });
            return LikesActiveAddqqq.data;
        }
        deletecommentsgroups = async (deleteids,postids) => {
            const deletecommentsqqq = await Axios.post('deletecommentsgroupsss',{
                deleteids:deleteids,
                postids:postids,
            });
            return deletecommentsqqq.data;
        }
        deletesreplaylistallgroups = async (deleteids) => {
            const deletesreplaylistallsss = await Axios.post('deletesreplaylistallgroupsqqq',{
                deleteids:deleteids,
            });
            return deletesreplaylistallsss.data;
        }
        newcommentupdatelistgroup = async (newfeed,fileempty,feedid,deleteimgsss) => {
            const formData = new FormData();
            formData.append('feeds',newfeed.comment);
            formData.append('avatar',newfeed.file);
            formData.append('fileempty',fileempty);
            formData.append('feedid',feedid);
            formData.append('deleteimgsss',deleteimgsss);
            const newfeedsupdatelistqqq = await Axios.post('newcommentupdatelistgroupsss',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return newfeedsupdatelistqqq.data;
        }
        newreplayupdategroupsss = async (userInforeplay,postids) => {
            const newreplayupdate = await Axios.post('newreplayupdategroupsssqqq',{
                replay:userInforeplay.replay,
                postids:postids,
            });
            return newreplayupdate.data;
        }
        removefeaturedsmmmlist  = async (feedsids) => {
            const removefeaturedsmmmlistqqq = await Axios.post('removefeaturedsmmmlistsss',{
                feedsids:feedsids,
            });
            return removefeaturedsmmmlistqqq.data;
        }
        HideCommentlist  = async (feedsids) => {
            const HideCommentlistqqq = await Axios.post('HideCommentlistsss',{
                feedsids:feedsids,
            });
            return HideCommentlistqqq.data;
        }
        listannoumcementsqqq  = async (groupid) => {
            const listannoumcements = await Axios.post('listannoumcementsss',{
                groupid:groupid,
            });
            return listannoumcements.data;
        }
        updategroups  = async (userinfo,groupuserid,groupids) => {
            const updategroupsqqq = await Axios.post('updategroupsss',{
                name:userinfo.name,
                description:userinfo.description,
                website:userinfo.website,
                groupuserid:groupuserid,
                groupids:groupids,
            });
            return updategroupsqqq.data;
        }
        groupsgallerysadds = async (newfeed,loadids) => {
            const formData = new FormData();
            formData.append('avatar',newfeed.file);
            formData.append('groupids',loadids);
            const groupsgallerysaddsqqq = await Axios.post('groupsgallerysaddsss',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return groupsgallerysaddsqqq.data;
        }
        groupsgaleryviewsall  = async (groupid) => {
            const groupsgaleryviewsallsss = await Axios.post('groupsgaleryviewsallqqq',{
                groupid:groupid,
            });
            return groupsgaleryviewsallsss.data;
        }
        deletegalleryall  = async (groupid) => {
            const deletegalleryallqqq = await Axios.post('deletegalleryallsss',{
                groupid:groupid,
            });
            return deletegalleryallqqq.data;
        }
        groupmembersall  = async (groupid) => {
            const groupmembersallqqq = await Axios.post('groupmembersallsss',{
                groupid:groupid,
            });
            return groupmembersallqqq.data;
        }
        newmessageadds  = async (userInfo,userids,postids) => {
            const newmessageaddsqqq = await Axios.post('newmessageaddsss',{
                messages:userInfo.messages,
                userids:userids,
                postids:postids,
            });
            return newmessageaddsqqq.data;
        }
        makeadmisads  = async (userids,groupsids) => {
            const makeadmisadswww = await Axios.post('makeadmisadsss',{
                userids:userids,
                groupsids:groupsids,
            });
            return makeadmisadswww.data;
        }
        adminpremissionlist  = async (groupsids,storageid) => {
            const adminpremissionqqq = await Axios.post('adminpremissionsss',{
                groupsids:groupsids,
                storageid:storageid,
            });
            return adminpremissionqqq.data;
        }
        removeadmisads  = async (userids,groupsids) => {
            const removeadmisadsss = await Axios.post('removeadmisadsqqq',{
                userids:userids,
                groupsids:groupsids,
            });
            return removeadmisadsss.data;
        }
        removeadmisgrouplist  = async (userids,groupsids) => {
            const removeadmisgrouplistsss = await Axios.post('removeadmisgrouplistqqq',{
                userids:userids,
                groupsids:groupsids,
            });
            return removeadmisgrouplistsss.data;
        }
        Blockadmisgroupqqq  = async (userids,groupsids) => {
            const Blockadmisgroupqqqsss = await Axios.post('Blockadmisgroupqqqwww',{
                userids:userids,
                groupsids:groupsids,
            });
            return Blockadmisgroupqqqsss.data;
        }
        allmemberslist = async (pageNumber) => {
             const allmemberslistsss = await Axios.get(`allmemberslistqqq/${pageNumber}`);
            return allmemberslistsss.data;
        }
        profilecoverphoto = async (userInfo,usersids) => {
            const formData = new FormData();
            formData.append('avatar',userInfo.file);
            formData.append('usersids',usersids);
            const profilecoverphotosss = await Axios.post('profilecoverphotoqqq',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return profilecoverphotosss.data;
        }
        profilephotoupdatelist = async (userInfo,usersids) => {
            const formData = new FormData();
            formData.append('avatar',userInfo.file);
            formData.append('usersids',usersids);
            const profilephotoupdatelistqqq = await Axios.post('profilephotoupdatelistsss',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return profilephotoupdatelistqqq.data;
        }
        gpostlistfeeds = async (pageNumber,storageid) => {
            const gpostlistfeedsqqq = await Axios.get(`gpostlistfeedssss/${pageNumber}/${storageid}`);
            return gpostlistfeedsqqq.data;
        }
        
        HideCommentlistfeeds = async (feedsids) => {
            const HideCommentlistfeedsqqq = await Axios.post('HideCommentlistfeedsss',{
                feedsids:feedsids,
            });
            return HideCommentlistfeedsqqq.data;
        }
        newprofileupdatelist = async (userInfo,registerid,sdate) => {
          
            const newprofileupdatelistsss = await Axios.post('newprofileupdatelistqqq',{
                name:userInfo.name,
                dob:sdate,
                sex:userInfo.sex,
                city:userInfo.city,
                country:userInfo.country,
                phone:userInfo.phone,
                about:userInfo.about,
                facebook:userInfo.facebook,
                twitter:userInfo.twitter,
                linkedin:userInfo.linkedin,
                registerid:registerid,
            });
            return newprofileupdatelistsss.data;
        }
        // Inboxlistsss = async (userids) => {
        //     const Inboxlistssswww = await Axios.post('Inboxlistsssqqq',{
        //         userids:userids,
        //     });
        //     return Inboxlistssswww.data;
        // }
        followlist = async (profileuserids,storageid) => {
            const followlistqqq = await Axios.post('followlistsss',{
                profileuserids:profileuserids,
                storageid:storageid,
            });
            return followlistqqq.data;
        }
        joinsfollowlist = async (profileuserids,userids) => {
            const joinsfollowlistqqq = await Axios.post('joinsfollowlistsss',{
                profileuserids:profileuserids,
                userids:userids,
            });
            return joinsfollowlistqqq.data;
        }
        joinsfollowlistsec = async (followuserids,userids) => {
            const joinsfollowlistsecqqq = await Axios.post('joinsfollowlistsecsss',{
                followuserids:followuserids,
                userids:userids,
            });
            return joinsfollowlistsecqqq.data;
        }
        leavefollowlist = async (profileuserids,userids) => {
            const leavefollowlistqqq = await Axios.post('leavefollowlistsss',{
                profileuserids:profileuserids,
                userids:userids,
            });
            return leavefollowlistqqq.data;
        }
        followlistcount = async (profileuserids) => {
            const followlistcountqqq = await Axios.post('followlistcountsss',{
                profileuserids:profileuserids,
            });
            return followlistcountqqq.data;
        }
        followinglistcount = async (profileuserids) => {
            const followinglistcountqqq = await Axios.post('followinglistcountsss',{
                profileuserids:profileuserids,
            });
            return followinglistcountqqq.data;
        }
        grouplistcount = async (profileuserids) => {
            const grouplistcountqqq = await Axios.post('grouplistcountsss',{
                profileuserids:profileuserids,
            });
            return grouplistcountqqq.data;
        }
        galeryviewsall  = async (profileusers) => {
            const galeryviewsallqqq = await Axios.post('galeryviewsallsss',{
                profileusers:profileusers,
            });
            return galeryviewsallqqq.data;
        }
        gallerysadds = async (newfeed,profileusers) => {
            const formData = new FormData();
            formData.append('avatar',newfeed.file);
            formData.append('profileusers',profileusers);
            const gallerysaddsqqq = await Axios.post('gallerysaddsss',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return gallerysaddsqqq.data;
        }
        deleteprofilegalleryall  = async (profileids) => {
            const deleteprofilegalleryallqqq = await Axios.post('deleteprofilegalleryallsss',{
                profileids:profileids,
            });
            return deleteprofilegalleryallqqq.data;
        }
        Advertsviewslist  = async (profileids) => {
            const Advertsviewslistqqq = await Axios.post('Advertsviewslistsss',{
                profileids:profileids,
            });
            return Advertsviewslistqqq.data;
        }
        Advertsviewslistbusiness  = async (profileids) => {
            const Advertsviewslistbusinessqqq = await Axios.post('Advertsviewslistbusinesss',{
                profileids:profileids,
            });
            return Advertsviewslistbusinessqqq.data;
        }
        viewpaymentdetails  = async (storageid) => {
            const viewpaymentdetailsqqq = await Axios.post('viewpaymentdetailsss',{
                storageid:storageid,
            });
            return viewpaymentdetailsqqq.data;
        }
        allclassifiedssslist = async (pageNumber) => {
            const allclassifiedlist = await Axios.get(`Allclassifieds/${pageNumber}`);
            return allclassifiedlist.data;
        }
        allplatinumlist = async (pageNumber) => {
            const allplatinumlistqqq = await Axios.get(`allplatinumlistwww/${pageNumber}`);
            return allplatinumlistqqq.data;
        }
        allGoldlist = async (pageNumber) => {
            const allGoldlistqqq = await Axios.get(`allGoldlistsss/${pageNumber}`);
            return allGoldlistqqq.data;
        }
        allSilverlist = async (pageNumber) => {
            const allSilverlistsss = await Axios.get(`allSilverlistqqq/${pageNumber}`);
            return allSilverlistsss.data;
        }
        allbussinesslist = async (pageNumber) => {
            const allbussinesslistsss = await Axios.get(`Allbusines/${pageNumber}`);
            return allbussinesslistsss.data;
        }
        Activemembersss = async (storageid) => {
            const Activemember = await Axios.post('Activememberqqq',{
                storageid:storageid,
            });
            return Activemember.data;
        }
        follwerssslistqqq = async (profileids) => {
            const follwersss = await Axios.post('follwerssslist',{
                profileids:profileids,
            });
            return follwersss.data;
        }
        follwinglistqqq = async (profileids) => {
            const follwinglistqqqsss = await Axios.post('follwinglistsss',{
                profileids:profileids,
            });
            return follwinglistqqqsss.data;
        }
        follwerloginsall = async (loginsids) => {
            const follwerloginsallqqq = await Axios.post('follwerloginsallsss',{
                loginsids:loginsids,
            });
            return follwerloginsallqqq.data;
        }
        messageallsss = async (pageNumber,profileids) => {
            // const messageallsssqqq = await Axios.post('messageallssswww',{
            //     profileids:profileids,
            // });
            // return messageallsssqqq.data;
            const allclassifiedlist = await Axios.get(`messageallssswww/${pageNumber}/${profileids}`);
            return allclassifiedlist.data;
        
        }
        joinpremissionlist = async (groupfeedsids,storageid) => {
            const joinpremissionlistqqq = await Axios.post('joinpremissionlistwww',{
                groupfeedsids:groupfeedsids,
                storageid:storageid,
            });
            return joinpremissionlistqqq.data;
        }
        ApiBusinessAdslist = async (searchvalue) => {
            const allSilverlistsss = await Axios.get(`ApiBusinessAdslist/${searchvalue}`);
            return allSilverlistsss.data;
        }
        ApiPeoplelist = async (searchvalue) => {
            const ApiPeoplelistsss = await Axios.get(`ApiPeoplelistqqq/${searchvalue}`);
            return ApiPeoplelistsss.data;
        }
        ApiBusinessDirectorylist = async (searchvalue) => {
            const ApiBusinessDirectorylistsss = await Axios.get(`ApiBusinessDirectorylistqqq/${searchvalue}`);
            return ApiBusinessDirectorylistsss.data;
        }
        ApiNewsfeedlist = async (searchvalue) => {
            const ApiNewsfeedlistsss = await Axios.get(`ApiNewsfeedlistqqq/${searchvalue}`);
            return ApiNewsfeedlistsss.data;
        }
        ApiGroupslist = async (searchvalue) => {
            const ApiGroupslistsss = await Axios.get(`ApiGroupslistqqq/${searchvalue}`);
            return ApiGroupslistsss.data;
        }
        follownotifactionlist = async (storageid) => {
            const follownotifactionlistqqq = await Axios.post('follownotifactionlistsss',{
                storageid:storageid,
            });
            return follownotifactionlistqqq.data;
        }
        follownotifactioncount = async (storageid) => {
            const follownotifactioncountqqq = await Axios.post('follownotifactioncountsss',{
                storageid:storageid,
            });
            return follownotifactioncountqqq.data;
        }
        notifactionupdatelist = async (storageid) => {
            const notifactionupdatelistqqq = await Axios.post('notifactionupdatelistsss',{
                storageid:storageid,
            });
            return notifactionupdatelistqqq.data;
        }
        messagenotifactionlist = async (pageNumber,storageid) => {
            const messagenotifactionlistqqq = await Axios.get(`messagenotifactionlistsss/${pageNumber}/${storageid}`);
            return messagenotifactionlistqqq.data;

            // const messagenotifactionlistqqq = await Axios.post('messagenotifactionlistsss',{
            //     storageid:storageid,
            // });
            // return messagenotifactionlistqqq.data;
        }
        newmessageaddsreplay  = async (userInfo,userids,postids,messagesid) => {
            const newmessageaddsreplayqqq = await Axios.post('newmessageaddsreplaysss',{
                messages:userInfo.messages,
                userids:userids,
                postids:postids,
                messagesid:messagesid,
            });
            return newmessageaddsreplayqqq.data;
        }
        feedlistsss = async (storageid) => {
            const feedlistssswww = await Axios.post('feedlistsssqqq',{
                storageid:storageid,
            });
            return feedlistssswww.data;
        }
        createpages = async (userInfo,storageid,fileempty) => {
            const formData = new FormData();
            formData.append('name',userInfo.name);
            formData.append('avatar',userInfo.file);
            formData.append('company',userInfo.company);
            formData.append('website',userInfo.website);
            formData.append('industry',userInfo.industry);
            formData.append('companysize',userInfo.companysize);
            formData.append('companytype',userInfo.companytype);
            formData.append('tags',userInfo.tags);
            formData.append('storageid',storageid);
            formData.append('fileempty',fileempty);
            const createpagesqqq = await Axios.post('createpagesss',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return createpagesqqq.data;
        }
        companyviewsall = async (pageid) => {
            const companyviewsallqqq = await Axios.post('companyviewsallsss',{
                pageid:pageid,
            });
            return companyviewsallqqq.data;
        }
        companycoverphoto = async (userInfo,usersids) => {
            const formData = new FormData();
            formData.append('avatar',userInfo.file);
            formData.append('usersids',usersids);
            const companycoverphotosss = await Axios.post('companycoverphotoqqq',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return companycoverphotosss.data;
        }
        
        companyphotoupdatelist = async (userInfo,usersids) => {
            const formData = new FormData();
            formData.append('avatar',userInfo.file);
            formData.append('usersids',usersids);
            const companyphotoupdatelistsss = await Axios.post('companyphotoupdatelistsss',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return companyphotoupdatelistsss.data;
        }
    
      
        
    render(){
        const contextValue = {
            
            rootState:this.state,

            loginUser:this.loginUser,
            RegisterUser:this.RegisterUser,
            ForgetMailpassword:this.ForgetMailpassword,
            isLoggedIn:this.isLoggedIn,
            logoutUser:this.logoutUser,


            pricesall:this.pricesall,
            memberlistall:this.memberlistall,
            changeplanlist:this.changeplanlist,
            upgradeplanslist:this.upgradeplanslist,

            memberstripe:this.memberstripe,
            memberstripeupgrade:this.memberstripeupgrade,
            stripesuccesslist:this.stripesuccesslist,
            paypalsuccessbook:this.paypalsuccessbook,

            viewuserprofile:this.viewuserprofile,
            loginUserimage:this.loginUserimage,

            newbookadd:this.newbookadd,
            newbookupdate:this.newbookupdate,
            newbusinessadd:this.newbusinessadd,
            businessupdate:this.businessupdate,
            classifiedspreview:this.classifiedspreview,
            Businesspreview:this.Businesspreview,

            PlatinumgoldAds:this.PlatinumgoldAds,
            GoldgoldAds:this.GoldgoldAds,
            paltinumrandAds:this.paltinumrandAds,
            PlatinumAds:this.PlatinumAds,
            GoldAds:this.GoldAds,
            SilverAds:this.SilverAds,
            BronzeAds:this.BronzeAds,
            businessAds:this.businessAds,
            GoldAdslatest:this.GoldAdslatest,
            SilverAdslatest:this.SilverAdslatest,
            membersallAds:this.membersallAds,

            profileupdatelist:this.profileupdatelist,
            newfeedsadd:this.newfeedsadd,
            latestfeedslist:this.latestfeedslist,
            LikesActiveAdd:this.LikesActiveAdd,
            newcommentadd:this.newcommentadd,
            commentlistall:this.commentlistall,
            Replayall:this.Replayall,
            Replayviewsall:this.Replayviewsall,
            latestlikeslist:this.latestlikeslist,
            newfeedsupdatelist:this.newfeedsupdatelist,
            deletebooksssqqq:this.deletebooksssqqq,
            reportadds:this.reportadds,

            newcommentupdatelist:this.newcommentupdatelist,
            deletecomments:this.deletecomments,
            newreplayupdateqqq:this.newreplayupdateqqq,
            deletesreplaylistall:this.deletesreplaylistall,
            getPhotoslist:this.getPhotoslist,

            creategroups:this.creategroups,
            allgroupslist:this.allgroupslist,
            mygroupslist:this.mygroupslist,
            viewgroupslists:this.viewgroupslists,
            coverphotoupdatelist:this.coverphotoupdatelist,
            groupphotoupdatelist:this.groupphotoupdatelist,
            joinsgroupslists:this.joinsgroupslists,
            joinsalllist:this.joinsalllist,
            leavegroupslist:this.leavegroupslist,
            newfeedsgroupadd:this.newfeedsgroupadd,
            groupannouncementadd:this.groupannouncementadd,
            announcmentslist:this.announcmentslist,
            gpostlist:this.gpostlist,
            groupfeedsupdatelist:this.groupfeedsupdatelist,
            deletegrouppostsss:this.deletegrouppostsss,
            reportpostupdatelist:this.reportpostupdatelist,
            setfeaturedslist:this.setfeaturedslist,

            latestlikeslistgroup:this.latestlikeslistgroup,
            newcommentaddgroup:this.newcommentaddgroup,
            commentlistallgroup:this.commentlistallgroup,
            Replayallgroup:this.Replayallgroup,
            Replayviewsallgroups:this.Replayviewsallgroups,
            LikesActiveAddgroup:this.LikesActiveAddgroup,
            deletecommentsgroups:this.deletecommentsgroups,
            deletesreplaylistallgroups:this.deletesreplaylistallgroups,
            newcommentupdatelistgroup:this.newcommentupdatelistgroup,
            newreplayupdategroupsss:this.newreplayupdategroupsss,
            removefeaturedsmmmlist:this.removefeaturedsmmmlist,
            HideCommentlist:this.HideCommentlist,
            listannoumcementsqqq:this.listannoumcementsqqq,
            updategroups:this.updategroups,
            groupsgallerysadds:this.groupsgallerysadds,
            groupsgaleryviewsall:this.groupsgaleryviewsall,
            deletegalleryall:this.deletegalleryall,
            groupmembersall:this.groupmembersall,
            newmessageadds:this.newmessageadds,
            makeadmisads:this.makeadmisads,
            adminpremissionlist:this.adminpremissionlist,
            removeadmisads:this.removeadmisads,
            removeadmisgrouplist:this.removeadmisgrouplist,
            Blockadmisgroupqqq:this.Blockadmisgroupqqq,
            allmemberslist:this.allmemberslist,

            profilecoverphoto:this.profilecoverphoto,
            profilephotoupdatelist:this.profilephotoupdatelist,
            gpostlistfeeds:this.gpostlistfeeds,
            HideCommentlistfeeds:this.HideCommentlistfeeds,
            newprofileupdatelist:this.newprofileupdatelist,
            //Inboxlistsss:this.Inboxlistsss,
            followlist:this.followlist,
            joinsfollowlist:this.joinsfollowlist,
            joinsfollowlistsec:this.joinsfollowlistsec,
            leavefollowlist:this.leavefollowlist,
            followlistcount:this.followlistcount,
            followinglistcount:this.followinglistcount,
            grouplistcount:this.grouplistcount,
            galeryviewsall:this.galeryviewsall,
            gallerysadds:this.gallerysadds,
            deleteprofilegalleryall:this.deleteprofilegalleryall,
            Advertsviewslist:this.Advertsviewslist,
            Advertsviewslistbusiness:this.Advertsviewslistbusiness,

            viewpaymentdetails:this.viewpaymentdetails,

            allclassifiedssslist:this.allclassifiedssslist,
            allplatinumlist:this.allplatinumlist,
            allGoldlist:this.allGoldlist,
            allSilverlist:this.allSilverlist,
            allbussinesslist:this.allbussinesslist,
            Activemembersss:this.Activemembersss,

            follwerssslistqqq:this.follwerssslistqqq,
            follwinglistqqq:this.follwinglistqqq,
            follwerloginsall:this.follwerloginsall,
            messageallsss:this.messageallsss,

            joinpremissionlist:this.joinpremissionlist,

            ApiBusinessAdslist:this.ApiBusinessAdslist,
            ApiPeoplelist:this.ApiPeoplelist,
            ApiBusinessDirectorylist:this.ApiBusinessDirectorylist,
            ApiNewsfeedlist:this.ApiNewsfeedlist,
            ApiGroupslist:this.ApiGroupslist,
            
            follownotifactionlist:this.follownotifactionlist,
            follownotifactioncount:this.follownotifactioncount,
            notifactionupdatelist:this.notifactionupdatelist,
            messagenotifactionlist:this.messagenotifactionlist,
            newmessageaddsreplay:this.newmessageaddsreplay,
            feedlistsss:this.feedlistsss,

            createpages:this.createpages,
            companyviewsall:this.companyviewsall,
            companycoverphoto:this.companycoverphoto,
            companyphotoupdatelist:this.companyphotoupdatelist,
           

           

        }
        return(
            <MyContext.Provider value={contextValue}>
                {this.props.children}
            </MyContext.Provider>
        )
    }

}

export default MyContextProvider;