import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import ReCAPTCHA from "react-google-recaptcha";
import Loader from 'react-loader-spinner';
import {toast} from "react-toastify";
import { CAPTCHA_SITE_KEY } from '../contexts/Apilinks';
import { useHistory } from "react-router-dom";

const TEST_SITE_KEY = CAPTCHA_SITE_KEY;
const DELAY = 1500;


function Registerpage(props) {
  
  let history = useHistory();

  const {RegisterUser,isLoggedIn,pricesall} = useContext(MyContext);
  const [isprice, setisprice] = useState([]);
  const [ismemberprice, setmemberprice] = useState();
  const [ispricelist, setispricelist] = useState(false);
  const [value, setvalue] = useState("[empty]");
  const [load, setload] = useState(false);
  const [recaptchaLoaded, setrecaptchaLoaded] = useState(false);


  const [loadinglogin, setloadinglogin] = useState(false);
  const _reCaptchaRef = React.createRef()
  const [userInfo, setuserInfo] = useState({
    name: '',
    email: '',
    password: '',
    conformpassword: '',
    membership: '',
});
const onChangeValue = (e) => {
    setuserInfo({
        ...userInfo,
            [e.target.name]:e.target.value
    });
} 

  useEffect( () => {
    setTimeout(() => {
      setload(true );
    }, DELAY);
    console.log("didMount - reCaptcha Ref-", _reCaptchaRef);
    prices();
   }, []); 

   
   const prices = async() =>{
    try {
           const pricesresult = await pricesall();
           if(pricesresult.success === 1){
                  console.log(pricesresult.pricelist[0].Silver);
                  setisprice(pricesresult.pricelist[0]) ;
                  setispricelist(true) ;
           }
    } catch (error) { throw error;}
}
 
  const handleChange = (value) => {
    setvalue(value);
   
  };
  const asyncScriptOnLoad = () => {
    setrecaptchaLoaded(true);
    _reCaptchaRef.current.execute();
  };
  
 const Register = async (event) => {

  setloadinglogin(true);
  
  event.preventDefault();
  event.persist();
  if(userInfo.password !== userInfo.conformpassword){
    toast.error('password and confirm password does not match.',{
      position: "top-center",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
    });  
    setloadinglogin(false);
    return;
  }
  const data = await RegisterUser(userInfo,userInfo.recaptcha_response.value,ismemberprice);
  console.log(data.memberid)
  if(data.success === 1){
    setloadinglogin(false);
    event.target.reset(); 
    localStorage.setItem('loginToken',JSON.stringify(data.memberid));       
           
    event.target.reset(); 
    await isLoggedIn();
      if(userInfo.membership === 'Bronze'){
        history.push(`Myaccount`);
        
      } 
      if(userInfo.membership !== 'Bronze'){
        history.push(`/Payment/${data.memberid}`);
      } 
      

  }
  else{
    setloadinglogin(false);
    toast.error( data.msg, {
        position: "top-center",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
      });  
}

}
    
const MemberMode = (event) => {
  console.log(event.target.value);
  if(event.target.value === 'Silver')
  { 
         setmemberprice(isprice.Silver);
  }
  if(event.target.value === 'Gold')
  { 
         setmemberprice(isprice.Gold);
  }
  if(event.target.value === 'Platinum')
  { 
         setmemberprice(isprice.Platinum);
  }
 if(event.target.value === 'Bronze')
  { 
         setmemberprice(isprice.Bronze);
  }

} 
     
        //const { value, load, recaptchaLoaded,isLoading } = state || {};
        return(
            <form onSubmit={Register} className="login-forms">
             
                <div className="clearfix"></div>
            <div className="form-row">
            
                <div className="form-group col-md-12">
                  
                    <input type="text" name="name" value={userInfo.name} onChange={onChangeValue}className="form-control" placeholder="Name" required   />
                   
                </div>
                <div className="form-group col-md-12">
                  
                    <input type="email" name="email" value={userInfo.email} onChange={onChangeValue}className="form-control" placeholder="Email" required />
                </div>
                <div className="form-group col-md-12">
                    
                    <input type="password" name="password" value={userInfo.password} onChange={onChangeValue}className="form-control" placeholder="Password"  required />
                    
                </div>
                <div className="form-group col-md-12">
                   
                    <input type="password" name="conformpassword" value={userInfo.conformpassword} onChange={onChangeValue} className="form-control" placeholder="Confirm Password" required />
                </div>
                <div className="form-group col-md-12">
                  
                    <select  name="membership" value={userInfo.membership}   className="wqsss form-control" onChange={onChangeValue} onClick={MemberMode} required>
                                  <option value=""> Choose the membership type </option>
                                  <option value="Bronze"> Bronze - Free </option>
                                  <option value="Silver"> Silver - $ {isprice.Silver} </option>
                                  <option value="Gold"> Gold - $ {isprice.Gold} </option>
                                  <option value="Platinum"> Platinum - $ {isprice.Platinum} </option>
                                  
                    </select>
                </div>
           
                <input type="hidden" name="recaptcha_response" ref={(val) => userInfo.recaptcha_response = val} className="form-control" placeholder="Your password here..." required  defaultValue={value} />

                {load && (
          <ReCAPTCHA
            style={{ display: "inline-block" }}
            theme="dark"
            size="invisible"
            ref={_reCaptchaRef}
            sitekey={TEST_SITE_KEY}
            onChange={handleChange}
            asyncScriptOnLoad={asyncScriptOnLoad}
          />
        )}

      {ispricelist === true ? <> 
                <div className="form-group col-md-12 ">
                {loadinglogin ?
                            <>
                                   <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                            </>
                         :  <>
                         {isprice !== '' ? 
                            <button type="submit" className="btn btn-style-two" style={{marginBottom: "37px"}} disabled={!recaptchaLoaded}> Register</button>
                        :null}
                      </>
                }
                </div>
                </> : null }
            </div>
           
        </form>  
        );
    }

export default Registerpage;