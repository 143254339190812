import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';

import Loader from 'react-loader-spinner';

import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function ReplayEdit(props) {
       const {newreplayupdateqqq} = useContext(MyContext);
      
      
   
       const [userInforeplay, setuserInforeplay] = useState({
              replay: props.feedcomment,
          });
     
     
       const [loadingedit, setloadingedit] = useState(false);
       

       const onChangeValue = (e) => {
              setuserInforeplay({
                  ...userInforeplay,
                      [e.target.name]:e.target.value
              });
             
       }    
      
     
       const newreplayupdate = async(event) =>{
              try {
                     setloadingedit(true);
                     event.preventDefault();
                     event.persist();
                     const newreplayupdatesss = await newreplayupdateqqq(userInforeplay,props.feedid,);
                     if(newreplayupdatesss.success === 1){
                            setloadingedit(false);
                            event.target.reset(); 
                            props.close();
                            props.refresh(props.replaypostid);
                            
                            props.morecomments(false);
                     }
              } catch (error) { throw error;}      
       }



  return (
  
       <>
            <div className="">
            <div className="feedswww">
                   <h3 className="create-post"> Edit Reply Comment </h3>
                   
                     <form onSubmit={newreplayupdate} className="login-forms">
                            <textarea  name="replay" value={userInforeplay.replay} className=" col-md-12" rows="3"    onChange={onChangeValue} required />
                     <div className="clearfix" style={{marginTop:"27px"}}></div>
                 

                    
                    
                    
                  
                           
                            {loadingedit ?
                            <>
                                   <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                            </>
                            : 
                                   <div className="form-group col-sm-12 text-right">
                                          <button type="submit" className="db-tit-btn2"> Edit  </button>
                                          
                                   </div>
                             }
                 
                     </form>
                     </div>
                     </div>

       </>
   
  );
}

export default ReplayEdit;