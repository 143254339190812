import React,{useContext,useState,useEffect} from 'react';

import { MyContext } from '../contexts/MyContext';

import Loader from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import userphotos from '../Img/user_profile.jpg';
import Moment from 'react-moment';

import Popup from 'reactjs-popup';
import CommentEditgroup from './CommentEditgroup';
import ReplayEditgroup from './ReplayEditgroup';

import {toast} from "react-toastify";

import { confirmAlert } from 'react-confirm-alert'; 
import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function CommentgroupFeed(props) {

       const {newcommentaddgroup,commentlistallgroup,Replayallgroup,Replayviewsallgroups,LikesActiveAddgroup,latestlikeslistgroup,deletecommentsgroups,deletesreplaylistallgroups} = useContext(MyContext);
    
       useEffect( () => {
              latestfeedslikes(props.commentuserid);
       }, []);

      
       const [userInfo, setuserInfo] = useState({
              comment: '',
              file:'',
          });
       const [replayuserInfo, setreplayuserInfo] = useState({
              replay:'',
          });
       
       const [isreplayactive, setreplayactive] = useState(false);
       const [loadingadd, setloadingadd] = useState(false);
       const [fileempty, setfileempty] = useState(false);
       const [filetemp, setfiletemp] = useState(null);
       const [invalidImage, setinvalidImage] = useState(null);
       const [islatestfeeds, setlatestfeeds] = useState([]);
       
       const onChangeValue = (e) => {
              setuserInfo({
                  ...userInfo,
                      [e.target.name]:e.target.value
              });
             
       }    
       const onChangeValuereplay = (e) => {
              setreplayuserInfo({
                  ...replayuserInfo,
                      [e.target.name]:e.target.value
              });
             
       }    
       
      
       const [iscommentall, setcommentall] = useState([]);
       const [iscommentactive, setcommentactive] = useState(false);
       
    
       const commentlist = async(postids) =>{
              try {
                   
                     setcommentactive(iscommentactive === false ? true : false);
                     const commentlistallsss = await commentlistallgroup(postids);
                     if(commentlistallsss.success === 1){
                            console.log('comment all',commentlistallsss.commentlist);
                            setcommentall(commentlistallsss.commentlist);
                           
                          
                     }
              } catch (error) { throw error;}      
       } 
        const commentlistaddlist = async(postids) =>{
              try {
                   
                     const commentlistallsss = await commentlistallgroup(postids);
                     if(commentlistallsss.success === 1){
                            console.log('comment all',commentlistallsss.commentlist);
                            setcommentall(commentlistallsss.commentlist);
                            setlikescommentsactive(postids);
                     }
              } catch (error) { throw error;}      
       } 
       const [isreplayall, setreplayall] = useState([]);
       const Replayviews = async(postids) =>{
              try {
                 
                     const Replayviewsss = await Replayviewsallgroups(postids);
                     if(Replayviewsss.success === 1){
                            console.log('Replay view all',Replayviewsss.Replayviewslist);
                            setreplayall(Replayviewsss.Replayviewslist);
                            
                     }
              } catch (error) { throw error;}    
       }
       const [isreplaycommentids, setreplaycommentids] = useState(null);
       const Replayactive = async(commentids) =>{
              setreplayactive(isreplayactive === false ? true : false);
              setreplaycommentids(commentids);
       }
       const Replay = async(event) =>{
                     try {
                            event.preventDefault();
                            event.persist();
                             const Replaysss = await Replayallgroup(replayuserInfo.feed_comment_id.value,replayuserInfo.commentuserid.value,replayuserInfo);
                             if(Replaysss.success === 1){
                                   event.target.reset(); 
                                    Replayviews(replayuserInfo.feed_comment_id.value);
                                    setreplayuserInfo({
                                          ...replayuserInfo,
                                          replay:'',
                                      });
                             }
                     } catch (error) { throw error;}      
              } 
   
              const [islikescoments, setlikescoments] = useState();
              const [islikescommentsactive, setlikescommentsactive] = useState('');
       const newfeedscomment = async(event) =>{
              try {
                      setloadingadd(true);
                      event.preventDefault();
                      event.persist();
                      const newcomment = await newcommentaddgroup(userInfo,props.commentuserid,userInfo.fileempty.value,props.postids);
                      console.log(newcomment.commesss[0].comments);
                      if(newcomment.success === 1){
                             setloadingadd(false);
                             event.target.reset(); 
                             setuserInfo({
                                   ...userInfo,
                                   comment:'',
                                       file:'',
                               });
                               setfileempty(false);
                               setfiletemp(null);
                               setinvalidImage(null);
                               commentlistaddlist(props.postids);
                               setlikescoments(newcomment.commesss[0].comments)
                     }else{
                            setloadingadd(false); 
                            toast.error('Please try again later', {
                                   position: "top-center",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                                 }); 
                     }
              } catch (error) { throw error;}      
       }


       const resetFile = (event) => {
              event.preventDefault();
              setfiletemp(null)
            }

            let reader = new FileReader();

   const handleChange = (event) => {
       const imageFile = event.target.files[0];
             const imageFilname = event.target.files[0].name;

             if (!imageFile) {
              setinvalidImage('Please select image.');
               return false;
             }
         
             if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif)$/)) {
              setinvalidImage('Please select valid image JPG,JPEG,PNG');
               return false;
             }
             reader.onload = (e) => {
               const img = new Image();
               img.onload = () => {

//----------------------------- Resize img code -------------------------------------------------
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);

                var MAX_WIDTH = 437;
                var MAX_HEIGHT = 437;
                var width = img.width;
                var height = img.height;

                if (width > height) {
                  if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                  }
                } else {
                  if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                  }
                }
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
                ctx.canvas.toBlob((blob) => {
                  const file = new File([blob], imageFilname, {
                      type: 'image/jpeg',
                      lastModified: Date.now()
                  });
                  setuserInfo({
                     ...userInfo,
                     file:file,
                })
                }, 'image/jpeg', 1);


//-------------------------------------------------------------------------------------
              setfiletemp( URL.createObjectURL(imageFile))
              setfileempty(true);
              setinvalidImage(null)
              };
               img.onerror = () => {
                     setinvalidImage('Invalid image content.');
                 return false;
               };
               //debugger
               img.src = e.target.result;
             };
             reader.readAsDataURL(imageFile);

     }; 

     const [isviewlikes, setviewlikes] = useState([]);
     const latestfeedslikes = async(storageid) =>{
       try {
              const latestfeedsqqq = await latestlikeslistgroup(storageid);
              if(latestfeedsqqq.success === 1){
                     console.log('latestlikes',latestfeedsqqq.likeslist);
                     setviewlikes(latestfeedsqqq.likeslist);
                     
              }
       } catch (error) { throw error;}      
} 

     const [islikes, setlikes] = useState(false);
     const [islikesloads, setlikesloads] = useState();
     const [islikesloadsactive, setlikesloadsactive] = useState('');
     const LikesActive = async(feedid,likeuserid) =>{
                  try {
                         const LikesActivesss = await LikesActiveAddgroup(feedid,likeuserid);
                         console.log(LikesActivesss);
                         if(LikesActivesss.success === 1){
                                console.log(LikesActivesss.counsss[0].likes);
                                setlikesloads(LikesActivesss.counsss[0].likes);
                                setlikes(true);
                                setlikesloadsactive(feedid);
                         }
                  } catch (error) { throw error;}      
           }
           const [ismorecomments, setmorecomments] = useState(false);
           const [ismorecommentsids, setmorecommentsids] = useState(false);

          
                 
                  const moreoptionscomments = (ids) =>{
                         setmorecommentsids(ids)
                         setmorecomments(ismorecomments === false ? true : false);
                         
                  }

                  const [ismorereplays, setmorereplays] = useState(false);
                  const [ismorereplaysids, setmorereplaysids] = useState(false);

                      const moreoptionsreplay = (ids) =>{
                         setmorereplaysids(ids)
                         setmorereplays(ismorereplays === false ? true : false);
                         
                  }

                  const deletes = (feedids,postids) => {
                     confirmAlert({
                       title: 'Confirm to Delete ',
                       message: 'Are you sure to do this.',
                       buttons: [
                         {
                           label: 'Yes',
                           onClick: () => deletebooksgroups(feedids,postids)
                         },
                         {
                           label: 'No',
                         }
                       ]
                     })
                   };     
                   const deletebooksgroups = async (feedids,postids) => {
                         
                     try {
                            const deletebooksss = await deletecommentsgroups(feedids,postids);
                            if(deletebooksss.success === 1){
                                   commentlist(props.postids);
                                   setcommentactive(true);
                                   
                            }
                     } catch (error) { throw error;}      
              
              
              }
                    const deletesreplay = (feedids,replaypostids) => {
                     confirmAlert({
                       title: 'Confirm to Delete ',
                       message: 'Are you sure to do this.',
                       buttons: [
                         {
                           label: 'Yes',
                           onClick: () => deletesreplaylist(feedids,replaypostids)
                         },
                         {
                           label: 'No',
                         }
                       ]
                     })
                   };     
                   const deletesreplaylist = async (feedids,replaypostids) => {
                         
                     try {
                            const deletesreplaylistsss = await deletesreplaylistallgroups(feedids);
                            if(deletesreplaylistsss.success === 1){
                                   Replayviews(replaypostids)
                                   
                            }
                     } catch (error) { throw error;}      
              
              
              }
                  
  return (
  
       <>
            <div className="post-comt-box">
            <div className="bbb2">
            <div className="yyy9"> 
              {isviewlikes.map((itemlikes, index) => ( 
                     <div key={itemlikes.glike_id} style={{padding:0}}>
                     {props.postids === itemlikes.glike_feed_ids ?  <>
                                   <FontAwesomeIcon icon={['fas','thumbs-up']} className="activelikeswww"  />
                                   
                             {props.likecount === 0 ? null :
                              <ins className="liqqssswww">{props.likecount}  </ins>
                             }
                     
                     </>
                     : null}

                     </div>
              ))}
         
              {islikes === true ? <>
                     <FontAwesomeIcon icon={['fas','thumbs-up']} className="activelikeswww"  /> 
                     </>
              :
                      <FontAwesomeIcon icon={['fas','thumbs-up']} className="enablelikeswww"  onClick={() => LikesActive(props.postids,props.commentuserid)} />
              }
                     
                    
                      {islikesloadsactive === props.postids ? <>  
                      
                            {islikesloads !== '' ? 
                              <ins className="liqqssswww"> {islikesloads} </ins>
                     : null }

                       </> : <>
                       
                       {props.likecount === 0  ? null :
                            <ins className="liqqssswww">  {props.likecount}</ins>
                     }
                     
                      </> }
                        
                    
            
              </div>
              <span className="bbb7"> 
              {props.commentstoogle === 0 ? <>
                     <FontAwesomeIcon icon={['fas','comment-dots']} className="enablelikes"  onClick={() => commentlist(props.postids)} />
                     {islikescommentsactive === props.postids ? <>  
                            {islikescoments !== '' ? 
                              <ins className="liqqssswww"> {islikescoments} </ins>
                     : null }

                       </> : <>
                       
                            {props.commentcount === 0 ? null :
                                   <ins className="comment-count"> {props.commentcount} </ins>
                            }
                      </> }
                      </> : 
                      
                      <h6 className="yyy15"> Turn Off Commenting </h6>
                      
                      
                      }
              </span>
              <div className="clearfix"></div>
           </div>
{/* -------------------------------------------------------------------------------------------------------------------------------------------- like -------------------------------------------------------------------------------------------------------------------------------------- */}  





{/* -------------------------------------------------------------------------------------------------------------------------------------------- display Comments userimage, date -------------------------------------------------------------------------------------------------------------------------------------- */}  
  
{iscommentactive === true ? <>  
            {iscommentall.map((itemcomments, index) => ( 
              <div className="col-md-12" key={itemcomments.gfeed_comment_id} style={{padding:0}}>
              <div className="col-md-2s"  style={{padding:0}}>
                     {itemcomments.profile_image !=='' ? 
                            <img src={BaseURLuploads+itemcomments.profile_image} alt="" className="rounded-circle mr-2 feeds2" />
                     :
                            <img src={userphotos} alt="" className="rounded-circle mr-2 feeds2"  />
                     }
                      </div>
                    
                    
                      <div className="col-md-10 bbb" style={{padding:0}}>
                                   <p> <b> {itemcomments.name} </b> <br />  <Moment fromNow>
                                   {itemcomments.gcdate} 
                                   </Moment> 
                                  
                                   {props.feedmemberid == props.commentuserid ? 
                                          <span onClick={()=>Replayactive(itemcomments.gfeed_comment_id)} style={{color:"#1da1f3",cursor:"pointer"}}> ~ Reply </span>
                                   : null }
                                   
                                   </p>
 {/* -------------------------------------------------------------------------------------------------------------------------------------------- Replay Comment -------------------------------------------------------------------------------------------------------------------------------------- */}  

                            {isreplayactive === true && itemcomments.gfeed_comment_id === isreplaycommentids ?     
                  <form onSubmit={Replay} className=""> 
                            <textarea  name="replay" value={replayuserInfo.replay} className=" col-md-12 bbb5" rows="2"  placeholder=" Reply "  onChange={onChangeValuereplay} required />
                              <input type="hidden" ref={(val) => replayuserInfo.feed_comment_id = val} value={itemcomments.gfeed_comment_id}/>
                              <input type="hidden" ref={(val) => replayuserInfo.commentuserid = val} value={props.commentuserid}/>
                            {loadingadd ?
                            <>
                                   <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                            </>
                            : 
                                   <div className="form-group col-sm-12 text-right">
                                          <button type="submit" className="db-tit-btn2qqq"> send </button>
                                          
                                   </div>
                             }
                   
                    
                     </form>
                     : null } 
                                
                            </div>
                      <div className="clearfix"></div>


{/* -------------------------------------------------------------------------------------------------------------------------------------------- display Comment post  and replay post and Edit -------------------------------------------------------------------------------------------------------------------------------------- */}

                
                      {itemcomments.gfeed_com_mem_id == props.commentuserid || props.feedmemberid  == props.commentuserid ?    
                     <div className="more-post-optns">
                     <FontAwesomeIcon icon={['fas','ellipsis-h']} className="enablelikes"  onClick={() => moreoptionscomments(itemcomments.gfeed_comment_id)} />
                     {ismorecomments === true && ismorecommentsids === itemcomments.gfeed_comment_id ? 
<ul>
       <Popup trigger={<li> <FontAwesomeIcon icon={['fas','user-edit']} className="enablelikes"/>Edit Post</li>} position="top left">
       {close => (
       <div className="feedspopups">
               <a className="close" onClick={close}>
              &times;
              </a>
             
              <CommentEditgroup feedid={itemcomments.gfeed_comment_id} feedcomment={itemcomments.gfeed_comm} image={itemcomments.gcimage} postids = {props.postids} refresh={()=>commentlist(props.postids)} close={close} commentactive={setcommentactive} morecomments={setmorecomments}/>

            
             
       </div>
       )}
       </Popup>
                                  
              <li onClick={()=>deletes(itemcomments.gfeed_comment_id,props.postids)}><FontAwesomeIcon icon={['fas','trash-alt']} className="enablelikes"/>Delete Post</li>							 
       
                            </ul>
                     : null}    
                      </div>
: null }
 <div className="col-md-10" style={{padding:0}}>
                     <div className="acomment-content">
                            <p>{itemcomments.gfeed_comm}</p>

                            {itemcomments.gcimage ? 
                            <div className="rtmedia-item-thumbnail">
                                   <img src={BaseURLuploads+itemcomments.gcimage} alt="feedsimages" />
                            </div>
                     : null }
                     </div>
                     {itemcomments.grep_acc === 1 ? 
                            <span className="acomment-content2sss" onClick={()=>Replayviews(itemcomments.gfeed_comment_id)} >  View Reply </span>
                     : null }
                    


       {isreplayall.map((itemreplays, index) => ( 
              <div className="col-md-12" key={itemreplays.greplay_id} style={{padding:0}}>
                     {itemcomments.gfeed_comment_id ===  itemreplays.greplaypost_id ? <> 
                     <div className="replsss">
                     <div className="col-md-2s"  style={{padding:0}}>
                     {itemreplays.profile_image !=='' ? 
                            <img src={BaseURLuploads+itemreplays.profile_image} alt="" className="rounded-circle mr-2 feeds2" />
                     :
                            <img src={userphotos} alt="" className="rounded-circle mr-2 feeds2"  />
                     }
                      </div>
                      <div className="col-md-10 bbb" style={{padding:0}}>
                                   <p> <b> {itemreplays.name} </b> <br />  <Moment fromNow>
                                   {itemreplays.grdate} 
                                   </Moment> </p>
                     </div>
                     </div>
                     


 
                     {itemreplays.greplaypost_mem_id == props.commentuserid ?    
                     <div className="more-post-optns">
                     <FontAwesomeIcon icon={['fas','ellipsis-h']} className="enablelikes"  onClick={() => moreoptionsreplay(itemreplays.greplay_id)} />
                     {ismorereplays === true && ismorereplaysids === itemreplays.greplay_id ? 
<ul>
       <Popup trigger={<li> <FontAwesomeIcon icon={['fas','user-edit']} className="enablelikes"/>Edit Post</li>} position="top left">
       {close => (
       <div className="feedspopups">
               <a className="close" onClick={close}>
              &times;
              </a>
              <ReplayEditgroup feedid={itemreplays.greplay_id} feedcomment={itemreplays.greplay_comment}   close={close} replaypostid={itemreplays.greplaypost_id} refresh={()=>Replayviews(itemreplays.greplaypost_id)} morecomments={setmorereplays}/> 

            
             
       </div>
       )}
       </Popup>
                                  
            <li onClick={()=>deletesreplay(itemreplays.greplay_id,itemreplays.greplaypost_id)}><FontAwesomeIcon icon={['fas','trash-alt']} className="enablelikes"/>Delete Reply </li>							
       
                            </ul>
                     : null}    
                      </div>
: null }


                     {itemreplays.greplaypost_id === itemcomments.gfeed_comment_id ? 
                     <div className="acomment-replay">
                            <p>{itemreplays.greplay_comment}</p>
                     </div>
                     : null }
                    </>: null }
              </div>
                    
       ))} 

      </div>
              </div>
            ))}


{/* -------------------------------------------------------------------------------------------------------------------------------------------- Create Comment post -------------------------------------------------------------------------------------------------------------------------------------- */}
<div  className="bbb4">
                     {props.userimgs !=='' ? 
                            <img src={BaseURLuploads+props.userimgs} alt="" className="rounded-circle mr-2 feeds2" />
                     :
                            <img src={userphotos} alt="" className="rounded-circle mr-2 feeds2"  />
                     }
                     <form onSubmit={newfeedscomment} className="">
                            <textarea  name="comment" value={userInfo.comment} className=" col-md-10" rows="2"  placeholder=" Post your comment "  onChange={onChangeValue} required />
                                   <div className="clearfix"></div>
                                   <div className="bbb3">
                                          <FontAwesomeIcon icon={['fas','image']} className="iconsss2" />
                                          <label className="fileContainer">
                                          <input type="file" name="file" className="form-control" placeholder="Title" onChange={handleChange} />
                                          </label>
                                   </div>
                                   {fileempty === true ? 
                                   <input type="hidden" name="fileempty" ref={(val) => userInfo.fileempty = val} className="form-control" defaultValue="imgactive" />
                                   : 
                                   <input type="hidden" name="fileempty" ref={(val) => userInfo.fileempty = val} className="form-control" defaultValue="imgempty" />
                                   }
                           
                  
                    
                            {loadingadd ?
                            <>
                                   <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                            </>
                            : 
                                   <div className="form-group  text-right">
                                          <button type="submit" className="btn2zzz"> Post  </button>
                                          
                                   </div>
                             }
                   
                    
                     </form>
                        
       </div>
</>: null}  
              </div>

       </>
   
  );
}

export default CommentgroupFeed;