
import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import Loader from 'react-loader-spinner';

import {toast} from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link } from "react-router-dom"; 
import userphotos from '../Img/user_profile.jpg';
import ContentLoader from "react-content-loader";
import covers from '../Img/covers.jpg';
import { useHistory } from "react-router-dom";
import {WEB_URL,WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURL = WEB_URL;
let BaseURLuploads = WEB_URL_UPLOAD;

function Groups(props) {
       let history = useHistory();
     
       const {creategroups,allgroupslist,mygroupslist,} = useContext(MyContext);
       const [loadingaddgroup, setloadingaddgroup] = useState(false);
       const [userInfo, setuserInfo] = useState({
        name: '',
        description: '',
        website: '',
    });
    const [isall, setisall] = useState(false);
    const [ismy, setismy] = useState(false);
    const [iscreate, setiscreate] = useState(false);
  
    const all =()=>{
       setisall(false);setismy(false);setiscreate(false);
    }
    const my =()=>{
       setisall(true);setismy(true);setiscreate(false);
    }
    const create =()=>{
       setisall(true);setismy(false);setiscreate(true);
    }

    const onChangeValue = (e) => {
        setuserInfo({
            ...userInfo,
                [e.target.name]:e.target.value
        });
    } 
    
    useEffect( () => {
      allgroups();
      mygroups(props.storageid);
   
     }, []);

     const [isallgroups, setisallgroups] = useState([]);
     const [isallgroupswww, setisallgroupswww] = useState([]);
     //const [isallgroupsjoins, setisallgroupsjoins] = useState([]);
  
const allgroups = async () => {
       try {
              const allgroupsss = await allgroupslist();
                     if(allgroupsss.success === 1){
                            console.log(allgroupsss.listall.length);
                            setisallgroups(allgroupsss.listall);
                           // setisallgroupswww(allgroupsss.listallwww);

              // const todoIdList = allgroupsss.listall;
              // console.time('.map()')
              //  Promise.all(todoIdList.map(id => {
              // return new Promise((resolve) => {
              // fetch(BaseURL+`allgroupslistjoin/${id.groups_id}/${props.storageid}`)
              //        .then(response => {
              //        return new Promise(() => {
              //        response.json()
              //        .then(todo => {
              //        if(todo.listalljoins.length > 0){
              //               console.log(todo.listalljoins);
              //               setisallgroupsjoins(prevBooks => {
              //                      return [...prevBooks, ...todo.listalljoins]
              //        })    
              //        }
              //                                           resolve()
              //                                           })
              //                                    })
              //                                    })
              //                             })
              //                      }))
              //                      .then(() => {
              //                      console.timeEnd('.map()');
              //                      })
                                   // allgroupsss.listall.forEach(async function(color) {
                                   //        const allgroupsssjoins = await allgroupslistjoin();
                                   //        setisallgroupsjoins(allgroupsssjoins.listalljoins)
                                   //        console.log(color.groups_id);
                                   //      });

                            }
              } catch (error) { throw error;}    
       }
     const [ismygroups, setismygroups] = useState([]);
       const mygroups = async (memberids) => {
              try {
                     const mygroupssss = await mygroupslist(memberids);
                     console.log(mygroupssss);
                            if(mygroupssss.success === 1){
                                   setismygroups(mygroupssss.listmy);
                            }
              } catch (error) { throw error;}    
       }

            const addgroups = async (event) => {
              try {
                     setloadingaddgroup(true);
                            event.preventDefault();
                            event.persist();
                            const addgroups = await creategroups(userInfo,props.storageid,);
                            console.log(addgroups);
                            if(addgroups.success === 1){
                                   console.log('yyyyyyyyyyyyyyyyyyyyyyyyyy',addgroups);
                                   setloadingaddgroup(false);
                                   event.target.reset(); 
                                   toast.info( 'Create a group successfully ', {
                                    position: "top-center",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                                  });  
                                  history.push(`/GroupViews/${addgroups.groupsid}/${props.storageid}`);
                                  
                                  allgroups();
                                  mygroups(props.storageid);
                            }

              } catch (error) { throw error;}    
       }
       // {props.match.params.Add}

     
            return ( 
                   <>
                    <span className="udb-inst"> Groups </span>
                    <div className="ccc6">
                            <ul className="memberstabsss">
                                   <li onClick={all} className={isall? "nav-item": "active" } >
                                          All Groups
                                   </li>
                                   <li onClick={my} className={ismy? "active": "nav-item" } >
                                          My Groups
                                   </li>
                                   <li onClick={create} className={iscreate? "active": "nav-item" } > 
                                   Create Group
                                   </li>
                            </ul>
                     </div>
<div className="ccc8" >
{/* ------------------------------------------------------------------------------------------------------------------------------ All Groups ----------------------------------------------------------------------------------------------------------------- */}
{isall === false ? <>
       {isallgroups.map((item, index) => ( 
              <div className="col-md-4" key={item.groups_id}>
   
      
<div className="ccc7">
     
                     <Link to={`/GroupViews/${item.groups_id}/${props.storageid}`} className="link-btn">
                            <div className="ccc7img">
                                   {item.coverimage === '' ?<><img src={covers} alt="userprofile" /></>
                                   :  <img src={BaseURLuploads+item.coverimage} alt="Classifiedsads" />}
                                    <div className="listing-author ">
                                          {item.image !=='' ? 
                                                 <img src={BaseURLuploads+item.image} alt="" className="rounded-circle mr-2" />
                                          :
                                                 <img src={userphotos} alt="" className="rounded-circle mr-2"  />
                                           }
                                   </div>
                            </div>
                            <h1>{item.groups_name}</h1>
                     </Link>
                    
         
                  
</div>

              </div>
       ))}

</> : null }
{/* ------------------------------------------------------------------------------------------------------------------------------ My Groups ----------------------------------------------------------------------------------------------------------------- */}
{ismy === true ? <>
       {ismygroups.map((item, index) => ( 
              <div className="col-md-4" key={item.groups_id}>

              </div>
       ))}

</> : null }
</div>
{/* ------------------------------------------------------------------------------------------------------------------------------ Create Group ----------------------------------------------------------------------------------------------------------------- */}
       {iscreate === true ? 
              <div className="ud-cen-s1">
                     <form onSubmit={addgroups} className="login-forms">
                            <div className="clearfix"></div>
                            <div className="form-row">
                            <div className="form-group col-md-12">
                                   <label className="font-weight-bold col-md-4"> Group Name :  </label>
                                   <input type="text" name="name" value={userInfo.name} onChange={onChangeValue} className="form-control col-md-8" placeholder="Your Group Name here..."  required  />
                                   
                            </div>
                            <div className="form-group col-md-12">
                                   <label className="font-weight-bold col-md-4">  Description :  </label>
                                   <textarea  name="description" value={userInfo.description} className="form-control col-md-8" rows="5"  placeholder="Group Description"  onChange={onChangeValue} required />
                            </div>
                            <div className="form-group col-md-12">
                                   <label className="font-weight-bold col-md-4">Group Website : </label>
                                   <input type="text" name="website" value={userInfo.website} onChange={onChangeValue} className="form-control col-md-8" placeholder=" Website link here..."    />
                            </div>
                            <div className="form-group col-md-12 ">
                            {loadingaddgroup ?
                                          <>
                                                 <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                                          </>
                                          : 
                                   <button type="submit" className="db-tit-btn" style={{
                                   marginBottom: "37px"}} > Create Group </button>
                            }
                            </div>
                            
                            </div>
        
                     </form>  
              </div>
       : null}

             </>
              );
          }
   
export default Groups;
