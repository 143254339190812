import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../../contexts/MyContext';

import {NavLink} from 'react-router-dom';
import { useHistory } from "react-router-dom";
import {toast} from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function SideBar(props) {
  let history = useHistory();
  const {rootState,} = useContext(MyContext);
  const {isAuth,theUser} = rootState;

  const handleClick = () => {
        props.togglsssmode();
}

const logsss =() =>{
  
    props.toastmsgsss();
    props.togglsssmode();
}
const logsss2 =() =>{
  toast.info('Please register to get your free membership id ',{
    position: "top-center"});  
    history.push(`/Login`);
    
}
const logsss3 =(userids) =>{
  toast.info('Membership ID : #BF45IO8Z-'+userids,{
    position: "top-center",autoClose: false,draggable: false,closeOnClick: false,});  
}
const Activemember =(userids) =>{
  props.refresh(userids);
  props.togglsssmode();
}

  return (
    // Pass on our props
    <div className="fullmenus">
      
      <div className="container">
             <div className="row">
            
             <span className="closss" onClick={handleClick}>   <FontAwesomeIcon icon={['fas','times-circle']} />  </span>
    <div className=" col-md-4">   
      <ul>

          <li className="menu-item" onClick={handleClick}><NavLink exact activeClassName='is-active' to="/">Home</NavLink></li>

          <li className="menu-item" onClick={handleClick}><NavLink exact activeClassName='is-active' to="/AllClassifieds"> Business Ads </NavLink></li>
          <li className="menu-item" onClick={handleClick}><NavLink exact activeClassName='is-active' to="/AllBusiness">  Business Directory  </NavLink></li> 
          
      </ul> 
    </div>
    <div className=" col-md-4">
                   
        <ul>
                  {isAuth === true && theUser !== '' ?
                      <li className="menu-item" onClick={handleClick}><NavLink exact  to="/Members">  View All Members  </NavLink></li> 
                  : 
                      <li className="menu-item" onClick={logsss2}><NavLink exact  to="#">  View All Members   </NavLink></li> 
                  } 
                  {isAuth === true && theUser !== '' ?
                          <li className="menu-item" onClick={()=>logsss3(theUser)}><NavLink exact  to="#">  Get Membership ID  </NavLink></li> 
                        : 
                            <li className="menu-item" onClick={logsss2}><NavLink exact  to="#">  Get Membership ID  </NavLink></li> 
                        }
                       
                  {isAuth === true && theUser !== '' ? <>
                      <li className="menu-item" onClick={()=> Activemember(theUser)}><span className='is-activesss' style={{cursor:"pointer"}} >  Promote Your Business   </span></li>
                    
                  </>  : <>
                      <li className="menu-item" onClick={logsss}><NavLink  to="/Login">  Promote Your Business   </NavLink></li>
                    </>
                  }
                  {isAuth === true && theUser !== '' ? <>
                      <li className="menu-item"><NavLink exact  to="/Createcompany">  Create your company page  </NavLink> </li>
                    
                  </>  : <>
                      <li className="menu-item" onClick={logsss}><NavLink  to="/Login">  Create your company page   </NavLink></li>
                    </>
                  }
                 
                </ul>     
    </div>
    <div className=" col-md-4">
                   
        <ul>
        {isAuth === true && theUser !== '' ?
                            <li className="menu-item" onClick={handleClick}><NavLink  to="/NewsFeed"> News Feed  </NavLink></li>
                        : 
                            <li className="menu-item" onClick={logsss}><NavLink  to="/Login"> Register  </NavLink></li>
                        }
                       

                        {isAuth === true && theUser !== '' ?
                           <li className="menu-item" onClick={handleClick}><NavLink activeClassName='is-active' to="/Myaccount">   My Account    </NavLink></li> 
                        : 
                            <li className="menu-item" onClick={logsss}><NavLink  to="/Login">  My Account  </NavLink></li>
                        }
                  <li className="menu-item" onClick={handleClick}><NavLink exact activeClassName='is-active' to="/Contact"> Contact Form   </NavLink></li> 
                  
          </ul>         
   
    </div>
    <div className=" col-md-12">
    <h1>  Connect with  <strong> <i> Entrepreneurs </i>  </strong>  around the world. <br /> Promote your business to our  <strong> <i>100k subscribes </i>  </strong>  </h1>
    <div className="footer-widget__social" >
              <a href="https://www.facebook.com/groups/businessownersmarketers" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={['fab','facebook-square']} className="chooseus3 chooseus3facebook" /></a>
              <a href="https://twitter.com/Noblein" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={['fab','twitter-square']} className="chooseus3 chooseus3twitter" /></a>
              <a href="https://www.linkedin.com/groups/3790878/" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={['fab','linkedin']} className="chooseus3 chooseus3linkedin" /></a>
              </div>
    </div>



                 
                







    </div>
    </div>
    </div>
  );
};

export default SideBar;