import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import {Link } from "react-router-dom"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import userphotos from '../Img/user_profile.jpg';
import Moment from 'react-moment';
import Popup from 'reactjs-popup';
import CommentNewsFeed from './CommentNewsFeed';
import PostEdit from './PostEdit';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useHistory } from "react-router-dom"; 
import { WEB_URL,WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURL = WEB_URL;
let BaseURLuploads = WEB_URL_UPLOAD;

function NotificationFeedView(props) {
  
  const {rootState,feedlistsss,deletebooksssqqq,reportadds,HideCommentlistfeeds} = useContext(MyContext);
  const {isAuth,theUser} = rootState;
  useEffect( () => {
       window.scrollTo(0, 0);
       feedlist(props.match.params.Addpreview);
   }, []); 
 
   const [isfeedlist, setisfeedlist] = useState([]);

const feedlist = async (storageid) => {
       try {
              const feedlistqqq = await feedlistsss(storageid);
                     if(feedlistqqq.success === 1){
                            console.log('feedlist',feedlistqqq.feedlistall);
                            setisfeedlist(feedlistqqq.feedlistall);
                            
                     }
       } catch (error) { throw error;}    
}
const [issetmore, setmore] = useState(false);
const [issetmoreids, setmoreids] = useState(false);
const moreoptions = (ids) =>{
       setmoreids(ids)
       setmore(issetmore === false ? true : false);
       
}
const [ismore, setismore] = useState(false);
const [ismoreids, setismoreids] = useState();
const toggleClass = (ids) => {
       setismore(ismore === false ? true : false);
       setismoreids(ids);
   };
   const [isurls, setisurls] = useState(false);
   const [isyoutube, setisyoutube] = useState(false);

   const toggleClassurls = () => {
       setisurls(isurls === false ? true : false);
   };
  const toggleClassyoutube = () => {
       setisyoutube(isyoutube === false ? true : false);
   };
  
   const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]{11,11}).*/;  

   const deletes = (feedids) => {
       confirmAlert({
         title: 'Confirm to Delete ',
         message: 'Are you sure to do this.',
         buttons: [
           {
             label: 'Yes',
             onClick: () => deletebooks(feedids)
           },
           {
             label: 'No',
           }
         ]
       })
     };     
     const deletebooks = async (feedids) => {
       try {
              const deletebooksss = await deletebooksssqqq(feedids);
              if(deletebooksss.success === 1){
                     setisfeedlist([]);
                     feedlist(props.match.params.Addpreview);
                     
              }
       } catch (error) { throw error;} 
}
const externallinks = (url) => {
       if (!!url && !!url.trim()) { 
              url = url.trim(); 
              if (!/^(https?:)?\/\//i.test(url)) { 
                  url = 'http://' + url; 
                  window.open(url, '_blank');
              }else{
                     window.open(url, '_blank');
              }
          } 
     } 
     const [isreportpost, setreportpost] = useState({
       reportpost: '',
   });
   const [count, setCount] = useState(1);
const radiosChange = (e) => {
       setreportpost({
              ...isreportpost,
              [e.target.name]:e.target.value
       });
}   

     const reportpostupdate = async(event) =>{
       try {
             
              event.preventDefault();
              event.persist();
              const reportpostupdatesss = await reportadds(isreportpost,isreportpost.storageid.value,isreportpost.feedsids.value);
              if(reportpostupdatesss.success === 1){
                     event.target.reset(); 
                     setreportpost({
                            ...isreportpost,
                            reportpost:'',
                        });
                        setmore(issetmore === false ? true : false);
              }
       } catch (error) { throw error;}      
}
const HideComment = async(feedids) =>{
       try {
              const HideCommentsss = await HideCommentlistfeeds(feedids);
              if(HideCommentsss.success === 1){
                     console.log('HideCommentsss',HideCommentsss.listanncccsss);
              
                     setisfeedlist([]);
                     feedlist(props.match.params.Addpreview);
              }
       } catch (error) { throw error;}      
} 
  return (
  
    <div className="notificationsss">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1> News Feed </h1>
<div className="col-md-2"> &nbsp; </div>    
<div className="col-md-8 notificationsssfeeds yyy">
{isfeedlist.length > 0 ? <>   
<div className="feeds yyy2 ">
<div className="feedswww">
<ul className="activity-list" > 

       {isfeedlist.map((item, index) => ( 
              <li  className="col-md-12" key={item.feed_id} style={{padding:0}}  >

                     <div className="more-post-optns">
                     <FontAwesomeIcon icon={['fas','ellipsis-h']} className="enablelikes"  onClick={() => moreoptions(item.feed_id)} />
                     {issetmore === true && issetmoreids === item.feed_id ? 
<ul>
{item.feed_member_id === theUser ? <>
       <Popup trigger={<li> <FontAwesomeIcon icon={['fas','user-edit']} className="enablelikes"/>Edit Post</li>} position="top left">
       {close => (
       <div className="feedspopups">
               <a className="close" onClick={close}>
              &times;
              </a>
          
            
              <div className="feeds yyy2">
              <div className="feedswww">
               
                 <PostEdit feedid={item.feed_id}  feedtext={item.feedtext}  image={item.image} url={item.url} youtube={item.youtube} grouprefresh={()=> feedlist(props.match.params.Addpreview)} resetposts={()=>setisfeedlist([])} userids={item.feed_member_id} close={close} moreoptions ={() => moreoptions(item.feed_id)}  newsetCount ={()=>setCount(1)} />   

              </div>
              </div>

       </div>
       )}
       </Popup>
                                  
                                   <li onClick={()=>deletes(item.feed_id)}><FontAwesomeIcon icon={['fas','trash-alt']} className="enablelikes"/>Delete Post</li>	

          <li> <FontAwesomeIcon icon={['fas','comments']} className="enablelikes"/>Comment <BootstrapSwitchButton checked= {item.commentstoogle === 0 ? true : false} onstyle="outline-primary" offstyle="outline-secondary" onlabel='On' offlabel='Off'
                     
                     onChange={()=>HideComment(item.feed_id)}

                     />

</li>  
</>: null }   
<Popup trigger={ <li className="bad-report"><FontAwesomeIcon icon={['fas','flag']} className="enablelikes"/>Report Post</li>} position="top left">
       {close => (
       <div className="feedspopups">
               <a className="close" onClick={close}>
              &times;
              </a>
              <div className="pppsss">
            <div className="feedswww">
            <h4> Report </h4>
            <hr />
              <form onSubmit={reportpostupdate} className="repportsss">
              <ul> 
                     <li><input type="radio" value="Nudity" name="reportpost" onChange={radiosChange} /> Nudity </li>
                     <li><input type="radio" value="Violence" name="reportpost" onChange={radiosChange}/> Violence </li>
                     <li> <input type="radio" value="Harassment" name="reportpost" onChange={radiosChange}/> Harassment </li>
                     <li> <input type="radio" value="Suicide or self-injury" name="reportpost" onChange={radiosChange}/> Suicide or self-injury </li>
                     <li> <input type="radio" value="False news" name="reportpost" onChange={radiosChange}/> False news </li>
                     <li> <input type="radio" value="Spam" name="reportpost" onChange={radiosChange}/> Spam </li>
                     <li> <input type="radio" value="Unauthorised sales" name="reportpost" onChange={radiosChange}/> Unauthorised sales </li>
                     <li> <input type="radio" value="Hate speech" name="reportpost" onChange={radiosChange}/> Hate speech </li>
                     <li> <input type="radio" value="Terrorism" name="reportpost" onChange={radiosChange}/> Terrorism </li>
                   
                   <input type="hidden" value={item.feed_id} ref={(val) => isreportpost.feedsids = val} name="feedsids" /> 
                   <input type="hidden" value={item.feed_member_id} ref={(val) => isreportpost.storageid = val} name="storageid" /> 
              </ul>
                     <div className="form-group col-sm-12 text-right">
                            <button type="submit" className="db-tit-btn2"> Report  </button>
                      </div>
              </form>
             
              </div>
              </div>   
       </div>
       )}
       </Popup>                        						
                  
        
                            </ul>
                     : null}    
                      </div> 

                     <div className="userlistfeeds">
                            <div className="col-md-2s" style={{padding:0}} >
                                   {item.profile_image !=='' ? 
                                          <img src={BaseURLuploads+item.profile_image} alt="" className="rounded-circle mr-2 feeds2" />
                                   :
                                          <img src={userphotos} alt="" className="rounded-circle mr-2 feeds2"  />
                                   }
                            </div>
                                   <div className="col-md-10s" style={{padding:0}}>
                                   <p> <b> {item.name} </b> posted an update </p> <br/>
                                   <div className="clearfix"> </div>
                                   <p className="userlistfeedsmmm">  <Moment fromNow>
                                   {item.date} 
                                   </Moment></p>
                            </div>
                     </div>
                     <div className="clearfix"> </div>
                     <div className="shortcontent">
                   
                   
                     <div className={ "block-ellipsis" + (ismoreids === item.feed_id ? " block-ellipsisqqq " : "")} dangerouslySetInnerHTML={{ __html: item.feedtext }} />

                     {item.feedtext.length > 177 ? <>
                            {ismore === true && ismoreids === item.feed_id ?  
                                   <span className="morelink" onClick={toggleClass}> Close  </span> 
                            :  
                                   
                            <span className="morelink" onClick={() => toggleClass(item.feed_id)} > read more ...  </span>
                            }
                     </> : null }
                          
                     {item.image ? 
                            <div className="rtmedia-item-thumbnail">
<Popup trigger={<span className="popupsssimg2"> <FontAwesomeIcon icon={['fas','search']} className="iconsss2zzz2 " />  <img src={BaseURLuploads+item.image} alt="feedsimages"  /> </span>} position="top left">
       {close => (
       <div className="feedspopups">
               <a className="close" onClick={close}>
              &times;
              </a>
          
            
              <div className="feeds yyy2">
              <div className="feedswww">
              <img src={BaseURLuploads+item.image} alt="feedsimages" className="popupsssimg" />

              </div>
              </div>

       </div>
       )}
       </Popup>
                                  
                            </div>
                     : null }
                     <div className="clearfix"> </div>
                     {item.youtube.match(regExp) ? 
                     <> 
                            {item.youtube.match(regExp).length >= 2 ? 

                            <iframe id="ytplayer" type="text/html" className="videosss2"
                            src={`https://www.youtube.com/embed/` + item.youtube.match(regExp)[2] + `?rel=0&showinfo=0&color=white&iv_load_policy=3`}
                            frameBorder="0" allowFullScreen></iframe>
                            : null }
                     </>
                            : null 
                     } 
                      {item.url !== '' ? <>  
                            <div className="col-md-12 ccc2"> 
                                   <hr />
                                 <p onClick={() => externallinks(item.url)}>{item.url} </p> 
                            </div>
                      </> : null }


{/* -------------------------------------------------------------------------------------------------------------------------------------------- Like, Share, Comment -------------------------------------------------------------------------------------------------------------------------------------- */}
<div className="yyy8" style={{float:"left"}}>
      <CommentNewsFeed commentuserid ={item.feed_member_id} userimgs={item.profile_image} postids={item.feed_id} feedmemberid={item.feed_member_id}  likecount={item.likes} commentcount={item.comments} commentstoogle={item.commentstoogle} /> 

                     </div>
                     </div>
              </li>
       )) }
{/* </> : null } */}


</ul>


</div>
</div>
</>: null }



</div>   
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationFeedView;