import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../../contexts/MyContext';

import {toast} from "react-toastify";
import {NavLink} from 'react-router-dom';

import SideBar from "./SideBar";
import Search from "./Search";
import Follownotificationcount from "./Follownotificationcount";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../Img/logo.png';
import { useHistory } from "react-router-dom";
import {Motion, spring, presets} from 'react-motion';

function Navbarmenu() {
  let history = useHistory();
  const {rootState,Activemembersss} = useContext(MyContext);
   const {isAuth,theUser} = rootState;
   const [isMenu, setisMenu] = useState(false);
   const [toggle, settoggle] = useState(false);
   const [isfullnav, setfullnav] = useState(false);
   const [isfullnavsearch, setfullnavsearch] = useState(false);
    
  const logsss =() =>{
    toast.info('please login to access this page ',{
      position: "top-center"});  
  }
  const logsss2 =() =>{
    toast.info('Please register to get your free membership id ',{
      position: "top-center"});  
      history.push(`/Login`);
  }
  const logsss3 =(userids) =>{
    toast.info('Membership ID : #BF45IO8Z-'+userids,{
      position: "top-center",autoClose: false,draggable: false,closeOnClick: false,});  
  }
  const toggleClass = () => {
    //setisMenu({isMenu: !isMenu})
    setisMenu(isMenu === false ? true : false);
};
const [islogssscount, setlogssscount] = useState(false);
const logssscount = () => {
    setlogssscount(true);
    setisMenu(isMenu === false ? true : false);
};

      let boxClass = ["main-menu menu-right menuq1"];
      if(isMenu) {
        boxClass.push('menuq2');
      }else{
        boxClass.push('');
      }
      
      const handleClick = () =>{
        settoggle(!toggle);
        setfullnav(isfullnav === false ? true : false);
      }
 const handleClickSearch = () =>{
        setfullnavsearch(isfullnavsearch === false ? true : false);
      }


      const Activemember = async (userids) =>{
        const Activememberlist = await Activemembersss(userids);
          if(Activememberlist.success === 1){
            setisMenu(isMenu === false ? true : false);
             history.push(`/ClassifiedAdd`);
          }
          if(Activememberlist.success === 0){
            toast.info('Please upgrade to your membership',{
              position: "top-center"});  
              history.push(`/Myaccount`);
         }
              
      }

      return(
        <header className="header-middle">
          {/* <div className="topBar">
             <div className="container">
              <div className="row">
              <div className=" col-md-6" style={{padding:0}}> 
              <h1>BECOME A MEMBER TODAY</h1>
              </div>
              <div className=" col-md-6" style={{padding:0}}>
                <ul> 
              <li className="topmenu-item" onClick={toggleClass}><NavLink to="/Myaccount">   My Account    </NavLink></li> 
              <li className="topmenu-item" onClick={toggleClass}><NavLink to="/Login">  Login    </NavLink></li>
              </ul> 
                 </div>
								
							</div>
						</div>
			   	</div> */}
        
                    <nav className="navBar " >
                    <div className="container">
             <div className="row">
             <div className=" col-md-3" style={{padding:0}}>
                    <div className=" logo">
                        <NavLink exact activeClassName='is-active' to="/">
                          <img src={logo} alt="logo" /> 
                        </NavLink>
                    </div>
                  
                  </div>
             <div className=" col-md-9" style={{padding:0}}>
             
                    <span className="res5c" style={{ display: 'none' }} onClick={toggleClass} >  <FontAwesomeIcon icon={['fas','bars']} />    </span>
                        <ul className={boxClass.join(' ')}>
                          
                            <li className="menu-item" onClick={toggleClass}><NavLink exact activeClassName='is-active' to="/">Home</NavLink></li>
                          
{/*                           
                            {isAuth === true && theUser !== '' ?
                         <li className="menu-item" onClick={toggleClass}><NavLink  to="/NewsFeed"> News Feed  </NavLink></li>
                        : 
                        <li className="menu-item" onClick={logsss}><NavLink  to="/Login"> News Feed  </NavLink></li>
                          
                        } */}
                          
                        <li className="menu-item" onClick={toggleClass}><NavLink exact activeClassName='is-active' to="/AllClassifieds"> Business Ads </NavLink></li>
                        <li className="menu-item" onClick={toggleClass}><NavLink exact activeClassName='is-active' to="/AllBusiness">  Business Directory  </NavLink></li> 
                        {isAuth === true && theUser !== '' ?
                          <li className="menu-item" onClick={()=>logsss3(theUser)}><NavLink exact  to="#">  Get Membership ID  </NavLink></li> 
                        : 
                            <li className="menu-item" onClick={logsss2}><NavLink exact  to="#">  Get Membership ID  </NavLink></li> 
                        }
                        
          {isAuth === true && theUser !== '' ? <>
              <li className="menu-item" onClick={()=> Activemember(theUser)}><span className='is-activesss' style={{cursor:"pointer"}} >  Promote Your Business   </span></li>
             
          </>  : <>
              <li className="menu-item" onClick={logsss}><NavLink  to="/Login">  Promote Your Business   </NavLink></li>
             </>
          }
                     

                        {isAuth === true && theUser !== '' ?
                            <li className="menu-item" onClick={toggleClass}><NavLink  to="/NewsFeed"> News Feed  </NavLink></li>
                        : 
                            <li className="menu-item" onClick={logsss}><NavLink  to="/Login"> Register  </NavLink></li>
                        }
                       

                        {isAuth === true && theUser !== '' ?
                           <li className="menu-item" onClick={toggleClass}><NavLink activeClassName='is-active' to="/Myaccount">   My Account    </NavLink></li> 
                        : 
                            <li className="menu-item" onClick={logsss}><NavLink  to="/Login">  My Account  </NavLink></li>
                        }
                              
                              

                            
 
                      
              
                       {/* <li className="menu-item" onClick={toggleClass}><NavLink exact activeClassName='is-active' to="/Contact"> Contact Us </NavLink></li>  */}
{/* ------------------------------------------------------------------------- */}
{/* menu icons */}

                             
                              <li className="menu-item navSidebar-wraper"> 
                              <div className="single-navicon">
                                
                              <span className="navSidebar-button">
                                
                                <svg viewBox="0 0 96 96" height="1em" onClick={handleClick} style={style}>
                               
        <Motion 
          style={{
            x: spring(toggle ? 1 : 0, presets.wobbly ),
            y: spring(toggle ? 0: 1, presets.wobbly ),
          }}
        >
          {({ x, y }) =>
            <g 
              id="navicon" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="14" 
              strokeLinecap="round" 
              strokeLinejoin="round"
             >
              <line 
                transform={`translate(${x * 12}, ${x * -7}) rotate(${x * 45}, 7, 26)`} 
                x1="7" y1="26" x2="89" y2="26" 
               />
              <line 
                transform={`translate(${x * 12}, ${x * 7}) rotate(${x * -45}, 7, 70)`} 
                x1="7" y1="70" x2="89" y2="70" 
               />
              <line 
                transform={`translate(${x * -96})`} 
                opacity={y} 
                x1="7" y1="48" x2="89" y2="48"
               />
            </g>
          }
        </Motion>
      </svg>
      </span>
      </div>
      </li>

      <li className="menu-item navSidebar-wrapersearchsss" >
        <FontAwesomeIcon icon={['fas','search']} onClick={handleClickSearch} />

          {/* follow, message, like and comment notification count */}
    {isAuth === true && theUser !==''?
      <NavLink onClick={logssscount} to="/Notificationall" >
        {islogssscount === false ? 
          <><Follownotificationcount storageid={theUser} /></>
        : <><FontAwesomeIcon icon={['fas','bell']} /></> }
      </NavLink>   
    :  null }          
      </li>
 {/* <li className="menu-item navSidebar-wraperbellsss" >
    <div className="wraperbellsss" >
      
    {isAuth === true && theUser !==''?
      <NavLink onClick={logssscount} to="/Notificationall">
        {islogssscount === false ? 
          <><Follownotificationcount storageid={theUser} /></>
        : <><FontAwesomeIcon icon={['fas','bell']} /></> }
      </NavLink>   
    :  <NavLink  to="/Login">
        <><FontAwesomeIcon icon={['fas','bell']} onClick={logsss}/></>
     </NavLink> }          
    </div>
  </li> */}
  
      {isfullnavsearch === true ? <>
                   <Search togglsssmodesearch={handleClickSearch} /> 
                   </>
      : null }
      
   
{/* ------------------------------------------------------------------------- */}
                            
                             
                        </ul> 


                        </div>
            </div>


            </div>
                    </nav>

                  {isfullnav === true ? <>
                   <SideBar togglsssmode={handleClick} toastmsgsss={logsss} refresh={()=> Activemember(theUser)}/> 
                  
                   </>
                   : null }

               



         
            </header>
        );
    }

export default Navbarmenu;


const style = {
  overflow: 'visible',
  cursor: 'pointer',
  // disable touch highlighting on devices
  WebkitTapHighlightColor: "rgba(0,0,0,0)",
}