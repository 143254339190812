
import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import Loader from 'react-loader-spinner';

import {toast} from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link } from "react-router-dom"; 


import DashboardProfilePost from './DashboardProfilePost';
import ContentLoader from "react-content-loader";
import userphotos from '../Img/user_profile.jpg';
import {WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function DashboardAccount(props) {

       const loaderfeatured = [1,2,3,4,5,6,7,8];
       const {loginUserimage,viewuserprofile} = useContext(MyContext);
       const [loadinglogin, setloadinglogin] = useState(false);    

       const [userInfo, setuserInfo] = useState({file:'',filetemp:'' });
       const [userlist, setuserlist] = useState({userimg:[]});
       const [isupload, setisupload] = useState(false);
       const [isuploadloader, setisuploadloader] = useState(false);
       const [isname, setisname] = useState();
       const [ismember, setismember] = useState();

       const [invalidImage, setinvalidImage] = useState();

    


       useEffect( () => {
             userdetails(props.storageid);
          
            }, []);

            const [ismemberimg, setismemberimg] = useState();
       const userdetails = async(storageid) =>{
              try {
                     const viewuser = await viewuserprofile(storageid);
                     if(viewuser.success === 1){
                            console.log(viewuser.memberlist);
                            setuserlist({
                                   ...userlist,
                                   userimg:[...viewuser.memberlist]
                            } ); 
                            setisname(viewuser.memberlist[0].name);
                            setismember(viewuser.memberlist[0].member);
                            setismemberimg(viewuser.memberlist[0].profile_image);
                            setisuploadloader(false)
                     }
              } catch (error) { throw error;}      
       }
      
       const  editUploadMode =() =>{
              setisupload(true);
       }

       const resetFile = (event) => {
              event.preventDefault();
              setuserInfo({
                     ...userInfo,
                     filetemp: ''
                })
            }

            let reader = new FileReader();
   const handleChange = (event) => {


       const imageFile = event.target.files[0];
             const imageFilname = event.target.files[0].name;

             if (!imageFile) {
              setinvalidImage('Please select image.');
               return false;
             }
         
             if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif)$/)) {
              setinvalidImage('Please select valid image JPG,JPEG,PNG,GIF');
               return false;
             }
             reader.onload = (e) => {
               const img = new Image();
               img.onload = () => {

//----------------------------- Resize img code -------------------------------------------------
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);

                var MAX_WIDTH = 300;
                var MAX_HEIGHT = 300;
                var width = img.width;
                var height = img.height;

                if (width > height) {
                  if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                  }
                } else {
                  if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                  }
                }
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
                ctx.canvas.toBlob((blob) => {
                  const file = new File([blob], imageFilname, {
                      type: 'image/jpeg',
                      lastModified: Date.now()
                  });
                  setuserInfo({
                     ...userInfo,
                     file:file,
                     filetemp: URL.createObjectURL(imageFile)
                })
                }, 'image/jpeg', 1);


//-------------------------------------------------------------------------------------
              
              setinvalidImage(null)
              };
               img.onerror = () => {
                     setinvalidImage('Invalid image content.');
                 return false;
               };
               debugger
               img.src = e.target.result;
             };
             reader.readAsDataURL(imageFile);
     };

const userphotosAdd = async(event) =>{
       try {
              setuserInfo({
                     ...userInfo,
                     filetemp: ''
                })
               
       setloadinglogin(true);
       event.preventDefault();
       event.persist();
       if(invalidImage !== null){
              toast.error(invalidImage, {
                position: "top-center",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
              }); 
              setloadinglogin(false);
              return;
            }
            setisuploadloader(true);
            setisupload(false);
       const dataimage = await loginUserimage(userInfo.file,props.storageid);
       console.log(dataimage.success);
       if(dataimage.success === 1){
           setloadinglogin(false);
          toast.success( 'Success', {
           position: "top-center",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
         });  
         userdetails(props.storageid);
        
         
       }else{
           setloadinglogin(false);
           toast.error('Please Try Again Later', {
              position: "top-center",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
            });  
       }
} catch (error) { throw error;}    

}

const logsss =() =>{
  toast.info('Please upgrade your membership',{
    position: "top-center"});  
}

const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]{11,11}).*/;  

            return ( 
                   <>
                   <span className="udb-inst">User Dashboard</span>

        {ismember === 'Bronze' ? <>
              <div className="ccc2" ><a onClick={logsss} className="db-tit-btndassss">  Post your business Ads.  Very effective marketing solution for business owners </a></div>
        </>  : null
        }



                   <div className="ud-cen-s1">
            <ul>
                                    <li>
                        <div>
                            <h4>All Listings</h4>
                            <p>Total no of listings</p>
                            <b> </b>
                            <a href="db-all-listing">&nbsp;</a>
                        </div>
                    </li>
                    <li>
                        <div>
                            <h4> Membership Id </h4>
                            <p> &nbsp; </p>
                            <b>#BF45IO8Z-{props.storageid}</b>
                            <a href="db-enquiry">&nbsp;</a>
                        </div>
                    </li>
                                <li>
                    <div>
                        <h4>Membership Plan</h4>
                        <p> &nbsp; </p>
                        <b>{ismember}</b>
                        <a href="db-followings">&nbsp;</a>
                    </div>
                </li>
            </ul>
       
             

                     <div className="col-md-12" style={{padding:0}}>
                     <div className="aaa9">
                   
                       <h1> Profile page  <span> <Link to={`/ProfileView/${props.storageid}`} > View my profile </Link>  </span></h1>
                       <div className="ud-pro-link">
                     {invalidImage !== null ? <p className="error" style={{float:"left",textAlign: "left"}}>{invalidImage}</p> : null }
                            {userlist.userimg.length > 0 && isuploadloader === false ? <> 
                            {userlist.userimg.map((item, index) => (
              <div key={item.register_id} className="profilepageleftsss">
                     <div className="profilepageleftsss2"> 
                    
                     {item.profile_image && userInfo.filetemp === '' ?
                              <>
                          <div className="col-md-4" style={{padding:0}}>
                            <img src={BaseURLuploads+item.profile_image} alt="userprofile" className="profileimgsss" />
                            <button onClick={editUploadMode} className="buttsss"> <FontAwesomeIcon icon={['fas','images']} className="iconsss2 " /> Change </button>
                     </div>
                     
                     <div className="col-md-6" style={{padding:0}}>
                     <h2> {item.name} </h2> 
                     </div>
                     </>
                    : 
                    <>
                      {userInfo.filetemp ?
                        <div >
                             <img className="profileimgsss"  src={userInfo.filetemp} alt="UploadImage"/>
                            <button onClick={resetFile} className="btn btn-primary4 zzz"> <FontAwesomeIcon icon={['fas','trash-alt']} className="iconsss2" />  Remove</button>
                        </div>
                       :
                       <>
                        <img src={userphotos} alt="userprofile" className="profileimgsss"/>
                        <button onClick={editUploadMode} className="btn btn-primary4">  <FontAwesomeIcon icon={['fas','images']} className="iconsss2" /> Add Photo  </button>
                        </>
                        }
                    </>
                     }
                  </div>
               </div>
                   ))}

                     {isupload === true ?
                            <>
                             <form onSubmit={userphotosAdd} className="central-meta2nnnq">
                                   <div className="form-group2 ">
                                   <input type="file" name="file" onChange={handleChange}  className="form-control" placeholder=""   required/>
                                   </div>
                      {/* Loading */}
                      {loadinglogin ?
                           <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} />
                         : 
                            <div className="form-group col-sm-12 text-right">
                                   <button type="submit" className="btn btn-primary"> <FontAwesomeIcon icon={['fas','save']} className="iconsss2sssq" /> Save </button>
                            </div>
                      } 
                      </form>
                      </> : null }
              </>
              :
              <ContentLoader height={200} width={326} speed={2} primarycolor="#f3f3f3"  secondarycolor="#ecebeb" > 
                    <rect x="0" y="0" rx="0" ry="0" width="167" height="167"/> 
                    <rect x="207" y="47" rx="0" ry="0" width="137" height="47"/> 
                    <rect x="10" y="237" rx="0" ry="0" width="183" height="27"/> 
                     </ContentLoader> 
            }



</div>  
</div>  
              </div>  

             


            {props.storageid!=='' ?
              <div className="col-md-12 yyy">
                     <DashboardProfilePost storageid={props.storageid} memberprofile={ismemberimg} membername={isname}/>
           
              </div>
       : null } 







              </div>

             </>
              );
          }
   
export default DashboardAccount;
