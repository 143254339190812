import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Loader from 'react-loader-spinner';
import {toast} from "react-toastify";
import StripeCheckout from "react-stripe-checkout";

import { useHistory } from "react-router-dom";
import { WEB_URL,API_KEY_STRIPE } from '../contexts/Apilinks';

let baseURLpaypal= WEB_URL;

function Payment(props) {
       let history = useHistory();
       const {pricesall,upgradeplanslist,memberlistall,memberstripeupgrade} = useContext(MyContext);

       const [isprice, setisprice] = useState([]);
       const [ismemberlist, setismemberlist] = useState([]);
       const [ismemberprice, setmemberprice] = useState();
       const [ispricechange, setispricechange] = useState(false);

       
       
       const [loadinglogin, setloadinglogin] = useState(false);
       const [paynowpaypal, setpaynowpaypal] = useState(false);
       const [paynowstripe, setpaynowstripe] = useState(true);
       
       const [userInfo, setuserInfo] = useState();
          const onChangeValue = (e) => {
              setuserInfo(e.target.value);
          } 

       useEffect( () => {
              memberlistpreview(props.match.params.memberid);
              prices();
             }, []); 
          
             const memberlistpreview = async(memberid) =>{
              try {
                     const memberlistresult = await memberlistall(memberid);
                     if(memberlistresult.success === 1){
                            console.log(memberlistresult.memberlist);
                            setismemberlist(memberlistresult.memberlist[0]);
                            setuserInfo(memberlistresult.memberlist[0].update_member)
                     }
              } catch (error) { throw error;}
          }

           const prices = async() =>{
              try {
                     const pricesresult = await pricesall();
                     if(pricesresult.success === 1){
                            console.log(pricesresult.pricelist);
                            setisprice(pricesresult.pricelist[0]) ;
                           
                     }
              } catch (error) { throw error;}
          }
          
       const paypalMode = () => {
              setpaynowpaypal(true);
              setpaynowstripe(false);
          }
         const stripeMode = () => {
              setpaynowpaypal(false);
              setpaynowstripe(true);
          }

          
const MemberMode = (event) => {
       
       if(event.target.value === 'Gold')
       { 
              setmemberprice(isprice.Gold - isprice.Silver);
       }
       if(event.target.value === 'Platinum')
       { 
              if(ismemberlist.member === 'Silver'){ 
                     setmemberprice(isprice.Platinum - isprice.Silver);
              }else { 
                     setmemberprice(isprice.Platinum - isprice.Gold);
               }
              
       }
      
       setispricechange(true) ;
     } 

     const upgradeplans = async (event) => {
       setloadinglogin(true);
       event.preventDefault();
       event.persist();
       const data = await upgradeplanslist(props.match.params.memberid,userInfo,ismemberprice);
       if(data.success === 1){
         setloadinglogin(false);
         event.target.reset(); 
         toast( data.msg, {
              position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
            });  
            setispricechange(false) ;
            memberlistpreview(props.match.params.memberid);
            setpaynowpaypal(false);
            setpaynowstripe(true);
       }
       else{
         setloadinglogin(false);
         toast.error( data.msg, {
             position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
           });  
     }
     
     }
       const makePayment = async (token) => {
              try {
                     const datastripe = await memberstripeupgrade(token,props.match.params.memberid,ismemberlist.update_member,ismemberlist.update_member_price);
                     console.log(datastripe);
                     if(datastripe.success === 1){
                      history.push(`/StripeSuccessUpgrade/${datastripe.stripeid}`);
                            } 
              } catch (error) { throw error;}    
       }


  return (
    <div className="aaa payaaa">
       <div className="container">
             <div className="row">
     
   
   
             <div className="col-md-1" > &nbsp; </div>
     <div className="col-md-10">
     <form onSubmit={upgradeplans} className="login-forms">
                <div className="clearfix"></div>
            <div className="form-row">
                   
            <div className="col-md-4">     
              <div className="table-header disablsss">
                     <h4> Silver </h4>
                     <h2>  {isprice.Silver} </h2> 
                     <div className="form-group col-md-12 ">
                            <input type="radio" className="radio" id="awesome-item-1" value="Silver" checked={userInfo === "Silver"} onChange={onChangeValue}   disabled />
                            <label className="radio__label" htmlFor="awesome-item-1"> <span >Active  </span></label>
                     </div>
              </div>
              </div>
              <div className="col-md-4">     
              <div className={ "table-header" + (ismemberlist.member === 'Gold' ? " disablsss " : " ")}>
             
                     <h4> Gold </h4>
                     {ismemberlist.member === 'Silver' ? 
                            <> <h2>{isprice.Gold - isprice.Silver} </h2></>
                     :
                            <> <h2>{isprice.Gold} </h2></>
                     }
                    
                     <div className="form-group col-md-12">
                     {ismemberlist.member === 'Gold' ? 
                             <input type="radio" className="radio" id="awesome-item-2" value="Gold"   onClick={MemberMode} disabled />
                      :
                            <input type="radio" className="radio" id="awesome-item-2" value="Gold" checked={userInfo === "Gold"} onChange={onChangeValue}   onClick={MemberMode} />
                     } 
                            <label className="radio__label" htmlFor="awesome-item-2"> <span >Active  </span></label>
                     </div>
              </div>
              </div>
              <div className="col-md-4">     
              <div className={ "table-header" + (ismemberlist.member === 'Platinum' ? " disablsss " : " ")}>
                     <h4> Platinum </h4>
                     {ismemberlist.member === 'Gold' ? 
                            <> <h2>{isprice.Platinum - isprice.Gold} </h2> </>
                     :
                            <> <h2>{isprice.Platinum - isprice.Silver} </h2> </>
                     }
                              
                     <div className="form-group col-md-12">
                            <input type="radio" className="radio" id="awesome-item-3" value="Platinum" checked={userInfo === "Platinum"} onChange={onChangeValue}  onClick={MemberMode}  />
                            <label className="radio__label" htmlFor="awesome-item-3"> <span >Active  </span></label>
                     </div>
              </div>
              </div>

                
                {ispricechange === true ? <> 
                <div className="form-group col-md-12 ">
                {loadinglogin ?
                            <>
                                   <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                            </>
                         : 
                    <button type="submit" className="db-tit-btn2payaaa" style={{
                      marginBottom: "37px"}} > Update </button>
                }
                </div>
                </> : null }
            </div>
        </form>  
    
        {userInfo === '' ? <> 
             <h1 style={{color: '#ff5800'}}> Please activate  your membership   </h1> 
       </> : null }
       {ispricechange === false && userInfo !== '' ? <> 
              <div className="clearfix"></div>
                            <h1> Onetime Fee: Paypal / Debit And Credit Card Payment </h1>
                            <h1  style={{color: '#1da1f3'}}>Choose your payment method</h1>
                            <hr/>
                            <div className="col-md-4" >
                          <div className="row-payment-method payment-row">
                     <div className="select-icon">
                            <input type="radio" id="radio2" name="radios" value="pp" defaultChecked onClick={stripeMode} />
                            <label htmlFor="radio2"></label>
                     </div>
                     <div className="select-txt">
                  
                            <p className="pymt-type-name"> <span className="stripsss"> Stripe </span></p>
                            
                     </div>
                    
                     
                     </div>
                     </div>

                   
                     <div className="col-md-4" >
                     <div className="row-payment-method payment-row">
                     <div className="select-icon">
                            <input type="radio" id="radio1" name="radios" value="pp"  onClick={paypalMode}/>
                            <label htmlFor="radio1"></label>
                     </div>
                     <div className="select-txt">
                    
                            <p className="pymt-type-name"><span className="Pay">Pay</span><span className="pal">pal</span></p>
                           
                     </div>
                    
                     
                     </div>
                     </div>
                   


                   



                     <div className="col-md-12" >  
                          <div className="clearfix"></div>
               {paynowstripe === true ? 
                          <StripeCheckout stripeKey={API_KEY_STRIPE} token={makePayment} name="Membership" description=" Onetime Fee" amount={ismemberlist.update_member_price * 100}  currency="USD" shippingAddress billingAddress >
                     <div className="pricing-onebuttonss"> Proceed to checkout  <FontAwesomeIcon icon={['fas','shopping-cart']} className="iconsss2" /> </div>
                     </StripeCheckout>
              : 

                     <a href={baseURLpaypal+`paypalpayupgrades/`+props.match.params.memberid+`/`+ismemberlist.update_member_price+`/`+ismemberlist.update_member}  className="pricing-onebuttonss" > Proceed to checkout <FontAwesomeIcon icon={['fas','shopping-cart']} className="iconsss2" /></a>


              } 

</div>
                     
</> : null }
 



    </div>
    </div>
    </div>
    </div>
  );
}

export default Payment;