
import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';

import {Link } from "react-router-dom"; 

//import ContentLoader from "react-content-loader";

function AccountInformation(props) {
  
       const {viewpaymentdetails,viewuserprofile} = useContext(MyContext);
      
       const [userlist, setuserlist] = useState([]);
       const [userlistempty, setuserlistempty] = useState(false);
    
       const [ismember, setismember] = useState();
    


       useEffect( () => {
             userdetails(props.storageid);
             user(props.storageid);
          
            }, []);
            const user = async(storageid) =>{
              try {
                     const viewuser = await viewuserprofile(storageid);
                     if(viewuser.success === 1){
                            console.log(viewuser.memberlist);
                           
                            setismember(viewuser.memberlist[0].member);
                     }
              } catch (error) { throw error;}      
       }

       const userdetails = async(storageid) =>{
              try {
                     const viewuser = await viewpaymentdetails(storageid);
                     console.log('paymentdetails',viewuser);
                     if(viewuser.success === 1){
                            console.log('paymentdetails',viewuser.paymentdetails);
                            setuserlist(viewuser.paymentdetails); 
                     }
                     if(viewuser.success === 0){
                            setuserlistempty(true);

                     }
              } catch (error) { throw error;}      
       }
      
    

            return ( 
                   <>
                   {userlistempty === false ? <>  
       {userlist.map((item, index) => ( 
           <div className="" key={item.register_id}> 
                       <h3>Payment Information</h3>
            <ul>
                <li><b>Payment Plan name : </b> {item.member}</li>
               
                <li><b>Payment Type : </b>  Onetime Payment  </li>
                
                <li><span className="ud-stat-pay-btn"><b> Amount :</b> ${item.payment_gross}</span></li>
                <li><span className="ud-stat-pay-btn"><b>transaction id :</b> {item.txn_id} </span></li>
                <li><span className="ud-stat-pay-btn"><b>Payment Status :</b> {item.payment_status} </span></li>

              {item.member !=='Platinum' ? 
                     <li ><Link to={`/PaymentUpgrade/${props.storageid}` }className="thm-btnqqq2"> Upgrade Plan </Link></li>
              : null }
            </ul>
  
            
                         
        </div>  
       ))}   

</> : <>  
{ismember === 'Bronze' ? 
       <div className=""> 
                       <h3>Payment Information</h3>
            <ul>
                <li><b> Payment Plan name : </b> Bronze  </li>
                <li><span className="ud-stat-pay-btn"><b> Amount : </b> Free </span></li>
                <li ><Link to={`/Payment/${props.storageid}` }className="thm-btnqqq2"> Upgrade Membership </Link></li>
                         
            </ul>
        </div>  

: 
       <div className=""> 
       <h3>Payment Information</h3>
       <ul>
       <li><b> Payment Plan name : </b> {ismember}  </li>
      
       {ismember === 'Platinum' ? null :
              <li ><Link to={`/Payment/${props.storageid}` }className="thm-btnqqq2"> Upgrade Membership </Link></li>
       }
       </ul>
       </div>  

}

</> }


             </>
              );
          }
   
export default AccountInformation;
