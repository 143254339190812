
import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import Loader from 'react-loader-spinner';

import {toast} from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link } from "react-router-dom"; 

import ContentLoader from "react-content-loader";
import Popup from 'reactjs-popup';
import CoverPhoto from './CoverPhoto';
import GroupPhoto from './GroupPhoto';
import GroupPost from './GroupPost';
import Settingsall from './Settingsall';
import Galleryall from './Galleryall';
import Membersall from './Membersall';




import userphotos from '../Img/user_profile.jpg';
import covers from '../Img/covers.jpg';
import {WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function Groups(props) {

     
       const {rootState,viewgroupslists,viewuserprofile,joinsgroupslists,joinsalllist,leavegroupslist,adminpremissionlist} = useContext(MyContext);
       const {isAuth,theUser} = rootState;
   
    useEffect( () => {
      groupspreviews(props.match.params.Addpreview);
      adminpremission(props.match.params.Addpreview,props.match.params.Addpreviewuser)
     
     }, []);

     useEffect( () => {
       window.scrollTo(0, 0);
       
   }, []);  

   

const [isadminpremission, setisadminpremission] = useState([]);
    
     const adminpremission = async(groupids,storageid) =>{
             try {
                    
                    const adminpremissionsss = await adminpremissionlist(groupids,storageid);
                    if(adminpremissionsss.success === 1){
                           console.log('adminpremissionsss',adminpremissionsss.adminpremissionqqq);
                           if(adminpremissionsss.adminpremissionqqq.length > 0){ 
                                  setisadminpremission(adminpremissionsss.adminpremissionqqq[0].join_user_id);
                           }
                           
                    }
             } catch (error) { throw error;}      
      } 


     const [iscoverphotoss, setiscoverphotos] = useState(false);
     const coverphotos = async() =>{
       setiscoverphotos(iscoverphotoss === false ? true : false);
       setisgroupphotoss(false);
     } 
     const [isgroupphotoss, setisgroupphotoss] = useState(false);
     const groupphotos = async() =>{
       setisgroupphotoss(isgroupphotoss === false ? true : false);
       setiscoverphotos(false);
     }
    
     const [isviewgroups, setisviewgroups] = useState([]);
     const [isviewgroupsdesc, setisviewgroupsdesc] = useState([]);
     const [isviewgroupsurl, setisviewgroupsurl] = useState([]);
     const [isviewgroupsname, setisviewgroupsname] = useState([]);
     const [isviewgroupsids, setisviewgroupsids] = useState([]);
     const [isviewgroupsuserids, setisviewgroupsuserids] = useState([]);
       const groupspreviews = async (groupsids) => {
              try {
                     const groupspreviewsqqq = await viewgroupslists(groupsids);
                            if(groupspreviewsqqq.success === 1){
                                   setisviewgroups(groupspreviewsqqq.viewsall);
                                   setisviewgroupsdesc(groupspreviewsqqq.viewsall[0].groups_description);
                                   setisviewgroupsurl(groupspreviewsqqq.viewsall[0].groups_website);
                                   setisviewgroupsname(groupspreviewsqqq.viewsall[0].groups_name);
                                   setisviewgroupsuserids(groupspreviewsqqq.viewsall[0].groups_userid);
                                   setisviewgroupsids(groupspreviewsqqq.viewsall[0].groups_id);
                                   userdetails(groupspreviewsqqq.viewsall[0].groups_userid);
                                   joinsall(props.match.params.Addpreview,props.match.params.Addpreviewuser);
                            }
              } catch (error) { throw error;}    
       }
       
       const [loadingjoin, setloadingjoin] = useState(false);
       const joinsgroups = async (groupsids,userids) => {
              try {
                     setloadingjoin(true);
                     const joinsgroupsss = await joinsgroupslists(groupsids,userids);
                            if(joinsgroupsss.success === 1){
                                   joinsall(props.match.params.Addpreview,props.match.params.Addpreviewuser);
                                   setloadingjoin(false);
                            }
                     setloadingjoin(false);
              } catch (error) { throw error;}    
       }
       const leavegroups = async (groupsids,userids) => {
              try {
                     const leavegroupsss = await leavegroupslist(groupsids,userids);
                            if(leavegroupsss.success === 1){
                                   joinsall(props.match.params.Addpreview,props.match.params.Addpreviewuser);
                            }
              } catch (error) { throw error;}    
       }
       const [isjoinstatus, setisjoinstatus] = useState([]);
       const [isjoinstatusactive, setisjoinstatusactive] = useState([]);
       const joinsall = async (groupsids,userids) => {
              try {
                     const joinsallqqq = await joinsalllist(groupsids,userids);
                            if(joinsallqqq.success === 1){
                                   console.log('mmmmmmmm',joinsallqqq.joinsliststatus);
                                   setisjoinstatus(joinsallqqq.joinsliststatus);
                                   if(joinsallqqq.joinsliststatus.length){
                                          setisjoinstatusactive(joinsallqqq.joinsliststatus[0].status);
                                   }
                            }
              } catch (error) { throw error;}    
       }
       
       const [ismembername, setmembername] = useState();
       const [ismemberprofile, setmemberprofile] = useState();
       const userdetails = async(storageid) =>{
              try {
                     const viewuser = await viewuserprofile(storageid);
                     if(viewuser.success === 1){
                            console.log('memberlist',viewuser.memberlist);
                            setmembername(viewuser.memberlist[0].name);
                            setmemberprofile(viewuser.memberlist[0].profile_image);
                     }
              } catch (error) { throw error;}      
       } 

       const [isHome, setHome] = useState(false);
       const [isMembers, setMembers] = useState(false);
       const [isSettings, setSettings] = useState(false);
       const [isGallery, setGallery] = useState(false);
        
      const Home =()=>{
           setHome(false);setMembers(false);setSettings(false);setGallery(false);
        }
      const Members =()=>{
           setHome(true);setMembers(true);setSettings(false);setGallery(false);
        }
      const Settings =()=>{
           setHome(true);setMembers(false);setSettings(true);setGallery(false);
        }
      const Gallery =()=>{
           setHome(true);setMembers(false);setSettings(false);setGallery(true);
        }
        const externallinks = (url) => {
              if (!!url && !!url.trim()) { 
                     url = url.trim(); 
                     if (!/^(https?:)?\/\//i.test(url)) { 
                         url = 'http://' + url; 
                         window.open(url, '_blank');
                     }else{
                            window.open(url, '_blank');
                     }
                 } 
            }       
            return ( 
                   <div className="groupsallwww">
                   <div className="container">
                   <div className="row">
{isviewgroups.length > 0 ? <>                           
{isviewgroups.map((item, index) => ( 
       <div className="col-md-12" key={item.groups_id}>
              <div className="cover-image-container">
             

                     {/* <div className="header-cover-image" style={item.coverimage === '' ? {backgroundImage: `url(${covers})` } : {backgroundImage: `url(${BaseURLuploads+item.coverimage})` } }>  */}
                     <div className="header-cover-image" >
                            {item.coverimage !== '' ? 
                                   <img src={BaseURLuploads+item.coverimage} alt="coverimg" className="yyy16" />
                            : 
                                   <img src={covers} alt="coverimg" className="yyy16" />
                            }
                     <h1 className="ccc10">{item.groups_name}</h1>
                            <div className="group-item-actions">
                                   <div className="moderators-lists">
                                          <div className="moderators-title"> Group Admin </div>
                                          <ul className="user-list">
                                                 <li>
                                                        {ismemberprofile !=='' ? 
                                                               <img src={BaseURLuploads+ismemberprofile} alt="" className="ccc9" />
                                                        :
                                                               <img src={userphotos} alt="" className="ccc9"  />
                                                        }
                                                        <h2> {ismembername} </h2>
                                                       
                                                 </li>
                                          </ul>
                                   </div>
				</div>
                     


                     {isAuth === true && theUser === item.groups_userid || isadminpremission === theUser ? <>
                            {item.coverimage === '' ? <>
                                  <p className="ccc11" onClick={()=>coverphotos()}> Add Cover Photo </p>
                                  {iscoverphotoss === true ? 
                                          <CoverPhoto groupsids={props.match.params.Addpreview} cancels={()=>coverphotos()} refreshcovers={()=>  groupspreviews(props.match.params.Addpreview)}/>
                                   : null }
                            </>
                            : <>
                                   <p className="ccc11" onClick={()=>coverphotos()}> Edit Cover Photo </p>
                                   {iscoverphotoss === true ? 
                                          <CoverPhoto groupsids={props.match.params.Addpreview} cancels={()=>coverphotos()} refreshcovers={()=>  groupspreviews(props.match.params.Addpreview)}/>
                                   : null }
                            </>
                            } 
                     </>: null }


                     <div className="generic-button">
                     {isAuth === true && theUser !== '' ? <>
              {isjoinstatus.length > 0 ? <>   
                     {isjoinstatus.map((item, index) => ( 
                            <div  key={item.join_id}>
                                   {item.status === 1 || item.status === 4 ? 
                                    <button className="group-buttonsss" onClick={()=>leavegroups(props.match.params.Addpreview,theUser)} >Leave Group</button>
                                   :<>
                                    { item.status !== 3 &&  item.super !== 1 ? <>
                                          {loadingjoin ?
                                                 <><Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/></>
                                          : 
                                                 <button className="group-buttonsss" onClick={()=>joinsgroups(props.match.params.Addpreview,theUser)} > Join Group </button>
                                          }
                                   </>: null }
                                   </>
                                   }
 
                            </div>
                     ))}
              </> : <>
               {isAuth === true && theUser !== item.groups_userid ? <>
                     {loadingjoin ?
                            <><Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/></>
                     : 
                            <button className="group-buttonsss" onClick={()=>joinsgroups(props.match.params.Addpreview,theUser)} > Join Group </button>
                     }
              </> : null }
              </>
              }       
                           

                     </> : null }
                            
                     </div>


                     </div>
                     <div className="col-md-3">
                     
                            <div className="item-header-avatar">
                           
                            {item.image === '' ? <>
                            <p> &nbsp;</p>
                            {isAuth === true && theUser === item.groups_userid || isadminpremission === theUser ? <>
                                  <span onClick={()=>groupphotos()}> Add Photo </span>
                                  {isgroupphotoss === true ? 
                                          <GroupPhoto groupsids={props.match.params.Addpreview} cancels={()=>groupphotos()} refreshphoto={()=>  groupspreviews(props.match.params.Addpreview)}/>
                                   : null }
                            </>: null }
                                    <img src={userphotos} className="avatar group-4-avatar " alt="Groupphoto" />
                            </>
                            : <>
                            <p> &nbsp;</p>
                            {isAuth === true && theUser === item.groups_userid || isadminpremission === theUser? <>
                                   <span onClick={()=>groupphotos()}> Edit Photo </span>
                                   {isgroupphotoss === true ? 
                                          <GroupPhoto groupsids={props.match.params.Addpreview} cancels={()=>groupphotos()} refreshphoto={()=>  groupspreviews(props.match.params.Addpreview)} />
                                   : null }
                            </>: null }
                                    <img src={BaseURLuploads+item.image} className="avatar group-4-avatar " alt="Groupphoto" />
                            </>
                            } 
                   
                                  
                                  
                            </div>
			</div>

                     <div className="col-md-9">

 


<ul className="profile-nav">	
       <li  onClick={Home} className={isHome? "bp-groups-tab": "groupactives" } >
              <FontAwesomeIcon icon={['fas','home']} className="groups-tabsss" />
              <span className="nav-link-text">Home</span>   
       </li>
       <li onClick={Members} className={ isMembers ? "groupactives": "bp-groups-tab" }>
              <FontAwesomeIcon icon={['fas','users']} className="groups-tabsss" />
              <span className="nav-link-text">Members</span>
       </li>
     
       {isAuth === true && theUser === isviewgroupsuserids && isviewgroupsids == props.match.params.Addpreview || isAuth === true && theUser === isadminpremission && isviewgroupsids == props.match.params.Addpreview ?
       <li onClick={Settings} className={ isSettings ? "groupactives": "bp-groups-tab" }>
              <FontAwesomeIcon icon={['fas','cog']} className="groups-tabsss" />
              <span className="nav-link-text"> Settings </span>
       </li>
       : null }
       <li onClick={Gallery} className={ isGallery ? "groupactives": "bp-groups-tab" }>
              <FontAwesomeIcon icon={['fas','images']} className="groups-tabsss" />
              <span className="nav-link-text"> Gallery </span>
       </li>
</ul>




			</div>


              </div>
       </div>
))}

                     
{isAuth === true && theUser !=='' && isHome === false ?
       <div className="yyy">
              
              <div className="col-md-3">
                     <h5 className="widget-title">About Group</h5>
                     <p>{isviewgroupsdesc}</p>
                     <p className="urlsqwsss" onClick={() => externallinks(isviewgroupsurl)}>{isviewgroupsurl}</p>
              
              </div>
              <div className="col-md-9 yyy5">
              {isviewgroupsuserids !== '' ?
                     <GroupPost groupid={props.match.params.Addpreview} storageid={props.match.params.Addpreviewuser} groupusercreateid={isviewgroupsuserids} statuspermision={isjoinstatusactive}  adminpremissionsss={isadminpremission}  />
              : null}   
              </div>
       </div>
: null } 
{isAuth === true && theUser !=='' && isMembers === true ?
<div className=" yyywww yyy">

              <div className="col-md-3">
                     <h5 className="widget-title">About Group </h5>
                     <p>{isviewgroupsdesc}</p>
                     <p className="urlsqwsss" onClick={() => externallinks(isviewgroupsurl)}>{isviewgroupsurl}</p>
              
              </div>
              <div className="col-md-9 yyy5">
             
              <Membersall loadids={props.match.params.Addpreview} groupuserid ={isviewgroupsuserids}adminpremissionsss={isadminpremission} />

              </div>
       </div>
: null } 

{isAuth === true && theUser === isviewgroupsuserids && isSettings === true && isviewgroupsids == props.match.params.Addpreview || isAuth === true && theUser === isadminpremission && isSettings === true && isviewgroupsids == props.match.params.Addpreview ?
<div className=" yyywww yyy">

              <div className="col-md-3">
                     <h5 className="widget-title">About Group </h5>
                     <p>{isviewgroupsdesc}</p>
                     <p className="urlsqwsss" onClick={() => externallinks(isviewgroupsurl)}>{isviewgroupsurl}</p>
              
              </div>
              <div className="col-md-9 yyy5">
             
              <Settingsall name={isviewgroupsname} desc={isviewgroupsdesc} url={isviewgroupsurl} groupuserid ={isviewgroupsuserids}  loadsss={()=>groupspreviews(props.match.params.Addpreview)} loadids={props.match.params.Addpreview} />

              </div>
       </div>
: null } 

{isAuth === true && theUser !=='' && isGallery === true && isviewgroupsids == props.match.params.Addpreview?
<div className=" yyywww yyy">

              <div className="col-md-3">
                     <h5 className="widget-title">About Group </h5>
                     <p>{isviewgroupsdesc}</p>
                     <p className="urlsqwsss" onClick={() => externallinks(isviewgroupsurl)}>{isviewgroupsurl}</p>
              
              </div>
              <div className="col-md-9 yyy5">
           
              <Galleryall loadids={props.match.params.Addpreview} groupuserid ={isviewgroupsuserids} theUser={theUser} groupidsss={isviewgroupsids} adminpremissionsss={isadminpremission} />

              </div>
       </div>

: null } 










</> : <>   
       <div className="container">
              <div className="row">
                     <div className="col-md-3" > &nbsp;</div>
                     <div className="col-md-6 loadinsstopsss" >
                            <div className="loadings ">
                                   <FontAwesomeIcon icon={['fas','check-circle']} />
                                   <p> Loading the group. Please wait. </p>
                            </div>
                     </div>
              </div>
       </div>
 
 </>}    


  
                     
                     
             </div>
             </div>
             </div>
              );
          }
   
export default Groups;
