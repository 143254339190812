import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'react-loader-spinner';
import { useHistory } from "react-router-dom";

import {Link } from "react-router-dom"; 
import ContentLoader from "react-content-loader";
import Popup from 'reactjs-popup';
import userphotos from '../Img/user_profile.jpg';
import {toast} from "react-toastify";
import {WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function Membersall(props) {

       
       const {rootState,groupmembersall,newmessageadds,makeadmisads,removeadmisads,removeadmisgrouplist,Blockadmisgroupqqq} = useContext(MyContext);
       const {theUser} = rootState;
       
       useEffect( () => {
        groupmembers(props.loadids);
           }, []); 
           const [isgroupmembers, setgroupmembers] = useState([]);
           const [isgroupadmins, setgroupadmins] = useState([]);
           const groupmembers = async(groupids) =>{
              try {
                     const groupmembersqqq = await groupmembersall(groupids);
                            if(groupmembersqqq.success === 1){
                                   console.log('groupmemberslist',groupmembersqqq.groupmemberslist);
                                   setgroupmembers(groupmembersqqq.groupmemberslist) ;
                                   setgroupadmins(groupmembersqqq.groupadmins) ;
                     }
              } catch (error) { throw error;}
            }
     
       const [isMembers, setMembers] = useState(false);
       const [isAdmin, setAdmin] = useState(false);
             
       const Members =()=>{
              setMembers(false);setAdmin(false);
       }
       const Admin =()=>{
              setMembers(true);setAdmin(true);
       }
       const [userInfo, setuserInfo] = useState({
              messages: '',
          });
       const onChangeValue = (e) => {
              setuserInfo({
                  ...userInfo,
                      [e.target.name]:e.target.value
              });
             
       }
       const newmessage = async(event) =>{
              try {
                     event.preventDefault();
                     event.persist();
                     const newmessagesss = await newmessageadds(userInfo,theUser,userInfo.postids.value);
                     if(newmessagesss.success === 1){
                            event.target.reset(); 
                            setuserInfo({
                                   ...userInfo,
                                   messages:'',
                               });
                            
                     }
              } catch (error) { throw error;}      
       }
        const makeadmis = async(userids) =>{
              try {
                     const makeadmissss = await makeadmisads(userids,props.loadids);
                     if(makeadmissss.success === 1){
                            groupmembers(props.loadids);
                            setmore(false);
                           
                     }
              } catch (error) { throw error;}      
       }
       const removeadmis = async(userids) =>{
              try {
                     const removeadmisss = await removeadmisads(userids,props.loadids);
                     if(removeadmisss.success === 1){
                            groupmembers(props.loadids);
                            setmore(false);
                     }
              } catch (error) { throw error;}      
       }
       const removeadmisgroup = async(userids) =>{
              try {
                     const removeadmisgroupsss = await removeadmisgrouplist(userids,props.loadids);
                     if(removeadmisgroupsss.success === 1){
                            groupmembers(props.loadids);
                            setmore(false);
                     }
              } catch (error) { throw error;}      
       }
        const Blockadmisgroup = async(userids) =>{
              try {
                     const Blockadmisgroupsss = await Blockadmisgroupqqq(userids,props.loadids);
                     if(Blockadmisgroupsss.success === 1){
                            groupmembers(props.loadids);
                            setmore(false);
                     }
              } catch (error) { throw error;}      
       }
       
const [issetmore, setmore] = useState(false);
const [issetmoreids, setmoreids] = useState(false);
       const moreoptions = (ids) =>{
              setmoreids(ids)
              setmore(issetmore === false ? true : false);
              
       }

        return(
       <div className="ud-cen-s1mmm">
              <div className="ud-cen-s1s">
                     <ul className="profilelist">

                      <li  onClick={Members} className={isMembers? "bp-groups-tab": "groupactivesmembers" } >
                            <FontAwesomeIcon icon={['fas','user-friends']} className="groups-tabsss" />
                            <span className="nav-link-text"> All Members </span>   
                     </li>
                     <li onClick={Admin} className={ isAdmin ? "groupactivesmembers": "bp-groups-tab" }>
                            <FontAwesomeIcon icon={['fas','user-tie']} className="groups-tabsss" />
                            <span className="nav-link-text"> Admin </span>
                     </li>
       </ul>  
     
{isMembers === false ? <>
       {isgroupmembers.map((item, index) => ( 
               <div className="col-md-4" key={item.join_id}> 
              <div className="list-wrapswqasas">
                     <div className="more-post-optns">
                            <FontAwesomeIcon icon={['fas','ellipsis-h']} className="enablelikes"  onClick={() => moreoptions(item.join_id)} />
                            {issetmore === true && issetmoreids === item.join_id ? 
                     <ul>
                            <Popup trigger={<li> <FontAwesomeIcon icon={['fas','envelope-open']} className="enablelikes"/> Message </li>} position="top left">
                            {close => (
                            <div className="feedspopups">
                                   <a className="close" onClick={close}>
                                   &times;
                                   </a>
                     <div className="feeds">
                     <div className="feedswww">
                                   <h1> Message </h1>
                     <form onSubmit={newmessage} className="login-forms">
                            <textarea  name="messages" value={userInfo.messages} className=" col-md-12" rows="3"  placeholder="  "  onChange={onChangeValue} required />
                            <input type="hidden" name="postids" ref={(val) => userInfo.postids = val} className="form-control" defaultValue={item.register_id} />
                            <div className="form-group col-sm-12 text-right">
                                   <button type="submit" className="db-tit-btn2"> Send  </button>
                            </div>
                     </form>
                            
                            
                            </div>
                            </div>
                            </div>
                            )}
                            </Popup>
                         
                      {props.groupuserid === theUser || props.adminpremissionsss === theUser ? <>
                            <li onClick={() => makeadmis(item.join_user_id)}> <FontAwesomeIcon icon={['fas','user-tag']} className="enablelikes"/> Make Admin </li>
                            <li onClick={() => removeadmisgroup(item.join_user_id)}> <FontAwesomeIcon icon={['fas','user-tag']} className="enablelikes"/> Remove from Group </li>
                            <li onClick={() => Blockadmisgroup(item.join_user_id)}> <FontAwesomeIcon icon={['fas','user-tag']} className="enablelikes"/> Block from Group </li>
                            </>
                     : null }

                     </ul>
       : null }
                     </div>

                            <div className="item-avatar">
                                   {item.profile_image !=='' ? 
                                          <img src={BaseURLuploads+item.profile_image} alt="" className="ccc9" />
                                   :
                                          <img src={userphotos} alt="" className=""  />
                                   }
                                   <h4>{item.name}</h4>
                            </div>

                            <button className="buttons2" > View Profile </button> 

              </div>
              </div> 
             
       ))}


</>   : null }













{isAdmin === true ? <>
{isgroupadmins.map((item, index) => ( 
       <div className="col-md-4" key={item.join_id}>
  <div className="list-wrapswqasas">
  <div className="more-post-optns">
                            <FontAwesomeIcon icon={['fas','ellipsis-h']} className="enablelikes"  onClick={() => moreoptions(item.join_id)} />
                            {issetmore === true && issetmoreids === item.join_id ? 
                     <ul>
                            <Popup trigger={<li> <FontAwesomeIcon icon={['fas','user-edit']} className="enablelikes"/> Message </li>} position="top left">
                            {close => (
                            <div className="feedspopups">
                                   <a className="close" onClick={close}>
                                   &times;
                                   </a>
                     <div className="feeds">
                     <div className="feedswww">
                                   <h1> Message </h1>
                     <form onSubmit={newmessage} className="login-forms">
                            <textarea  name="messages" value={userInfo.messages} className=" col-md-12" rows="3"  placeholder="  "  onChange={onChangeValue} required />
                            <input type="hidden" name="postids" ref={(val) => userInfo.postids = val} className="form-control" defaultValue={item.register_id} />
                            <div className="form-group col-sm-12 text-right">
                                   <button type="submit" className="db-tit-btn2"> Send  </button>
                            </div>
                     </form>
                            
                            
                            </div>
                            </div>
                            </div>
                            )}
                            </Popup>
                            {props.groupuserid === theUser && item.status !== 3 && item.status === 4 ?
                            <li onClick={() => removeadmis(item.join_user_id)}> <FontAwesomeIcon icon={['fas','user-tag']} className="enablelikes"/> Remove Admin  </li>
                     : null }
                     </ul>
       : null }
                     </div>
             <div className="item-avatar">
                                   {item.profile_image !=='' ? 
                                          <img src={BaseURLuploads+item.profile_image} alt="" className="ccc9" />
                                   :
                                          <img src={userphotos} alt="" className=""  />
                                   }
                                   <span>   Admin </span>
                                   <h4>{item.name}</h4>
                            </div>
              
                            <button className="buttons2" > View Profile </button> 
                            </div>
            
       </div>
))}
  </>   : null }

          </div>
          </div>
        );
    }

export default Membersall;