import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Classifiedsedits from './Classifiedsedits';

import userphotos from '../Img/user_profile.jpg';

import {Link } from "react-router-dom"; 
import ContentLoader from "react-content-loader";
import { WEB_URL_UPLOAD } from '../contexts/Apilinks';

let BaseURLuploads = WEB_URL_UPLOAD;


function ClassifiedsPreview(props) {

    const {classifiedspreview,rootState} = useContext(MyContext);
    const {theUser} = rootState;
    const [isbookpreview, setisbookpreview] = useState([]);
    const [addClass, setaddClass] = useState();
    const [isEditing, setisEditing] = useState(false);
    const [isEditingplan, setisEditingplan] = useState(false);
    //const loaderplatinum = [1,2,3,4,5,6,7,8];
    useEffect( () => {
       classifiedslistpreview(props.match.params.Addpreview);
      }, []); 
   
      const classifiedslistpreview = async (ids) => {
       window.scrollTo(0, 0);
      
       try {
                     const dataaddpreview = await classifiedspreview(ids);
                     console.log(dataaddpreview);
                     if(dataaddpreview.success === 1){
                            console.log(dataaddpreview.classifiedlist);
                            setisbookpreview(dataaddpreview.classifiedlist);
                     }
              } catch (error) { throw error;}    
       }
       const editModepreview =() =>{
              setisEditing(true);
       }
       const editModepreviewplan =() =>{
              setisEditingplan(true);
       }
       const editModepreviewchildedit =() =>{
              setisEditing(false);
              setisbookpreview([]);
              classifiedslistpreview(props.match.params.Addpreview);
       }
      
      const toggle = () => {
               setaddClass({addClass: !addClass});
            }
            
       let boxClass = ["redmoesss"];
       if(addClass) {
              boxClass.push('redmoesssqqq');
       
       }


       const externallinks = (url) => {
              if (!!url && !!url.trim()) { 
                     url = url.trim(); 
                     if (!/^(https?:)?\/\//i.test(url)) { 
                         url = 'http://' + url; 
                         window.open(url, '_blank');
                     }else{
                            window.open(url, '_blank');
                     }
                 } 
            }  

   

        return(
               <>
               <div className="clearfix"></div>
       <div className="bookadspreview">
            
              <div className="container">
                     <div className="row">
                     <div className="col-md-1"> &nbsp; </div>
                     {isbookpreview.length > 0 ? <>
                     {isbookpreview.map((item, index) => ( 
                             <div key={item.classified_id} className="col-md-10" style={{padding:0}}> 
                          
                     {isEditing === true && isEditingplan === false ?
                              <Classifiedsedits classifiedid={props.match.params.Addpreview} title={item.title}  description={item.description} website={item.website} phone={item.phone} category={item.category} other={item.other} editModepreviewchilds={editModepreviewchildedit } email={item.email} />
                          
                             : <>
                           
                            <div className="col-md-12 ">
                                   
                            {theUser === item.class_member_id || theUser === 2  || theUser === 6 ? <>  
                                   <button className="editsss" onClick={editModepreview}> Edit <FontAwesomeIcon icon={['fas','edit']} className="iconsss2" /></button>
                                  
                                
                             </>: null}

                           
                     <div className="clearfix"></div>

                    
<div className="col-md-12 " style={{padding:0}}>
<div className="clearfix"></div>
                 
                    
<h1>{item.title}</h1>
                            <hr />

    
       <div className="col-md-12 ">
              <img src={BaseURLuploads+item.image}  alt="" className="msmqqsss" />
       </div>
              <div className="col-md-12 aaa11" style={{padding:0}}>
              <div className="col-md-3 ">
              {item.profile_image !== '' ? <>
                     <img src={BaseURLuploads+item.profile_image} alt="userprofile" className=" aaa10" />
              </> :  <>
                     <img src={userphotos} alt="userprofile" className=" aaa10"/>
              </>
              }
                            <h2 className="usernamesss">{item.name}</h2>


                            <Link to={`/ProfileView/${item.register_id}`} >
                            <button className="buttons2" > View Profile </button> 
                            </Link>

               <Link to={`/MemberAds/${item.register_id}`} >
                            <button className="buttons2" > View all Ads by this member </button> 
                            </Link>


       </div>      
        <div className="col-md-9 aaa12">
                          <p> <b> Category </b>  : <>
                             {item.category === 'other' ?
                                <>{item.other}</>
                              :  <>{item.category}  </>}
                               </></p>
                        
                        
                          <p> <b> Email </b> : <>{item.email} </> </p>
                          <p> <b> Phone </b>   : <>{item.phone} </> </p>
                          <p> <b> Website Link </b> : <span onClick={() => externallinks(item.website)}>{item.website} </span> </p>

                          </div>
                         
        </div>
                                  
                         
                          </div>
                          <div className="col-md-12" style={{backgroundColor: '#ffffffb3'}}>
                      
                          <hr />
                      
                          <div className="bookadspreviewwww" dangerouslySetInnerHTML={{ __html: item.description }} />
                           

                            

</div>





                          </div>
         
                            
                      </>
                   
                     }



                      </div>
                      ))}
                      </>
                      : 
                      <>   <div className="col-md-8 ">
                      <ContentLoader height={1700} width={1000} speed={3} primarycolor="#f3f3f3"  secondarycolor="#ecebeb" > 
                      <rect x="0" y="107" rx="0" ry="0" width="800" height="477"/> 
                      
                      </ContentLoader>
             
                      </div> 
                     
                   </>
              }
             







              
             </div>
             </div>
             </div>
             </>
        );
    }

export default ClassifiedsPreview;