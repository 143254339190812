import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';

import Loader from 'react-loader-spinner';
import {toast} from "react-toastify";
import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function CoverPhoto(props) {
       const {coverphotoupdatelist} = useContext(MyContext);
       const [userInfo, setuserInfo] = useState({
              file:'',
          });
     
    
       const [loadingedit, setloadingedit] = useState(false);
       const [fileempty, setfileempty] = useState(false);
       const [filetemp, setfiletemp] = useState(null);
       const [invalidImage, setinvalidImage] = useState(null);
       const coverphotos = async() =>{
              props.cancels();
            }
       const onChangeValue = (e) => {
              setuserInfo({
                  ...userInfo,
                      [e.target.name]:e.target.value
              });
             
       }    
       const coverphotoupdate = async(event) =>{
              try {
                     setloadingedit(true);
                     event.preventDefault();
                     event.persist();
                     if(invalidImage !== null){
                            toast.error(invalidImage, {
                              position: "top-center",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                            }); 
                            setloadingedit(false);
                            return;
                          }
                         
                     const coverphotoupdatesss = await coverphotoupdatelist(userInfo,props.groupsids,);
                     if(coverphotoupdatesss.success === 1){
                            setloadingedit(false);
                            event.target.reset(); 
                            props.refreshcovers(props.groupsids);
                            props.cancels();
                     }else{
                            setloadingedit(false);
                             toast.error( 'Please Try Again', {
                               position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                             });  
                     }
              } catch (error) { throw error;}      
       }


       const resetFile = (event) => {
              event.preventDefault();
              setfiletemp(null)
            }

            let reader = new FileReader();

   const handleChange = (event) => {
       const imageFile = event.target.files[0];
             const imageFilname = event.target.files[0].name;

             if (!imageFile) {
              setinvalidImage('Please select image.');
               return false;
             }
         
             if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif)$/)) {
              setinvalidImage('Please select valid image JPG,JPEG,PNG');
               return false;
             }
             reader.onload = (e) => {
               const img = new Image();
               img.onload = () => {

//----------------------------- Resize img code -------------------------------------------------
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);

                var MAX_WIDTH = 1537;
                var MAX_HEIGHT = 437;
                var width = img.width;
                var height = img.height;

                if (width > height) {
                  if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                  }
                } else {
                  if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                  }
                }
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
                ctx.canvas.toBlob((blob) => {
                  const file = new File([blob], imageFilname, {
                      type: 'image/jpeg',
                      lastModified: Date.now()
                  });
                  setuserInfo({
                     ...userInfo,
                     file:file,
                })
                }, 'image/jpeg', 1);


//-------------------------------------------------------------------------------------
              setfiletemp( URL.createObjectURL(imageFile))
              setfileempty(true);
              setinvalidImage(null)
              };
               img.onerror = () => {
                     setinvalidImage('Invalid image content.');
                 return false;
               };
               //debugger
               img.src = e.target.result;
             };
             reader.readAsDataURL(imageFile);

     }; 

    
  

  return (
  
       <>
            <div className="covergroups">
            <div className="">
                  
                     <form onSubmit={coverphotoupdate} className="login-forms">
                          
                            <input type="file" name="file" className="form-control"  onChange={handleChange}   required/>
                                 
                            
                            {loadingedit ?
                            <>
                                   <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                            </>
                            : 
                                   <div className="form-group col-sm-12 text-right">
                                          <button type="submit" className="db-tit-btn2"> Save  </button>
                                          <h6 onClick={()=>coverphotos()}> Cancel </h6>
                                   </div>
                             }

                     </form>
                     </div>
                     </div>

       </>
   
  );
}

export default CoverPhoto;