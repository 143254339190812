import React,{useContext,useState,useEffect, useRef,useCallback} from 'react';
import { MyContext } from '../contexts/MyContext';
import {Link } from "react-router-dom"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';
import {toast } from "react-toastify";
import userphotos from '../Img/user_profile.jpg';
import { WEB_URL,WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURL = WEB_URL;
let BaseURLuploads = WEB_URL_UPLOAD;

function Messagelistall(props) {
  
  const {rootState,messageallsss,newmessageaddsreplay} = useContext(MyContext);
  const {isAuth,theUser} = rootState;

//   useEffect( () => {
//        messageall(props.match.params.Addpreview); 
    
//       }, []);
      const [pageNumber, setPageNumber] = useState(1);
      
  const observer = useRef()
  const loadingRef = useCallback(node => {
    
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting ) {
        setPageNumber(prevPageNumber => prevPageNumber + 1)
        //latestfeeds(prevPageNumber => prevPageNumber + 1)
      }
    })
    if (node) observer.current.observe(node)
  }, [])
  useEffect( () => {
    window.scrollTo(0, 0);
    
}, []); 
const [isFetching, setIsFetching] = useState(false);
  useEffect( () => {
    if (isFetching === true) return;
   
    messageall(pageNumber,props.match.params.Addpreview); 
        return () => {
          console.log("This will be logged on unmount");
        }
  }, [pageNumber])
      const [isqqq, setqqq] = useState([]);  
      const [ismessage, setismessage] = useState([]);
      const messageall = async (pageNumber,profileid) => {
       try {
              const messageallqqq = await messageallsss(pageNumber,profileid);
                     if(messageallqqq.success === 1){
                            console.log('message list',messageallqqq.messagealllist)
                            setismessage(prevBooks => {
                                   return [...prevBooks, ...messageallqqq.messagealllist]
                            })
                         
                            if(messageallqqq.messagealllist.length < 1){
                                   setIsFetching(true);
                            }
                            //setismessage(messageallqqq.messagealllist);

const nnn = messageallqqq.messagealllist.map (movie => movie.messages_id )      
              console.log('uuuuu',nnn);

const todoIdList =nnn;
              console.time('.map()')
Promise.all(todoIdList.map(id => {
 
   return new Promise((resolve) => {
      fetch(BaseURL+`replymessage/${id}`)
      //fetch(BaseURL+`slivercounts/${id.class_member_id}`)
      .then(response => {
            return new Promise(() => {
                     response.json()
                     .then(todo => {
                       
                     if(todo.replyallsss.length > 0){
                            console.log('messagereplaysss',todo.replyallsss);
                            //setqqq(todo.slivercountslist.length);
                            setqqq(prevBooks => {
                                 return [...prevBooks, ...todo.replyallsss]
                              });  

                              // const nnnqqq = todo.slivercountslist.map (movieqqq => movieqqq.class_member_id )      
                              // console.log('uuuuuqqq',nnnqqq.length);
                             
                              //  ss(nnnqqq.length  );
                     }
                  resolve()
               })
         })
      })
    })
 }))
 .then(() => {console.timeEnd('.map()'); })



                     }
              } catch (error) { throw error;}    
       } 
       const [userInfo, setuserInfo] = useState({
              messages: '',
          });
       const onChangeValue = (e) => {
              setuserInfo({
                  ...userInfo,
                      [e.target.name]:e.target.value
              });
             
       }
       const newmessage = async(event) =>{
              try {
                     
                     event.preventDefault();
                     event.persist();
                     const newmessagesss = await newmessageaddsreplay(userInfo,props.match.params.Addpreview,userInfo.sendid.value,userInfo.messagesid.value);
                     if(newmessagesss.success === 1){
                            event.target.reset(); 
                            setuserInfo({
                                   ...userInfo,
                                   messages:'',
                               });
                               toast.info('Message Sent Successfully',{
                                   position: "top-center"});     
                                   setismessage([]);
                                   setqqq([]);
                                   setPageNumber(1);
                            messageall(props.match.params.Addpreview); 
                            setmore(issetmore === false ? true : false);
                     }
              } catch (error) { throw error;}      
       }
       const [issetmore, setmore] = useState(false);
       const [issetmoreids, setmoreids] = useState(false);

       const moreoptions = (ids) =>{
              setmoreids(ids)
              setmore(issetmore === false ? true : false);
              
       }
  return (
  
    <div className="notificationsss">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
          <span  className="bizoctaclosss"><Link to={`/ProfileView/${props.match.params.Addpreview}`} > <FontAwesomeIcon icon={['fas','times-circle']} /> </Link></span>
            <h1>Inbox</h1>
            <div className="col-md-12"> 
            <ul className="notificationqqq2">
            {ismessage.map((item, index) => (  
       <li ref={loadingRef}   key={item.messages_id}>
              {item.profile_image !=='' ? 
                     <img src={BaseURLuploads+item.profile_image} alt="" className="mr-2mmm" />
              :
               <img src={userphotos} alt="" className="mr-2mmm"  />
              }
                  
              <span> {item.name} </span> <br/>
              <span className="timqqq"> <FontAwesomeIcon icon={['fas','clock']} className="iconsss2 " /> <Moment format="MMM D, YYYY" withTitle>
                                       {item.date} 
                                    </Moment></span>
              <div className="clearfix "></div>
              <div className="col-md-12 ">
                    <p>{item.message} </p> 
              </div>

              <div className="col-md-12 ">     

{item.replay === 1 ? <>               
       {isqqq.map((itemreplay, index) => ( <span key={itemreplay.messagesreplay_id} > 
              {itemreplay.replaymessage_userids === item.profile_ids && item.messages_id === itemreplay.messagesids ? <>  
                     <div className="rplsssqqq" >
                            <p>{itemreplay.replaymessage} </p> 
                     </div>
                                 
                    
              </>: null }
       </span>
       ))}
</>: null }

</div>
              <span className="mnx2" onClick={() => moreoptions(item.messages_id)}> Reply </span>
{issetmore === true && issetmoreids === item.messages_id ? 
              <form onSubmit={newmessage} className="login-forms">
              <textarea  name="messages" value={userInfo.messages} className=" col-md-12" rows="3"  placeholder="  "  onChange={onChangeValue} required />
              <input type="hidden" name="sendid" ref={(val) => userInfo.sendid = val} className="form-control" defaultValue={item.message_userids} />
              <input type="hidden" name="messagesid" ref={(val) => userInfo.messagesid = val} className="form-control" defaultValue={item.messages_id} />
           
                            <div className="form-group col-sm-12 text-right">
                                   <button type="submit" className="db-tit-btn2"> Send </button>
                            </div>
                     </form>
: null }
       </li>
       
))}
            </ul>




          
        
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Messagelistall;