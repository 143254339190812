import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';

import Loader from 'react-loader-spinner';

import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function CommentEditgroup(props) {
       const {newcommentupdatelistgroup} = useContext(MyContext);
      
      
   
       const [userInfocomment, setuserInfocomment] = useState({
              comment: props.feedcomment,
              file:'',
          });
     
       const [isdeleteimg, setdeleteimg] = useState(false);
       const [loadingedit, setloadingedit] = useState(false);
       const [fileempty, setfileempty] = useState(false);
       const [filetemp, setfiletemp] = useState(null);
       const [invalidImage, setinvalidImage] = useState(null);

       const onChangeValue = (e) => {
              setuserInfocomment({
                  ...userInfocomment,
                      [e.target.name]:e.target.value
              });
             
       }    
      
     
       const deletepostimg = async() =>{
              setdeleteimg(true);
       }
       const newcommentupdate = async(event) =>{
              try {
                     setloadingedit(true);
                     event.preventDefault();
                     event.persist();
                     const newcommentupdatesss = await newcommentupdatelistgroup(userInfocomment,userInfocomment.fileempty.value,props.feedid,userInfocomment.deleteimgsss.value);
                     if(newcommentupdatesss.success === 1){
                            setloadingedit(false);
                            event.target.reset(); 
                            props.close();
                            props.refresh(props.postids);
                            
                            props.commentactive(true);
                            props.morecomments(false);
                     }
              } catch (error) { throw error;}      
       }


       const resetFile = (event) => {
              event.preventDefault();
              setfiletemp(null)
            }

            let reader = new FileReader();

   const handleChange = (event) => {
       const imageFile = event.target.files[0];
             const imageFilname = event.target.files[0].name;

             if (!imageFile) {
              setinvalidImage('Please select image.');
               return false;
             }
         
             if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif)$/)) {
              setinvalidImage('Please select valid image JPG,JPEG,PNG');
               return false;
             }
             reader.onload = (e) => {
               const img = new Image();
               img.onload = () => {

//----------------------------- Resize img code -------------------------------------------------
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);

                var MAX_WIDTH = 437;
                var MAX_HEIGHT = 437;
                var width = img.width;
                var height = img.height;

                if (width > height) {
                  if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                  }
                } else {
                  if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                  }
                }
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
                ctx.canvas.toBlob((blob) => {
                  const file = new File([blob], imageFilname, {
                      type: 'image/jpeg',
                      lastModified: Date.now()
                  });
                  setuserInfocomment({
                     ...userInfocomment,
                     file:file,
                })
                }, 'image/jpeg', 1);


//-------------------------------------------------------------------------------------
              setfiletemp( URL.createObjectURL(imageFile))
              setfileempty(true);
              setinvalidImage(null)
              };
               img.onerror = () => {
                     setinvalidImage('Invalid image content.');
                 return false;
               };
               //debugger
               img.src = e.target.result;
             };
             reader.readAsDataURL(imageFile);

     }; 

    
  

  return (
  
       <>
            <div className="">
            <div className="feedswww">
                   <h3 className="create-post"> Edit Post Comment</h3>
                   
                     <form onSubmit={newcommentupdate} className="login-forms">
                            <textarea  name="comment" value={userInfocomment.comment} className=" col-md-12 ccc4 " rows="3"  placeholder=" Start a post "  onChange={onChangeValue} required />
                     <div className="clearfix"></div>

                     <div className="col-md-12 ccc5" style={{padding:0}}>
                     <ul> 
                            <li>
                            <div className="col-md-7" > 
                                   <label className="fileContainersss">
                                   <input type="file" name="file" className="form-control" placeholder="Title" onChange={handleChange} />
                                   </label>
                                   
                                   {fileempty === true ? 
                                   <input type="hidden" name="fileempty" ref={(val) => userInfocomment.fileempty = val} className="form-control" defaultValue="imgactive" />
                                   : 
                                   <input type="hidden" name="fileempty" ref={(val) => userInfocomment.fileempty = val} className="form-control" defaultValue="imgempty" />
                                   }
                                   </div>
                                  <div className="col-md-5" >
                                   {props.image !== '' && isdeleteimg === false ? <>
                                          <img src={BaseURLuploads+props.image} alt="postimage" />
                                          <span className="morelink" onClick={() => deletepostimg()} > Delete   </span>
                                          </>
                                   : null}
                                   {isdeleteimg === true ? 
                                   <input type="hidden" name="deleteimgsss" ref={(val) => userInfocomment.deleteimgsss = val} className="form-control" defaultValue="imgdelete" />
                                   :
                                   <input type="hidden" name="deleteimgsss" ref={(val) => userInfocomment.deleteimgsss = val} className="form-control" defaultValue="" />
                                   }
                                   </div>
                            </li>
                      
                     </ul>
                     </div>
                    
                    
                  
                           
                            {loadingedit ?
                            <>
                                   <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                            </>
                            : 
                                   <div className="form-group col-sm-12 text-right">
                                          <button type="submit" className="db-tit-btn2"> Edit  </button>
                                          
                                   </div>
                             }
                 
                     </form>
                     </div>
                     </div>

       </>
   
  );
}

export default CommentEditgroup;