
import React,{useState} from 'react';
import { Page, Text, View, Document, StyleSheet,Image} from "@react-pdf/renderer";


//import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
import logo from '../Img/logo.png';

function PdfDocumentrecepits(props) {

  console.log("pdf props", props.bookpdflist);
  return (
    <Document>
      <Page style={styles.page} >


        {props.bookpdflist.map((item, index) => {
              return (
                <View key={index} style={styles.movieContainer}>
                
                  <Image object-fit="fill" src={logo} style={styles.image} alt="images" />

                
                  <View style={styles.movieDetails}>
                    
                    <Text style={styles.movieTitle}>Plan name : {item.member}</Text>
                    <Text style={styles.movieTitle}>Duration :
                            {item.member ==='Silver' ? <> Onetime Payment for 1.5 Years Membership </> : null }
                            {item.member ==='Gold' ? <> Onetime Payment for 5 Years Membership </> : null }
                            {item.member ==='Platinum' ? <> Onetime Payment for lifetime membership </> : null }
                     </Text>
                    <Text style={styles.movieTitle}>Checkout cost  : ${item.payment_gross}</Text>
                    <Text style={styles.movieTitle}>transaction id  : {item.txn_id}</Text>
                    <Text style={styles.movieTitle}>Payment Status  : {item.payment_status}</Text>
                    
                  

                </View>

                 
                <Text style={styles.movieTitle32s}>Thank you for posting your Business Ads on <Text style={styles.movieTitle3s}>bizonio.com.</Text></Text>
                </View>
              );
            })}
      </Page>
    </Document>
  );
}
export default PdfDocumentrecepits;


const styles = StyleSheet.create({
      page: {
         backgroundColor: "#ffffff"
       },
       section: {
         margin: 10,
         padding: 10,
         flexGrow: 1
       },
       movieContainer: {
         display: "flex",
         flexDirection: "column",
         padding: 37
       },
       movieDetails: {
        display: "flex",
        marginLeft: 5,
        borderWidth: 1,
        borderColor: "#20232a",
        borderRadius: 6,
        padding: 27,
        marginBottom:17
       },
       movieTitle: {
         fontSize: 16,
         marginTop: 10,
         marginBottom: 10,
         fontWeight:700,
       },
       movieTitle2: {
         fontSize: 22,
         marginBottom: 17,
         color: '#fb6a1f',
         fontWeight: "bold"
       },
       movieTitle3: {
         fontSize: 20,
         marginBottom: 27,
         color: '#fb6a1f',
         fontWeight:700
       },
       movieTitle32s: {
        fontSize: 16,
      },
        movieTitle3s: {
         fontSize: 16,
         color: '#fb6a1f',
       },
       movieOverview: {
         fontSize: 10
       },
       movieTitle7s: {
        color: '#2196f3',
       },
     
       image: {
        alignItems: "center",
        justifyContent: "center",
        display: "block",
        flexDirection: "column",
        maxWidth:200,
        marginBottom:37
       },
       subtitle: {
         display: "flex",
         justifyContent: "space-between",
         flexDirection: "row",
         width: 150,
         alignItems: "center",
         marginBottom: 12
       },
       vote: {
         display: "flex",
         flexDirection: "row"
       },
       rating: {
         height: 10,
         width: 10
       },
       vote_text: {
         fontSize: 10
       },
       vote_pop: {
         fontSize: 10,
         padding: 2,
         backgroundColor: "#61C74F",
         color: "#fff"
       },
       vote_pop_text: {
         fontSize: 10,
         marginLeft: 4
       },
       overviewContainer: {
         minHeight: 110
       },
       detailsFooter: {
         display: "flex",
         flexDirection: "row"
       },
       lang: {
         fontSize: 8,
         fontWeight: 700
       },
       vote_average: {
         fontSize: 8,
         marginLeft: 4,
         fontWeight: "bold"
       }
     });


