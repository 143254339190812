import React,{useContext,useState,useEffect} from 'react';

import { HashLink as Link } from 'react-router-hash-link';
import { MyContext } from '../contexts/MyContext';
import {toast} from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';
import linesss from '../Img/line.png';

import bronzessimg from '../Img/pricing_bg_shape-1.png';
import businesssimg from '../Img/bg-shape1-home5.png';

import userphotos from '../Img/user_profile.jpg';
import { WEB_URL,WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURL = WEB_URL;
let BaseURLuploads = WEB_URL_UPLOAD;

function Home() {
    const {rootState,PlatinumgoldAds,GoldgoldAds,paltinumrandAds,PlatinumAds,GoldAds,pricesall,SilverAds,businessAds,membersallAds} = useContext(MyContext);
  const {isAuth,theUser} = rootState;
  const [isprice, setisprice] = useState([]);
  const [isPlatinum, setPlatinum] = useState([]);
  const [isPlatinumgold, setPlatinumgold] = useState([]);
  const [isGold, setGold] = useState([]);
  const [isSilver, setSilver] = useState([]);
  const [ismembersall, setmembersall] = useState([]);
  const [isbusiness, setbusiness] = useState([]);
  useEffect( () => {
   Platinumgold();
   Goldgold();
   Platinumrand();
   Platinum();
    Gold();
    prices();
    Silver();
    business();
    membersall();
}, []);

const Platinumgold = async() =>{
  try {
         const Platinumgoldresult = await PlatinumgoldAds();
                if(Platinumgoldresult.success === 1){
                       console.log(Platinumgoldresult.Platinumgoldlist);
                       setPlatinumgold(Platinumgoldresult.Platinumgoldlist) ;
         }
  } catch (error) { throw error;}
}
const [isgoldgold, setgoldgold] = useState([]);
const Goldgold = async() =>{
  try {
         const Goldgoldsss = await GoldgoldAds();
                if(Goldgoldsss.success === 1){
                       console.log(Goldgoldsss.Goldgoldlist);
                       setgoldgold(Goldgoldsss.Goldgoldlist) ;
         }
  } catch (error) { throw error;}
}
const [isPlatinumrand, setPlatinumrand] = useState([]);
const Platinumrand = async() =>{
  try {
         const Platinumrandsss = await paltinumrandAds();
                if(Platinumrandsss.success === 1){
                       console.log(Platinumrandsss.Platinumrandssslist);
                       setPlatinumrand(Platinumrandsss.Platinumrandssslist) ;
         }
  } catch (error) { throw error;}
}
const Platinum = async() =>{
  try {
         const Platinumresult = await PlatinumAds();
                if(Platinumresult.success === 1){
                       console.log(Platinumresult.Platinumlist);
                       setPlatinum(Platinumresult.Platinumlist) ;
         }
  } catch (error) { throw error;}
}
const Gold = async() =>{
  try {
         const Goldresult = await GoldAds();
                if(Goldresult.success === 1){
                       console.log(Goldresult.goldlist);
                       setGold(Goldresult.goldlist) ;
         }
  } catch (error) { throw error;}
}
const prices = async() =>{
  try {
         const pricesresult = await pricesall();
         if(pricesresult.success === 1){
                console.log(pricesresult.pricelist);
                setisprice(pricesresult.pricelist[0]) ;
               
         }
  } catch (error) { throw error;}
}
const [isqqq, setqqq] = useState([]);   
const Silver = async() =>{
   try {
          const Silverresult = await SilverAds();
                 if(Silverresult.success === 1){
                        console.log('tttttttttt',Silverresult.Silverlist);
                        setSilver(Silverresult.Silverlist) ;

//                const nnn = Silverresult.Silverlist.map (movie => movie.class_member_id )      
//               console.log('uuuuu',nnn);

// const todoIdList =nnn;
//               console.time('.map()')
// Promise.all(todoIdList.map(id => {
 
//    return new Promise((resolve) => {
//       fetch(BaseURL+`slivercounts/${id}`)
//       //fetch(BaseURL+`slivercounts/${id.class_member_id}`)
//       .then(response => {
//             return new Promise(() => {
//                      response.json()
//                      .then(todo => {
                       
//                      if(todo.slivercountslist.length > 0){
//                             console.log('wwwwwwwwwwwwwwwwwwwwwwww',todo.slivercountslist);
//                             //setqqq(todo.slivercountslist.length);
//                             setqqq(prevBooks => {
//                                  return [...prevBooks, ...todo.slivercountslist]
//                               });  

//                               // const nnnqqq = todo.slivercountslist.map (movieqqq => movieqqq.class_member_id )      
//                               // console.log('uuuuuqqq',nnnqqq.length);
                             
//                               //  ss(nnnqqq.length  );
//                      }
//                   resolve()
//                })
//          })
//       })
//     })
//  }))
//  .then(() => {console.timeEnd('.map()'); })
                                 














          }
   } catch (error) { throw error;}
 }
 const membersall = async() =>{
   try {
          const membersallresult = await membersallAds();
                 if(membersallresult.success === 1){
                        console.log(membersallresult.membersalllist);
                        setmembersall(membersallresult.membersalllist) ;
          }
   } catch (error) { throw error;}
 }
 const business = async() =>{
   try {
          const businessresult = await businessAds();
                 if(businessresult.success === 1){
                        console.log(businessresult.businesslist);
                        setbusiness(businessresult.businesslist) ;
          }
   } catch (error) { throw error;}
 }
 const loginprofilesss =()=>{
   toast.info('Please login to view this particular member',{
      position: "top-center"});  
 }
 const loginprofilesssall =()=>{
   toast.info('Please login to view all the members',{
      position: "top-center"});  
 }
  return (
   <>
  <div className="homsss2 aaatops">
 
    <div className="container">
      <div className="row">
    
       
      <div className="col-md-7 aaa2s" style={{padding:0}}>
  
<div className="sadarfesdsgtsss">
           
               <h1>  Connect with  Entrepreneurs around the world. </h1>
               <h2>   Post Ads, Add your business profile. Get a lifetime membership id for our International Business Club. Get notified  about our online and offline business events   </h2>
               <Link smooth to={`/#members`} className="thm-btnqqqmngsss"> <span> Add your business </span> </Link>
               <Link smooth to={`/#members`} className="thm-btnqqq"> <span> Become a Member </span> </Link>
            </div>
       
          
     
    

        
                             
           </div>
     
        <div className="col-md-5 topsqqq">
        
          <div className="">
          {isPlatinumgold.map((item, index) => ( 
           <div className="col-md-12" key={item.classified_id}>
           <div className="single-listing-item ">
               <Link to={`/ClassifiedsPreview/${item.classified_id}`} className="link-btn">
                   <div className="aaatops2">
                   <img src={BaseURLuploads+item.image} alt="Classifiedsads" /> 
                   <div className="listing-tagwww">
                       {item.category === 'other' ?
                           <>{item.other}</>
                           :  <>{item.category}  </>}
                       </div>
                   <h3> {item.title} </h3>
                   <div className="clearfix"></div>
                   <p className="timqqq"> <FontAwesomeIcon icon={['fas','clock']} className="iconsss2 " /> <Moment format="MMM D, YYYY" withTitle>
                                   {item.date} 
                              </Moment></p>
                              
                   </div>
                   </Link>
                      
                  
           </div>
       </div>    
                  )) }
                  {isgoldgold.map((item, index) => ( 
           <div className="col-md-12" key={item.classified_id}>
           <div className="single-listing-item ">
               <Link to={`/ClassifiedsPreview/${item.classified_id}`} className="link-btn">
                   <div className="aaatops2">
                   <img src={BaseURLuploads+item.image} alt="Classifiedsads" /> 
                   <div className="listing-tagwww">
                       {item.category === 'other' ?
                           <>{item.other}</>
                           :  <>{item.category}  </>}
                       </div>
                   <h3> {item.title} </h3>
                   <div className="clearfix"></div>
                   <p className="timqqq"> <FontAwesomeIcon icon={['fas','clock']} className="iconsss2 " /> <Moment format="MMM D, YYYY" withTitle>
                                   {item.date} 
                              </Moment></p>
                              
                   </div>
                   </Link>
                      
                  
           </div>
       </div>    
                  )) }
                  {isPlatinumrand.map((item, index) => ( 
           <div className="col-md-12" key={item.classified_id}>
           <div className="single-listing-item ">
               <Link to={`/ClassifiedsPreview/${item.classified_id}`} className="link-btn">
                   <div className="aaatops2">
                   <img src={BaseURLuploads+item.image} alt="Classifiedsads" /> 
                   <div className="listing-tagwww">
                       {item.category === 'other' ?
                           <>{item.other}</>
                           :  <>{item.category}  </>}
                       </div>
                   <h3> {item.title} </h3>
                   <div className="clearfix"></div>
                   <p className="timqqq"> <FontAwesomeIcon icon={['fas','clock']} className="iconsss2 " /> <Moment format="MMM D, YYYY" withTitle>
                                   {item.date} 
                              </Moment></p>
                              
                   </div>
                   </Link>
                      
                  
           </div>
       </div>    
                  )) }
          </div>
        </div>
      </div>
    </div>
  </div> 
{/* -------------------------------------------------------------------------------------- */}

{/* -------------------------------------------------------------------------------------- */}
<section id="members" className="pricing-section style-two">
      
   <div className="container">
   <div className="row">
      <div className="col-md-12 sec-title text-center">
      
         <h3>Membership Levels</h3>
      </div>
      <div className="tabs-box">
         
                   {/* {isprice.map((item, index) => ( 
                       <div Key={item.price_id}>
      {item.price_id} */}
                     <div className="col-md-3 pricing-block" style={{paddingLeft:0}}>
                        <div className="pricing-block-two ">
                           <div className="pricing-table">
                              <div className="pattern-layer" ></div>
                              <div className="choice-box">
                                 <h6>Popular </h6>
                              </div>
                              <div className="table-header">
                                 <h4> Bronze </h4>
                                 <h2 className="Freesss">Free</h2>
                              </div>
                              <div className="table-content">
                                 <ul>
                                 
                                
                                    <li> <FontAwesomeIcon icon={['fas','check']}/> Get Membership id to post on our Facebook group.</li>
                                    <li> <FontAwesomeIcon icon={['fas','check']}/> Post Advertisements on News Feed</li>
                                    <li> <FontAwesomeIcon icon={['fas','check']}/> Create your company page</li>
                                   
                                    <li className="timqqqnnn3"> &nbsp;<br /> &nbsp;<br /> &nbsp;  </li>
                                  
                                  
                                  
                                   
                                 </ul>
                              </div>
                              <div className="table-footer">
                              <Link to={`/Myaccount`} className="service-one__btn" > Get Started </Link>
                              </div>
                           </div>
                        </div>
                     </div> 
                     <div className="col-md-3 pricing-block" style={{paddingLeft:0}}>
                        <div className="pricing-block-two">
                           <div className="pricing-table">
                              <div className="pattern-layer" ></div>
                              <div className="choice-box">
                                 <h6>Popular </h6>
                              </div>
                              <div className="table-header">
                                 <h4> Silver </h4>
                                 <h2>{isprice.Silver}</h2>
                              </div>
                              <div className="table-content">
                                 <ul>
                                 
                                    <li> <FontAwesomeIcon icon={['fas','check']}/> Onetime Payment </li>
                                    <li> <FontAwesomeIcon icon={['fas','check']}/>Post Unlimited Business Ads</li>
                                    
                                    <li> <FontAwesomeIcon icon={['fas','check']}/> View members directory </li>
                                    <li> <FontAwesomeIcon icon={['fas','check']}/> Create your company page  <br />&nbsp; <br /> &nbsp;<br /> &nbsp;</li>
                                   
                                   
                                  
                                   
                                 </ul>
                              </div>
                              <div className="table-footer">
                              <Link to={`/Myaccount`} className="service-one__btn" > Get Started </Link>
                              </div>
                           </div>
                        </div>
                     </div>
                    
                     <div className="col-md-3 pricing-block"  style={{paddingLeft:0}}>
                        <div className="pricing-block-two aaa8">
                        <div className="spotlight-tag"><p className="spotlight-tag-text">Popular Choice</p></div>
                           <div className="pricing-table">
                              <div className="pattern-layer" ></div>
                              
                              <div className="table-header">
                                 <h4>Gold </h4>
                                 <h2>{isprice.Gold}</h2>
                              </div>
                              <div className="table-content">
                                 <ul>
                                    <li> <FontAwesomeIcon icon={['fas','check']}/>  Onetime Payment </li>
                                    <li> <FontAwesomeIcon icon={['fas','check']}/> Business Ads, Company Page </li>
                                    <li> <FontAwesomeIcon icon={['fas','check']}/> Connect with other Business Owners</li>
                                    <li> <FontAwesomeIcon icon={['fas','check']}/> View members directory <br /> &nbsp; <br /> &nbsp;</li>
                                   
                                 </ul>
                              </div>
                              <div className="table-footer">
                              <Link to={`/Myaccount`} className="service-one__btn"  > Get Started </Link>
                              </div>
                           </div>
                        </div>
                     </div>
                    
                     <div className="col-md-3 pricing-block" style={{paddingleft:0,paddingRight:0}}>
                        <div className="pricing-block-two">
                           <div className="pricing-table">
                              <div className="pattern-layer" ></div>
                              <div className="choice-box">
                                 <h6>Popular</h6>
                              </div>
                              <div className="table-header">
                                 <h4>Platinum</h4>
                                 <h2>{isprice.Platinum}</h2>
                              </div>
                              <div className="table-content">
                                 <ul>
                                   
                                    <li> <FontAwesomeIcon icon={['fas','check']}/> Ads highlighted on top </li>
                                    <li>  <FontAwesomeIcon icon={['fas','check']}/> Onetime Payment for lifetime membership  </li>
                                    <li> <FontAwesomeIcon icon={['fas','check']}/> Business Ads, Company Page </li>
                                    <li> <FontAwesomeIcon icon={['fas','check']}/> Connect with other Business Owners and send messages to them </li>
                                   
                                   
                                   
                                 </ul>
                              </div>
                              <div className="table-footer">
                              <Link to={`/Myaccount`} className="service-one__btn"  > Get Started </Link>
                              </div>
                           </div>
                        </div>
                     </div>
                     {/* </div>
                 
                     ))} */}
                     
      </div>
   </div>
   </div>
</section>
<div className="clearfix"></div>
{/* -------------------------------------------------------------------------------------- */}

{/* -------------------------------------------------------------------------------------- */}
{/* <div className="mnqwsass">
<img src={linesss} style={{maxWidth: "max-content"}}/>
</div> */}
{/* -------------------------------------------------------------------------------------- */}
<div className="aaatopsseconds">
    <div className="container">
      <div className="row">
    
        <div className="col-md-3">  
        <div className="aaatopsseconds2">  
            <h2 className="font-alt heading"> Platinum </h2> 
            <Link to={`/AllPlatinumClassifieds`} className="full-widthwww">  View all </Link>  
        </div>
        </div>
        <div className="col-md-9">
       
          <div className="">
          {isPlatinum.map((item, index) => ( 
           <div className="col-md-4" key={item.classified_id}>
           <div className="single-listing-item ">
               <div className="listing-image">
               <Link to={`/ClassifiedsPreview/${item.classified_id}`} className="link-btn">
                       <img src={BaseURLuploads+item.image} alt="Classifiedsads" />
                      
                       </Link>
                </div>

             
              
                   <div className="listing-content ">
                   <Link to={`/ClassifiedsPreview/${item.classified_id}`} className="link-btn">  <h3> {item.title} </h3>     </Link>
                   </div>
                   <hr/>
                   <div className="col-md-12 Platinum2" >
                  <div className="col-md-12 timqqqnnn2" style={{padding:0}}>
                  <div className="col-md-4s" style={{padding:0}}>
                     {item.profile_image !=='' ? 
                                 <img src={BaseURLuploads+item.profile_image} alt="" className="rounded-circle mr-2" />
                                 :
                                 <img src={userphotos} alt="" className="rounded-circle mr-2"  />
                              }
                     </div>
                      <div className="col-md-8s" style={{padding:0}}>
                        <span>by <b> {item.name} </b> <br/> <p>  <Link to={`/MemberAds/${item.register_id}`} > View all Ads by this member </Link>  </p> </span>
                     </div>

                  </div>
                  {/* <div className="col-md-12 timqqqnnn" style={{paddingRight:0}}>
                     <p className="timqqq"> <FontAwesomeIcon icon={['fas','clock']} className="iconsss2 " /> <Moment format="MMM D, YYYY" withTitle>
                                       {item.date} 
                                    </Moment></p>

                  </div> */}
               </div>
                    <div className="Platinum3">
                       <h4>  <Link to={`/ClassifiedsPreview/${item.classified_id}`} className="link-btn"> Read More     </Link>  <FontAwesomeIcon icon={['fas','plus-circle']} className="iconsss2 "/></h4>
                      
                   </div>
            
                    
                  
           </div>
       </div>    
                  )) }
          </div>
        </div>
      </div>
    </div>
  </div> 

<div className="aaa3">
    <div className="container">
      <div className="row">
    
        <div className="col-md-12">
        <h2 className="font-alt heading"> Gold </h2>
          <div className="">
          {isGold.map((item, index) => ( 
           <div className="col-md-4" key={item.classified_id}>
           <div className="single-listing-item ">
               <div className="listing-image">
               <Link to={`/ClassifiedsPreview/${item.classified_id}`} className="link-btn">
                       <img src={BaseURLuploads+item.image} alt="Classifiedsads" />
                       {/* <div className="listing-tag">
                       {item.category === 'other' ?
                           <>{item.other}</>
                           :  <>{item.category}  </>}
                       </div> */}
                       </Link>
                </div>
                   <div className="listing-content">
                    <Link to={`/ClassifiedsPreview/${item.classified_id}`} className="link-btn">  <h3> {item.title} </h3></Link>
                   <div className="aaa2" dangerouslySetInnerHTML={{ __html: item.description }} />
                   <hr />
                   <div className="listing-author ">
                   {item.profile_image !=='' ? 
                                <img src={BaseURLuploads+item.profile_image} alt="" className="rounded-circle mr-2" />
                                :
                                <img src={userphotos} alt="" className="rounded-circle mr-2"  />
                            }
                  
                     <span> {item.name} <br/> <p>  <Link to={`/MemberAds/${item.register_id}`} > View all Ads by this member </Link>  </p> </span></div>
               
                   </div>
                       {/* <div className="listing-box-footer">
                         
                           <div className="link-box">
                             <Link to={`/ClassifiedsPreview/${item.classified_id}`}><span>View More </span></Link></div>
                       </div> */}
                  
           </div>
       </div>    
                  )) }

<Link to={`/AllGoldClassifieds`} className="full-widthwww2">  View all </Link> 
          </div>
        </div>
      </div>
    </div>
  </div> 
{/* -------------------------------------------------------------------------------------- */}
{/* -------------------------------------------------------------------------------------- */}
<div className="aaa5">
{/* <div className="aaa4imgs">
      <img src={bronzessimg} alt="Classifiedsads" />
   </div>
<div className="aaa5imgs">
      <img src={businesssimg} alt="Classifiedsads" />
   </div> */}
    <div className="container">
      <div className="row">
      
        <div className="col-md-7">
        <h2 className="font-alt heading"> Business Directory </h2>
          <div className="">
          {isbusiness.map((item, index) => ( 
           <div className="col-md-12" key={item.business_id }>
            
       
            <div className={ "single-listing-item" + (index === 3 ? " aaa5wwwsss " : " ")}>
          
               <div className="listing-image">
               <Link to={`/BusinessPreview/${item.business_id}`} className="link-btn">
                       <img src={BaseURLuploads+item.image} alt="Classifiedsads" />
                       <div className="listing-tag">
                       {item.category === 'other' ?
                           <>{item.other}</>
                           :  <>{item.category}  </>}
                       </div>
                       </Link>
                      
                </div>
                <div className="listing-content">
               
                     
                     <Link to={`/BusinessPreview/${item.business_id}`} className="link-btn">
                     <h3> {item.title} </h3>
                     <div className="listing-author ">
                   {item.profile_image !=='' ? 
                                <img src={BaseURLuploads+item.profile_image} alt="" className="rounded-circle mr-2" />
                                :
                                <img src={userphotos} alt="" className="rounded-circle mr-2"  />
                            }
                  
                     <span> {item.name} <br/> <p>  <Link to={`/MemberBusinessAds/${item.register_id}`} > View all Ads by this member </Link>  </p> </span> </div>
                     <div className="aaa2" dangerouslySetInnerHTML={{ __html: item.description }} />
                     </Link>
                   </div>
                      
                  
           </div>
       </div>    
                  )) }
   <Link to={`/AllBusiness`} className="full-widthwww2">  View all </Link> 
          </div>
        </div>

 <div className="col-md-5">
        
          <div className="box-info-wrap">
          {isbusiness.map((item, index) => ( 
           <div className="col-md-12" key={item.business_id } style={{padding:0}}>
           <div className="single-listing-itemmm ">
          
          
               <div className="">
               <Link to={`/BusinessPreview/${item.business_id}`} className="link-btn">
                       <img src={BaseURLuploads+item.image} alt="Classifiedsads" />
                      
                       </Link>
                      
                </div>
                <div className="listing-contentmmm">
           
                     <Link to={`/BusinessPreview/${item.business_id}`} className="link-btn">
                     <h3> {item.title} </h3>
                     <div className="listing-tagmmm">
                       {item.category === 'other' ?
                           <>{item.other}</>
                           :  <>{item.category}  </>}
                       </div>
                     <div className="aaa2" dangerouslySetInnerHTML={{ __html: item.description }} />
                     </Link>
                   </div>
                      
                  
           </div>
       </div>    
                  )) }
 
          </div>
        </div>















      </div>
    </div>
  </div> 




<div className="aaa4">

    <div className="container">
      <div className="row">
     
        <div className="col-md-12">
        <h2 className="font-alt heading"> Silver </h2>
          <div className="">
          {isSilver.map((item, index) => ( 
           <div className="col-md-6" key={item.classified_id}>
{/* 
                  {isqqq.map((sss, index) => (
                  <div className="" key={sss.classified_id}>

                  {sss.class_member_id === item.class_member_id ? <> {item.class_member_id}  </> : null}
                     </div>

                  ))} */}
         {/* {isqqq}qqqqqqqqq */}
           <div className="single-listing-itemw ">
        {/* <div className="listing-tag">
                       {item.category === 'other' ?
                           <>{item.other}</>
                           :  <>{item.category}  </>}
                       </div> */}
                <div className="col-md-12">
                   <div className="listing-contentw">
                  
                   <Link to={`/ClassifiedsPreview/${item.classified_id}`} className="link-btn"> <h3> {item.title} </h3> </Link>
                   <div className="col-md-12" style={{padding:0}}>
                
                     <div className="col-md-6">
                     <div className="listing-author ">
                   {item.profile_image !=='' ? 
                                <img src={BaseURLuploads+item.profile_image} alt="" className="rounded-circle mr-2" />
                                :
                                <img src={userphotos} alt="" className="rounded-circle mr-2"  />
                            }
                  
                  <br/>  <span> {item.name} <br/> <p>  <Link to={`/MemberAds/${item.register_id}`} > View all Ads by this member </Link>  </p> </span></div>
                     </div> 
                
                     <div className="col-md-6" >
                   <div className="listing-imagew">
                        <Link to={`/ClassifiedsPreview/${item.classified_id}`} className="link-btn">
                              <img src={BaseURLuploads+item.image} alt="Classifiedsads" />
                              
                              </Link>
                        </div>
                   </div>
                   
                   <div className="aaa2" dangerouslySetInnerHTML={{ __html: item.description }} />
                   </div>
                   </div>
                       <div className="listing-box-footer">
                         
                           <div className="link-box">
                             <Link to={`/ClassifiedsPreview/${item.classified_id}`}><span> Continue reading  </span></Link></div>
                       </div>
                       </div>
                  
           </div>
       </div>    
                  )) }

<Link to={`/AllSilverClassifieds`} className="full-widthwww2">  View all </Link> 
          </div>
        </div>
      </div>
    </div>
  </div> 
{/* -------------------------------------------------------------------------------------- */}

{/* -------------------------------------------------------------------------------------- */}
<div  className="gp-scrolling-gradient" >
   <div className="gp-animated-gradient-column">
      <div className="container">
         <div className="vc_column-inner ">
            <div className="wpb_wrapper">
               <h1 className="vc_custom_heading "> Our Members </h1>
               <div id="gp_buddypress_members_1" className="gp-bp-element ">
                  <ul className="gp-bp-wrapper gp-bp-members gp-bp-round-avatars gp-align-center gp-style-classic" aria-live="polite" aria-relevant="all" aria-atomic="true">
                   
{ismembersall.map((item, index) => ( 
                     <li className="gp-post-item odd" key={item.register_id }>
      {isAuth === true && theUser !=='' ? 
                         <Link to={`/ProfileView/${item.register_id}`} className="gp-bp-avatar">
                           <span className="gp-bp-hover-effect"></span> 
                         
                                 <img src={BaseURLuploads+item.profile_image} className="avatar user-72-avatar avatar-90 photo" width="90" height="90" alt="Profile picture" />
                               <div className="gp-user-offline">
                              <div className="bp-tooltip" data-bp-tooltip="active 3 hours, 32 minutes ago"></div>
                           </div>
                           </Link>
      : 
                  <a className="gp-bp-avatar" onClick={loginprofilesss}>
                  <span className="gp-bp-hover-effect"></span> 
               
                        <img src={BaseURLuploads+item.profile_image} className="avatar user-72-avatar avatar-90 photo" width="90" height="90" alt="Profile picture" />
                     <div className="gp-user-offline">
                     <div className="bp-tooltip" data-bp-tooltip="active 3 hours, 32 minutes ago"></div>
                  </div>
                  </a> 
      }
                     </li>
))}                   
                  </ul>
      {isAuth === true && theUser !=='' ? <>
               <div className=" bb_custom">  <Link to={`/Members`} className="btn3-style-outline"> View All Members </Link></div>
      </>:<>
               <div className=" bb_custom" onClick={loginprofilesssall}>  <a className="btn3-style-outline"> View All Members </a></div>
      </>}

               </div>
           
            </div>
         </div>
      </div>
   </div>
   <div className="gp-animated-gradient-bg" ></div>
</div>
{/* -------------------------------------------------------------------------------------- */}
{/* -------------------------------------------------------------------------------------- */}
{/* -------------------------------------------------------------------------------------- */}
{/* -------------------------------------------------------------------------------------- */}
{/* -------------------------------------------------------------------------------------- */}




   </>
  );
}

export default Home;