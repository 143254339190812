
  import React,{useContext,useState,useEffect, useRef,useCallback } from 'react';
  import { MyContext } from '../contexts/MyContext';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import {Link } from "react-router-dom"; 
  //import Moment from 'react-moment';
  import ContentLoader from "react-content-loader";
  import userphotos from '../Img/user_profile.jpg';
  import Moment from 'react-moment';
  import {toast} from "react-toastify";
  import { useHistory } from "react-router-dom";

  import { WEB_URL,WEB_URL_UPLOAD } from '../contexts/Apilinks';
  let BaseURLuploads = WEB_URL_UPLOAD;
  
  function AllBusiness(props) {
    const {rootState,allbussinesslist,Activemembersss} = useContext(MyContext);
    const {isAuth,theUser} = rootState;
    
    const [pageNumber, setPageNumber] = useState(1);
    let history = useHistory();
    
  
  
    const observer = useRef()
    const loadingRef = useCallback(node => {
      
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting ) {
          setPageNumber(prevPageNumber => prevPageNumber + 1)
          //latestfeeds(prevPageNumber => prevPageNumber + 1)
        }
      })
      if (node) observer.current.observe(node)
    }, [])
    useEffect( () => {
      window.scrollTo(0, 0);
      
  }, []);    
    useEffect( () => {
      if (isFetching === true) return;
      allclassifieds(pageNumber);
          return () => {
            console.log("This will be logged on unmount");
          }
    }, [pageNumber])
  
    const [isclassifieds, setclassifieds] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
  
    const allclassifieds = async(pageNumber) => {
      const allclassifiedsss = await allbussinesslist(pageNumber);
      if(allclassifiedsss.success === 1){
             console.log('allclassifiedsss',allclassifiedsss);
             setclassifieds(prevBooks => {
                    return [...prevBooks, ...allclassifiedsss.data]
             })
          
             if(allclassifiedsss.data.length < 1){
                    setIsFetching(true);
             }
      }
  }

  const logsss =() =>{
    toast.info('Please register to access this page ',{
      position: "top-center"});  
  }
  const Activemember = async (userids) =>{
    const Activememberlist = await Activemembersss(userids);
      if(Activememberlist.success === 1){
         history.push(`/BusinessAdd`);
      }
      if(Activememberlist.success === 0){
        toast.info('Please upgrade your membership',{
          position: "top-center"});  
          history.push(`/Myaccount`);
     }
          
  }
  
  return ( 
        <>
      
         <div className="aaa13">
         <div className="container">
            <div className="row">
            <div className="col-md-12">
            {isAuth === true && theUser !== '' ? <>
              <div className="ccc2" onClick={()=> Activemember(theUser)}><Link className="db-tit-btn"><FontAwesomeIcon icon={['fas','paper-plane']} className="" />  Add Your Business Information</Link></div>
          </>  : <>
              <div className="ccc2" onClick={logsss}><Link to="/Login" className="db-tit-btn"><FontAwesomeIcon icon={['fas','paper-plane']} className="" />   Add Your Business Information </Link></div> </>
          }
           
              
           {isclassifieds.map(item => (
          <div  ref={loadingRef}  className="col-md-4" key={item.business_id}>
          <div className="single-listing-item ">
              <div className="listing-image">
              <Link to={`/BusinessPreview/${item.business_id}`} className="link-btn">
                      <img src={BaseURLuploads+item.image} alt="Classifiedsads" />
                      <div className="listing-tag">
                      {item.category === 'other' ?
                          <>{item.other}</>
                          :  <>{item.category}  </>}
                      </div>
                      </Link>
               </div>
               <div className="col-md-12 Platinum2" >
                    <div className="col-md-8" style={{padding:0}}>
                   
                       {item.profile_image !=='' ? 
                                   <img src={BaseURLuploads+item.profile_image} alt="" className="rounded-circle mr-2" />
                                   :
                                   <img src={userphotos} alt="" className="rounded-circle mr-2"  />
                                }
                       
                          <span>by {item.name} </span>
                      
  
                    </div>
                    <div className="col-md-4" style={{paddingRight:0}}>
                       <p className="timqqq"> <FontAwesomeIcon icon={['fas','clock']} className="iconsss2 " /> <Moment format="MMM D, YYYY" withTitle>
                                         {item.date} 
                                      </Moment></p>
  
                    </div>
                 </div>
                  <div className="listing-content">
                  <Link to={`/BusinessPreview/${item.business_id}`} className="link-btn">
                  <h3> {item.title} </h3> </Link>
                  <div className="aaa2" dangerouslySetInnerHTML={{ __html: item.description }} />
                  </div>
                      <div className="listing-box-footer">
                        
                          <div className="link-box">
                            <Link to={`/BusinessPreview/${item.business_id}`}><span>View More </span></Link></div>
                      </div>
                 
          </div>
      </div>   
         
        ))}
  
  {isclassifieds.length < 0 ?
                       <div className="loading" >
                              <h2> Load More </h2>
                       </div>
                : null}
           
        </div>
  
     
      </div>
      </div>
      </div>
      </>
    );
  
  
  }
  
  export default AllBusiness
  