import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import {Link } from "react-router-dom"; 
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WEB_URL,WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURL = WEB_URL;
let BaseURLuploads = WEB_URL_UPLOAD;

function Usernotifications(props) {
  
  const {viewuserprofile} = useContext(MyContext);

  useEffect( () => {
       userdetails(props.storageid);
    
      }, []);

      const [ismemberimg, setismemberimg] = useState();
      const [isname, setisname] = useState();
      const [isabout, setisabout] = useState();
      const [isdate, setisdate] = useState();

 const userdetails = async(storageid) =>{
        try {
               const viewuser = await viewuserprofile(storageid);
               if(viewuser.success === 1){
                      console.log(viewuser.memberlist);
                     
                      setisname(viewuser.memberlist[0].name);
                      setismemberimg(viewuser.memberlist[0].profile_image);
                      setisabout(viewuser.memberlist[0].about);
                      setisdate(viewuser.memberlist[0].date);
               }
        } catch (error) { throw error;}      
 }

  
     
  return (
         <>
      
  
       {ismemberimg === '' && isabout ==='' ? <> 
              <div className="lognosss">
                     <FontAwesomeIcon icon={['fas','check-circle']} />
                     <p> Hi, <span> {isname}! </span> Your new profile on bizonio is ready. Update your bio, your profile image, and more    <Link to={`/Profileeditingall/${props.storageid}`}><span> here. </span></Link> <br /> 
                            <Moment format="MMM D, YYYY" withTitle className="timssqqq">
                                   {isdate} 
                            </Moment>
                     </p>
              </div>
       </>: null}
   
    </>
  );
};

export default Usernotifications;