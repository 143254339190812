import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
function Follownotificationcount(props) {
    
       const {follownotifactioncount} = useContext(MyContext);
      
    useEffect( () => {
       follownotifaction(props.storageid); 
      
     }, []);

   
const [isfollownotificationcount, setisfollownotificationcount] = useState([]);
const [iscounsss, setiscounsss] = useState(false);

const follownotifaction = async (storageid) => {
       try {
              const followqqq = await follownotifactioncount(storageid);
                     if(followqqq.success === 1){
                            console.log('follownotificationcount',followqqq.notificationsfollowscountsss);
                            setisfollownotificationcount(followqqq.notificationsfollowscountsss);
                            setiscounsss(true);
                     }
       } catch (error) { throw error;}    
}

  return (
  
       <>
       {isfollownotificationcount !== 0 && iscounsss === true ? 
              <span className="iconcountsss" > {isfollownotificationcount} </span>
       : null}
           <FontAwesomeIcon icon={['fas','bell']} />

       </>
   
  );
}

export default Follownotificationcount;