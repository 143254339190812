
import React,{useState,useEffect} from 'react';
//include page
import Loginpage from './Loginpage';
import Forgetpassword from './Forgetpassword';
import Registerpage from './Registerpage';

function Loginsection() {
  

  const [isFeatured, setFeatured] = useState([]);
  useEffect( () => {
    window.scrollTo(0, 0);
  }, []);

  
   const [isLogin, setisLogin] = useState(false);
   const [isRegister, setisRegister] = useState(false);
   const [isForget, setisForget] = useState(false);

   const RegisterMode = () => {
       window.scrollTo({top: 0, behavior: 'smooth'});
       setisLogin(false);
       setisRegister(false);
       setisForget(false);
      }
    
  const LoginMode = () => {
       window.scrollTo({top: 0, behavior: 'smooth'});
       setisLogin(true);
       setisRegister(true);
       setisForget(false);
    
  
  }
  const ForgetMode = () => {
       window.scrollTo({top: 0, behavior: 'smooth'});
       setisLogin(false);
       setisRegister(true);
       setisForget(true);
  }  



        return(
            <>
            
            <div className="clearfix"></div>
               <div className="login-1">
              
                  <div className="container">
                     <div className="row">
                     <div className="col-md-1" > &nbsp; </div>
                     <div className="col-md-6" >
                       <div className="logsss3">
                       {isLogin === true ?
                            <>
                              <div className="login-form">
                                        <h2> Login </h2>
                              </div>
                              <Loginpage />
                              <h6 onClick={ForgetMode}> Forgot Password </h6>
                                <h6 onClick={RegisterMode}> Don't have an account? <span style={{color:'#fa6342'}}> Register </span>  </h6>


                            </>
                            : null  }


                          {/* Login Mode */}
                          {/* {isLogin !== true ?
                              <>
                              <Loginpage />
                               
                             </>
                            : null } */}

                            {/* Register Mode */}
                              {isRegister !== true ?
                                <>
                                  <div className="login-form">
                                    <h2> Register </h2>
                                  </div>
                                
                                <Registerpage LoginMode ={LoginMode}/>
                                <h6 onClick={LoginMode}> Already have an Account ? <span style={{color:'#fa6342'}}> Login here </span></h6>
                                  </>
                              : null }

                              {/* Forget Password */}
                              {isForget === true ? 
                              <>
                                <div className="login-form">
                                    <h2> Recover your password </h2>
                                </div>
                              <Forgetpassword  />  
                              <h6 onClick={RegisterMode}> Don't have an account? <span style={{color:'#fa6342'}}> Register </span>  </h6>
                              </>
                              : null  }



                       </div>
                       </div>
                       <div className="col-md-1" > &nbsp; </div>
                       <div className="col-md-4 logsss3 mnxzsss" >
                       {isRegister !== true ?
                                <>
                                  <div className="login-form">
                                    <h2> Login </h2>
                                  </div>
                                
                              
                                <h6 onClick={LoginMode}> Already have an Account ? <span style={{color:'#fa6342'}}> Login here </span></h6>
                                  </>
                              : null }

                        {isLogin === true ?
                            <>
                              <div className="login-form">
                                        <h2> Register </h2>
                              </div>
                             
                                <h6 onClick={RegisterMode}> Don't have an account? <span style={{color:'#fa6342'}}> Register </span>  </h6>


                            </>
                            : null  }

                            {isForget === true ? 
                              <>
                                <div className="login-form">
                                    <h2> Login / Register </h2>
                                </div>
                           
                              <h6 onClick={LoginMode}> Already have an Account ? <span style={{color:'#fa6342'}}> Login here </span></h6>

                              <h6 onClick={RegisterMode}> Don't have an account? <span style={{color:'#fa6342'}}> Register </span>  </h6>

                              </>
                              : null  }

                         </div>
                     
                    
                   
                       </div>
                  </div>

       

                    
            </div>
            </>
        );
    }
export default Loginsection;