import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'react-loader-spinner';
import Moment from 'react-moment';
import {toast } from "react-toastify";
import userphotos from '../Img/user_profile.jpg';
import {WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function Messagelist(props) {
       const {newmessageadds,messageallsss} = useContext(MyContext);
      
       useEffect( () => {
              messageall(props.profileusers); 
           
             }, []);

             const [ismessage, setismessage] = useState([]);
             const messageall = async (profileid) => {
              try {
                     const messageallqqq = await messageallsss(profileid);
                            if(messageallqqq.success === 1){
                                   console.log('message list',messageallqqq.messagealllist)
                                   setismessage(messageallqqq.messagealllist);
                            }
                     } catch (error) { throw error;}    
              } 

       const [userInfo, setuserInfo] = useState({
              messages: '',
          });
       const onChangeValue = (e) => {
              setuserInfo({
                  ...userInfo,
                      [e.target.name]:e.target.value
              });
             
       }
       const newmessage = async(event) =>{
              try {
                     
                     event.preventDefault();
                     event.persist();
                     const newmessagesss = await newmessageadds(userInfo,props.storageid,props.profileusers);
                     if(newmessagesss.success === 1){
                            event.target.reset(); 
                            setuserInfo({
                                   ...userInfo,
                                   messages:'',
                               });
                               toast.info('Message Sent Successfully',{
                                   position: "top-center"});     
                            
                     }
              } catch (error) { throw error;}      
       }


  return (
  
       <>
        
            <div className="prosss yyy6 messagemmm2">
            <div className="login-form"><h2> Message </h2></div>
{props.profileusers != props.storageid ? <>      
            <form onSubmit={newmessage} className="login-forms">
              <textarea  name="messages" value={userInfo.messages} className=" col-md-12" rows="3"  placeholder="  "  onChange={onChangeValue} required />
                          
                            <div className="form-group col-sm-12 text-right">
                                   <button type="submit" className="db-tit-btn2"> Send </button>
                            </div>
                     </form>
</> : null }
{props.profileusers == props.storageid ? <> 
                     <ul className="profile-fieldsmmm2">

{ismessage.map((item, index) => (  
       <li key={item.messages_id}>
              {item.profile_image !=='' ? 
                     <img src={BaseURLuploads+item.profile_image} alt="" className="mr-2mmm" />
              :
               <img src={userphotos} alt="" className="mr-2mmm"  />
              }
                  
              <span> {item.name} </span>
              <span className="timqqq"> <FontAwesomeIcon icon={['fas','clock']} className="iconsss2 " /> <Moment format="MMM D, YYYY" withTitle>
                                       {item.date} 
                                    </Moment></span>
              <div className="clearfix "></div>
              <div className="col-md-12 ">
                    <p>{item.message} </p> 
              </div>

       </li>
))}
                     </ul>

</> :null}		                   



                     </div>

       </>
   
  );
}

export default Messagelist;