import React,{useState,useEffect,useContext} from 'react';
import {BrowserRouter,Route,Switch} from 'react-router-dom';
import { ToastContainer} from "react-toastify";
import {NavLink, Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import 'bootstrap/dist/css/bootstrap.min.css';
import "react-toastify/dist/ReactToastify.css";
import '../App.css';
import logo from '../Img/logo.png';

import Navbarmenu from './menu/Navbarmenu';
import Home from './Home';
import Contmail from './Contmail';
import Loginsection from './Loginsection';
import Resetpassword from './Resetpassword';
import Logout from './Logout';

import Payment from './Payment';
import PaymentUpgrade from './PaymentUpgrade';
import StripeSuccess from './StripeSuccess';
import StripeSuccessUpgrade from './StripeSuccessUpgrade';
import PaypalSuccess from './PaypalSuccess';
import PaypalSuccessUpgrade from './PaypalSuccessUpgrade';
import PaypalCancel from './PaypalCancel';

import Myaccount from './Myaccount';

import ClassifiedAdd from './ClassifiedAdd';
import BusinessAdd from './BusinessAdd';
import AllClassifieds from './AllClassifieds';
import AllPlatinumClassifieds from './AllPlatinumClassifieds';
import AllGoldClassifieds from './AllGoldClassifieds';
import AllSilverClassifieds from './AllSilverClassifieds';
import AllBusiness from './AllBusiness';
import ClassifiedsPreview from './ClassifiedsPreview';
import BusinessPreview from './BusinessPreview';

import NewsFeed from './NewsFeed';
import GroupViews from './GroupViews';
import Members from './Members';
import ProfileView from './ProfileView';
import MemberAds from './MemberAds';
import MemberBusinessAds from './MemberBusinessAds';

import Notificationall from './Notificationall';
import Messagelistall from './Messagelistall';
import NotificationFeedView from './NotificationFeedView';
import Profileeditingall from './Profileeditingall';

import Createcompany from './Createcompany';
import CompanyPage from './CompanyPage';

import Demo from './Demo';


import { fab } from "@fortawesome/free-brands-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas} from '@fortawesome/free-solid-svg-icons';
library.add(fas, fab)



function Dashboard() {
 

  useEffect( () => {
    window.addEventListener('scroll', toggleVisibility);
  
  }, []);
  
  const scrollToTop =()=> {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  
const [is_visible, set_visible] = useState(false);

const toggleVisibility =()=> {
  if (window.pageYOffset > 700) {
    set_visible(true);
  
  } else {
    set_visible(false);
  }
}


  return (
    <BrowserRouter basename="/">
      <ToastContainer />
      
      <div className="App" id="App">
          <Navbarmenu />
          
          <div className="clearfix"></div>
       

        <Switch>
         <Route exact path="/" component={Home}/>
         <Route exact path="/Contact" component={Contmail}/>
         <Route exact path="/Login" component={Loginsection}/>
         <Route exact path="/ResetPassword/:passkey" component={Resetpassword}/>
         <Route exact path="/Logout" component={Logout}/>
         <Route exact path="/Notificationall" component={Notificationall}/>
         <Route exact path="/Messagelistall/:Addpreview" component={Messagelistall}/>

         <Route exact path="/Payment/:memberid" component={Payment}/>
         <Route exact path="/PaymentUpgrade/:memberid" component={PaymentUpgrade}/>
         <Route exact path="/StripeSuccess/:stripesid" component={StripeSuccess}/>
         <Route exact path="/StripeSuccessUpgrade/:stripesid" component={StripeSuccessUpgrade}/>
         <Route exact path="/PaypalSuccess/:paypalid" component={PaypalSuccess}/>
         <Route exact path="/PaypalSuccessUpgrade/:paypalid" component={PaypalSuccessUpgrade}/>
         <Route exact path="/PaypalCancel" component={PaypalCancel}/>

         <Route exact path="/Myaccount" component={Myaccount}/>
         <Route exact path="/ClassifiedAdd" component={ClassifiedAdd}/>
         <Route exact path="/BusinessAdd" component={BusinessAdd}/>
         <Route exact path="/AllClassifieds" component={AllClassifieds}/>
         <Route exact path="/AllPlatinumClassifieds" component={AllPlatinumClassifieds}/>
         <Route exact path="/AllGoldClassifieds" component={AllGoldClassifieds}/>
         <Route exact path="/AllSilverClassifieds" component={AllSilverClassifieds}/>
         <Route exact path="/AllBusiness" component={AllBusiness}/>
         <Route exact path="/ClassifiedsPreview/:Addpreview" component={ClassifiedsPreview}/>
         <Route exact path="/BusinessPreview/:Addpreview" component={BusinessPreview}/>

         <Route exact path="/NewsFeed" component={NewsFeed}/>
         <Route exact path="/GroupViews/:Addpreview/:Addpreviewuser" component={GroupViews}/>
         <Route exact path="/Members" component={Members}/>
         <Route exact path="/ProfileView/:Addpreview" component={ProfileView}/>
         <Route exact path="/MemberAds/:Addpreview" component={MemberAds}/>
         <Route exact path="/MemberBusinessAds/:Addpreview" component={MemberBusinessAds}/>
         <Route exact path="/NotificationFeedView/:Addpreview" component={NotificationFeedView}/>
         <Route exact path="/Profileeditingall/:Addpreview" component={Profileeditingall}/>

         <Route exact path="/Createcompany" component={Createcompany}/>
         <Route exact path="/CompanyPage/:Addpreview" component={CompanyPage}/>

         <Route exact path="/Demo" component={Demo}/>

         

         
        </Switch>
          <div className="clearfix"></div>









          <div id="Footer" className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div id="content_block_02">
                  <div className="content-box">
                      <div className="sec-title ">
                      <div className=" logo">
                        <NavLink exact activeClassName='is-active' to="/">
                          <img src={logo} alt="logo" /> 
                        </NavLink>
                    </div>
                      </div>
                </div>
              </div>

              <p>Bizonio is an International Business Club for business owners and Entrepreneurs. Initially started as a Facebook and LinkedIn group with real entrepreneurs we decided to start an Online business club and a Social Networking website for those in business. On this site, you can connect with other entrepreneurs, contact them and you can also get business advice and help. You can also post any number of business Ads when you become a member. Posting business Ads on this site can help you grow your business.</p>
            </div>
            
            
            <div className="col-md-1"> &nbsp; </div>

            <div className="col-md-3 aaa6">
            <div id="content_block_02">
                  <div className="content-box">
                      <div className="sec-title ">
                        <h1 className="title"> Links </h1>
                      </div>
                </div>
              </div>
            
                <ul>
                <li className="menu-itemsss"><NavLink activeClassName='is-active' to="/AllClassifieds"> <FontAwesomeIcon icon={['fas','caret-right']} className="" />  Business Ads  </NavLink></li>
                <li className="menu-itemsss"><NavLink activeClassName='is-active' to="/AllBusiness"> <FontAwesomeIcon icon={['fas','caret-right']} className="footsss" />   Business Directory   </NavLink></li>
               
                <li className="menu-items"><NavLink activeClassName='is-active' to="/Contact"> <FontAwesomeIcon icon={['fas','caret-right']} className="footsss" /> Contact Form </NavLink></li>
                </ul>
            
           
            

            </div> 
           
           

            <div className="col-md-3 aaa7">
            <div id="content_block_02">
                  <div className="content-box">
                      <div className="sec-title ">
                        <h1 className="title">  Follow Us </h1>
                      </div>
                </div>
              </div>
            
              <div className="footer-widget__social" >
              <a href="https://www.facebook.com/groups/businessownersmarketers" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={['fab','facebook-square']} className="chooseus3 chooseus3facebook" /></a>
              <a href="https://twitter.com/Noblein" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={['fab','twitter-square']} className="chooseus3 chooseus3twitter" /></a>
              <a href="https://www.linkedin.com/groups/3790878/" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={['fab','linkedin']} className="chooseus3 chooseus3linkedin" /></a>
              </div>

              

            </div> 
          </div>
        </div>
      </div>
         


















          <div className="clearfix"></div>
         
          <div className="footersss2">
          <p><Link to="/" > bizonio.com </Link> © All Rights Reserved - 2021 </p>
          {is_visible && ( <>
              <span onClick={scrollToTop} className="scrolls"><FontAwesomeIcon icon={['fas','angle-up']} className="gold" /></span><span className="ltx-hex"><span className="ltx-hex-inner"></span></span>	
              </>
              )}
          </div>
            
       
        </div>
    </BrowserRouter>
  );
}

export default Dashboard;
