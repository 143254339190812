
import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import Loader from 'react-loader-spinner';

import {toast} from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link } from "react-router-dom"; 

import ContentLoader from "react-content-loader";
import userphotos from '../Img/user_profile.jpg';
import DatePicker from 'react-date-picker';
import {WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function ProfileSetting(props) {
 
       const loaderfeatured = [1,2,3,4,5,6,7,8];
       const {profileupdatelist,viewuserprofile} = useContext(MyContext);
       const [loadingadd, setloadingadd] = useState(false);
     
       const [fileempty, setfileempty] = useState(false);
       const [filetemp, setfiletemp] = useState(null);
       const [invalidImage, setinvalidImage] = useState(null);
      
       const [sdate, setsdate] = useState(new Date());
       const [sdatevalid, setsdatevalid] = useState(false);
       const accsss = () =>{
              setsdatevalid(true);
       }
       const [userInfo, setuserInfo] = useState({
        name: '',
        password: '',
        conformpassword: '',
        about: '',
        facebook: '',
        twitter: '',
        linkedin: '',
        dob: '',
        sex: '',
        city: '',
        country:'',
        phone: '',
    });

    const onChangeValue = (e) => {
        setuserInfo({
            ...userInfo,
                [e.target.name]:e.target.value
        });
    } 
    


    useEffect( () => {
      userdetails(props.storageid);
   
     }, []);


const userdetails = async(storageid) =>{
       try {
              const viewuser = await viewuserprofile(storageid);
              if(viewuser.success === 1){
                     console.log(viewuser.memberlist);
                     setuserInfo({
                      ...userInfo,
                      name:[viewuser.memberlist[0].name],
                      about:[viewuser.memberlist[0].about],
                      facebook:[viewuser.memberlist[0].facebook],
                      twitter:[viewuser.memberlist[0].twitter],
                      linkedin:[viewuser.memberlist[0].linkedin],
                      dob: [viewuser.memberlist[0].dob],
                      sex: [viewuser.memberlist[0].sex],
                      city: [viewuser.memberlist[0].city],
                      country:[viewuser.memberlist[0].country],
                      phone: [viewuser.memberlist[0].phone],
               } );
                     
              }
       } catch (error) { throw error;}      
}

       

            const profileupdate = async (event) => {
              try {
                     setloadingadd(true);
                            event.preventDefault();
                            event.persist();
                            if(invalidImage !== null){
                              toast.error(invalidImage, {
                                position: "top-center",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                              }); 
                              setloadingadd(false);
                              return;
                            }
                            if(userInfo.password !== userInfo.conformpassword){
                              toast.error('password and confirm password does not match.',{
                                position: "center",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                              });  
                              setloadingadd(false);
                              return;
                            }
                           
                            const dataadd = await profileupdatelist(userInfo,props.storageid,userInfo.fileempty.value,sdate);
                            console.log(dataadd);
                            if(dataadd.success === 1){
                                   console.log(dataadd);
                                  
                                   setloadingadd(false);
                                   event.target.reset(); 
                                   toast( 'Success', {
                                    position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                                  });  
                                  
                            }else{
                                   setloadingadd(false);
                                    toast.error( 'Please Try Again', {
                                      position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                                    });  
                            }

              } catch (error) { throw error;}    
       }
       // {props.match.params.Add}

       const resetFile = (event) => {
              event.preventDefault();
              setfiletemp(null)
            }

            let reader = new FileReader();

            const handleChange = (event) => {
              const imageFile = event.target.files[0];
                    const imageFilname = event.target.files[0].name;
       
                    if (!imageFile) {
                     setinvalidImage('Please select image.');
                      return false;
                    }
                
                    if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif)$/)) {
                     setinvalidImage('Please select valid image JPG,JPEG,PNG');
                      return false;
                    }
                    reader.onload = (e) => {
                      const img = new Image();
                      img.onload = () => {
       
       //----------------------------- Resize img code -------------------------------------------------
                       var canvas = document.createElement('canvas');
                       var ctx = canvas.getContext("2d");
                       ctx.drawImage(img, 0, 0);
       
                       var MAX_WIDTH = 437;
                       var MAX_HEIGHT = 437;
                       var width = img.width;
                       var height = img.height;
       
                       if (width > height) {
                         if (width > MAX_WIDTH) {
                           height *= MAX_WIDTH / width;
                           width = MAX_WIDTH;
                         }
                       } else {
                         if (height > MAX_HEIGHT) {
                           width *= MAX_HEIGHT / height;
                           height = MAX_HEIGHT;
                         }
                       }
                       canvas.width = width;
                       canvas.height = height;
                       var ctx = canvas.getContext("2d");
                       ctx.drawImage(img, 0, 0, width, height);
                       ctx.canvas.toBlob((blob) => {
                         const file = new File([blob], imageFilname, {
                             type: 'image/jpeg',
                             lastModified: Date.now()
                         });
                         setuserInfo({
                            ...userInfo,
                            file:file,
                       })
                       }, 'image/jpeg', 1);
       
       
       //-------------------------------------------------------------------------------------
                     setfiletemp( URL.createObjectURL(imageFile))
                     setfileempty(true);
                     setinvalidImage(null)
                     };
                      img.onerror = () => {
                            setinvalidImage('Invalid image content.');
                        return false;
                      };
                      //debugger
                      img.src = e.target.result;
                    };
                    reader.readAsDataURL(imageFile);
       
            }; 
       
            return ( 
                   <>
                   <span className="udb-inst"> User Profile </span>
                   <div className="ud-cen-s1">
                   <form onSubmit={profileupdate} className="login-forms">
             
             <div className="clearfix"></div>
         <div className="form-row">
      
             <div className="form-group col-md-12">
                 <label className="font-weight-bold col-md-4">Full Name :  </label>
                 <input type="text" name="name" value={userInfo.name} onChange={onChangeValue}className="form-control col-md-8" placeholder="Your name here..."    />
                
             </div>
             <div className="form-group col-md-12">
                      <label className="font-weight-bold col-md-4"> Change Profile Photo :  </label>
                     
                      <input type="file" name="file" className="form-control col-md-8" placeholder="Title"  onChange={handleChange}   />
                    
                      {fileempty === true ? 

                        <input type="hidden" name="fileempty" ref={(val) => userInfo.fileempty = val} className="form-control" defaultValue="imgactive" />
                        : 
                        <input type="hidden" name="fileempty" ref={(val) => userInfo.fileempty = val} className="form-control" defaultValue="imgempty" />
                        }
                  </div>
                 
                   
           
             <div className="form-group col-md-12">
                 <label className="font-weight-bold col-md-4"> Password :  </label>
                 <input type="password" name="password" value={userInfo.password} onChange={onChangeValue} className="form-control col-md-8" placeholder="Your password here..."   />
                 
             </div>
             <div className="form-group col-md-12">
                 <label className="font-weight-bold col-md-4"> Confirm Password :  </label>
                 <input type="password" name="conformpassword" value={userInfo.conformpassword} onChange={onChangeValue} className="form-control col-md-8" placeholder="Your password here..."  />
             </div>
             
             <div className="form-group col-md-12">
                 <label className="font-weight-bold col-md-4"> About :  </label>
                 <textarea  name="about" value={userInfo.about} className="form-control col-md-8" rows="5"  placeholder="About Me"  onChange={onChangeValue} />
             </div>
             <div className="form-group col-md-12">
                 <label className="font-weight-bold col-md-4">Facebook : </label>
                 <input type="text" name="facebook" value={userInfo.facebook} onChange={onChangeValue} className="form-control col-md-8" placeholder="Facebook link here..."    />
                
             </div>
              <div className="form-group col-md-12">
                 <label className="font-weight-bold col-md-4">Twitter : </label>
                 <input type="text" name="twitter" value={userInfo.twitter} onChange={onChangeValue} className="form-control col-md-8" placeholder="Twitter link here..."    />
                
             </div>
             <div className="form-group col-md-12">
                 <label className="font-weight-bold col-md-4">Linkedin :  </label>
                 <input type="text" name="linkedin" value={userInfo.linkedin} onChange={onChangeValue} className="form-control col-md-8" placeholder="Linkedin link here..."    />
                
             </div>
             <div className="form-group col-md-12">
                            <label className="font-weight-bold col-md-3"> Date of Birth :  </label>
                            {sdatevalid === true ? 
                                   <DatePicker onChange={setsdate} value={sdate}/>
                            : <button  onClick={accsss}> choose Date </button> }
                            {/* <input type="text" name="dob" value={userInfo.dob} onChange={onChangeValue}  className="form-control col-md-9" placeholder="Date of Birth"  /> */}
                     </div>

                    <div className="form-group col-md-12">
                            <label className="font-weight-bold col-md-3"> Gender : </label>
                            <div className="form-group col-md-9">
                                   <div className="form-group col-md-6">
                                          <input type="radio" name="sex" value="Male" onChange={onChangeValue}  className="form-control "   checked={userInfo.sex == 'Male'}  /> Male 
                                   </div>
                                   <div className="form-group col-md-6">
                                          <input type="radio" name="sex" value="Female" onChange={onChangeValue}  className="form-control"  checked={userInfo.sex == 'Female'} /> Female
                                   </div>
                            </div>
                     </div>
                     <div className="form-group col-md-12">
                            <label className="font-weight-bold col-md-3"> City : </label>
                            <input type="text" name="city" value={userInfo.city} onChange={onChangeValue}  className="form-control col-md-9" placeholder="City"  />
                     </div>
                     <div className="form-group col-md-12">
                            <label className="font-weight-bold col-md-3"> Country : </label>
                            <input type="text" name="country" value={userInfo.country} onChange={onChangeValue}  className="form-control col-md-9" placeholder="Country"  />
                     </div>
                     <div className="form-group col-md-12">
                            <label className="font-weight-bold col-md-3"> Phone : </label>
                            <input type="text" name="phone" value={userInfo.phone} onChange={onChangeValue}  className="form-control col-md-9" placeholder="Phone"  />
                     </div>
           
             <div className="form-group col-md-12 ">
             {loadingadd ?
                         <>
                                <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                         </>
                      : 
                 <button type="submit" className="db-tit-btn" style={{
                   marginBottom: "37px"}} > Save </button>
             }
             </div>
            
         </div>
        
     </form>  








          

                   










              </div>

             </>
              );
          }
   
export default ProfileSetting;
