import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../../contexts/MyContext';
import covers from '../../Img/covers.jpg';
import userphotos from '../../Img/user_profile.jpg';
import Moment from 'react-moment';
import {Link } from "react-router-dom"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Popup from 'reactjs-popup';
import { WEB_URL,WEB_URL_UPLOAD } from '../../contexts/Apilinks';
let BaseURL = WEB_URL;
let BaseURLuploads = WEB_URL_UPLOAD;

function Search(props) {
  
  const {rootState,ApiBusinessAdslist,ApiPeoplelist,ApiBusinessDirectorylist,ApiNewsfeedlist,ApiGroupslist} = useContext(MyContext);
  const {isAuth,theUser} = rootState;

       const handleClickSearch = () => {
              props.togglsssmodesearch();
       }
       
       const [issearchValue, setsearchValue] = useState({
              title: '',
       });
       const [issearchactivesss, setsearchactivesss] = useState(false);
       const handleOnChange = (e) => {
              setsearchactivesss(true);
              setsearchValue({
                     ...issearchValue,
                     [e.target.name]:e.target.value
              });
              if(isBusinessAds === false && issearchValue.title !== '' ){ 
                     ApiBusinessAds(issearchValue.title);
              }
              if(ispeople === true && issearchValue.title !== '' ){ 
                     ApiPeople(issearchValue.title);
              }
              if(isBusinessDirectory === true && issearchValue.title !== '' ){ 
                     ApiBusinessDirectory(issearchValue.title);
              }
              if(isNewsfeed === true && issearchValue.title !== '' ){ 
                     ApiNewsfeed(issearchValue.title);
              }
              if(isGroups === true && issearchValue.title !== '' ){ 
                     ApiGroups(issearchValue.title);
              }


          }
       const [isBusinessAds, setisBusinessAds] = useState(false);
       const [ispeople, setispeople] = useState(false);
       const [isBusinessDirectory, setisBusinessDirectory] = useState(false);
      
       const [isNewsfeed, setisNewsfeed] = useState(false);
       const [isGroups, setisGroups] = useState(false);
     
       const BusinessAds =()=>{
              setisBusinessAds(false);setispeople(false);setisBusinessDirectory(false);setisNewsfeed(false);setisGroups(false);
              ApiBusinessAds(issearchValue.title);
       }
       const People =()=>{
              setisBusinessAds(true);setispeople(true);setisBusinessDirectory(false);setisNewsfeed(false);setisGroups(false);
              ApiPeople(issearchValue.title);
       } 
       const BusinessDirectory =()=>{
              setisBusinessAds(true);setispeople(false);setisBusinessDirectory(true);setisNewsfeed(false);setisGroups(false);
              ApiBusinessDirectory(issearchValue.title);
       }  
       const Newsfeed =()=>{
              setisBusinessAds(true);setispeople(false);setisBusinessDirectory(false);setisNewsfeed(true);setisGroups(false);
              ApiNewsfeed(issearchValue.title);
       }
       const Groups =()=>{
              setisBusinessAds(true);setispeople(false);setisBusinessDirectory(false);setisNewsfeed(false);setisGroups(true);
              ApiGroups(issearchValue.title);
       }    
    
       const [isBusinessAdspreview, setisBusinessAdspreview] = useState([]);
          
       const ApiBusinessAds = async (searchValue) => {
              try {
                     const ApiBusinessAdsss = await ApiBusinessAdslist(searchValue);
                     console.log('BusinessAds',ApiBusinessAdsss);
                      if(ApiBusinessAdsss.success === 1){
                             setisBusinessAdspreview(ApiBusinessAdsss.BusinessAdsqqq);
                      }
              } catch (error) { throw error;}    
       }
       const [isPeoplepreview, setisPeoplepreview] = useState([]);
       const ApiPeople = async (searchValue) => {
              try {
                     const ApiPeoplesss = await ApiPeoplelist(searchValue);
                     console.log('People',ApiPeoplesss);
                      if(ApiPeoplesss.success === 1){
                             setisPeoplepreview(ApiPeoplesss.Peopleqqq);
                      }
              } catch (error) { throw error;}    
       }
       const [isBusinessDirectorypreview, setisBusinessDirectorypreview] = useState([]);
       const ApiBusinessDirectory = async (searchValue) => {
              try {
                     const ApiBusinessDirectorysss = await ApiBusinessDirectorylist(searchValue);
                     console.log('Business Directory',ApiBusinessDirectorysss);
                      if(ApiBusinessDirectorysss.success === 1){
                            setisBusinessDirectorypreview(ApiBusinessDirectorysss.BusinessDirectoryqqq);
                      }
              } catch (error) { throw error;}    
       }
    
 const [isNewsfeedpreview, setisNewsfeedpreview] = useState([]);
       const ApiNewsfeed = async (searchValue) => {
              try {
                     const ApiNewsfeedsss = await ApiNewsfeedlist(searchValue);
                     console.log('Newsfeed',ApiNewsfeedsss);
                      if(ApiNewsfeedsss.success === 1){
                            setisNewsfeedpreview(ApiNewsfeedsss.Newsfeedqqq);
                      }
              } catch (error) { throw error;}    
       }
 const [isGroupspreview, setisGroupspreview] = useState([]);
       const ApiGroups = async (searchValue) => {
              try {
                     const ApiGroupsss = await ApiGroupslist(searchValue);
                     console.log('Groups',ApiGroupsss);
                      if(ApiGroupsss.success === 1){
                            setisGroupspreview(ApiGroupsss.Groupsqqq);
                      }
              } catch (error) { throw error;}    
       }

       const externallinks = (url) => {
              if (!!url && !!url.trim()) { 
                     url = url.trim(); 
                     if (!/^(https?:)?\/\//i.test(url)) { 
                         url = 'http://' + url; 
                         window.open(url, '_blank');
                     }else{
                            window.open(url, '_blank');
                     }
                 } 
            }  
       const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]{11,11}).*/;   
       
  return (
    // Pass on our props
    <div className="fullmenussearch">
       <span className="clossssearch" onClick={handleClickSearch}>   <FontAwesomeIcon icon={['fas','times-circle']} />  </span>
      <div className="container">
             <div className="row">
             <div className="fullmenussearchsss">
             <div className="log-bor">&nbsp;</div>
            
  
            

    <div className="col-md-12">

   

<div className="searchbar">
       <form className="">
              <input name="title"  type="text" placeholder="Search Business Ads, People, Business directory, Newsfeed, Groups ..."  onChange={handleOnChange}   value={issearchValue.title}
                />
              {/* {isBusinessAds === false ? <>
                  <p  className="btnmmm" onClick={BusinessAds}> search  </p>
              </> : null}
              {ispeople === true ? <>
                  <p  className="btnmmm" onClick={People}> search  </p>
              </> : null}
              {isBusinessDirectory === true ? <>
                  <p  className="btnmmm" onClick={BusinessDirectory}> search  </p>
              </> : null}
              {isNewsfeed === true ? <>
                  <p  className="btnmmm" onClick={Newsfeed}> search  </p>
              </> : null}
              {isGroups === true ? <>
                  <p  className="btnmmm" onClick={Groups}> search  </p>
              </> : null}
              */}
              
       </form>
</div>
            
            
            
            
            </div>
{issearchactivesss === true ? <>  
<div className="searchlistsss">
<div className="col-md-4">
      <ul>
      <div className="login-form">    <h2> Search </h2> </div>
          <li className={isBusinessAds === false ? "activesearch":null } onClick={BusinessAds}> Business Ads </li>
          <li className={ispeople === true ?  "activesearch":null } onClick={People}> People </li>
          <li className={isBusinessDirectory === true ?  "activesearch":null } onClick={BusinessDirectory}> Business Directory  </li>
          <li className={isNewsfeed === true ?  "activesearch":null } onClick={Newsfeed}> Newsfeed  </li>
          <li className={isGroups === true ?  "activesearch":null } onClick={Groups}> Groups  </li>
      </ul> 
</div>
{/* -------------------------------------------------------------------------------------------------------------- Business Ads ------------------------------------------------------------------------------------------------------- */}
<div className="col-md-8 searchlistsss2">
{isBusinessAds === false ? <>
       {isBusinessAdspreview.length === 0 ? <p> No results found </p> : null}
       {isBusinessAdspreview.map((item, index) => ( 
              <div className="col-md-6" key={item.classified_id}>
              <div className="single-listing-item ">
                  <div className="listing-image">
                  <Link target="_parent" onClick={handleClickSearch}to={`/ClassifiedsPreview/${item.classified_id}`} className="link-btn">
                          <img src={BaseURLuploads+item.image} alt="Classifiedsads" />
                         
                          </Link>
                   </div>
      
                
                      <div className=" ">
                      <Link target="_parent" onClick={handleClickSearch} to={`/ClassifiedsPreview/${item.classified_id}`} className="link-btn">  <h3> {item.title} </h3>     </Link>
                      </div>
                       <div className="Platinum3">
                          <h4>  <Link target="_parent" onClick={handleClickSearch} to={`/ClassifiedsPreview/${item.classified_id}`} className="link-btn"> Read More     </Link>  <FontAwesomeIcon icon={['fas','plus-circle']} className="iconsss2 "/></h4>
                         
                      </div>
               
                       
                     
              </div>
          </div>    
       ))}
</> : null}
{/* -------------------------------------------------------------------------------------------------------------- People ------------------------------------------------------------------------------------------------------- */}
{ispeople === true ? <> 
       {isPeoplepreview.length === 0 ? <p> No results found </p> : null}
       {isPeoplepreview.map((item, index) => ( 
               <div  className="col-md-4" key={item.register_id}> 
               <div className="list-wrapswqasas">
              
 
 
             <div className="item-avatar">
                                    {item.profile_image !=='' ? 
                                           <img src={BaseURLuploads+item.profile_image} alt="" className="ccc9" />
                                    :
                                           <img src={userphotos} alt="" className=""  />
                                    }
                                    <h4>{item.name}</h4>
                             </div>
                             <Link target="_parent" onClick={handleClickSearch} to={`/ProfileView/${item.register_id}`} >
                             <button className="buttons2" > View Profile </button> 
                             </Link>
               
                             </div>
                             </div>
       ))}
</> : null}

 {/* --------------------------------------------------------------------------------------------------------------  Business Directory ------------------------------------------------------------------------------------------------------- */} 
 {isBusinessDirectory === true ? <> 
       {isBusinessDirectorypreview.length === 0 ? <p> No results found </p> : null}
       {isBusinessDirectorypreview.map((item, index) => ( 
               <div className="col-md-6" key={item.business_id}>
               <div className="single-listing-item ">
                   <div className="listing-image">
                   <Link onClick={handleClickSearch} target="_parent" to={`/BusinessPreview/${item.business_id}`} className="link-btn">
                           <img src={BaseURLuploads+item.image} alt="Classifiedsads" />
                          
                           </Link>
                    </div>
       
                
                       <div className="">
                       <Link onClick={handleClickSearch} target="_parent"  to={`/BusinessPreview/${item.business_id}`} className="link-btn">  <h3> {item.title} </h3>     </Link>
                       </div>
                        <div className="Platinum3">
                           <h4>  <Link onClick={handleClickSearch} to={`/BusinessPreview/${item.business_id}`} target="_parent" className="link-btn"> Read More     </Link>  <FontAwesomeIcon icon={['fas','plus-circle']} className="iconsss2 "/></h4>
                          
                       </div>
                
                        
                      
               </div>
           </div>    
       ))}
</> : null}

{/* --------------------------------------------------------------------------------------------------------------  NewsFeed ------------------------------------------------------------------------------------------------------- */} 
 {isNewsfeed === true ? <> 
       {isNewsfeedpreview.length === 0 ? <p> No results found </p> : null}
       {isNewsfeedpreview.map((item, index) => ( 
              <div key={item.feed_id} className="col-md-10" style={{padding:0}}> 

              <div className="block-ellipsis" dangerouslySetInnerHTML={{ __html: item.feedtext }} />

              {item.image ? 
                            <div className="rtmedia-item-thumbnail">
<Popup trigger={<span className="popupsssimg2"> <FontAwesomeIcon icon={['fas','search']} className="iconsss2zzz2 " />  <img src={BaseURLuploads+item.image} alt="feedsimages"  /> </span>} position="top left">
       {close => (
       <div className="feedspopups">
               <a className="close" onClick={close}>
              &times;
              </a>
          
            
              <div className="feeds yyy2">
              <div className="feedswww">
              <img src={BaseURLuploads+item.image} alt="feedsimages" className="popupsssimg" />

              </div>
              </div>

       </div>
       )}
       </Popup>
       </div>
       : null }
       <div className="clearfix"> </div>
                     {item.youtube.match(regExp) ? 
                     <> 
                            {item.youtube.match(regExp).length >= 2 ? 

                            <iframe id="ytplayer" type="text/html" className="videosss2"
                            src={`https://www.youtube.com/embed/` + item.youtube.match(regExp)[2] + `?rel=0&showinfo=0&color=white&iv_load_policy=3`}
                            frameBorder="0" allowFullScreen></iframe>
                            : null }
                     </>
                            : null 
                     } 
                      {item.url !== '' ? <>  
                            <div className="col-md-12 ccc2"> 
                                   <hr />
                                 <p onClick={() => externallinks(item.url)}>{item.url} </p> 
                            </div>
                      </> : null }

              </div>
       ))}
</> : null}

{/* -------------------------------------------------------------------------------------------------------------- Groups ------------------------------------------------------------------------------------------------------- */} 
 {isGroups === true ? <> 
       {isGroupspreview.length === 0 ? <p> No results found </p> : null}
       {isGroupspreview.map((item, index) => ( 
              <div className="col-md-4" key={item.groups_id}>
              <div className="ccc7">
                                   <Link onClick={handleClickSearch} to={`/GroupViews/${item.groups_id}/${theUser}`} target="_parent"  className="link-btn">
                                          <div className="ccc7img">
                                                 {item.coverimage === '' ?<><img src={covers} alt="userprofile" /></>
                                                 :  <img src={BaseURLuploads+item.coverimage} alt="Classifiedsads" />}
                                                  <div className="listing-author ">
                                                        {item.image !=='' ? 
                                                               <img src={BaseURLuploads+item.image} alt="" className="rounded-circle mr-2" />
                                                        :
                                                               <img src={userphotos} alt="" className="rounded-circle mr-2"  />
                                                         }
                                                 </div>
                                          </div>
                                          <h1>{item.groups_name}</h1>
                                   </Link>
              </div>
                            </div>
       ))}
</> : null}

</div>
</div>

</> : null }




    </div>
    </div>
    </div>
    </div>
  );
};

export default Search;