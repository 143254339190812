import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import Loader from 'react-loader-spinner';
function Followunfollow(props) {
    
       const {followlist,joinsfollowlist,leavefollowlist,} = useContext(MyContext);
      
    useEffect( () => {
       follow(props.profileids,props.storageid); 
      
     }, []);

     const [loadingadd, setloadingadd] = useState(false);
const [isfollow, setisfollow] = useState([]);
const [isuserfollowsss, setisuserfollowsss] = useState([]);
const follow = async (profileuserids,storageid) => {
       try {
              const followqqq = await followlist(profileuserids,storageid);
                     if(followqqq.success === 1){
                            console.log('follow',followqqq.followsss);
                            console.log('userfollowsss',followqqq.userfollowsss);
                            setisfollow(followqqq.followsss);
                            setisuserfollowsss(followqqq.userfollowsss);
                     }
       } catch (error) { throw error;}    
}
const joinsfollow = async (userids) => {
       try {
              setloadingadd(true);
              const joinsfollowsss = await joinsfollowlist(props.profileids,userids);
                     if(joinsfollowsss.success === 1){
                            follow(props.profileids,props.storageid); 
                            props.refreshfollowcount(props.profileids);
                            props.refreshfollowingcount(props.profileids);
                            //followcount(props.match.params.Addpreview); 
                           // followingcount(props.match.params.Addpreview); 
                           setloadingadd(false);
                     }
                     setloadingadd(false);
       } catch (error) { throw error;}    
}
const leavefollow = async (userids) => {
       try {
              const leavefollowsss = await leavefollowlist(props.profileids,userids);
                     if(leavefollowsss.success === 1){
                            follow(props.profileids,props.storageid); 
                            props.refreshfollowcount(props.profileids);
                            props.refreshfollowingcount(props.profileids);
                           // followcount(props.match.params.Addpreview); 
                           // followingcount(props.match.params.Addpreview); 
                           
                     }
       } catch (error) { throw error;}    
}
  return (
  
       <>
            {isuserfollowsss.length > 0 ? null : <>  <button className="group-buttonsss" onClick={()=>joinsfollow(props.storageid)} > Follow </button> </>   }

            {isfollow.length > 0 ? <>
                     {isfollow.map((item, index) => ( 
                            <div  key={item.follow_id}>
                                   {item.follow_status === 1 && props.storageid === item.follow_userid ? 
                                    <button className="group-buttonsss" onClick={()=>leavefollow(props.storageid)} > UnFollow</button>
                                   :<>
                                    { item.follow_status === 0 && props.storageid === item.follow_userid ? <>
                                          {loadingadd ?<>
                                                 <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                                          </>: 
                                                 <button className="group-buttonsss" onClick={()=>joinsfollow(props.storageid)} >Follow </button>
                                          }
                                   </>: null }
                                   </>
                                   }

                                   {/* {theUser == item.follow_userid ? <>
                                   {item.follow_userid}xxxxxxxxxxx

                                   </>
                                   : null } */}
                                   
                            </div>
                     ))}

                     </> : null  }

       </>
   
  );
}

export default Followunfollow;