import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'react-loader-spinner';
import { useHistory } from "react-router-dom";

import {Link } from "react-router-dom"; 
import ContentLoader from "react-content-loader";
import user_profile from '../Img/user_profile.jpg';
import Popup from 'reactjs-popup';
import {toast} from "react-toastify";

import {WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function Advertslist(props) {


       
       const {rootState,Advertsviewslist} = useContext(MyContext);
       const {theUser} = rootState;
     
   
       useEffect( () => {
              Advertsviews(props.profileusers);
              
             }, []);

            

             const [isAdverts, setisAdverts] = useState([]);
             const Advertsviews = async (profileusers) => {
              try {
                     const Advertsviewsss = await Advertsviewslist(profileusers);
                            if(Advertsviewsss.success === 1){
                                   console.log('Adverts list',Advertsviewsss.Advertslist)
                                   setisAdverts(Advertsviewsss.Advertslist);
                            }
                     } catch (error) { throw error;}    
              }
            

        return(
               <div className="ud-cen-s1yyy">
                       
                     
                      <div className="ud-cen-s1s urlsqwsss3">
                      <div className="login-form"><h2>  Business Ads  </h2></div>
                   
{isAdverts.map((item, index) => ( 
        <div className="col-md-10 uiuiuiu" key={item.classified_id} style={{marginRight:"27px"}}>
        <div className="single-listing-item ">
            <div className="listing-image">
            <Link to={`/ClassifiedsPreview/${item.classified_id}`} className="link-btn">
                    <img src={BaseURLuploads+item.image} alt="Classifiedsads" />
                    <div className="listing-tag">
                    {item.category === 'other' ?
                        <>{item.other}</>
                        :  <>{item.category}  </>}
                    </div>
                    </Link>
             </div>
                <div className="listing-content">
                <div className="listing-author ">
                {item.profile_image !=='' ? 
                             <img src={BaseURLuploads+item.profile_image} alt="" className="rounded-circle mr-2" />
                             :
                             <img src={user_profile} alt="" className="rounded-circle mr-2"  />
                         }
               
                  <span> {item.name} </span></div>
                <h3> {item.title} </h3>
                <div className="aaa2" dangerouslySetInnerHTML={{ __html: item.description }} />
                </div>
                    <div className="listing-box-footer">
                      
                        <div className="link-box">
                          <Link to={`/ClassifiedsPreview/${item.classified_id}`}><span>View More </span></Link></div>
                    </div>
               
        </div>
    </div>   
       
      ))}



          </div>
          </div>
        );
    }

export default Advertslist;