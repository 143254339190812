import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';

import Loader from 'react-loader-spinner';

import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function GroupPostEdit(props) {
       const {groupfeedsupdatelist} = useContext(MyContext);
      
      
   
       const [userInfo, setuserInfo] = useState({
              feed: props.feedtext,
              file:'',
              url:props.url,
              youtube:props.youtube,
          });
     
       const [isdeleteimg, setdeleteimg] = useState(false);
       const [loadingedit, setloadingedit] = useState(false);
       const [fileempty, setfileempty] = useState(false);
       const [filetemp, setfiletemp] = useState(null);
       const [invalidImage, setinvalidImage] = useState(null);

       const onChangeValue = (e) => {
              setuserInfo({
                  ...userInfo,
                      [e.target.name]:e.target.value
              });
             
       }    
      
     
       const deletepostimg = async() =>{
              setdeleteimg(true);
       }
       const newfeedsupdate = async(event) =>{
              try {
                     setloadingedit(true);
                     event.preventDefault();
                     event.persist();
                     const newfeedsqqqupdate = await groupfeedsupdatelist(userInfo,userInfo.fileempty.value,props.feedid,userInfo.deleteimgsss.value);
                     if(newfeedsqqqupdate.success === 1){
                            setloadingedit(false);
                            event.target.reset(); 
                            props.resetposts([]);
                            //props.newsetCount(1);
                            props.grouprefresh(1,props.groupid);
                            props.moreoptions(props.feedid);
                            props.close();
                            if(props.annc === 1){
                                   props.listannoumcements(props.groupid);
                            }
                     }
              } catch (error) { throw error;}      
       }


       const resetFile = (event) => {
              event.preventDefault();
              setfiletemp(null)
            }

            let reader = new FileReader();

   const handleChange = (event) => {
       const imageFile = event.target.files[0];
             const imageFilname = event.target.files[0].name;

             if (!imageFile) {
              setinvalidImage('Please select image.');
               return false;
             }
         
             if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif)$/)) {
              setinvalidImage('Please select valid image JPG,JPEG,PNG');
               return false;
             }
             reader.onload = (e) => {
               const img = new Image();
               img.onload = () => {

//----------------------------- Resize img code -------------------------------------------------
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);

                var MAX_WIDTH = 437;
                var MAX_HEIGHT = 437;
                var width = img.width;
                var height = img.height;

                if (width > height) {
                  if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                  }
                } else {
                  if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                  }
                }
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
                ctx.canvas.toBlob((blob) => {
                  const file = new File([blob], imageFilname, {
                      type: 'image/jpeg',
                      lastModified: Date.now()
                  });
                  setuserInfo({
                     ...userInfo,
                     file:file,
                })
                }, 'image/jpeg', 1);


//-------------------------------------------------------------------------------------
              setfiletemp( URL.createObjectURL(imageFile))
              setfileempty(true);
              setinvalidImage(null)
              };
               img.onerror = () => {
                     setinvalidImage('Invalid image content.');
                 return false;
               };
               //debugger
               img.src = e.target.result;
             };
             reader.readAsDataURL(imageFile);

     }; 

    
  

  return (
  
       <>
            <div className="pppsss">
            <div className="feedswww">
                   <h3 className="create-post"> Edit Post</h3>
                   
                     <form onSubmit={newfeedsupdate} className="login-forms">
                            <textarea  name="feed" value={userInfo.feed} className=" col-md-12 ccc4 " rows="4"  placeholder=" Start a post "  onChange={onChangeValue} required />
                     <div className="clearfix"></div>

                     <div className="col-md-12 ccc5" style={{padding:0}}>
                     <ul> 
                            <li>
                            <div className="col-md-7" >  
                                   <label className="fileContainersss">
                                   <input type="file" name="file" className="form-control" placeholder="Title" onChange={handleChange} />
                                   </label>
                                   
                                   {fileempty === true ? 
                                   <input type="hidden" name="fileempty" ref={(val) => userInfo.fileempty = val} className="form-control" defaultValue="imgactive" />
                                   : 
                                   <input type="hidden" name="fileempty" ref={(val) => userInfo.fileempty = val} className="form-control" defaultValue="imgempty" />
                                   }
                                   </div>
                                  <div className="col-md-5" >
                                   {props.image !== '' && isdeleteimg === false ? <>
                                          <img src={BaseURLuploads+props.image} alt="postimage" />
                                          <span className="morelink" onClick={() => deletepostimg()} > Delete  </span>
                                          </>
                                   : null}
                                   {isdeleteimg === true ? 
                                   <input type="hidden" name="deleteimgsss" ref={(val) => userInfo.deleteimgsss = val} className="form-control" defaultValue="imgdelete" />
                                   :
                                   <input type="hidden" name="deleteimgsss" ref={(val) => userInfo.deleteimgsss = val} className="form-control" defaultValue="" />
                                   }
                                   </div>
                            </li>
                      
                     </ul>
                     </div>
                    
                    
                  
                            <input type="text" name="url" value={userInfo.url} onChange={onChangeValue} className="form-control"  placeholder=" website url "/>
                    
                            <input type="text" name="youtube" value={userInfo.youtube} onChange={onChangeValue} className="form-control"  placeholder=" youtube url "/>
                            <div className="col-md-4" style={{padding:0}}>
                            {loadingedit ?
                            <>
                                   <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                            </>
                            : 
                                   <div className="form-group col-sm-12 text-right">
                                          <button type="submit" className="db-tit-btn2"> Edit Post  </button>
                                          
                                   </div>
                             }
                   </div>
                     </form>
                     </div>
                     </div>

       </>
   
  );
}

export default GroupPostEdit;