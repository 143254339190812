import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import {Link } from "react-router-dom"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import notificationicon from '../Img/notification-icon.png';
import Messagenotification from './Messagenotification';
import Usernotifications from './Usernotifications';
import { WEB_URL,WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURL = WEB_URL;
let BaseURLuploads = WEB_URL_UPLOAD;

function Notificationall(props) {
  
  const {rootState,} = useContext(MyContext);
  const {isAuth,theUser} = rootState;

  
     
  return (
  
    <div className="notificationsss">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
          <span  className="bizoctaclosss"><Link to={`/Myaccount`} > <FontAwesomeIcon icon={['fas','times-circle']} /> </Link></span>
            <h1>Notifications</h1>
              <div className="bizocta"> 
              <img src={notificationicon} alt="" />
              <h2>Welcome to your Notifications!</h2>
              <p>  This is where we'll notify you when you have new followers, or social activity (such as when someone likes your comment or your post </p>
              </div>
 

             {isAuth === true && theUser !==''?
              <><Usernotifications storageid={theUser} /></>
            : null } 
            {isAuth === true && theUser !==''?
              <><Messagenotification storageid={theUser} /></>
            : null }


          </div>
        </div>
      </div>
    </div>
  );
};

export default Notificationall;