import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import {Link } from "react-router-dom"; 
import Loader from 'react-loader-spinner';
import userphotos from '../Img/user_profile.jpg';
import { useHistory } from "react-router-dom";
import {WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function Followerslist(props) {
       let history = useHistory();
       const {follwerssslistqqq,joinsfollowlistsec,follwerloginsall} = useContext(MyContext);
      
    useEffect( () => {
      follwersss(props.profileusers); 
      //follwerlogins(props.storageid); 
     }, []);

     const [isfollwersss, setisfollwersss] = useState([]);
     const follwersss = async (profileid) => {
      try {
             const follwersssqqq = await follwerssslistqqq(profileid);
                    if(follwersssqqq.success === 1){
                           console.log('follwers list',follwersssqqq.follwerslist)
                           setisfollwersss(follwersssqqq.follwerslist);
                    }
             } catch (error) { throw error;}    
      } 
//       const [islogins, setislogins] = useState([]);
//      const follwerlogins = async (loginsids) => {
//       try {
//              const follwerloginsqqq = await follwerloginsall(loginsids);
//                     if(follwerloginsqqq.success === 1){
//                            console.log('follwers logins',follwerloginsqqq.follwerloginslist)
//                            setislogins(follwerloginsqqq.follwerloginslist);
//                     }
//              } catch (error) { throw error;}    
//       }
//       const joinsfollowsec = async (followuserids) => {
//        try {
//               const joinsfollowsss = await joinsfollowlistsec(followuserids,props.storageid);
//                      if(joinsfollowsss.success === 1){

//                      }
//        } catch (error) { throw error;}    
// }
       const viewprofiles =(profilesids) =>{
            
              window.open(`/ProfileView/${profilesids}`, '_self');
             // history.push(`/ProfileView/${profilesids}`);
       }



  return (
  
       <>
            <div className="prosss yyy6">
            <div className="">
               

       <div className="profile public">
              <div className="login-form"><h2> Followers </h2></div>
             
		<div className="bp-widget base">
			<ul className="profile-fieldsmmm2">
				
{isfollwersss.map((item, index) => ( 
        
	<li key={item.follow_id}>
              {item.profile_image !=='' ? 
                     <img src={BaseURLuploads+item.profile_image} alt="" className="mr-2mmm" />
              :
               <img src={userphotos} alt="" className="mr-2mmm"  />
              }
                  
                     <span> {item.name} </span>
     
              <button className="group-buttonsssmmm2" onClick={() => viewprofiles(item.register_id)}> View Profile </button> 
      
              {/* {item.follow_userid === props.storageid ? null : <> */}
     
{/* 
{islogins.map((itemlogins, index) => ( 
       <div key={itemlogins.follow_id }> */}

               {/* {itemlogins.follow_profile_id === item.follow_userid ? <> 
              
                     <button className="group-buttonsssmmm2" onClick={()=>joinsfollowsec(item.follow_userid)}> Follow </button>
              </>:   null }  */}

          
             {/* {item.follow_userid &&(itemlogins.follow_profile_id) ?

             <>  {itemlogins.follow_profile_id} www </>


       : null} */}







{/* {item.follow_userid  === itemlogins.follow_profile_id? <> 
              
              qqq
       </>:   null }  */}

       {/* </div>
))} */}

               {/* {islogins.length > 0 ? null : <button className="group-buttonsssmmm2" onClick={()=>joinsfollowsec(item.follow_userid)}> Follow {item.follow_userid}</button> } */}
            









                     {/* </>
              } */}
                     {/* {item.follow_userid} */}

       </li>

))}
			</ul>
		</div>

       </div>








                     </div>
                     </div>

       </>
   
  );
}

export default Followerslist;