import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';

import {Link } from "react-router-dom"; 


function PaypalSuccess(props) {
  const {paypalsuccessbook} = useContext(MyContext);
  const [paypaldetails, setpaypaldetails] = useState([]);

  useEffect( () => {
     window.scrollTo(0, 0);
     successdata(props.match.params.paypalid);
 }, []);


 const successdata = async (paymentid) => {
  const data = await paypalsuccessbook(paymentid);
  console.log(data);
  if(data.success === 1){
       setpaypaldetails(data.bookslist);
  }
}
    return (
      <>
      <div className="clearfix"></div>
      <div className="successs">
       <div className="container">
         <div className="row">
          
     
        
         <div className="col-md-3" > &nbsp; </div>
                  <div className="col-md-6" >

                  <div className="logssssuccesss">
              <div className="login-form">
                  <h2>  Payment Receipt </h2>
              </div>

              <h2 className="successsh2">Your Payment Process Checking.</h2>

              <div className="clearfix"></div>
               <p onClick={() =>  successdata(props.match.params.paypalid)} className="dbbtnsss" style={{marginBottom:'37px'}}> Refresh </p>
           
               <div className="clearfix"></div>
            {paypaldetails.map((item, index) => (
                   <div key={item.pay_id}>

                 {item.payment_status === 'completed' ? 
                       <> 
                         <h3> Order No : #00067-7060-{item.member_id} </h3>    
                         <h3> PayPal transaction id :  {item.txn_id} </h3>    
                         <h3> Total Amount: {item.payment_gross} </h3>   
                         <h3> Currency: {item.currency_code} </h3>  
                         <h3> Payment Status : {item.payment_status} </h3> 
                         <Link to={`/Myaccount`}  className="db-tit-btn" > Continue </Link>
                       </>
                        : 
                      <> 
                         <h3> Order No :#00067-7060-{item.member_id} </h3>    
                         <h3> Total Amount: {item.payment_gross} </h3>   
                         <h3> Currency: {item.currency_code} </h3>  
                         <h3 style={{color: "#fd2e1f"}}> Payment Status : {item.payment_status} </h3> 
                         <Link to={`/Payment/${item.member_id}`} className="db-tit-btn" > Payment Again </Link>
                      </>
                      }       
                          
                         
                               
                   </div>

                     ))}
                           
                  
             </div>

             

      </div>
      </div>
 </div>
</div>
     </>
      
    );
}

export default PaypalSuccess;