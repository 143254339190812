import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'react-loader-spinner';
import { useHistory } from "react-router-dom";

import {Link } from "react-router-dom"; 
import ContentLoader from "react-content-loader";

import Popup from 'reactjs-popup';
import {toast} from "react-toastify";

import {WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function Gallerylist(props) {


       
       const {rootState,gallerysadds,galeryviewsall,deleteprofilegalleryall} = useContext(MyContext);
       const {theUser} = rootState;
       const [loadingadd, setloadingadd] = useState(false);
       const [filetemp, setfiletemp] = useState(null);
       const [invalidImage, setinvalidImage] = useState(null);
       const [showResults, setshowResults] = useState(false);
       const [userInfo, setuserInfo] = useState({
              file:'',
       });
   
       useEffect( () => {
             galeryviews(props.profileusers);
              
             }, []);

            

             const [isgalleryslist, setisgalleryslist] = useState([]);
             const galeryviews = async (profileusers) => {
              try {
                     const groupsgaleryviewsss = await galeryviewsall(profileusers);
                            if(groupsgaleryviewsss.success === 1){
                                   console.log('gallery list',groupsgaleryviewsss.galleryslist)
                                   setisgalleryslist(groupsgaleryviewsss.galleryslist);
                            }
                     } catch (error) { throw error;}    
              }
              const deletegallery = async (profileids) => {
              try {
                     const deletegallerysss = await deleteprofilegalleryall(profileids);
                            if(deletegallerysss.success === 1){
                                   console.log('delete gallery list',deletegallerysss)
                                   galeryviews(props.profileusers);
                            }
                     } catch (error) { throw error;}    
              }
          const groupsgallerys = async (event) => {
              try {
                     setloadingadd(true);
                            event.preventDefault();
                            event.persist();
                            if(invalidImage !== null){
                              toast.error(invalidImage, {
                                position: "top-center",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                              }); 
                              setloadingadd(false);
                              return;
                            }
                           
                            const dataadd = await gallerysadds(userInfo,props.profileusers);
                            console.log(dataadd);
                            if(dataadd.success === 1){
                                   console.log(dataadd);
                                   setloadingadd(false);
                                   event.target.reset(); 
                                   galeryviews(props.profileusers);
                                   toast.info('Image Upload Successfully',{
                                          position: "top-center"});
                            }else{
                                   setloadingadd(false);
                                    toast.error( 'Please Try Again', {
                                      position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                                    });  
                            }

              } catch (error) { throw error;}    
       }
       // {props.match.params.Add}

       const resetFile = (event) => {
              event.preventDefault();
              setfiletemp(null)
            }

            let reader = new FileReader();

   const handleChange = (event) => {
       const imageFile = event.target.files[0];
             const imageFilname = event.target.files[0].name;

             if (!imageFile) {
              setinvalidImage('Please select image.');
               return false;
             }
         
             if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif)$/)) {
              setinvalidImage('Please select valid image JPG,JPEG,PNG');
               return false;
             }
             reader.onload = (e) => {
               const img = new Image();
               img.onload = () => {

//----------------------------- Resize img code -------------------------------------------------
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);

                var MAX_WIDTH = 437;
                var MAX_HEIGHT = 437;
                var width = img.width;
                var height = img.height;

                if (width > height) {
                  if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                  }
                } else {
                  if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                  }
                }
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
                ctx.canvas.toBlob((blob) => {
                  const file = new File([blob], imageFilname, {
                      type: 'image/jpeg',
                      lastModified: Date.now()
                  });
                  setuserInfo({
                     ...userInfo,
                     file:file,
                })
                }, 'image/jpeg', 1);


//-------------------------------------------------------------------------------------
              setfiletemp( URL.createObjectURL(imageFile))
             
              setinvalidImage(null)
              };
               img.onerror = () => {
                     setinvalidImage('Invalid image content.');
                 return false;
               };
               //debugger
               img.src = e.target.result;
             };
             reader.readAsDataURL(imageFile);

     }; 
     const [isUpload, setUpload] = useState(false);
      const Upload =() =>{
       setUpload(isUpload === false ? true : false);
      }

        return(
               <div className="ud-cen-s1yyy s1yyymmm2">
                       
                       <div className="login-form"><h2> Gallery </h2></div>
                      <div className=" urlsqwsss3">
                    
                     

  
                      { props.profileusers == theUser ? <> 
                                   <button onClick={Upload} className=" buttons3 " > Upload </button>
                          
       {isUpload === true ? <> 
              {isgalleryslist.length < 10 ? <>             
              <div className="col-md-2">
                      &nbsp;
                      </div>
              <div className="col-md-8">
                     <form onSubmit={groupsgallerys} className="login-forms">
                            <div className="clearfix"></div>
                            <div className="form-row">
                            <div className="form-group urlsqwsss2">
                                 
                                   <div className="clearfix"></div>
                                   <input type="file" name="file" className="form-control" placeholder="Title"  onChange={handleChange}   required/>
                                   
                            </div>
                            <div className="form-group col-md-12 ">
                            {loadingadd ?
                                          <>
                                                 <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                                          </>
                                          : 
                                   <button type="submit" className="btn2zzz2" style={{
                                   marginBottom: "37px"}} > Upload Gallery </button>
                            }
                            </div>
                            
                            </div>
        
                     </form>  
              </div>
              </> : <>   
       <h2 className="yyy14">Only ten photos are allowed</h2>
       <h2 className="yyy14">If you want more photos please delete previous photos </h2>

</>

}  
              </> : null}  
              </> : null}


              <div className="col-md-12">
{isgalleryslist.map((item, index) => ( 
       <div className="col-md-4" key={item.profilegallery_id}>

      



       <Popup trigger={ <img src={BaseURLuploads+item.profilegallery_gallery}  className="mqqqmm2" alt="gallerys" />} position="top left">
                            {close => (
                            <div className="feedspopups">
                                   <a className="close" onClick={close}>
                                   &times;
                                   </a>
                     <div className="feeds">
                     <div className="feedswww imgsssqqq2s">
                     <img src={BaseURLuploads+item.profilegallery_gallery}  className="imgsssqqq2" alt="gallerys" />
                            
                            </div>
                            </div>
                            </div>
                            )}
                            </Popup>

                            { props.profileusers == theUser ? <> 
              <p className=" sadtghjgfsss " onClick={() => deletegallery(item.profilegallery_id)}>   <FontAwesomeIcon icon={['fas','trash']} className="enablelikes"  /> Delete </p>
       </> : null }



       </div>
))}


              
              </div>
              </div>



                 

          </div>
        );
    }

export default Gallerylist;