import React,{useContext,useState,useEffect} from 'react';

import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Feedslists from './Feedslists';

import userphotos from '../Img/user_profile.jpg';
import { WEB_URL_UPLOAD } from '../contexts/Apilinks';

let BaseURLuploads = WEB_URL_UPLOAD;

function NewsFeed(props) {
       const {rootState,GoldAdslatest,SilverAdslatest} = useContext(MyContext);
       const {isAuth,theUser} = rootState;
       const [isGold, setGold] = useState([]);
       const [isSilver, setSilver] = useState([]);
       useEffect( () => {
              Gold();
              Silver();
       }, []);
       
const Gold = async() =>{
       try {
              const Goldresult = await GoldAdslatest();
                     if(Goldresult.success === 1){
                            console.log('gold ads',Goldresult.goldlist);
                            setGold(Goldresult.goldlist) ;
              }
       } catch (error) { throw error;}
     }
     
     const Silver = async() =>{
        try {
               const Silverresult = await SilverAdslatest();
                      if(Silverresult.success === 1){
                             console.log('silver ads',Silverresult.Silverlist);
                             setSilver(Silverresult.Silverlist) ;
               }
        } catch (error) { throw error;}
      }
  return (
  
       <div className="newsfeedsss">
              <div className="container">
                     <div className="row">
{isGold.length > 0 && isSilver.length > 0 ? <>                               
                            <div className="col-md-3 aaa14 resss" style={{padding:0}}>
                                   <h2 className=""> GOLD </h2>
                  {isGold.map((item, index) => ( 
                            <div className="col-md-12" key={item.classified_id} >
                            <div className="single-listing-item ">
                            <div className="col-md-12" style={{padding:0}}>
                                   <div className="listing-image">
                                   <Link to={`/ClassifiedsPreview/${item.classified_id}`} className="link-btn">
                                          <img src={BaseURLuploads+item.image} alt="Classifiedsads" />
                                         
                                          </Link>
                                   </div>
                                   </div>
                                   <div className="col-md-12" style={{padding:0}}>
                                   <div className="listing-contentzzz2">
                                   <Link to={`/ClassifiedsPreview/${item.classified_id}`} className="link-btn">

                                   <h3> {item.title} </h3>
                                   <div className="aaa2" dangerouslySetInnerHTML={{ __html: item.description }} />
                                  
                                   
                                          </Link>
                                        
                                          </div>
                                         
                                   
                            </div>
                            </div>
                            </div>    
                     )) } 
                            </div> 
                            <div className="col-md-6 yyy ">
                            {isAuth === true && theUser !=='' ?<>
                                    {/* <Feedslists storageid={theUser} /> */}
                                    <Feedslists storageid={theUser} />
                                   </>
                             : null }
                            </div>
                            <div className="col-md-3 ccc3 resss">
                            <h2 className=""> Platinum </h2>
                   {isSilver.map((item, index) => ( 
                            <div className="col-md-12 " key={item.classified_id}>
                                     
                            <div className="single-listing-item ">
                                   <div className="listing-image">
                                   <Link to={`/ClassifiedsPreview/${item.classified_id}`} className="link-btn">
                                          <img src={BaseURLuploads+item.image} alt="Classifiedsads" />
                                          <div className="listing-tag">
                                          {item.category === 'other' ?
                                          <>{item.other}</>
                                          :  <>{item.category}  </>}
                                          </div>
                                          </Link>
                                   </div>
                                   <div className="listing-contentzzz2 contentzzz2">
                                   <Link to={`/ClassifiedsPreview/${item.classified_id}`} className="link-btn">
                                   <div className="listing-author ">
                                  
                                   {item.profile_image !=='' ? 
                                                 <img src={BaseURLuploads+item.profile_image} alt="" className="rounded-circle mr-2" />
                                                 :
                                                 <img src={userphotos} alt="" className="rounded-circle mr-2"  />
                                                 }
                                   
                                          <span> {item.name} </span></div>
                                   <h3> {item.title} </h3>
                                   <div className="aaa2" dangerouslySetInnerHTML={{ __html: item.description }} />
                                   </Link>
                                   </div>
                                         
                                   
                            </div>
                            </div>    
                  )) } 
                            </div>
                    
  </> : <>   
  <div className="container">
         <div className="row">
                <div className="col-md-3" > &nbsp;</div>
                <div className="col-md-6 loadinsstopsss" >
                       <div className="loadings ">
                              <FontAwesomeIcon icon={['fas','check-circle']} />
                              <p> Loading the community updates. Please wait. </p>
                       </div>
                </div>
         </div>
  </div>

</>}  
</div>                      
              </div>

       </div>
   
  );
}

export default NewsFeed;