import React, { useContext, useEffect, useState, useRef,useCallback  } from 'react';

import { MyContext } from '../contexts/MyContext';
import {Link } from "react-router-dom"; 
import Popup from 'reactjs-popup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {toast } from "react-toastify";
import userphotos from '../Img/user_profile.jpg';
import {WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function Members() {
  const {rootState,allmemberslist,newmessageadds} = useContext(MyContext);
  const {theUser} = rootState;
  const [pageNumber, setPageNumber] = useState(1)
  


const observer = useRef()
const loadingRef = useCallback(node => {
  
  if (observer.current) observer.current.disconnect()
  observer.current = new IntersectionObserver(entries => {
    if (entries[0].isIntersecting ) {
      setPageNumber(prevPageNumber => prevPageNumber + 1)
      //latestfeeds(prevPageNumber => prevPageNumber + 1)
    }
  })
  if (node) observer.current.observe(node)
}, [])
  
useEffect( () => {
  if (isFetching === true) return;
  allmembers(pageNumber);
      return () => {
        console.log("This will be logged on unmount");
      }
}, [pageNumber])



const [ismembers, setmembers] = useState([]);
const [isFetching, setIsFetching] = useState(false);

const allmembers = async(pageNumber) => {
       const allmembersss = await allmemberslist(pageNumber);
       if(allmembersss.success === 1){
              console.log('allmembersss',allmembersss);
              setmembers(prevBooks => {
                     return [...prevBooks, ...allmembersss.memberlist]
              })
           
              if(allmembersss.memberlist.length < 1){
                     setIsFetching(true);
              }
       }
}
const [userInfo, setuserInfo] = useState({
       messages: '',
   });
const onChangeValue = (e) => {
       setuserInfo({
           ...userInfo,
               [e.target.name]:e.target.value
       });
      
}
const newmessage = async(event) =>{
       try {
              
              event.preventDefault();
              event.persist();
              const newmessagesss = await newmessageadds(userInfo,theUser,userInfo.postids.value);
              if(newmessagesss.success === 1){
                     event.target.reset(); 
                     setuserInfo({
                            ...userInfo,
                            messages:'',
                        });
                        toast.info('Message Sent Successfully',{
                            position: "top-center"});         
                     
              }
       } catch (error) { throw error;}      
}
const [issetmore, setmore] = useState(false);
const [issetmoreids, setmoreids] = useState(false);
       const moreoptions = (ids) =>{
              setmoreids(ids)
              setmore(issetmore === false ? true : false);
              
       }


    return (
           
        <div className="allmmmesss" >
      <div className="container" >
          <div className="row">
          <div className="col-md-1">
                 &nbsp;
                 </div>

          <div className="col-md-10">
          
        {ismembers.map((item, index) => ( 
            

         <div  ref={loadingRef} className="col-md-3" key={item.register_id}> 
              <div className="list-wrapswqasas">
              <div className="more-post-optns">
                            <FontAwesomeIcon icon={['fas','ellipsis-h']} className="enablelikes"  onClick={() => moreoptions(item.register_id)} />
                            {issetmore === true && issetmoreids === item.register_id ? 
                     <ul>
                            <Popup trigger={<li> <FontAwesomeIcon icon={['fas','user-edit']} className="enablelikes"/> Message </li>} position="top left">
                            {close => (
                            <div className="feedspopups">
                                   <a className="close" onClick={close}>
                                   &times;
                                   </a>
                     <div className="feeds">
                     <div className="feedswww">
                                   <h1> Message </h1>
                     <form onSubmit={newmessage} className="login-forms">
                            <textarea  name="messages" value={userInfo.messages} className=" col-md-12" rows="3"  placeholder="  "  onChange={onChangeValue} required />
                            <input type="hidden" name="postids" ref={(val) => userInfo.postids = val} className="form-control" defaultValue={item.register_id} />
                            <div className="form-group col-sm-12 text-right">
                                   <button type="submit" className="db-tit-btn2"> Send </button>
                            </div>
                     </form>
                            
                            
                            </div>
                            </div>
                            </div>
                            )}
                            </Popup>
                           
                     </ul>
       : null }
                     </div>


            <div className="item-avatar">
                                   {item.profile_image !=='' ? 
                                          <img src={BaseURLuploads+item.profile_image} alt="" className="ccc9" />
                                   :
                                          <img src={userphotos} alt="" className=""  />
                                   }
                                   <h4>{item.name}</h4>
                            </div>
                            <Link to={`/ProfileView/${item.register_id}`} >
                            <button className="buttons2" > View Profile </button> 
                            </Link>
              
                            </div>
                            </div>
         
          ))}
       
              {ismembers.length < 0 ?
                     <div className="loading" >
                            <h2>Load More  </h2>
                     </div>
              : null}
      </div>
      </div>
      </div>
      </div>
      )
  }



export default Members;