import React,{useContext,useState,useEffect, useRef, useCallback} from 'react';

import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';
import {toast} from "react-toastify";

import Popup from 'reactjs-popup';

import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import GroupPostEdit from './GroupPostEdit';
import Loader from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useHistory } from "react-router-dom"; 
import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function GroupAnnouncement(props) {
       let history = useHistory();
       const {rootState,groupannouncementadd,listannoumcementsqqq,deletegrouppostsss} = useContext(MyContext);

       const {isAuth,theUser} = rootState;

       const [isurls, setisurls] = useState(false);
       const [isyoutube, setisyoutube] = useState(false);
       const [userInfo, setuserInfo] = useState({
              feed: '',
              file:'',
              url:'',
              youtube:'',
       });

       const [loadingadd, setloadingadd] = useState(false);
       const [fileempty, setfileempty] = useState(false);
       const [filetemp, setfiletemp] = useState(null);
       const [invalidImage, setinvalidImage] = useState(null);

useEffect(() => {
   listannoumcements(props.groupid);
     }, []);
     
     const [isreportpost, setreportpost] = useState({
       reportpost: '',
   });

       const radiosChange = (e) => {
              setreportpost({
                     ...isreportpost,
                     [e.target.name]:e.target.value
              });
       } 
       
const [ismoreids, setismoreids] = useState();
const [ismore, setismore] = useState(false);
     const [issetmore, setmore] = useState(false);
     const [issetmoreids, setmoreids] = useState(false);
     const moreoptions = (ids) =>{
       setmoreids(ids)
       setmore(issetmore === false ? true : false);
}
          
     const [isannc, setannc] = useState([]);
     const listannoumcements = async(groupid) =>{
       try {
              const listannoumcementsss = await listannoumcementsqqq(groupid);
              if(listannoumcementsss.success === 1){
                     console.log('announcementslist',listannoumcementsss.annclist);
                     setannc(listannoumcementsss.annclist);
              }
       } catch (error) { throw error;}      
} 
       const onChangeValue = (e) => {
              setuserInfo({
                  ...userInfo,
                      [e.target.name]:e.target.value
              });
       }
   
     
     
       const externallinks = (url) => {
              if (!!url && !!url.trim()) { 
                     url = url.trim(); 
                     if (!/^(https?:)?\/\//i.test(url)) { 
                         url = 'http://' + url; 
                         window.open(url, '_blank');
                     }else{
                            window.open(url, '_blank');
                     }
                 } 
            }  
            const toggleClass = (ids) => {
              setismore(ismore === false ? true : false);
              setismoreids(ids);
          };
    
       
   

       const newgroupannouncement = async(event) =>{
              try {
                     setloadingadd(true);
                     event.preventDefault();
                     event.persist();
                     const newgroupannouncementsss = await groupannouncementadd(userInfo,props.storageid,userInfo.fileempty.value,props.groupid);
                     if(newgroupannouncementsss.success === 1){
                            setloadingadd(false);
                            event.target.reset(); 
                            setuserInfo({
                                   ...userInfo,
                                       feed:'',
                                       file:'',
                                       url:'',
                                       youtube:'',
                               });
                               setisurls(false);
                               setisyoutube(false);
                               setfileempty(false);
                               setfiletemp(null);
                               setinvalidImage(null);
                               toast.info('Success ',{
                                   position: "top-center"});  
                              
                               props.resetposts([]);
                               props.grouprefresh(1,props.groupid);
                               props.setmore(false);
                               listannoumcements(props.groupid);
                            
                     }
              } catch (error) { throw error;}      
       }
       
         
           const toggleClassurls = () => {
              setisurls(isurls === false ? true : false);
          };
         const toggleClassyoutube = () => {
              setisyoutube(isyoutube === false ? true : false);
          };

       const resetFile = (event) => {
              event.preventDefault();
              setfiletemp(null)
            }

            let reader = new FileReader();

   const handleChange = (event) => {
       const imageFile = event.target.files[0];
             const imageFilname = event.target.files[0].name;

             if (!imageFile) {
              setinvalidImage('Please select image.');
               return false;
             }
         
             if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif)$/)) {
              setinvalidImage('Please select valid image JPG,JPEG,PNG');
               return false;
             }
             reader.onload = (e) => {
               const img = new Image();
               img.onload = () => {

//----------------------------- Resize img code -------------------------------------------------
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);

                var MAX_WIDTH = 437;
                var MAX_HEIGHT = 437;
                var width = img.width;
                var height = img.height;

                if (width > height) {
                  if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                  }
                } else {
                  if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                  }
                }
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
                ctx.canvas.toBlob((blob) => {
                  const file = new File([blob], imageFilname, {
                      type: 'image/jpeg',
                      lastModified: Date.now()
                  });
                  setuserInfo({
                     ...userInfo,
                     file:file,
                })
                }, 'image/jpeg', 1);


//-------------------------------------------------------------------------------------
              setfiletemp( URL.createObjectURL(imageFile))
              setfileempty(true);
              setinvalidImage(null)
              };
               img.onerror = () => {
                     setinvalidImage('Invalid image content.');
                 return false;
               };
               //debugger
               img.src = e.target.result;
             };
             reader.readAsDataURL(imageFile);

     }; 


     const deletes = (feedids) => {
       confirmAlert({
         title: 'Confirm to Delete ',
         message: 'Are you sure to do this.',
         buttons: [
           {
             label: 'Yes',
             onClick: () => deletebooks(feedids)
           },
           {
             label: 'No',
           }
         ]
       })
     };     
     const deletebooks = async (feedids) => {
       try {
              const deletebooksss = await deletegrouppostsss(feedids);
              if(deletebooksss.success === 1){
                     props.resetpostsannc([]);
                     props.grouprefreshannc(1,props.groupid);
                     listannoumcements(props.groupid);

               
              }
       } catch (error) { throw error;} 
}
   const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]{11,11}).*/;  


  return (
  
       <>
         
     
            {props.groupusercreateid === theUser || props.adminpremissionss === theUser ? <>
              <div className="feeds">
            <div className="feedswww">
              <Popup trigger={ <form className="login-forms">
                     <h5 className="widget-title"> Group Announcement </h5>
                     <textarea name="" rows="3" placeholder="What's new?" aria-label="Post what's new" id="whats-new" className="bp-suggestions" required ></textarea>
                     <div className="clearfix"></div><div className="col-md-12 " style={{padding:0}}><div className="col-md-12" style={{padding:0}}><ul> <li><FontAwesomeIcon icon={['fas','image']} className="iconsss2" /><label className="fileContainer"><input type="file" name="" className="form-control" placeholder="Title"  /></label></li><li> <FontAwesomeIcon icon={['fas','globe-americas']} className="iconsss2"  /></li><li> <FontAwesomeIcon icon={['fab','youtube']} className="iconsss2" /> </li></ul> </div> <div className="col-md-12" style={{padding:0}}><div className="form-group col-sm-12 text-right"><button type="submit" className="db-tit-btn2"> Post Update </button></div></div></div>
                    
                     </form>} position="top left">
             {close => (
             <div className="feedspopups">
                     <a className="close" onClick={close}>
                    &times;
                    </a>
                 
                   
       <div className="feeds  yyy4">
            <div className="feedswww">
{isannc.length < 5 ? 
              <form onSubmit={newgroupannouncement} className="login-forms">
                     <h5 className="widget-title"> Group Announcement </h5>
                     <textarea name="feed" rows="3" placeholder="What's new?" aria-label="Post what's new" id="whats-new" className="bp-suggestions" value={userInfo.feed} onChange={onChangeValue} required></textarea>
                     <div className="clearfix"></div>
                   
                     <div className="col-md-12 " style={{padding:0}}>
                     <div className="col-md-12" style={{padding:0}}>
                     <ul> 
                            <li>
                                   <FontAwesomeIcon icon={['fas','image']} className="iconsss2" />
                                   <label className="fileContainer">
                                   <input type="file" name="file" className="form-control" placeholder="Title" onChange={handleChange} />
                                   </label>
                                   {fileempty === true ? 
                                   <input type="hidden" name="fileempty" ref={(val) => userInfo.fileempty = val} className="form-control" defaultValue="imgactive" />
                                   : 
                                   <input type="hidden" name="fileempty" ref={(val) => userInfo.fileempty = val} className="form-control" defaultValue="imgempty" />
                                   }
                            </li>
                            <li>
                                   <FontAwesomeIcon icon={['fas','globe-americas']} className="iconsss2"  onClick={toggleClassurls}/>
                                  
                            </li>
                             <li>
                                   <FontAwesomeIcon icon={['fab','youtube']} className="iconsss2"  onClick={toggleClassyoutube}/>
                                  
                            </li>
                     </ul>
                     </div>
                    
                     <div className="col-md-12" style={{padding:0}}>
                            {loadingadd ?
                            <>
                                   <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                            </>
                            : 
                                   <div className="form-group col-sm-12 text-right">
                                          <button type="submit" className="db-tit-btn2"> Post Update </button>
                                          
                                   </div>
                             }
                   </div>
                   </div>
                     {isurls === true ? 
                            <input type="text" name="url" value={userInfo.url} onChange={onChangeValue} className="form-control"  placeholder=" website url "/>
                     : null}
                     {isyoutube === true ? 
                            <input type="text" name="youtube" value={userInfo.youtube} onChange={onChangeValue} className="form-control"  placeholder=" youtube url "/>
                     : null}
                     </form>
: <>   
       <h2 className="yyy14">Only five announcements are allowed</h2>
       <h2 className="yyy14">If you want more announcement post please delete previous announcements </h2>

</>}
                     </div>
                     </div>
                     </div>
             )}
             </Popup>    

             </div>


</div></>
                     : null }





{isannc.length > 0 ? 
<div className="feeds yyy12">
              <div className="feedswww">

<ul className="activity-list_announcments" > 
<h5 className="widget-title"> Group Announcement </h5>
       {isannc.map((item, index) => ( 
              <li className="col-md-12" key={item.gfeed_id} style={{padding:0}}  >
             
                     
             <div className="more-post-optns">
               <FontAwesomeIcon icon={['fas','ellipsis-h']} className="enablelikes"  onClick={() => moreoptions(item.gfeed_id)} />
               {issetmore === true && issetmoreids === item.gfeed_id ? 
       <ul>
       { props.groupusercreateid === theUser || props.adminpremissionss === theUser ?  <>
       

       <Popup trigger={<li> <FontAwesomeIcon icon={['fas','user-edit']} className="enablelikes"/>Edit Announcement</li>} position="top left">
       {close => (
       <div className="feedspopups">
              <a className="close" onClick={close}>
              &times;
              </a>
              <GroupPostEdit feedid={item.gfeed_id}  groupid={item.feed_group_id} feedtext={item.gfeedtext} image={item.gimage} url={item.gurl} youtube={item.gyoutube}   close={close} moreoptions ={() => moreoptions(item.gfeed_id)}  grouprefresh={props.grouprefreshannc} resetposts={props.resetpostsannc}  close={close} listannoumcements ={() =>  listannoumcements(props.groupid)} annc={1} /> 
              
              
       </div>
       )}
       </Popup>
                   <li onClick={()=>deletes(item.gfeed_id)}><FontAwesomeIcon icon={['fas','trash-alt']} className="enablelikes"/>Delete Announcement</li>			
                                          
       </> 
       : null }		
              </ul>
               : null}    
                </div>           
                     
           
              <Popup trigger={ <div>
                     
                     <p className="yyy11">{item.gfeedtext}</p>   {item.gfeedtext.length > 177 ? <><span className="morelink" > read more ...  </span> </> : null } <hr/>      </div>} position="top left">
 {close => (
 <div className="feedspopups">
         <a className="close" onClick={close}>
        &times;
        </a>
        <div className="feeds yyy13">
              <div className="feedswww">
      

<div className="clearfix"> </div>
               <div className="shortcontent">
             
             
               <div className={ "block-ellipsis" + (ismoreids === item.gfeed_id ? " block-ellipsisqqq " : "")} dangerouslySetInnerHTML={{ __html: item.gfeedtext }} />


               {item.gfeedtext.length > 177 ? <>
                      {ismore === true && ismoreids === item.gfeed_id ?  
                             <span className="morelink" onClick={toggleClass}> Close  </span> 
                      :  
                             
                      <span className="morelink" onClick={() => toggleClass(item.gfeed_id)} > read more ...  </span>
                      }
              </> : null }       
                <div className="col-md-12" style={{padding:0}}> 
                <div className="col-md-5" style={{padding:0}}>
                       
               {item.gimage ? 
                      <div className="rtmedia-item-thumbnail">
                             <img src={BaseURLuploads+item.gimage} alt="feedsimages" />
                      </div>
               : null }
              </div>
                <div className="col-md-7">
                     
               {item.gyoutube.match(regExp) ? 
               <> 
                      {item.gyoutube.match(regExp).length >= 2 ? 

                      <iframe id="ytplayer" type="text/html" className="videosss2"
                      src={`https://www.youtube.com/embed/` + item.gyoutube.match(regExp)[2] + `?rel=0&showinfo=0&color=white&iv_load_policy=3`}
                      frameBorder="0" allowFullScreen></iframe>
                      : null }
               </>
                      : null 
               } 
                  </div>
                  </div>
                {item.gurl !== '' ? <>  
                      <div className="col-md-12 ccc2"> 
                             <hr />
                           <p onClick={() => externallinks(item.gurl)}>{item.gurl} </p> 
                           <br />
                           <br />
                      </div>
                </> : null }

                </div>


      



           
              </div>


       </div>
 </div>
 )}
 </Popup>
 

              </li>
       ))}
</ul>

</div>


</div>
: null }











       </>
   
  );
}

export default GroupAnnouncement;