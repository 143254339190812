import React,{useContext,useState,useEffect, useRef, useCallback} from 'react';

import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';
import {toast} from "react-toastify";
import Loader from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import userphotos from '../Img/user_profile.jpg';

import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function DashboardProfilePost(props) {
     
       const {newfeedsadd} = useContext(MyContext);
      

const [userInfo, setuserInfo] = useState({
       feed: '',
       file:'',
       url:'',
       youtube:'',
   });
  
const [loadingadd, setloadingadd] = useState(false);
const [fileempty, setfileempty] = useState(false);
const [filetemp, setfiletemp] = useState(null);
const [invalidImage, setinvalidImage] = useState(null);

const [isurls, setisurls] = useState(false);
const [isyoutube, setisyoutube] = useState(false);

const toggleClassurls = () => {
       setisurls(isurls === false ? true : false);
   };
  const toggleClassyoutube = () => {
       setisyoutube(isyoutube === false ? true : false);
   };
     


       

       const onChangeValue = (e) => {
              setuserInfo({
                  ...userInfo,
                      [e.target.name]:e.target.value
              });
             
       }
     
    
     
      
     
    



       const newfeeds = async(event) =>{
              try {
                     setloadingadd(true);
                     event.preventDefault();
                     event.persist();
                     const newfeedsqqq = await newfeedsadd(userInfo,props.storageid,userInfo.fileempty.value);
                     if(newfeedsqqq.success === 1){
                            setloadingadd(false);
                            event.target.reset(); 
                            setuserInfo({
                                   ...userInfo,
                                       feed:'',
                                       file:'',
                                       url:'',
                                       youtube:'',
                               });
                               
                               setfileempty(false);
                               setfiletemp(null);
                               setinvalidImage(null);
                            toast.info(' Posted successfully, View your post on newsfeed ',{
                                   position: "top-center"});   
                            }
              } catch (error) { throw error;}      
       }

      

       const resetFile = (event) => {
              event.preventDefault();
              setfiletemp(null)
            }

            let reader = new FileReader();

   const handleChange = (event) => {
       const imageFile = event.target.files[0];
             const imageFilname = event.target.files[0].name;

             if (!imageFile) {
              setinvalidImage('Please select image.');
               return false;
             }
         
             if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif)$/)) {
              setinvalidImage('Please select valid image JPG,JPEG,PNG');
               return false;
             }
             reader.onload = (e) => {
               const img = new Image();
               img.onload = () => {

//----------------------------- Resize img code -------------------------------------------------
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);

                var MAX_WIDTH = 437;
                var MAX_HEIGHT = 437;
                var width = img.width;
                var height = img.height;

                if (width > height) {
                  if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                  }
                } else {
                  if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                  }
                }
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
                ctx.canvas.toBlob((blob) => {
                  const file = new File([blob], imageFilname, {
                      type: 'image/jpeg',
                      lastModified: Date.now()
                  });
                  setuserInfo({
                     ...userInfo,
                     file:file,
                })
                }, 'image/jpeg', 1);


//-------------------------------------------------------------------------------------
              setfiletemp( URL.createObjectURL(imageFile))
              setfileempty(true);
              setinvalidImage(null)
              };
               img.onerror = () => {
                     setinvalidImage('Invalid image content.');
                 return false;
               };
               //debugger
               img.src = e.target.result;
             };
             reader.readAsDataURL(imageFile);

     }; 

  
  
   const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]{11,11}).*/;  

  


  return (
  
       <>
        <div className="col-md-12 yyy6 minsss">
          {props.storageid !== '' ? 
            <div className="feeds ">
            <div className="feedswww">
            
                     <form onSubmit={newfeeds} className="login-forms">
                            <div className="whats-new-content ">
                                   {props.memberprofile !=='' ? 
                                          <img src={BaseURLuploads+props.memberprofile} alt="" className="rounded-circle mr-2 feeds2" />
                                   :
                                          <img src={userphotos} alt="" className="rounded-circle mr-2 feeds2"  />
                                   }
                                   <textarea  name="feed" value={userInfo.feed} className=" col-md-10" rows="4"  placeholder={"What's new, " + props.membername + "? "}  onChange={onChangeValue} required />
                            </div>
                     <div className="clearfix"></div>
                   
                     <div className="col-md-12 " style={{padding:0}}>
                     <div className="col-md-7" style={{padding:0}}>
                     <ul> 
                            <li>
                                   <FontAwesomeIcon icon={['fas','image']} className="iconsss2" />
                                   <label className="fileContainer">
                                   <input type="file" name="file" className="form-control" placeholder="Title" onChange={handleChange} />
                                   </label>
                                   {fileempty === true ? 
                                   <input type="hidden" name="fileempty" ref={(val) => userInfo.fileempty = val} className="form-control" defaultValue="imgactive" />
                                   : 
                                   <input type="hidden" name="fileempty" ref={(val) => userInfo.fileempty = val} className="form-control" defaultValue="imgempty" />
                                   }
                            </li>
                            <li>
                                   <FontAwesomeIcon icon={['fas','globe-americas']} className="iconsss2"  onClick={toggleClassurls}/>
                                  
                            </li>
                             <li>
                                   <FontAwesomeIcon icon={['fab','youtube']} className="iconsss2"  onClick={toggleClassyoutube}/>
                                  
                            </li>
                     </ul>
                     </div>
                    
                     <div className="col-md-5" style={{padding:0}}>
                            {loadingadd ?
                            <>
                                   <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                            </>
                            : 
                                   <div className="form-group col-sm-12 text-right">
                                          <button type="submit" className="db-tit-btn2"> Post Update  </button>
                                          
                                   </div>
                             }
                   </div>
                   </div>
                     {isurls === true ? 
                            <input type="text" name="url" value={userInfo.url} onChange={onChangeValue} className="form-control"  placeholder=" website url "/>
                     : null}
                     {isyoutube === true ? 
                            <input type="text" name="youtube" value={userInfo.youtube} onChange={onChangeValue} className="form-control"  placeholder=" youtube url "/>
                     : null}
                     
                   
                     </form>
                     </div>

              </div>
                
                : null}  



              </div>
            

       </>
   
  );
}

export default DashboardProfilePost;