import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import Loader from 'react-loader-spinner';

import CoverPhotoProfile from './CoverPhotoProfile';
import ProfilePhoto from './ProfilePhoto';
import ProfilePost from './ProfilePost';
import Profilelist from './Profilelist';
import Messagelist from './Messagelist';
import Inboxlist from './Inboxlist';
import Gallerylist from './Gallerylist';
import Advertslist from './Advertslist';
import Followerslist from './Followerslist';
import Followinglist from './Followinglist';
import Followunfollow from './Followunfollow';

import {toast} from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link } from "react-router-dom"; 

import ContentLoader from "react-content-loader";


import userphotos from '../Img/user_profile.jpg';
import covers from '../Img/mmm.jpg';
import {WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function ProfileView(props) {
       const {rootState,memberlistall,followlistcount,followinglistcount,grouplistcount,PlatinumgoldAds,GoldgoldAds,Advertsviewslist} = useContext(MyContext);
       const {isAuth,theUser} = rootState;
   
    useEffect( () => {
       window.scrollTo(0, 0);
      profileviews(props.match.params.Addpreview); //allready use 
      followcount(props.match.params.Addpreview); 
      followingcount(props.match.params.Addpreview); 
      groupcount(props.match.params.Addpreview); 
      Platinumgold(); 
      Goldgold(); 
      Advertsviewsqqq(props.match.params.Addpreview); 
     }, []);
     
     const [isAdvertsprofile, setisAdvertsprofile] = useState([]);
     const Advertsviewsqqq = async (profileusers) => {
      try {
             const Advertsviewsss = await Advertsviewslist(profileusers);
                    if(Advertsviewsss.success === 1){
                           console.log('Adverts list',Advertsviewsss.Advertslist)
                           setisAdvertsprofile(Advertsviewsss.Advertslist);
                    }
             } catch (error) { throw error;}    
      }
    
     const [isPlatinumgold, setPlatinumgold] = useState([]);
     const Platinumgold = async() =>{
       try {
              const Platinumgoldresult = await PlatinumgoldAds();
                     if(Platinumgoldresult.success === 1){
                            console.log(Platinumgoldresult.Platinumgoldlist);
                            setPlatinumgold(Platinumgoldresult.Platinumgoldlist) ;
              }
       } catch (error) { throw error;}
     }
     const [isgoldgold, setgoldgold] = useState([]);
     const Goldgold = async() =>{
       try {
              const Goldgoldsss = await GoldgoldAds();
                     if(Goldgoldsss.success === 1){
                            console.log(Goldgoldsss.Goldgoldlist);
                            setgoldgold(Goldgoldsss.Goldgoldlist) ;
              }
       } catch (error) { throw error;}
     }




const [isprofileviews, setisprofileviews] = useState([]);
     const profileviews = async(storageid) =>{
       try {
              const profileviewssss = await memberlistall(storageid);
              if(profileviewssss.success === 1){
                     console.log('profileviewssss',profileviewssss.memberlist);
                     setisprofileviews(profileviewssss.memberlist);
                     
              }
       } catch (error) { throw error;}      
} 


const [isfollowcount, setisfollowcount] = useState([]);
const followcount = async (profileuserids) => {
       try {
              const followqqqcount = await followlistcount(profileuserids);
                     if(followqqqcount.success === 1){
                            console.log('followcount',followqqqcount.followssscount);
                            setisfollowcount(followqqqcount.followssscount);
                     }
       } catch (error) { throw error;}    
}
const [isfollowingcount, setisfollowingcount] = useState([]);
const followingcount = async (profileuserids) => {
       try {
              const followingqqqcount = await followinglistcount(profileuserids);
                     if(followingqqqcount.success === 1){
                            console.log('followcount',followingqqqcount.followingssscount);
                            setisfollowingcount(followingqqqcount.followingssscount);
                     }
       } catch (error) { throw error;}    
}
const [isgroupcount, setisgroupcount] = useState([]);
const groupcount = async (profileuserids) => {
       try {
              const groupqqqcount = await grouplistcount(profileuserids);
                     if(groupqqqcount.success === 1){
                            console.log('followcount',groupqqqcount.groupssscount);
                            setisgroupcount(groupqqqcount.groupssscount);
                     }
       } catch (error) { throw error;}    
}




const [iscoverphotoss, setiscoverphotos] = useState(false);
const coverphotos = async() =>{
  setiscoverphotos(iscoverphotoss === false ? true : false);
  setisgroupphotoss(false);
} 
const [isgroupphotoss, setisgroupphotoss] = useState(false);
const groupphotos = async() =>{
       setisgroupphotoss(isgroupphotoss === false ? true : false);
       setiscoverphotos(false);
     }






     const [isHome, setHome] = useState(false);
       const [isProfile, setProfile] = useState(false);
       const [isMessage, setMessage] = useState(false);
       const [isInbox, setInbox] = useState(false);
       const [isGallery, setGallery] = useState(false);
       const [isAdverts, setAdverts] = useState(false);
       const [isFollowers, setFollowers] = useState(false);
       const [isFollowing, setFollowing] = useState(false);
      const Home =()=>{
           setHome(false);setProfile(false);setMessage(false);setInbox(false);setGallery(false);setAdverts(false);setFollowers(false);setFollowing(false);
        }
      const Profile =()=>{
           setHome(true);setProfile(true);setMessage(false);setInbox(false);setGallery(false);setAdverts(false);setFollowers(false);setFollowing(false);
        }
      const Message =()=>{
           setHome(true);setProfile(false);setMessage(true);setInbox(false);setGallery(false);setAdverts(false);setFollowers(false);setFollowing(false);
        }
       const Inbox =()=>{
           setHome(true);setProfile(false);setMessage(false);setInbox(true);setGallery(false);setAdverts(false);setFollowers(false);setFollowing(false);
        }
       const Gallery =()=>{
           setHome(true);setProfile(false);setMessage(false);setInbox(false);setGallery(true);setAdverts(false);setFollowers(false);setFollowing(false);
       }
       const Adverts =()=>{
           setHome(true);setProfile(false);setMessage(false);setInbox(false);setGallery(false);setAdverts(true);setFollowers(false);setFollowing(false);
       }
       const Followers =()=>{
           setHome(true);setProfile(false);setMessage(false);setInbox(false);setGallery(false);setAdverts(false);setFollowers(true);setFollowing(false);
       }
       const Following =()=>{
           setHome(true);setProfile(false);setMessage(false);setInbox(false);setGallery(false);setAdverts(false);setFollowers(false);setFollowing(true);
       }
       
      

       return ( 
              <div className=""> 
{isprofileviews.length > 0 ? <>   
                     <div className="groupsallwww groupsallwwwmmm2">
                     <div className="container">
                     <div className="row">
                  
{isprofileviews.map((item, index) => ( 
       <div className="col-md-12" key={item.register_id} style={{padding:0}}>
              <div className="cover-image-container">
              
              <div className="header-cover-image" >
              {item.coverimage !== '' ?
                     <img src={BaseURLuploads+item.coverimage} alt="coverimg" className="yyy16" />
              : 
              <img src={covers} alt="coverimg" className="yyy16" />
              }   
			
                     {isAuth === true && theUser === item.register_id ? <>
                            {item.coverimage === '' ? <>
                                  <p className="ccc11mmm2" onClick={()=>coverphotos()}> Add Cover Photo </p>
                                  {iscoverphotoss === true ? 
                                          <CoverPhotoProfile registerids={props.match.params.Addpreview} cancels={()=>coverphotos()} refreshsss ={()=>profileviews(props.match.params.Addpreview)}/>
                                   : null }
                            </>
                            : <>
                                   <p className="ccc11mmm2" onClick={()=>coverphotos()}> Edit Cover Photo </p>
                                   {iscoverphotoss === true ? 
                                          <CoverPhotoProfile registerids={props.match.params.Addpreview} cancels={()=>coverphotos()} refreshsss ={()=>profileviews(props.match.params.Addpreview)}/>
                                   : null }
                            </>
                            } 
                     </>: null }
                     
    

                     <div className="generic-button">
                     {isAuth === true && theUser !== '' && theUser != props.match.params.Addpreview ? <>
             

                     <Followunfollow  storageid={theUser} profileids={props.match.params.Addpreview } refreshfollowcount={()=>followcount(props.match.params.Addpreview)}refreshfollowingcount={()=>followingcount(props.match.params.Addpreview)}/>

                     </> : null}
                            
                     </div>





                     <div className="item-header-avatarmmm2">
                    
                     {item.profile_image === '' ? <>
                     <p> &nbsp;</p>
                     {isAuth === true && theUser === item.register_id ? <>
                           <span className="ccc11mmm23" onClick={()=>groupphotos()}> Add Photo </span>
                           {isgroupphotoss === true ? 
                                   <ProfilePhoto registerids={props.match.params.Addpreview} cancels={()=>groupphotos()} refreshsss ={()=>profileviews(props.match.params.Addpreview)}/>
                            : null }
                     </>: null }
                             <img src={userphotos} className="avatar group-4-avatar " alt="Groupphoto" />
                     </>
                     : <>
                     <p> &nbsp;</p>
                     {isAuth === true && theUser === item.register_id ? <>
                            <span className="ccc11mmm23" onClick={()=>groupphotos()}> Edit Photo </span>
                            {isgroupphotoss === true ? 
                                   <ProfilePhoto registerids={props.match.params.Addpreview} cancels={()=>groupphotos()} refreshsss ={()=>profileviews(props.match.params.Addpreview)} />
                            : null }
                     </>: null }
                             <img src={BaseURLuploads+item.profile_image} className="avatar group-4-avatar " alt="Groupphoto" />
                     </>
                     } 
            
            <h1 className="ccc10mmm2">{item.name}</h1>
                           
                     </div>
              </div>

              <div className="col-md-3">
                     &nbsp;
              </div>
       <div className="col-md-9">
              <ul className="profile-nav">	
                     <li  onClick={Home} className={isHome? "bp-groups-tab": "groupactives" } >
                            <FontAwesomeIcon icon={['fas','home']} className="groups-tabsss" />
                            <span className="nav-link-text"> Post </span>   
                     </li>
                     <li onClick={Profile} className={ isProfile ? "groupactives": "bp-groups-tab" }>
                            <FontAwesomeIcon icon={['fas','users']} className="groups-tabsss" />
                            <span className="nav-link-text"> About </span>
                     </li>
                     <li onClick={Message} className={ isMessage ? "groupactives": "bp-groups-tab" }>
                     {isAuth === true && theUser == props.match.params.Addpreview ?  <> 
                            <Link to={`/Messagelistall/${props.match.params.Addpreview}`} >
                                   <FontAwesomeIcon icon={['fas','envelope']} className="groups-tabsss" />
                            <span className="nav-link-text"> Inbox </span>
                            </Link>
                     </> : <>
                            <FontAwesomeIcon icon={['fas','envelope']} className="groups-tabsss" />
                            <span className="nav-link-text"> Message </span> 
                     </> }
                     </li>
                     {/* {isAuth === true && theUser == props.match.params.Addpreview ? <>
                      <li onClick={Inbox} className={ isInbox ? "groupactives": "bp-groups-tab" }>
                            <FontAwesomeIcon icon={['fas','images']} className="groups-tabsss" />
                            <span className="nav-link-text"> Inbox </span>
                     </li>
                     </> : null } */}
                    
                     <li onClick={Gallery} className={ isGallery ? "groupactives": "bp-groups-tab" }>
                            <FontAwesomeIcon icon={['fas','images']} className="groups-tabsss" />
                            <span className="nav-link-text"> Gallery </span>
                     </li>
                     <li onClick={Adverts} className={ isAdverts ? "groupactives": "bp-groups-tab" }>
                            <FontAwesomeIcon icon={['fas','images']} className="groups-tabsss" />
                            <span className="nav-link-text"> Business Ads </span>
                     </li>
              </ul>
       </div>

              </div>
       </div>
))}

    

 


      <div className="yyy">
              
      <div className="col-md-3 resss">
      <ul className="connections">
             <li onClick={Followers} className={ isFollowers ? "groupactives": "bp-groups-tab" }><span className="count color-primary">{isfollowcount.length}</span><p>Followers</p></li>  
             <li onClick={Following} className={ isFollowing ? "groupactives": "bp-groups-tab" }><span className="count color-primary"> {isfollowingcount.length}</span><p>Following</p></li>  
             {/* <li><span className="count color-primary"> {isgroupcount.length}</span><p> Group </p></li>   */}
      </ul>
      {isAdvertsprofile.map((item, index) => ( 
           <div className="col-md-12" key={item.classified_id}>
           <div className="single-listing-item ">
               <Link to={`/ClassifiedsPreview/${item.classified_id}`} className="link-btn">
                   <div className="">
                   <img src={BaseURLuploads+item.image} alt="Classifiedsads" /> 
                   <div className="listing-tagwwwmmm2">
                       {item.category === 'other' ?
                           <>{item.other}</>
                           :  <>{item.category}  </>}
                      
                   <h3> {item.title} </h3>
                   <div className="clearfix"></div>
                   <div className="mmm2parasss2" dangerouslySetInnerHTML={{ __html: item.description }} />
                   </div>
                              
                   </div>
                   </Link>
                      
                  
           </div>
       </div>    
                  )) }
      </div>
<div className="col-md-6 yyy5">
       {isAuth === true && theUser !=='' && isHome === false ?
              <div className="col-md-12 yyy5">
                     <ProfilePost storageid={theUser} profileusers={props.match.params.Addpreview}/>
           
              </div>
       : null } 
       {isAuth === true && theUser !=='' && isProfile === true ?      
              <Profilelist storageid={theUser} profileusers={props.match.params.Addpreview}  profileviews={isprofileviews} refresh={()=> profileviews(props.match.params.Addpreview)} />
       : null } 
       
       {isAuth === true && theUser !=='' && isFollowers === true ?
              <Followerslist storageid={theUser} profileusers={props.match.params.Addpreview} />
       : null }
        {isAuth === true && theUser !=='' && isFollowing === true ?
              <Followinglist storageid={theUser} profileusers={props.match.params.Addpreview} />
       : null }
       {isAuth === true && theUser !=='' && isMessage === true ?
               <Messagelist storageid={theUser} profileusers={props.match.params.Addpreview}   />
       : null }  
       {/* {isAuth === true && theUser !=='' && isInbox === true ?
              <Inboxlist storageid={theUser} profileusers={props.match.params.Addpreview}  />
       : null }   */}
       {isAuth === true && theUser !=='' && isGallery === true ?
              <Gallerylist storageid={theUser} profileusers={props.match.params.Addpreview}  />
       : null }  
       {isAuth === true && theUser !=='' && isAdverts === true ?
              <Advertslist storageid={theUser} profileusers={props.match.params.Addpreview}  />
       : null }
</div>
             
             
<div className="col-md-3 resss">
{isPlatinumgold.map((item, index) => ( 
           <div className="col-md-12" key={item.classified_id}>
           <div className="single-listing-item ">
               <Link to={`/ClassifiedsPreview/${item.classified_id}`} className="link-btn">
                   <div className="">
                   <img src={BaseURLuploads+item.image} alt="Classifiedsads" /> 
                   <div className="listing-tagwwwmmm2">
                       {item.category === 'other' ?
                           <>{item.other}</>
                           :  <>{item.category}  </>}
                      
                   <h3> {item.title} </h3>
                   <div className="clearfix"></div>
                   <div className="mmm2parasss" dangerouslySetInnerHTML={{ __html: item.description }} />
                   </div>
                              
                   </div>
                   </Link>
                      
                  
           </div>
       </div>    
                  )) }
                  {isgoldgold.map((item, index) => ( 
            <div className="col-md-12" key={item.classified_id}>
            <div className="single-listing-item ">
                <Link to={`/ClassifiedsPreview/${item.classified_id}`} className="link-btn">
                    <div className="">
                    <img src={BaseURLuploads+item.image} alt="Classifiedsads" /> 
                    <div className="listing-tagwwwmmm2">
                        {item.category === 'other' ?
                            <>{item.other}</>
                            :  <>{item.category}  </>}
                       
                    <h3> {item.title} </h3>
                    <div className="clearfix"></div>
                    <div className="mmm2parasss" dangerouslySetInnerHTML={{ __html: item.description }} />
                    </div>
                               
                    </div>
                    </Link>
                       
                   
            </div>
        </div>    
                  )) }
</div>
             
       </div>






                     </div>
                     </div>
                     </div>
                     </> : <>   
       <div className="container">
              <div className="row">
                     <div className="col-md-3" > &nbsp;</div>
                     <div className="col-md-6 loadinsstopsss" >
                            <div className="loadings ">
                                   <FontAwesomeIcon icon={['fas','check-circle']} />
                                   <p>Please wait while we  set things up for you.</p>
                            </div>
                     </div>
              </div>
       </div>
 
 </>}    
              </div>
       );
}

export default ProfileView;