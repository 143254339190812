import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'react-loader-spinner';
import { useHistory } from "react-router-dom";

import {Link } from "react-router-dom"; 
import ContentLoader from "react-content-loader";

import {toast} from "react-toastify";


function Settingsall(props) {

  let history = useHistory();

       
       const {rootState,updategroups} = useContext(MyContext);
       const {theUser} = rootState;
       const [loadingadd, setloadingadd] = useState(false);
       const [userInfo, setuserInfo] = useState({
        name: props.name,
        description: props.desc,
        website: props.url,
    });
     

       useEffect( () => {
           
        //window.scrollTo(0, 0);
    
           }, []); 


     
       const onChangeValue = (e) => {
         
              setuserInfo({
                  ...userInfo,
                      [e.target.name]:e.target.value
              });
          } 

          const editsgroups = async (event) => {
            try {
              setloadingadd(true);
                          event.preventDefault();
                          event.persist();
                          const addgroups = await updategroups(userInfo,props.groupuserid,props.loadids);
                          console.log('groupupdates',addgroups);
                          if(addgroups.success === 1){
                                 console.log(addgroups);
                                 setloadingadd(false);
                                 event.target.reset(); 
                                 toast( 'Success', {
                                  position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                                });  
                                props.loadsss(props.loadids)
                              

                          }

            } catch (error) { throw error;}    
     }
      

        return(
               <div className="ud-cen-s1yyy">
                       
                     
                      <div className="ud-cen-s1s">
                      <h5 className="widget-title"> Edit Group </h5>
                     <form onSubmit={editsgroups} className="login-forms">
                            <div className="clearfix"></div>
                            <div className="form-row">
                            <div className="form-group col-md-12">
                                   <label className="font-weight-bold col-md-3"> Group Name :  </label>
                                   <input type="text" name="name" value={userInfo.name} onChange={onChangeValue} className="form-control col-md-8" placeholder="Your Group Name here..."  required  />
                                   
                            </div>
                            <div className="form-group col-md-12">
                                   <label className="font-weight-bold col-md-3">  Description :  </label>
                                   <textarea  name="description" value={userInfo.description} className="form-control col-md-8" rows="5"  placeholder="Group Description"  onChange={onChangeValue} />
                            </div>
                            <div className="form-group col-md-12">
                                   <label className="font-weight-bold col-md-3">Group Website : </label>
                                   <input type="text" name="website" value={userInfo.website} onChange={onChangeValue} className="form-control col-md-8" placeholder=" Website link here..."    />
                            </div>
                            <div className="form-group col-md-12 ">
                            {loadingadd ?
                                          <>
                                                 <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                                          </>
                                          : 
                                   <button type="submit" className="btn2zzz2" style={{
                                   marginBottom: "37px"}} > Update Group </button>
                            }
                            </div>
                            
                            </div>
        
                     </form>  
              </div>



                 

          </div>
        );
    }

export default Settingsall;