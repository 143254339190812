
import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';

import {Link } from "react-router-dom"; 

import ContentLoader from "react-content-loader";


function StripeSuccess(props) {


  const {stripesuccesslist} = useContext(MyContext);
  const [stripedetails, setstripedetails] = useState([]);

  useEffect( () => {
     window.scrollTo(0, 0);
     successdata(props.match.params.stripesid);
 }, []);



 const successdata = async (paymentid) => {
  const data = await stripesuccesslist(paymentid);
  console.log(data);
  if(data.success === 1){
    setstripedetails(data.bookslist);
  }
}


    return (
      <>
        <div className="clearfix"></div>
       <div className="successs">
        <div className="container">
          <div className="row">
            
    
          <div className="col-md-3" > &nbsp; </div>
                  <div className="col-md-6" >

                  <div className="logssssuccesss">
              <div className="login-form">
                  <h2>  Payment Receipt </h2>
              </div>
           
             <h2 className="successsh2">Your Payment Process Checking.</h2>
          
             {stripedetails.map((item, index) => (
                    <div key={item.stripe_id}>

                  {item.payment_status === 'succeeded' ? 
                        <> 
                          <h3> Order No : #BF45IO8Z-{item.member_id} </h3>    
                          <h3> Stripe transaction id :  {item.txn_id} </h3>    
                          <h3> Total Amount : <span style={{color: '#ff5800'}}> {item.payment_gross} </span> </h3>   
                          <h3> Currency : {item.currency_code} </h3>  
                          <h3> Payment Status : {item.payment_status} </h3> 
                          <Link to={`/Myaccount`}  className="pricing-onebuttonss" > Continue </Link>
                        </>
                         : 
                       <> 
                          <h3> Order No : #BF45IO8Z-{item.member_id} </h3>    
                          <h3> Total Amount : {item.payment_gross} </h3>   
                          <h3> Currency : {item.currency_code} </h3>  
                          <h3 style={{color: "#fd2e1f"}}> Payment Status : {item.payment_status} </h3> 
                          <Link to={`/Payment/${item.member_id}`} className="pricing-onebuttonss" > Payment Again </Link>
                       </>
                       }       
                           
                          
                              
                           </div>

))}
      
       
      </div>

</div>
</div>
</div>
</div>
</>
);
}


export default StripeSuccess;