
import React,{useContext,useState} from 'react';
import { MyContext } from '../contexts/MyContext';

import Loader from 'react-loader-spinner';
import {toast} from "react-toastify";
import { useHistory } from "react-router-dom";


function Loginpage() {
    let history = useHistory();

    const {loginUser,isLoggedIn} = useContext(MyContext);
    
    const [loadinglogin, setloadinglogin] = useState(false);
    const [userInfo, setuserInfo] = useState({
        email: '',
        password: '',
    });
    const onChangeValue = (e) => {
        setuserInfo({
            ...userInfo,
                [e.target.name]:e.target.value
        });
    } 
    const userLogin = async (event) => {
        setloadinglogin(true);
        event.preventDefault();
        event.persist();
        const data = await loginUser(userInfo);
        if(data.success === 1){
            setloadinglogin(false);
            localStorage.setItem('loginToken',JSON.stringify(data.users[0].register_id));       
           
          event.target.reset(); 
          await isLoggedIn();
            history.push('/Myaccount');
          
        }else{
            setloadinglogin(false);
            toast.error( data.msg, {
                position: "top-center",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
              });  
        }
        
    }
   
        return(
              
           <form onSubmit={userLogin} className="login-forms">
          
                 
                <div className="clearfix"></div>
                <div className="form-row">
                    <div className="form-group col-md-12">
                      
                        <input type="email" name="email" value={userInfo.email} onChange={onChangeValue}  className="form-control" placeholder="Email" required/>
                    </div>
                    <div className="form-group col-md-12">
                       
                        <input type="password" name="password" value={userInfo.password} onChange={onChangeValue} className="form-control" placeholder="Password" required/>
                    </div>
                    <div className="form-group col-md-12 ">
                    {loadinglogin ?
                            <>
                                   <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                            </>
                         : 
                        <button type="submit" className=""> Login </button>
                    } 
                    </div>
                </div>
               
                
            </form>  
                  
        );
}
export default Loginpage;