
import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfDocumentrecepits from "./PdfDocumentrecepits";




function Recepitlist(props) {
       const {viewpaymentdetails} = useContext(MyContext);
      
       const [userlist, setuserlist] = useState([]);
       const [userlistempty, setuserlistempty] = useState(false);
    

    


       useEffect( () => {
             userdetails(props.storageid);
          
            }, []);


       const userdetails = async(storageid) =>{
              try {
                     const viewuser = await viewpaymentdetails(storageid);
                     console.log('paymentdetails',viewuser);
                     if(viewuser.success === 1){
                            console.log('paymentdetails',viewuser.paymentdetails);
                            setuserlist(viewuser.paymentdetails); 
                     }
                     if(viewuser.success === 0){
                            setuserlistempty(true);

                     }
              } catch (error) { throw error;}      
       }




  return (
         <>
          <span className="udb-inst"> Payment Receipt </span>
    <div className="recpisssqqq">
    <div className="container">
        

    <div className="ud-rhs-pay">
        <div className="ud-rhs-pay-inn">
        {userlist.map((item, index) => ( 
           <div className="" key={item.register_id}> 
                   
            <ul>
                <li><b>Plan name : </b> {item.member}</li>
               
                <li><b>Duration : </b> 
                {item.member ==='Silver' ? <> Onetime Payment for 1.5 Years Membership </> : null }
                {item.member ==='Gold' ? <> Onetime Payment for 5 Years Membership </> : null }
                {item.member ==='Platinum' ? <> Onetime Payment for lifetime membership </> : null }
                </li>
                
                <li><span className="ud-stat-pay-btn"><b>Checkout cost :</b> ${item.payment_gross}</span></li>
                <li><span className="ud-stat-pay-btn"><b>transaction id :</b> {item.txn_id} </span></li>
                <li><span className="ud-stat-pay-btn"><b>Payment Status :</b> {item.payment_status} </span></li>

            
            </ul>
  
            
                         
        </div>  
        
       ))}   
      </div>  
        </div>  
{userlist.length > 0 ?  <>
      
      <div className="form-group col-md-12">
                    
      
                  </div>
      {<PDFDownloadLink
        document={<PdfDocumentrecepits bookpdflist={userlist} />}
        fileName="PaymentReceipt.pdf"
        style={{
          textDecoration: 'none',
          padding: '10px 27px 10px 27px',
          color: 'rgb(255 255 255)',
          backgroundColor: 'rgb(251, 106, 31)',
          marginTop: '7px',
          fontSize: '20px',
          float: 'left',
          borderRadius: '7px',
          marginLeft: '17px',
        }}
      >
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : "Download Pdf"
        }
      </PDFDownloadLink>}

      </>
      : null }















    </div>
    </div>
    </>
  );
}
export default Recepitlist;