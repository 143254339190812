import React,{useContext,useState,useEffect, useRef, useCallback} from 'react';

import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';

import Loader from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Moment from 'react-moment';
import Popup from 'reactjs-popup';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import userphotos from '../Img/user_profile.jpg';
import GroupAnnouncement from './GroupAnnouncement';
import GroupPostEdit from './GroupPostEdit';
import CommentgroupFeed from './CommentgroupFeed';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useHistory } from "react-router-dom"; 
import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function GroupPost(props) {
       let history = useHistory();
       const {rootState,viewuserprofile,newfeedsgroupadd,announcmentslist,gpostlist,deletegrouppostsss,reportpostupdatelist,setfeaturedslist,removefeaturedsmmmlist,HideCommentlist,joinpremissionlist} = useContext(MyContext);


       const {isAuth,theUser} = rootState;

 const [isurls, setisurls] = useState(false);
const [isyoutube, setisyoutube] = useState(false);
const [ismembername, setmembername] = useState([]);
const [ismemberprofile, setmemberprofile] = useState([]);
const [userInfo, setuserInfo] = useState({
       feed: '',
       file:'',
       url:'',
       youtube:'',
   });

const [loadingadd, setloadingadd] = useState(false);
const [loademoreloding, setloademoreloding] = useState(false);
const [fileempty, setfileempty] = useState(false);
const [filetemp, setfiletemp] = useState(null);
const [invalidImage, setinvalidImage] = useState(null);

const [ismoreids, setismoreids] = useState();
const [ismore, setismore] = useState(false);

const [page, setPage] = useState(1);

useEffect(() => {
       userdetails(props.storageid);
       announcments(props.groupid);
       gpost(page,props.groupid);
       
     }, []);
     


     const [ispost, setpost] = useState([]);
     const [ispostloademore, setpostloademore] = useState(false);
     const gpost = async(page,groupids) =>{
             try {
               setloademoreloding(true);
                    const gpostsss = await gpostlist(page,groupids);
                    if(gpostsss.success === 1){
                           console.log('latest',gpostsss.postlist);
                           if(gpostsss.postlist.length > 0){
                                    setpost(prevBooks => {
                                           return [...prevBooks, ...gpostsss.postlist]
                                   })
                                   setpostloademore(false);
                                   setloademoreloding(false);
                            }else{
                                   setpostloademore(true);
                                   setloademoreloding(false);
                            }
                          
                    }
             } catch (error) { throw error;}      
      } 

      const [count, setCount] = useState(1);
      const handleIncrement = (count) => {
       setCount(prevCount => prevCount + 1);
       console.log('count',count+1);
       gpost(count+1,props.groupid);
     };


     const [isreportpost, setreportpost] = useState({
       reportpost: '',
   });

       const radiosChange = (e) => {
              setreportpost({
                     ...isreportpost,
                     [e.target.name]:e.target.value
              });
       }   
       
       const reportpostupdate = async(event) =>{
              try {
                    
                     event.preventDefault();
                     event.persist();
                     const reportpostupdatesss = await reportpostupdatelist(isreportpost,props.storageid,props.groupid,isreportpost.feedsids.value);
                     if(reportpostupdatesss.success === 1){
                            event.target.reset(); 
                            setreportpost({
                                   ...isreportpost,
                                   reportpost:'',
                               });
                               setmore(issetmore === false ? true : false);
                     }
              } catch (error) { throw error;}      
       }



     const [issetmore, setmore] = useState(false);
     const [issetmoreids, setmoreids] = useState(false);
     const moreoptions = (ids) =>{
          
       setmoreids(ids)
       setmore(issetmore === false ? true : false);
       setjoinpremissions([]);
       joinpremissions(ids,props.storageid)
}

const [isjoinpremissions, setjoinpremissions] = useState([]);
    
const joinpremissions = async(groupfeedsids,storageid) =>{
        try {
             
               const joinpremissionsss = await joinpremissionlist(groupfeedsids,storageid);
               if(joinpremissionsss.success === 1){
                     
                      if(joinpremissionsss.success === 1){ 
                     
                      
                            if(joinpremissionsss.jionsalllist.length > 0){ 
                                   console.log('joinpremissionsss',joinpremissionsss.jionsalllist[0].gfeed_member_id);
                                   setjoinpremissions(joinpremissionsss.jionsalllist[0].gfeed_member_id);
                            }
                      }
                      
               }
        } catch (error) { throw error;}      
 } 

       const onChangeValue = (e) => {
              setuserInfo({
                  ...userInfo,
                      [e.target.name]:e.target.value
              });
             
       }
   
       const userdetails = async(storageid) =>{
              try {
                     const viewuser = await viewuserprofile(storageid);
                     if(viewuser.success === 1){
                            console.log('memberlist',viewuser.memberlist);
                            setmembername(viewuser.memberlist[0].name);
                            setmemberprofile(viewuser.memberlist[0].profile_image);
                     }
              } catch (error) { throw error;}      
       } 
      const [isannouncments, setannouncments] = useState([]);
    
      const announcments = async(groupids) =>{
              try {
                     const announcmentsss = await announcmentslist(groupids);
                     if(announcmentsss.success === 1){
                            console.log('memberlist',announcmentsss.listanncccsss);
                                   setannouncments(announcmentsss.listanncccsss);
                                   
                     }
              } catch (error) { throw error;}      
       } 
    
     
    
    const HideComment = async(groupids) =>{
     
              try {
                     //toggle();
                     const HideCommentsss = await HideCommentlist(groupids);
                     if(HideCommentsss.success === 1){
                            console.log('HideCommentsss',HideCommentsss.listanncccsss);
                           
                             setPage(1);
                             setCount(1);
                             setpost([]);
                             gpost(page,props.groupid);
                            // setmore(issetmore === false ? true : false);
                            setannouncments([]);
                            announcments(props.groupid);
                     }
              } catch (error) { throw error;}      
       } 
    
      
       const setfeatureds = (feedsids) => {
              confirmAlert({
                title: ' ',
                message: 'Set as post as Featured ',
                buttons: [
                  {
                    label: 'confirm',
                    onClick: () => setfeaturedsmmm(feedsids)
                  },
                  {
                    label: 'cancel',
                  }
                ]
              })
            };     
            const setfeaturedsmmm = async(feedsids) =>{
              try {
                     const setfeaturedsss = await setfeaturedslist(feedsids);
                     if(setfeaturedsss.success === 1){
                            console.log(setfeaturedsss.listanncccsss);
                            setannouncments([]);
                                   announcments(props.groupid);
                                   setPage(1);
                                   setCount(1);
                                   setpost([]);
                                   gpost(page,props.groupid);
                                   setmore(issetmore === false ? true : false);
                     }
              } catch (error) { throw error;}      
       } 
      const removefeatureds = (feedsids) => {
              confirmAlert({
                title: ' ',
                message: 'Set as post as Remove Featured ',
                buttons: [
                  {
                    label: 'confirm',
                    onClick: () => removefeaturedsmmm(feedsids)
                  },
                  {
                    label: 'cancel',
                  }
                ]
              })
            };     
            const removefeaturedsmmm = async(feedsids) =>{
              try {
                     const removefeaturedsmmmsss = await removefeaturedsmmmlist(feedsids);
                     if(removefeaturedsmmmsss.success === 1){
                            console.log(removefeaturedsmmmsss.listanncccsss);
                            setannouncments([]);
                                   announcments(props.groupid);
                                   setPage(1);
                                   setCount(1);
                                   setpost([]);
                                   gpost(page,props.groupid);
                                   setmore(issetmore === false ? true : false);
                     }
              } catch (error) { throw error;}      
       } 
     
       const newfeedsgroup = async(event) =>{
              try {
                     setloadingadd(true);
                     event.preventDefault();
                     event.persist();
                     const newfeedsqqq = await newfeedsgroupadd(userInfo,props.storageid,userInfo.fileempty.value,props.groupid);
                     if(newfeedsqqq.success === 1){
                            setloadingadd(false);
                            event.target.reset(); 
                            setuserInfo({
                                   ...userInfo,
                                       feed:'',
                                       file:'',
                                       url:'',
                                       youtube:'',
                               });
                               setisurls(false);
                               setisyoutube(false);
                               setfileempty(false);
                               setfiletemp(null);
                               setinvalidImage(null);
                               setPage(1);
                               setCount(1);
                               setpost([]);
                               gpost(page,props.groupid);
                               setmore(issetmore === false ? true : false);
                               //history.go(0);
                               
                     }
              } catch (error) { throw error;}      
       }
       const toggleClass = (ids) => {
              setismore(ismore === false ? true : false);
              setismoreids(ids);
          };
       const toggleClassurls = () => {
              setisurls(isurls === false ? true : false);
          };
         const toggleClassyoutube = () => {
              setisyoutube(isyoutube === false ? true : false);
          };

       const resetFile = (event) => {
              event.preventDefault();
              setfiletemp(null)
            }

            let reader = new FileReader();

   const handleChange = (event) => {
       const imageFile = event.target.files[0];
             const imageFilname = event.target.files[0].name;

             if (!imageFile) {
              setinvalidImage('Please select image.');
               return false;
             }
         
             if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif)$/)) {
              setinvalidImage('Please select valid image JPG,JPEG,PNG');
               return false;
             }
             reader.onload = (e) => {
               const img = new Image();
               img.onload = () => {

//----------------------------- Resize img code -------------------------------------------------
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);

                var MAX_WIDTH = 437;
                var MAX_HEIGHT = 437;
                var width = img.width;
                var height = img.height;

                if (width > height) {
                  if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                  }
                } else {
                  if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                  }
                }
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
                ctx.canvas.toBlob((blob) => {
                  const file = new File([blob], imageFilname, {
                      type: 'image/jpeg',
                      lastModified: Date.now()
                  });
                  setuserInfo({
                     ...userInfo,
                     file:file,
                })
                }, 'image/jpeg', 1);


//-------------------------------------------------------------------------------------
              setfiletemp( URL.createObjectURL(imageFile))
              setfileempty(true);
              setinvalidImage(null)
              };
               img.onerror = () => {
                     setinvalidImage('Invalid image content.');
                 return false;
               };
               //debugger
               img.src = e.target.result;
             };
             reader.readAsDataURL(imageFile);

     }; 


     const externallinks = (url) => {
       if (!!url && !!url.trim()) { 
              url = url.trim(); 
              if (!/^(https?:)?\/\//i.test(url)) { 
                  url = 'http://' + url; 
                  window.open(url, '_blank');
              }else{
                     window.open(url, '_blank');
              }
          } 
     }  

     const deletes = (feedids) => {
       confirmAlert({
         title: 'Confirm to Delete ',
         message: 'Are you sure to do this.',
         buttons: [
           {
             label: 'Yes',
             onClick: () => deletebooks(feedids)
           },
           {
             label: 'No',
           }
         ]
       })
     };     
     const deletebooks = async (feedids) => {
       try {
              const deletebooksss = await deletegrouppostsss(feedids);
              if(deletebooksss.success === 1){
                     setPage(1);
                     setCount(1);
                     setpost([]);
                     gpost(page,props.groupid);
              }
       } catch (error) { throw error;} 
}
   const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]{11,11}).*/;  

 
   
  return (
  
       <>
       <div className="col-md-8 yyy6 ">
      
           
                   {props.statuspermision === 1 || props.statuspermision === 3 || props.statuspermision === 4 ? <>
                     <div className="feeds">
                     <div className="feedswww">
                     <form onSubmit={newfeedsgroup} className="login-forms">
                     <div className="whats-new-content ">
                            {ismemberprofile !=='' ? 
                                   <img src={BaseURLuploads+ismemberprofile} alt="" className="rounded-circle mr-2 feeds2" />
                            :
                                   <img src={userphotos} alt="" className="rounded-circle mr-2 feeds2"  />
                            }
                                   <textarea  name="feed" value={userInfo.feed} className=" col-md-10" rows="4"  placeholder={"What's new, " + ismembername + "? "}  onChange={onChangeValue} required />
                            </div>
                     <div className="clearfix"></div>
                   
                     <div className="col-md-12 " style={{padding:0}}>
                     <div className="col-md-8" style={{padding:0}}>
                     <ul> 
                            <li>
                                   <FontAwesomeIcon icon={['fas','image']} className="iconsss2" />
                                   <label className="fileContainer">
                                   <input type="file" name="file" className="form-control" placeholder="Title" onChange={handleChange} />
                                   </label>
                                   {fileempty === true ? 
                                   <input type="hidden" name="fileempty" ref={(val) => userInfo.fileempty = val} className="form-control" defaultValue="imgactive" />
                                   : 
                                   <input type="hidden" name="fileempty" ref={(val) => userInfo.fileempty = val} className="form-control" defaultValue="imgempty" />
                                   }
                            </li>
                            <li>
                                   <FontAwesomeIcon icon={['fas','globe-americas']} className="iconsss2"  onClick={toggleClassurls}/>
                                  
                            </li>
                             <li>
                                   <FontAwesomeIcon icon={['fab','youtube']} className="iconsss2"  onClick={toggleClassyoutube}/>
                                  
                            </li>
                     </ul>
                     </div>
                    
                     <div className="col-md-4" style={{padding:0}}>
                            {loadingadd ?
                            <>
                                   <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                            </>
                            : 
                                   <div className="form-group col-sm-12 text-right">
                                          <button type="submit" className="db-tit-btn2"> Post Update </button>
                                          
                                   </div>
                             }
                   </div>
                   </div>
                     {isurls === true ? 
                            <input type="text" name="url" value={userInfo.url} onChange={onChangeValue} className="form-control"  placeholder=" website url "/>
                     : null}
                     {isyoutube === true ? 
                            <input type="text" name="youtube" value={userInfo.youtube} onChange={onChangeValue} className="form-control"  placeholder=" youtube url "/>
                     : null}
                     </form>
                     </div>
                     </div>
</> : null}
                     
                     





  {/* -------------------------------------------------------------------------------------------------------------------------------- Group Annoucments -------------------------------------------------------------------------------------------------------------------------------- */}
  {isannouncments.length > 0 ? <>  
<div className="feeds yyy2 yyy7">
<div className="feedswww">
<ul className="activity-list" > 
       {isannouncments.map((item, index) => ( 
               <li className="col-md-12 yyy10" key={item.gfeed_id} style={{padding:0}}  >
                  
                     
               <div className="more-post-optns">
               <FontAwesomeIcon icon={['fas','ellipsis-h']} className="enablelikes"  onClick={() => moreoptions(item.gfeed_id)} />
               {issetmore === true && issetmoreids === item.gfeed_id ? 
<ul>
{ props.groupusercreateid === theUser || props.adminpremissionsss === theUser ?  <>
       {item.anouncement === 1 ? 
              <li onClick={()=>removefeatureds(item.gfeed_id)}><FontAwesomeIcon icon={['fas','star']} className="enablelikes"/> Remove As Featured</li>
       : null }

       <Popup trigger={<li> <FontAwesomeIcon icon={['fas','user-edit']} className="enablelikes"/>Edit Post</li>} position="top left">
       {close => (
       <div className="feedspopups">
              <a className="close" onClick={close}>
              &times;
              </a>
              <GroupPostEdit feedid={item.gfeed_id}  groupid={item.feed_group_id} feedtext={item.gfeedtext} image={item.gimage} url={item.gurl} youtube={item.gyoutube} grouprefresh={()=>gpost(page,props.groupid)} resetposts={()=>setpost([])}  close={close} moreoptions ={() => moreoptions(item.gfeed_id)} newsetCount ={()=>setCount(1)} /> 
              
              
       </div>
       )}
       </Popup>
                     <li onClick={()=>deletes(item.gfeed_id)}><FontAwesomeIcon icon={['fas','trash-alt']} className="enablelikes"/>Delete Post</li>

                     <li> <FontAwesomeIcon icon={['fas','comments']} className="enablelikes"/>Comment <BootstrapSwitchButton checked= {item.commentstoogle === 0 ? true : false} onstyle="outline-primary" offstyle="outline-secondary" onlabel='On' offlabel='Off'
                            
                            onChange={()=>HideComment(item.gfeed_id)}

                            />	</li>	 
                                          
       </> 
 : null }				
               <Popup trigger={ <li className="bad-report"><FontAwesomeIcon icon={['fas','flag']} className="enablelikes"/>Report Post</li>} position="top left">
 {close => (
 <div className="feedspopups">
         <a className="close" onClick={close}>
        &times;
        </a>
        <div className="pppsss">
      <div className="feedswww">
      <h4> Report </h4>
      <hr />
        <form onSubmit={reportpostupdate} className="repportsss">
        <ul> 
               <li><input type="radio" value="Nudity" name="reportpost" onChange={radiosChange} /> Nudity </li>
               <li><input type="radio" value="Violence" name="reportpost" onChange={radiosChange}/> Violence </li>
               <li> <input type="radio" value="Harassment" name="reportpost" onChange={radiosChange}/> Harassment </li>
               <li> <input type="radio" value="Suicide or self-injury" name="reportpost" onChange={radiosChange}/> Suicide or self-injury </li>
               <li> <input type="radio" value="False news" name="reportpost" onChange={radiosChange}/> False news </li>
               <li> <input type="radio" value="Spam" name="reportpost" onChange={radiosChange}/> Spam </li>
               <li> <input type="radio" value="Unauthorised sales" name="reportpost" onChange={radiosChange}/> Unauthorised sales </li>
               <li> <input type="radio" value="Hate speech" name="reportpost" onChange={radiosChange}/> Hate speech </li>
               <li> <input type="radio" value="Terrorism" name="reportpost" onChange={radiosChange}/> Terrorism </li>
             
             <input type="hidden" value={item.gfeed_id} ref={(val) => isreportpost.feedsids = val} name="feedsids" /> 
        </ul>
               <div className="form-group col-sm-12 text-right">
                      <button type="submit" className="db-tit-btn2"> Report  </button>
                </div>
        </form>
       
        </div>
        </div>   
 </div>
 )}
 </Popup>   

                      </ul>
               : null}    
                </div>

<div className="userlistfeeds">
                      <div className="col-md-2s" style={{padding:0}} >
                             {item.profile_image !=='' ? 
                                    <img src={BaseURLuploads+item.profile_image} alt="" className="rounded-circle mr-2 feeds2" />
                             :
                                    <img src={userphotos} alt="" className="rounded-circle mr-2 feeds2"  />
                             }
                      </div>
                             <div className="col-md-10s" style={{padding:0}}>
                             <p> <b> {item.name} </b> posted an update </p> <br/>
                             <div className="clearfix"> </div>
                             <p className="userlistfeedsmmm">  <Moment fromNow>
                             {item.gdate} 
                             </Moment></p>
                      </div>
               </div>
<div className="clearfix"> </div>
               <div className="shortcontent">
             
             
               <div className={ "block-ellipsis" + (ismoreids === item.gfeed_id ? " block-ellipsisqqq " : "")} dangerouslySetInnerHTML={{ __html: item.gfeedtext }} />
               
               {item.gfeedtext.length > 177 ? <>
                   
                      {ismore === true && ismoreids === item.gfeed_id ?  
                             <span className="morelink" onClick={toggleClass}> Close  </span> 
                      :  
                             
                      <span className="morelink" onClick={() => toggleClass(item.gfeed_id)} > read more ...  </span>
                      }
               </> : null }
               {item.gimage ? 
                      <div className="rtmedia-item-thumbnail">
                             <img src={BaseURLuploads+item.gimage} alt="feedsimages" />
                      </div>
               : null }
               <div className="clearfix"> </div>
               {item.gyoutube.match(regExp) ? 
               <> 
                      {item.gyoutube.match(regExp).length >= 2 ? 

                      <iframe id="ytplayer" type="text/html" className="videosss2"
                      src={`https://www.youtube.com/embed/` + item.gyoutube.match(regExp)[2] + `?rel=0&showinfo=0&color=white&iv_load_policy=3`}
                      frameBorder="0" allowFullScreen></iframe>
                      : null }
               </>
                      : null 
               } 
                {item.gurl !== '' ? <>  
                      <div className="col-md-12 ccc2"> 
                             <hr />
                           <p onClick={() => externallinks(item.gurl)}>{item.gurl} </p> 
                      </div>
                </> : null }

                </div>


        <div className="yyy8">
              {props.statuspermision === 1 || props.statuspermision === 3 || props.statuspermision === 3 ? <>
                     <CommentgroupFeed commentuserid ={props.storageid} userimgs={ismemberprofile} postids={item.gfeed_id} feedmemberid={item.gfeed_member_id}  likecount={item.likes} commentcount={item.comments} commentstoogle={item.commentstoogle}/>
              </> : null }
        </div>




  </li>
))}
        </ul>
        
       </div>
</div>
</>
: null}    
             











                     
{/* --------------------------------------------------------------------------------------------------------------------------------  Latest Post -------------------------------------------------------------------------------------------------------------------------------- */}

{ispost.length > 0 ? <>   
<div className="feeds yyy2">
<div className="feedswww">
<ul className="activity-list" > 
       {ispost.map((item, index) => ( 
              <li className="col-md-12" key={item.gfeed_id} style={{padding:0}}  >
                 
                   
                     <div className="more-post-optns">
                     <FontAwesomeIcon icon={['fas','ellipsis-h']} className="enablelikes"  onClick={() => moreoptions(item.gfeed_id)} />
                     {issetmore === true && issetmoreids === item.gfeed_id ? 
<ul>
{ props.groupusercreateid === theUser || props.adminpremissionsss === theUser || isjoinpremissions === theUser ?  <>
       {item.anouncement === 1 ? 
              <li onClick={()=>setfeatureds(item.gfeed_id)}><FontAwesomeIcon icon={['fas','star']} className="enablelikes"/> Set As Featured</li>
       : null }

       <Popup trigger={<li> <FontAwesomeIcon icon={['fas','user-edit']} className="enablelikes"/>Edit Post</li>} position="top left">
       {close => (
       <div className="feedspopups">
               <a className="close" onClick={close}>
              &times;
              </a>
              <GroupPostEdit feedid={item.gfeed_id}  groupid={item.feed_group_id} feedtext={item.gfeedtext} image={item.gimage} url={item.gurl} youtube={item.gyoutube} grouprefresh={()=>gpost(page,props.groupid)} resetposts={()=>setpost([])}  close={close} moreoptions ={() => moreoptions(item.gfeed_id)} newsetCount ={()=>setCount(1)}/> 
             
             
       </div>
       )}
       </Popup>



       
                     <li onClick={()=>deletes(item.gfeed_id)}><FontAwesomeIcon icon={['fas','trash-alt']} className="enablelikes"/>Delete Post </li>	
                     
                     <li> <FontAwesomeIcon icon={['fas','comments']} className="enablelikes"/>Comment <BootstrapSwitchButton checked= {item.commentstoogle === 0 ? true : false} onstyle="outline-primary" offstyle="outline-secondary" onlabel='On' offlabel='Off'
                     
                     onChange={()=>HideComment(item.gfeed_id)}

                     />

</li>
                   
                            {/* <div className={`switch  ${toggleState}`}  onClick={(e)=>toggle(e, item.gfeed_id)}   data-id={index}/>   */}




                     {/* <li onClick={()=>HideComment(item.gfeed_id)}><FontAwesomeIcon icon={['fas','trash-alt']} className="enablelikes"/> Hide Comment</li>		  */}
 </> 
       : null }				
                     <Popup trigger={ <li className="bad-report"><FontAwesomeIcon icon={['fas','flag']} className="enablelikes"/>Report Post</li>} position="top left">
       {close => (
       <div className="feedspopups">
               <a className="close" onClick={close}>
              &times;
              </a>
              <div className="pppsss">
            <div className="feedswww">
            <h4> Report </h4>
            <hr />
              <form onSubmit={reportpostupdate} className="repportsss">
              <ul> 
                     <li><input type="radio" value="Nudity" name="reportpost" onChange={radiosChange} /> Nudity </li>
                     <li><input type="radio" value="Violence" name="reportpost" onChange={radiosChange}/> Violence </li>
                     <li> <input type="radio" value="Harassment" name="reportpost" onChange={radiosChange}/> Harassment </li>
                     <li> <input type="radio" value="Suicide or self-injury" name="reportpost" onChange={radiosChange}/> Suicide or self-injury </li>
                     <li> <input type="radio" value="False news" name="reportpost" onChange={radiosChange}/> False news </li>
                     <li> <input type="radio" value="Spam" name="reportpost" onChange={radiosChange}/> Spam </li>
                     <li> <input type="radio" value="Unauthorised sales" name="reportpost" onChange={radiosChange}/> Unauthorised sales </li>
                     <li> <input type="radio" value="Hate speech" name="reportpost" onChange={radiosChange}/> Hate speech </li>
                     <li> <input type="radio" value="Terrorism" name="reportpost" onChange={radiosChange}/> Terrorism </li>
                   
                   <input type="hidden" value={item.gfeed_id} ref={(val) => isreportpost.feedsids = val} name="feedsids" /> 
              </ul>
                     <div className="form-group col-sm-12 text-right">
                            <button type="submit" className="db-tit-btn2"> Report  </button>
                      </div>
              </form>
             
              </div>
              </div>   
       </div>
       )}
       </Popup>   
      
                            </ul>
                     : null}    
                      </div>

<div className="userlistfeeds">
                            <div className="col-md-2s" style={{padding:0}} >
                                   {item.profile_image !=='' ? 
                                          <img src={BaseURLuploads+item.profile_image} alt="" className="rounded-circle mr-2 feeds2" />
                                   :
                                          <img src={userphotos} alt="" className="rounded-circle mr-2 feeds2"  />
                                   }
                            </div>
                                   <div className="col-md-10s" style={{padding:0}}>
                                   <p> <b> {item.name} </b> posted an update </p> <br/>
                                   <div className="clearfix"> </div>
                                   <p className="userlistfeedsmmm">  <Moment fromNow>
                                   {item.gdate} 
                                   </Moment></p>
                            </div>
                     </div>
<div className="clearfix"> </div>
                     <div className="shortcontent">
                   
                   
                     <div className={ "block-ellipsis" + (ismoreids === item.gfeed_id ? " block-ellipsisqqq " : "")} dangerouslySetInnerHTML={{ __html: item.gfeedtext }} />
                     {item.gfeedtext.length > 177 ? <>

                            {ismore === true && ismoreids === item.gfeed_id ?  
                                   <span className="morelink" onClick={toggleClass}> Close  </span> 
                            :  
                                   
                            <span className="morelink" onClick={() => toggleClass(item.gfeed_id)} > read more ...  </span>
                            }
                     </> : null }
                     {item.gimage ? 
                            <div className="rtmedia-item-thumbnail">
                                   <img src={BaseURLuploads+item.gimage} alt="feedsimages" />
                            </div>
                     : null }
                     <div className="clearfix"> </div>
                     {item.gyoutube.match(regExp) ? 
                     <> 
                            {item.gyoutube.match(regExp).length >= 2 ? 

                            <iframe id="ytplayer" type="text/html" className="videosss2"
                            src={`https://www.youtube.com/embed/` + item.gyoutube.match(regExp)[2] + `?rel=0&showinfo=0&color=white&iv_load_policy=3`}
                            frameBorder="0" allowFullScreen></iframe>
                            : null }
                     </>
                            : null 
                     } 
                      {item.gurl !== '' ? <>  
                            <div className="col-md-12 ccc2"> 
                                   <hr />
                                 <p onClick={() => externallinks(item.gurl)}>{item.gurl} </p> 
                            </div>
                      </> : null }

                      </div>


              <div className="yyy8">
         
              {props.statuspermision === 1 || props.statuspermision === 3  || props.statuspermision === 4 ? <>
                     <CommentgroupFeed commentuserid ={props.storageid} userimgs={ismemberprofile} postids={item.gfeed_id} feedmemberid={item.gfeed_member_id}  likecount={item.likes} commentcount={item.comments}  commentstoogle={item.commentstoogle}/> 
            </> : null }
                   
              </div>




        </li>
))}
      
      
        {ispostloademore === false ? 
        <div className="loading" >
                {loademoreloding  ?
                            <>
                                   <Loader type="ThreeDots" color="#1da1f3" height={67} width={67} style={{float: "left",marginTop:"37px",display:"block",width:"100%"}}/>
                            </>
                            : 
                             <button className="yyy3"  onClick={()=>handleIncrement(count)}>Load More </button>
                }
           </div>



           : null }
         
           </ul>
       </div>
</div>

</> : null }












</div>
<div className="col-md-4 yyys">
      
      <GroupAnnouncement   groupid={props.groupid} storageid={props.storageid} groupusercreateid={props.groupusercreateid} grouprefresh={()=>gpost(page,props.groupid)} resetposts={()=>setpost([])} setmore={()=>moreoptions(false)}  grouprefreshannc={()=>gpost(page,props.groupid)} resetpostsannc={()=>setpost([])} adminpremissionss={props.adminpremissionsss} />

</div>
       </>
   
  );
}

export default GroupPost;