import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import {Link } from "react-router-dom"; 
import Loader from 'react-loader-spinner';
import userphotos from '../Img/user_profile.jpg';
import { useHistory } from "react-router-dom";
import {WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function Followerslist(props) {
       let history = useHistory();
       const {follwinglistqqq} = useContext(MyContext);
      
    useEffect( () => {
      follwersss(props.profileusers); 
      //follwerlogins(props.storageid); 
     }, []);

     const [isfollwersss, setisfollwersss] = useState([]);
     const follwersss = async (profileid) => {
      try {
             const follwersssqqq = await follwinglistqqq(profileid);
                    if(follwersssqqq.success === 1){
                           console.log('follwers list',follwersssqqq.follwerslist)
                           setisfollwersss(follwersssqqq.follwerslist);
                    }
             } catch (error) { throw error;}    
      } 

       const viewprofiles =(profilesids) =>{
            
              window.open(`/ProfileView/${profilesids}`, '_self');
             // history.push(`/ProfileView/${profilesids}`);
       }



  return (
  
       <>
            <div className="prosss yyy6">
            <div className="">
               

       <div className="profile public">
              <div className="login-form"><h2> Following </h2></div>
             
		<div className="bp-widget base">
			<ul className="profile-fieldsmmm2">
				
{isfollwersss.map((item, index) => ( 
        
	<li key={item.follow_id}>
              {item.profile_image !=='' ? 
                     <img src={BaseURLuploads+item.profile_image} alt="" className="mr-2mmm" />
              :
               <img src={userphotos} alt="" className="mr-2mmm"  />
              }
                  
                     <span> {item.name} </span>
     
              <button className="group-buttonsssmmm2" onClick={() => viewprofiles(item.register_id)}> View Profile </button> 
      
           
       </li>

))}
			</ul>
		</div>

       </div>








                     </div>
                     </div>

       </>
   
  );
}

export default Followerslist;