import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';
import Moment from 'react-moment';
import Loader from 'react-loader-spinner';


function Profilelist(props) {
       const {newprofileupdatelist} = useContext(MyContext);
      
     
   
       const [userInfo, setuserInfo] = useState({
              name: props.profileviews[0].name,
              dob: props.profileviews[0].dob,
              sex: props.profileviews[0].sex,
              city: props.profileviews[0].city,
              country:props.profileviews[0].country,
              phone: props.profileviews[0].phone,
              about: props.profileviews[0].about,
              facebook: props.profileviews[0].facebook,
              twitter: props.profileviews[0].twitter,
              linkedin: props.profileviews[0].linkedin,
          });
     
     
       const [loadingedit, setloadingedit] = useState(false);
       

       const onChangeValue = (e) => {
              setuserInfo({
                  ...userInfo,
                      [e.target.name]:e.target.value
              });
             
       }    
      
     
       const newprofileupdate = async(event) =>{
              try {
                     setloadingedit(true);
                     event.preventDefault();
                     event.persist();
                     const newprofileupdatesss = await newprofileupdatelist(userInfo,props.profileusers);
                     if(newprofileupdatesss.success === 1){
                            setloadingedit(false);
                            event.target.reset(); 
                            props.refresh(props.profileusers);
                            setEditProfile(false);
                           
                     }
              } catch (error) { throw error;}      
       }
       const [isEditProfile, setEditProfile] = useState(false);
       const EditProfile = () =>{
              setEditProfile(true);
       }


  return (
  
       <>
            <div className="prosss yyy6">
            <div className="">
                   {props.profileusers == props.storageid && isEditProfile === false ? 
                     <div className="col-md-12"> 
                     <p onClick={EditProfile} className="db-tit-btn2"> Edit Profile  </p>
                     </div>
              : null }
              {isEditProfile === true ? <>  
              <div className="clearfix"></div>
              <div className="login-form"><h2>  Edit Profile </h2></div>
                   
                     <form onSubmit={newprofileupdate} className="login-forms">
                        

                     <div className="form-group col-md-12">
                            <label className="font-weight-bold col-md-3"> Name : </label>
                            <input type="text" name="name" value={userInfo.name} onChange={onChangeValue}  className="form-control col-md-9" placeholder="Name" required />
                     </div>

                     <div className="form-group col-md-12">
                            <label className="font-weight-bold col-md-3"> Date of Birth :  </label>
                            <input type="text" name="dob" value={userInfo.dob} onChange={onChangeValue}  className="form-control col-md-9" placeholder="Date of Birth"  />
                     </div>

                    <div className="form-group col-md-12">
                            <label className="font-weight-bold col-md-3"> Gender : </label>
                            <div className="form-group col-md-9">
                                   <div className="form-group col-md-6">
                                          <input type="radio" name="sex" value="Male" onChange={onChangeValue}  className="form-control "   checked={userInfo.sex === 'Male'}  /> Male 
                                   </div>
                                   <div className="form-group col-md-6">
                                          <input type="radio" name="sex" value="Female" onChange={onChangeValue}  className="form-control"  checked={userInfo.sex === 'Female'} /> Female
                                   </div>
                            </div>
                     </div>
                     <div className="form-group col-md-12">
                            <label className="font-weight-bold col-md-3"> City : </label>
                            <input type="text" name="city" value={userInfo.city} onChange={onChangeValue}  className="form-control col-md-9" placeholder="City"  />
                     </div>
                     <div className="form-group col-md-12">
                            <label className="font-weight-bold col-md-3"> Country : </label>
                            <input type="text" name="country" value={userInfo.country} onChange={onChangeValue}  className="form-control col-md-9" placeholder="Country"  />
                     </div>
                     <div className="form-group col-md-12">
                            <label className="font-weight-bold col-md-3"> Phone : </label>
                            <input type="text" name="phone" value={userInfo.phone} onChange={onChangeValue}  className="form-control col-md-9" placeholder="Phone"  />
                     </div>
                     <div className="form-group col-md-12">
                 <label className="font-weight-bold col-md-3">Facebook : </label>
                 <input type="text" name="facebook" value={userInfo.facebook} onChange={onChangeValue} className="form-control col-md-9" placeholder="Facebook link here..."    />
                
             </div>
              <div className="form-group col-md-12">
                 <label className="font-weight-bold col-md-3">Twitter : </label>
                 <input type="text" name="twitter" value={userInfo.twitter} onChange={onChangeValue} className="form-control col-md-9" placeholder="Twitter link here..."    />
                
             </div>
             <div className="form-group col-md-12">
                 <label className="font-weight-bold col-md-3">Linkedin :  </label>
                 <input type="text" name="linkedin" value={userInfo.linkedin} onChange={onChangeValue} className="form-control col-md-9" placeholder="Linkedin link here..."    />
              </div>
              <div className="form-group col-md-12">
                 <label className="font-weight-bold col-md-3"> About :  </label>
                 <textarea  name="about" value={userInfo.about} className="form-control col-md-9" rows="7"  placeholder="About Me"  onChange={onChangeValue} />
             </div>


                            {loadingedit ?
                            <>
                                   <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                            </>
                            : 
                                   <div className="form-group col-sm-8 text-right">
                                          <button type="submit" className="db-tit-btn2"> Edit  </button>
                                          
                                   </div>
                             }
                 
                     </form>

                     </> :

       <div className="profile public">
              <div className="login-form"><h2> About </h2></div>
             
		<div className="bp-widget base">
			<ul className="profile-fields bp-tables-user">
				<li className="label"><label className="font-weight-bolds col-md-3"> Name : </label> <span className="data">{props.profileviews[0].name} </span></li>

				<li className="label"><label className="font-weight-bolds col-md-3"> Date of Birth : </label> <span className="data">
                            {props.profileviews[0].dob !== '' ?    
                                   <Moment format="MMM D, YYYY" withTitle className="timssqqqwww">
                                          {props.profileviews[0].dob}
                                   </Moment> 
                            : <>  {props.profileviews[0].dob}  </>}      
              </span></li>

		<li className="label"><label className="font-weight-bolds col-md-3"> Gender : </label>  <span className="data">{props.profileviews[0].sex} </span></li>
		<li className="label"> <label className="font-weight-bolds col-md-3"> City : </label> <span className="data">{props.profileviews[0].city} </span></li>
		<li className="label"> <label className="font-weight-bolds col-md-3"> Country : </label> <span className="data">{props.profileviews[0].country} </span></li>
		<li className="label"> <label className="font-weight-bolds col-md-3"> Phone : </label> <span className="data">{props.profileviews[0].phone} </span></li>
		<li className="label"> <label className="font-weight-bolds col-md-3"> Facebook : </label> <span className="data">{props.profileviews[0].facebook} </span></li>
		<li className="label"> <label className="font-weight-bolds col-md-3"> Twitter  : </label> <span className="data">{props.profileviews[0].twitter} </span></li>
		<li className="label"> <label className="font-weight-bolds col-md-3"> Linkedin  : </label> <span className="data">{props.profileviews[0].linkedin} </span></li>
              <li className="label"> <label className="font-weight-bolds col-md-3"> About : </label> <span className="data">{props.profileviews[0].about} </span></li>
			</ul>
		</div>

       </div>

}






                     </div>
                     </div>

       </>
   
  );
}

export default Profilelist;