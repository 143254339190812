import React,{useContext,useState,useEffect} from 'react';
import { MyContext } from '../contexts/MyContext';

import {Link } from "react-router-dom"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DashboardAccount from './DashboardAccount';
import DashboardClassificationAll from './DashboardClassificationAll';
import DashboardBusinessAll from './DashboardBusinessAll';
import ProfileSetting from './ProfileSetting';
import Groups from './Groups';
import AccountInformation from './AccountInformation';
import Recepitlist from './Recepitlist';
import Logout from './Logout';
import MyaccountBronze from './MyaccountBronze';


function Myaccount(props) {
  const {rootState,} = useContext(MyContext);
  const {isAuth,theUser} = rootState;

  useEffect( () => {
     window.scrollTo(0, 0);
     
 }, []);

   const [isDashboard, setisDashboard] = useState(false);
   const [isClassified, setisClassified] = useState(false);
   const [isBusiness, setisBusiness] = useState(false);
   const [isProfile, setisProfile] = useState(false);
   const [isLogOut, setisLogOut] = useState(false);
   const [isPending, setisPending] = useState(false);
   const [isAdmin, setisAdmin] = useState(false);
   const [isRecepit, setisRecepit] = useState(false);
   const [isGroups, setisGroups] = useState(false);
  
   const Dashboard =()=>{
      setisDashboard(false);setisClassified(false);setisBusiness(false);setisProfile(false);setisLogOut(false);setisAdmin(false);setisPending(false);setisRecepit(false);setisGroups(false);
   }
   const Classified =()=>{
      setisDashboard(true);setisClassified(true);setisBusiness(false);setisProfile(false);setisLogOut(false);setisAdmin(false);setisPending(false);setisRecepit(false);setisGroups(false);
   }
   const Business =()=>{
      setisDashboard(true);setisClassified(false);setisBusiness(true);setisProfile(false);setisLogOut(false);setisAdmin(false);setisPending(false);setisRecepit(false);setisGroups(false);
   }
   const Profile =()=>{
      setisDashboard(true);setisClassified(false);setisBusiness(false);setisProfile(true);setisLogOut(false);setisAdmin(false);setisPending(false);setisRecepit(false);setisGroups(false);
   }
   const GroupsActive =()=>{
      setisDashboard(true);setisClassified(false);setisBusiness(false);setisProfile(false);setisLogOut(false);setisAdmin(false);setisPending(false);setisRecepit(false);setisGroups(true);
   }
   // const Pending =()=>{
   //    setisDashboard(true);setisPending(true);setisAdmin(false);setisProfile(false);setisLogOut(false);setisRecepit(false);
   // }
   const Admin =()=>{
      setisDashboard(true);setisClassified(false);setisBusiness(false);setisPending(false);setisAdmin(true);setisProfile(false);setisLogOut(false);setisRecepit(false);setisGroups(false);
   }
   const Recepit =()=>{
      setisDashboard(true);setisClassified(false);setisBusiness(false);setisPending(false);setisAdmin(false);setisRecepit(true);setisProfile(false); setisLogOut(false);setisGroups(false);
   }

   const LogOut =()=>{
      setisDashboard(true);setisClassified(false);setisBusiness(false);setisProfile(false);setisAdmin(false);setisLogOut(true);setisPending(false);setisRecepit(false);setisGroups(false);
   }
    return (
    
      <div className="Myaccounts">
       <div className="container">
         <div className="row">
          
         <div className="col-md-3 responseviews">
             <div className="ud-rhs-pay">
        <div className="ud-rhs-pay-inn">
            {isAuth === true && theUser !=='' ?
               <AccountInformation storageid={theUser}/>
            : null } 
       
                        </div>
    </div>
              </div>
        
              <div className="col-md-2">
              <ul className="memberstabsss">
                     <li onClick={Dashboard} className={isDashboard? "nav-item": "active" } >
                     <FontAwesomeIcon icon={['fas','th-large']} className="iconsss2" />
                     Dashboard 
                     </li>
                     {isAuth === true && theUser !=='' ?
                     <MyaccountBronze storageid={theUser} Classifiedsss={Classified} Businesss={Business} isClassified={isClassified} isBusiness={isBusiness}/>
                  : null }
                    
                     {/* <li onClick={Classified} className={ isClassified ? "active": "nav-item" }>
                     <FontAwesomeIcon icon={['fas','user-cog']} className="iconsss2" />
                     All Classified Listings </li> 
                     <li onClick={Business} className={ isBusiness ? "active": "nav-item" }>
                     <FontAwesomeIcon icon={['fas','user-cog']} className="iconsss2" />
                     All Business Listings </li>  */}

                     <li onClick={Profile} className={ isProfile ? "active": "nav-item" }>
                     <FontAwesomeIcon icon={['fas','user-cog']} className="iconsss2" />
                     Profile Settings </li> 
                     <li onClick={GroupsActive} className={ isGroups ? "active": "nav-item" }>
                     <FontAwesomeIcon icon={['fas','users']} className="iconsss2" />
                     Groups </li> 

                
                     {theUser === 2 || theUser === 6 ?
                    <li onClick={Admin} className={ isAdmin ? "active": "nav-item" }>
                     <FontAwesomeIcon icon={['fas','money-check-alt']} className="iconsss2" />
                     Admin </li> 
                     : null }
                   
                 
                     
                     <li onClick={Recepit} className={isRecepit? "active": "nav-item" }>
                     <FontAwesomeIcon icon={['fas','receipt']} className="iconsss2" />
                     Receipt
                     </li> 

                     <li onClick={LogOut} className={isLogOut? "active": "nav-item" }>
                     <FontAwesomeIcon icon={['fas','sign-out-alt']} className="iconsss2" />
                     Log Out
                     </li>
              </ul>
              </div>
             <div className="col-md-7">
              <div className="ud-cen">
                <div className="log-bor">&nbsp;</div>
                  {isAuth === true && theUser !=='' && isDashboard === false ?
                  <><DashboardAccount  storageid={theUser} /></>
                  : null } 

                  {isAuth === true && theUser !=='' && isClassified === true ?
                  <><DashboardClassificationAll  storageid={theUser} /></>
                  : null } 

                  {isAuth === true && theUser !=='' && isBusiness === true ?
                  <><DashboardBusinessAll  storageid={theUser} /></>
                  : null } 

                  {isAuth === true && theUser !=='' && isProfile === true ?
                  <><ProfileSetting  storageid={theUser} /></>
                  : null } 

                  {isAuth === true && theUser !=='' && isGroups === true ?
                  <><Groups storageid={theUser} /></>
                  : null }
                  {isAuth === true && theUser !=='' && isRecepit === true ?
                  <><Recepitlist storageid={theUser} /></>
                  : null }


                   {isLogOut === true ?
                        <Logout /> 
                  : null }  

              </div>
             </div>
             <div className="col-md-3">
             <div className="ud-rhs-pay">
        <div className="ud-rhs-pay-inn">
            {isAuth === true && theUser !=='' ?
               <AccountInformation storageid={theUser}/>
            : null } 
       
                        </div>
    </div>
              </div>

             

      </div>
 </div>
</div>
    
    );
}

export default Myaccount;