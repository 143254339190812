import React,{useContext,useState,useEffect, useRef, useCallback} from 'react';

import {Link } from "react-router-dom"; 
import { MyContext } from '../contexts/MyContext';

import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Loader from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import userphotos from '../Img/user_profile.jpg';
import Moment from 'react-moment';
import Popup from 'reactjs-popup';
import CommentNewsFeed from './CommentNewsFeed';
import PostEdit from './PostEdit';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useHistory } from "react-router-dom"; 
import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function Feedslists(props) {
       let history = useHistory();
       const {viewuserprofile,newfeedsadd,latestfeedslist,deletebooksssqqq,reportadds,HideCommentlistfeeds} = useContext(MyContext);
      
       const [isFetching, setIsFetching] =  useState(false);
       const [page, setPage] = useState(1);
      
       const [loading, setLoading] = useState(false)
   
// const observer = useRef()
// const loadingRef = useCallback(node => {
//  if (loading === true) return
//   if (observer.current) observer.current.disconnect()
//   observer.current = new IntersectionObserver(entries => {
//     if (entries[0].isIntersecting ) {
//       setPage(prevPageNumber => prevPageNumber + 1)
//     }
//   })
//   if (node) observer.current.observe(node)
// }, [loading])   




const [ismembername, setmembername] = useState([]);
const [ismemberprofile, setmemberprofile] = useState([]);
const [userInfo, setuserInfo] = useState({
       feed: '',
       file:'',
       url:'',
       youtube:'',
   });
   const [isreport, setreport] = useState({
       report: '',
   });
const [isurls, setisurls] = useState(false);
const [isyoutube, setisyoutube] = useState(false);
const [ismoreids, setismoreids] = useState();
const [ismore, setismore] = useState(false);
const [loadingadd, setloadingadd] = useState(false);
const [fileempty, setfileempty] = useState(false);
const [filetemp, setfiletemp] = useState(null);
const [invalidImage, setinvalidImage] = useState(null);
const [islatestfeeds, setlatestfeeds] = useState([]);
const [issetmore, setmore] = useState(false);
const [issetmoreids, setmoreids] = useState(false);


useEffect(() => {
  userdetails(props.storageid);
  gpost(page,props.storageid);
}, []);
const [ispostloademore, setpostloademore] = useState(false);
const [loademoreloding, setloademoreloding] = useState(false);
const gpost = async(page,storageid) =>{
      try {
        setloademoreloding(true);
             const gpostsss = await latestfeedslist(page,storageid);
             if(gpostsss.success === 1){
                    console.log('latest',gpostsss.feedslist);
                    if(gpostsss.feedslist.length > 0){
                     setlatestfeeds(prevBooks => {
                                    return [...prevBooks, ...gpostsss.feedslist]
                            })
                            setpostloademore(false);
                            setloademoreloding(false);
                     }else{
                            setpostloademore(true);
                            setloademoreloding(false);
                     }
                   
             }
      } catch (error) { throw error;}      
} 

const [count, setCount] = useState(1);
const handleIncrement = (count) => {
setCount(prevCount => prevCount + 1);
console.log('count',count+1);
gpost(count+1,props.storageid);
};

const HideComment = async(feedids) =>{
       try {
              const HideCommentsss = await HideCommentlistfeeds(feedids);
              if(HideCommentsss.success === 1){
                     console.log('HideCommentsss',HideCommentsss.listanncccsss);
              
                     setPage(1);
                     setCount(1);
                     setlatestfeeds([]);
                     gpost(page,props.storageid);
              }
       } catch (error) { throw error;}      
} 
// useEffect( () => {
//   if (isFetching === true) return;
//   (async function latestfeeds() {
//        const latestfeedsqqq = await latestfeedslist(page);
//        if(latestfeedsqqq.success === 1){
//               console.log(latestfeedsqqq);
//               setlatestfeeds(prevBooks => {
//                      return [...prevBooks, ...latestfeedsqqq.feedslist]
//               })
//               setmore(false);
//               if(latestfeedsqqq.feedslist.length < 1){
//                      setIsFetching(true);
//                      setLoading(true)
//               }
//        }
//   })();
  
//       return () => {
//         console.log("This will be logged on unmount");
//       }
// }, [isFetching,page])

     
     
     
       
      
       const moreoptions = (ids) =>{
              setmoreids(ids)
              setmore(issetmore === false ? true : false);
              
       }
       

       const onChangeValue = (e) => {
              setuserInfo({
                  ...userInfo,
                      [e.target.name]:e.target.value
              });
             
       }
       const onChangereport = (e) => {
              setreport({
                  ...isreport,
                      [e.target.name]:e.target.value
              });
             
       }    

       const [isActiveusers, setActiveusers] = useState([]);
       const [isActiveusersccc, setActiveusersccc] = useState(false);
       const userdetails = async(storageid) =>{
              try {
                     const viewuser = await viewuserprofile(storageid);
                     if(viewuser.success === 1){
                            console.log('memberlist',viewuser.memberlist);
                            setmembername(viewuser.memberlist[0].name);
                            setmemberprofile(viewuser.memberlist[0].profile_image);
                            setActiveusers(viewuser.memberlist[0].status);
                            setActiveusersccc(true);
                     }
              } catch (error) { throw error;}      
       } 
     
      
     
       const [isreportpost, setreportpost] = useState({
              reportpost: '',
          });

       const radiosChange = (e) => {
              setreportpost({
                     ...isreportpost,
                     [e.target.name]:e.target.value
              });
       }   

          const reportpostupdate = async(event) =>{
              try {
                    
                     event.preventDefault();
                     event.persist();
                     const reportpostupdatesss = await reportadds(isreportpost,props.storageid,isreportpost.feedsids.value);
                     if(reportpostupdatesss.success === 1){
                            event.target.reset(); 
                            setreportpost({
                                   ...isreportpost,
                                   reportpost:'',
                               });
                               setmore(issetmore === false ? true : false);
                     }
              } catch (error) { throw error;}      
       }
   

       const newfeeds = async(event) =>{
              try {
                     setloadingadd(true);
                     event.preventDefault();
                     event.persist();
                     const newfeedsqqq = await newfeedsadd(userInfo,props.storageid,userInfo.fileempty.value);
                     if(newfeedsqqq.success === 1){
                            setloadingadd(false);
                            event.target.reset(); 
                            setuserInfo({
                                   ...userInfo,
                                       feed:'',
                                       file:'',
                                       url:'',
                                       youtube:'',
                               });
                               setisurls(false);
                               setisyoutube(false);
                               setfileempty(false);
                               setfiletemp(null);
                               setinvalidImage(null);
                               //history.go(0);
                               //newlatestfeeds();
                               setPage(1);
                               setCount(1);
                               setlatestfeeds([]);
                               gpost(page,props.storageid);
                               setmore(issetmore === false ? true : false);
                     }
              } catch (error) { throw error;}      
       }

       const editLatestFeeds = async () => {
              history.go(0);
       }

       // const newlatestfeeds = async () => {
       //        setlatestfeeds([]);
       //        let page = 1;
       //        const newlatestfeedsqqq = await latestfeedslist(page);
       //               setlatestfeeds(newlatestfeedsqqq.feedslist);
       // }

       const resetFile = (event) => {
              event.preventDefault();
              setfiletemp(null)
            }

            let reader = new FileReader();

   const handleChange = (event) => {
       const imageFile = event.target.files[0];
             const imageFilname = event.target.files[0].name;

             if (!imageFile) {
              setinvalidImage('Please select image.');
               return false;
             }
         
             if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif)$/)) {
              setinvalidImage('Please select valid image JPG,JPEG,PNG');
               return false;
             }
             reader.onload = (e) => {
               const img = new Image();
               img.onload = () => {

//----------------------------- Resize img code -------------------------------------------------
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);

                var MAX_WIDTH = 437;
                var MAX_HEIGHT = 437;
                var width = img.width;
                var height = img.height;

                if (width > height) {
                  if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                  }
                } else {
                  if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                  }
                }
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
                ctx.canvas.toBlob((blob) => {
                  const file = new File([blob], imageFilname, {
                      type: 'image/jpeg',
                      lastModified: Date.now()
                  });
                  setuserInfo({
                     ...userInfo,
                     file:file,
                })
                }, 'image/jpeg', 1);


//-------------------------------------------------------------------------------------
              setfiletemp( URL.createObjectURL(imageFile))
              setfileempty(true);
              setinvalidImage(null)
              };
               img.onerror = () => {
                     setinvalidImage('Invalid image content.');
                 return false;
               };
               //debugger
               img.src = e.target.result;
             };
             reader.readAsDataURL(imageFile);

     }; 

     const toggleClass = (ids) => {
       setismore(ismore === false ? true : false);
       setismoreids(ids);
   };
   const toggleClassurls = () => {
       setisurls(isurls === false ? true : false);
   };
  const toggleClassyoutube = () => {
       setisyoutube(isyoutube === false ? true : false);
   };
  
   const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]{11,11}).*/;  

   const deletes = (feedids) => {
       confirmAlert({
         title: 'Confirm to Delete ',
         message: 'Are you sure to do this.',
         buttons: [
           {
             label: 'Yes',
             onClick: () => deletebooks(feedids)
           },
           {
             label: 'No',
           }
         ]
       })
     };     
     const deletebooks = async (feedids) => {
       try {
              const deletebooksss = await deletebooksssqqq(feedids);
              if(deletebooksss.success === 1){
                     // history.go(0);
                     setPage(1);
                     setCount(1);
                     setlatestfeeds([]);
                     gpost(page,props.storageid);
                     //latestfeeds();
              }
       } catch (error) { throw error;} 
}

const externallinks = (url) => {
       if (!!url && !!url.trim()) { 
              url = url.trim(); 
              if (!/^(https?:)?\/\//i.test(url)) { 
                  url = 'http://' + url; 
                  window.open(url, '_blank');
              }else{
                     window.open(url, '_blank');
              }
          } 
     }  


  return (
  
       <>
     
       {isActiveusers === 'Active' && isActiveusersccc === true ?  null
       : 
              <> {isActiveusersccc === true ?
                     <h1 className="aacccsssqqq"> Please upgrade and post unlimited business Ads  </h1>
              : null}
              </>

       }

       {props.storageid !== '' ? 
            <div className="feeds ">
            <div className="feedswww">
                   <h3 className="create-post">Create Post</h3>
                    
                     <form onSubmit={newfeeds} className="login-forms">
                     <div className="whats-new-content ">
                     {ismemberprofile !=='' ? 
                            <img src={BaseURLuploads+ismemberprofile} alt="" className="rounded-circle mr-2 feeds2" />
                     :
                            <img src={userphotos} alt="" className="rounded-circle mr-2 feeds2"  />
                     }
                            <textarea  name="feed" value={userInfo.feed} className=" col-md-10" rows="4"  placeholder=" Start a post "  onChange={onChangeValue} required />
                            </div>
                     <div className="clearfix"></div>
                   
                     <div className="col-md-12 " style={{padding:0}}>
                     <div className="col-md-8" style={{padding:0}}>
                     <ul> 
                            <li>
                                   <FontAwesomeIcon icon={['fas','image']} className="iconsss2" />
                                   <label className="fileContainer">
                                   <input type="file" name="file" className="form-control" placeholder="Title" onChange={handleChange} />
                                   </label>
                                   {fileempty === true ? 
                                   <input type="hidden" name="fileempty" ref={(val) => userInfo.fileempty = val} className="form-control" defaultValue="imgactive" />
                                   : 
                                   <input type="hidden" name="fileempty" ref={(val) => userInfo.fileempty = val} className="form-control" defaultValue="imgempty" />
                                   }
                            </li>
                            <li>
                                   <FontAwesomeIcon icon={['fas','globe-americas']} className="iconsss2"  onClick={toggleClassurls}/>
                                  
                            </li>
                             <li>
                                   <FontAwesomeIcon icon={['fab','youtube']} className="iconsss2"  onClick={toggleClassyoutube}/>
                                  
                            </li>
                     </ul>
                     </div>
                    
                     <div className="col-md-4" style={{padding:0}}>
                            {loadingadd ?
                            <>
                                   <Loader type="ThreeDots" color="#f64442" height={67} width={67} style={{float: "left"}}/>
                            </>
                            : 
                                   <div className="form-group col-sm-12 text-right">
                                          <button type="submit" className="db-tit-btn2"> Post Update  </button>
                                          
                                   </div>
                             }
                   </div>
                   </div>
                     {isurls === true ? 
                            <input type="text" name="url" value={userInfo.url} onChange={onChangeValue} className="form-control"  placeholder=" website url "/>
                     : null}
                     {isyoutube === true ? 
                            <input type="text" name="youtube" value={userInfo.youtube} onChange={onChangeValue} className="form-control"  placeholder=" youtube url "/>
                     : null}
                     </form>
                     </div>
                     </div>
                    



                     : null}

{/* -------------------------------------------------------------------------------------------------------------------------------------------- View Post -------------------------------------------------------------------------------------------------------------------------------------- */}

{islatestfeeds.length > 0 ? <>   
<div className="feeds yyy2">
<div className="feedswww">
<ul className="activity-list" > 
{/* {islatestfeeds.length > 0 ? <>     <li ref={loadingRef} ></li> */}
       {islatestfeeds.map((item, index) => ( 
              <li  className="col-md-12" key={item.feed_id} style={{padding:0}}  >

                     <div className="more-post-optns">
                     <FontAwesomeIcon icon={['fas','ellipsis-h']} className="enablelikes"  onClick={() => moreoptions(item.feed_id)} />
                     {issetmore === true && issetmoreids === item.feed_id ? 
<ul>
{item.feed_member_id === props.storageid ? <>
       <Popup trigger={<li> <FontAwesomeIcon icon={['fas','user-edit']} className="enablelikes"/>Edit Post</li>} position="top left">
       {close => (
       <div className="feedspopups">
               <a className="close" onClick={close}>
              &times;
              </a>
          
            
              <div className="feeds yyy2">
              <div className="feedswww">
               
               <PostEdit feedid={item.feed_id}  feedtext={item.feedtext}  image={item.image} url={item.url} youtube={item.youtube} grouprefresh={()=>gpost(page,props.storageid)} resetposts={()=>setlatestfeeds([])} userids={props.storageid} close={close} moreoptions ={() => moreoptions(item.feed_id)}  newsetCount ={()=>setCount(1)} /> 

              </div>
              </div>

       </div>
       )}
       </Popup>
                                  
                                   <li onClick={()=>deletes(item.feed_id)}><FontAwesomeIcon icon={['fas','trash-alt']} className="enablelikes"/>Delete Post</li>	

          <li> <FontAwesomeIcon icon={['fas','comments']} className="enablelikes"/>Comment <BootstrapSwitchButton checked= {item.commentstoogle === 0 ? true : false} onstyle="outline-primary" offstyle="outline-secondary" onlabel='On' offlabel='Off'
                     
                     onChange={()=>HideComment(item.feed_id)}

                     />

</li>  
</>: null }   
<Popup trigger={ <li className="bad-report"><FontAwesomeIcon icon={['fas','flag']} className="enablelikes"/>Report Post</li>} position="top left">
       {close => (
       <div className="feedspopups">
               <a className="close" onClick={close}>
              &times;
              </a>
              <div className="pppsss">
            <div className="feedswww">
            <h4> Report </h4>
            <hr />
              <form onSubmit={reportpostupdate} className="repportsss">
              <ul> 
                     <li><input type="radio" value="Nudity" name="reportpost" onChange={radiosChange} /> Nudity </li>
                     <li><input type="radio" value="Violence" name="reportpost" onChange={radiosChange}/> Violence </li>
                     <li> <input type="radio" value="Harassment" name="reportpost" onChange={radiosChange}/> Harassment </li>
                     <li> <input type="radio" value="Suicide or self-injury" name="reportpost" onChange={radiosChange}/> Suicide or self-injury </li>
                     <li> <input type="radio" value="False news" name="reportpost" onChange={radiosChange}/> False news </li>
                     <li> <input type="radio" value="Spam" name="reportpost" onChange={radiosChange}/> Spam </li>
                     <li> <input type="radio" value="Unauthorised sales" name="reportpost" onChange={radiosChange}/> Unauthorised sales </li>
                     <li> <input type="radio" value="Hate speech" name="reportpost" onChange={radiosChange}/> Hate speech </li>
                     <li> <input type="radio" value="Terrorism" name="reportpost" onChange={radiosChange}/> Terrorism </li>
                   
                   <input type="hidden" value={item.feed_id} ref={(val) => isreportpost.feedsids = val} name="feedsids" /> 
              </ul>
                     <div className="form-group col-sm-12 text-right">
                            <button type="submit" className="db-tit-btn2"> Report  </button>
                      </div>
              </form>
             
              </div>
              </div>   
       </div>
       )}
       </Popup>                        						
                  
        
                            </ul>
                     : null}    
                      </div>

                     <div className="userlistfeeds">
                            <div className="col-md-2s" style={{padding:0}} >
                            <Link to={`/ProfileView/${item.register_id}`} >
                                   {item.profile_image !=='' ? 
                                          <img src={BaseURLuploads+item.profile_image} alt="" className="rounded-circle mr-2 feeds2" />
                                   :
                                          <img src={userphotos} alt="" className="rounded-circle mr-2 feeds2"  />
                                   }
                            </Link>
                            </div>
                                   <div className="col-md-10s" style={{padding:0}}>
                                   <p> <b> <Link to={`/ProfileView/${item.register_id}`} style={{color: "#635d5d"}} > {item.name}  </Link></b> posted an update </p> <br/>
                                   <div className="clearfix"> </div>
                                   <p className="userlistfeedsmmm">  <Moment fromNow>
                                   {item.date} 
                                   </Moment></p>
                            </div>
                     </div>
                     <div className="clearfix"> </div>
                     <div className="shortcontent">
                   
                   
                     <div className={ "block-ellipsis" + (ismoreids === item.feed_id ? " block-ellipsisqqq " : "")} dangerouslySetInnerHTML={{ __html: item.feedtext }} />

                     {item.feedtext.length > 177 ? <>
                            {ismore === true && ismoreids === item.feed_id ?  
                                   <span className="morelink" onClick={toggleClass}> Close  </span> 
                            :  
                                   
                            <span className="morelink" onClick={() => toggleClass(item.feed_id)} > read more ...  </span>
                            }
                     </> : null }
                          
                     {item.image ? 
                            <div className="rtmedia-item-thumbnail">
<Popup trigger={<span className="popupsssimg2"> <FontAwesomeIcon icon={['fas','search']} className="iconsss2zzz2 " />  <img src={BaseURLuploads+item.image} alt="feedsimages"  /> </span>} position="top left">
       {close => (
       <div className="feedspopups">
               <a className="close" onClick={close}>
              &times;
              </a>
          
            
              <div className="feeds yyy2">
              <div className="feedswww">
              <img src={BaseURLuploads+item.image} alt="feedsimages" className="popupsssimg" />

              </div>
              </div>

       </div>
       )}
       </Popup>
                                  
                            </div>
                     : null }
                     <div className="clearfix"> </div>
                     {item.youtube.match(regExp) ? 
                     <> 
                            {item.youtube.match(regExp).length >= 2 ? 

                            <iframe id="ytplayer" type="text/html" className="videosss2"
                            src={`https://www.youtube.com/embed/` + item.youtube.match(regExp)[2] + `?rel=0&showinfo=0&color=white&iv_load_policy=3`}
                            frameBorder="0" allowFullScreen></iframe>
                            : null }
                     </>
                            : null 
                     } 
                      {item.url !== '' ? <>  
                            <div className="col-md-12 ccc2"> 
                                   <hr />
                                 <p onClick={() => externallinks(item.url)}>{item.url} </p> 
                            </div>
                      </> : null }


{/* -------------------------------------------------------------------------------------------------------------------------------------------- Like, Share, Comment -------------------------------------------------------------------------------------------------------------------------------------- */}
<div className="yyy8" style={{float:"left"}}>
       <CommentNewsFeed commentuserid ={props.storageid} userimgs={ismemberprofile} postids={item.feed_id} feedmemberid={item.feed_member_id}  likecount={item.likes} commentcount={item.comments} commentstoogle={item.commentstoogle} />

                     </div>
                     </div>
              </li>
       )) }
{/* </> : null } */}

{ispostloademore === false ? 
        <div className="loading" >
                {loademoreloding  ?
                            <>
                                   <Loader type="ThreeDots" color="#1da1f3" height={67} width={67} style={{float: "left",marginTop:"37px",display:"block",width:"100%"}}/>
                            </>
                            : 
                             <button className="yyy3"  onClick={()=>handleIncrement(count)}>Load More </button>
                }
           </div>



           : null }
</ul>


</div>
</div>

</> : null }

       </>
   
  );
}

export default Feedslists;