import React,{useContext,useState,useEffect,useRef,useCallback} from 'react';
import { MyContext } from '../contexts/MyContext';
import userphotos from '../Img/user_profile.jpg';
import Moment from 'react-moment';
import {Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURLuploads = WEB_URL_UPLOAD;

function Messagenotification(props) {
    
       const {messagenotifactionlist,notifactionupdatelist} = useContext(MyContext);
       const [pageNumber, setPageNumber] = useState(1);
      
       const observer = useRef()
       const loadingRef = useCallback(node => {
         
         if (observer.current) observer.current.disconnect()
         observer.current = new IntersectionObserver(entries => {
           if (entries[0].isIntersecting ) {
             setPageNumber(prevPageNumber => prevPageNumber + 1)
             //latestfeeds(prevPageNumber => prevPageNumber + 1)
           }
         })
         if (node) observer.current.observe(node)
       }, [])
       useEffect( () => {
         window.scrollTo(0, 0);
         
     }, []); 

    useEffect( () => {
       //messagenotifaction(props.storageid); 
       notifactionupdate(props.storageid); 
     }, []);

     const [isFetching, setIsFetching] = useState(false);

     useEffect( () => {
       if (isFetching === true) return;
      
       messagenotifaction(pageNumber,props.storageid); 
           return () => {
             console.log("This will be logged on unmount");
           }
     }, [pageNumber]) 

const [ismessagenotification, setismessagenotification] = useState([]);

const messagenotifaction = async (pageNumber,storageid) => {
       try {
              const messagefollowqqq = await  messagenotifactionlist(pageNumber,storageid);
                     if(messagefollowqqq.success === 1){
                            console.log('follownotification',messagefollowqqq.notificationsmessage);
                            
                            setismessagenotification(prevBooks => {
                                   return [...prevBooks, ...messagefollowqqq.notificationsmessage]
                            })
                            if(messagefollowqqq.notificationsmessage.length < 1){
                                   setIsFetching(true);
                            }
                            
                     }
       } catch (error) { throw error;}    
}





const notifactionupdate = async (storageid) => {
       try {
              const followqqqsss = await notifactionupdatelist(storageid);
                     if(followqqqsss.success === 1){
                            console.log('notifactionupdate success')
                     }
       } catch (error) { throw error;}    
}


  return (
  
       <>
<ul className="notificationqqq">
{ismessagenotification.map((item, index) => ( 
       <div  ref={loadingRef} className="col-md-12" key={item.notification_ids} style={{padding:0}}>
 {/* -------------------------------------------------------------------------------------------------------------------------- Follow -------------------------------------------------------------------------------------------------------------------------------------- */}             
  {item.message_profile_id === 0 && item.msgreply_profile_id === 0 && item.like_profile_id === 0 && item.comm_profile_id === 0 ?
              <li> <Link to={`/ProfileView/${item.register_id}`} >
                     <p>
                     {item.profile_image !== '' ? <>
                            <img src={BaseURLuploads+item.profile_image} alt="userprofile" className=" " />
                     </> :  <>
                            <img src={userphotos} alt="userprofile" className=" "/>
                     </> }
                     <span> {item.name} </span> Followed you <br />
                     <b className="timqqq"> <FontAwesomeIcon icon={['fas','clock']} className="iconsss2 " /> <Moment format="MMM D, YYYY" withTitle>
                                       {item.follow_date} 
                                    </Moment> </b>
                      </p>
                      </Link>
              </li> 
        :null} 
{/* -------------------------------------------------------------------------------------------------------------------------- Message All -------------------------------------------------------------------------------------------------------------------------------------- */}
       {item.follow_userid === 0 && item.msgreply_profile_id === 0 && item.like_profile_id === 0 && item.comm_profile_id === 0 ?
              <li>
            <Link to={`/Messagelistall/${item.message_profile_id}`} >
                     <p>
                     {item.profile_image !== '' ? <>
                            <img src={BaseURLuploads+item.profile_image} alt="userprofile" className=" " />
                     </> :  <>
                            <img src={userphotos} alt="userprofile" className=" "/>
                     </> }
                      You got a new message from   <span> {item.name} </span>  <br />
                     <b className="timqqq"> <FontAwesomeIcon icon={['fas','clock']} className="iconsss2 " /> <Moment format="MMM D, YYYY" withTitle>
                                       {item.message_date} 
                                    </Moment> </b>
                      </p>
                      </Link>
              </li>
       :null} 
{/* -------------------------------------------------------------------------------------------------------------------------- Message Reply All -------------------------------------------------------------------------------------------------------------------------------------- */}

{item.message_profile_id === 0 && item.follow_userid === 0 && item.like_profile_id === 0 && item.comm_profile_id === 0 ? <>
  
               <li>
               <Link to={`/Messagelistall/${item.msgreply_profile_id}`} >
                        <p>
                        {item.profile_image !== '' ? <>
                               <img src={BaseURLuploads+item.profile_image} alt="userprofile" className=" " />
                        </> :  <>
                               <img src={userphotos} alt="userprofile" className=" "/>
                        </> }
                         You got a new reply message from   <span> {item.name} </span>  <br />
                        <b className="timqqq"> <FontAwesomeIcon icon={['fas','clock']} className="iconsss2 " /> <Moment format="MMM D, YYYY" withTitle>
                                          {item.msgreply_date} 
                                       </Moment> </b>
                         </p>
                         </Link>
                 </li>
     
</> :null}   

{/* -------------------------------------------------------------------------------------------------------------------------- Like News Feed -------------------------------------------------------------------------------------------------------------------------------------- */}

{item.message_profile_id === 0 && item.follow_userid === 0 && item.msgreply_profile_id === 0 && item.comm_profile_id === 0 ? <>
  
               <li>
               <Link to={`/NotificationFeedView/${item.like_feedids}`} >
                        <p>
                        {item.profile_image !== '' ? <>
                               <img src={BaseURLuploads+item.profile_image} alt="userprofile" className=" " />
                        </> :  <>
                               <img src={userphotos} alt="userprofile" className=" "/>
                        </> }
                           <span> {item.name} </span> liked your post   <br />
                        <b className="timqqq"> <FontAwesomeIcon icon={['fas','clock']} className="iconsss2 " /> <Moment format="MMM D, YYYY" withTitle>
                                          {item.like_date} 
                                       </Moment> </b>
                         </p>
                         </Link>
                 </li>
     
</> :null}   


{/* -------------------------------------------------------------------------------------------------------------------------- Comment News Feed -------------------------------------------------------------------------------------------------------------------------------------- */}

{item.message_profile_id === 0 && item.follow_userid === 0 && item.msgreply_profile_id === 0 && item.like_profile_id === 0 ? <>
  
               <li>
               <Link to={`/NotificationFeedView/${item.comm_feedids}`} >
                        <p>
                        {item.profile_image !== '' ? <>
                               <img src={BaseURLuploads+item.profile_image} alt="userprofile" className=" " />
                        </> :  <>
                               <img src={userphotos} alt="userprofile" className=" "/>
                        </> }
                           <span> {item.name} </span> commented on your post   <br />
                        <b className="timqqq"> <FontAwesomeIcon icon={['fas','clock']} className="iconsss2 " /> <Moment format="MMM D, YYYY" withTitle>
                                          {item.comm_date} 
                                       </Moment> </b>
                         </p>
                         </Link>
                 </li>
     
</> :null}   










       </div>

))}
</ul>
       </>
   
  );
}

export default Messagenotification;