import React from 'react';

import {Link } from "react-router-dom"; 

function PaypalCancel(props) {


    return (
      <div className="successs">
      <div className="container">
        <div className="row">
        <div className="col-md-3" > &nbsp; </div>
                  <div className="col-md-6" >
                  <div className="logssssuccesss">
             <h1 className="successsh2">Your PayPal Transaction Has Been Canceled.</h1>
             <Link to={`/Myaccount`}  className="db-tit-btn" > Payment Again </Link>
             {/* <Link to={`/Payment/${props.match.params.paypalid}`} className="btn btn-style-two" > Payment Again </Link> */}
             </div>
              </div>
              
              </div>
              </div>
              </div>
      
    );
}

export default PaypalCancel;