import React, { Component } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link } from "react-router-dom"; 
//import Moment from 'react-moment';
import ContentLoader from "react-content-loader";
import user_profile from '../Img/user_profile.jpg';

import { WEB_URL,WEB_URL_UPLOAD } from '../contexts/Apilinks';
let BaseURL = WEB_URL;
let BaseURLuploads = WEB_URL_UPLOAD;

class DashboardClassificationAll extends Component {
  
  state = {
    classifieds: [],
    total: null,
    per_page: null,
    current_page: 1,
  }

  

  componentDidMount() {
    this.makeHttpRequestWithPage(1);
    window.scrollTo(0, 0);
  }

 
  makeHttpRequestWithPage = async pageNumber => {
    let pageUser = this.props.storageid;
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    
    const response = await fetch(BaseURL+`DashboardAllclassifieds/${pageNumber}/${pageUser}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });
   
    const res = await response.json();
    console.log(res);
    this.setState({
       classifieds: res.data,
       total: res.total,
       per_page: res.per_page,
       current_page: res.page,
    });
  }

  render() {
 
    let  allUsers,mainData, renderPageNumbers;
    if (this.state.classifieds !== null) {
      allUsers = this.state.classifieds.map(item => (
        <div className="col-md-12" key={item.classified_id}>
        <div className="single-listing-item ">
        <div className="col-md-9" style={{padding:0}}>
            <div className="listing-image">
            <Link to={`/ClassifiedsPreview/${item.classified_id}`} className="link-btn">
                    <img src={BaseURLuploads+item.image} alt="Classifiedsads" />
                    <div className="listing-tag">
                    {item.category === 'other' ?
                        <>{item.other}</>
                        :  <>{item.category}  </>}
                    </div>
                    </Link>
             </div>
                <div className="listing-content">
                <div className="listing-author ">
                {item.profile_image !=='' ? 
                             <img src={BaseURLuploads+item.profile_image} alt="" className="rounded-circle mr-2" />
                             :
                             <img src={user_profile} alt="" className="rounded-circle mr-2"  />
                         }
               
                  <span> {item.name} </span></div>
                <h3> {item.title} </h3>
              
                </div>
                </div>
                    <div className="col-md-3" style={{padding:0}}>
                     <div className="">
                            
                            <div className="link-box">
                            <Link to={`/ClassifiedsPreview/${item.classified_id}`}><span>View More </span></Link></div>
                     </div>
                    </div>
               
        </div>
    </div>   
       
      ));
      if( this.state.classifieds.length > 0){
        mainData = ( <div>{allUsers}  </div> );
    } else{
      mainData = ( <div>
       <ContentLoader height={337}width={1200}speed={2}primarycolor="#f3f3f3" secondarycolor="#ecebeb" > <rect x="8" y="0" rx="0" ry="0" width="357" height="233"/> </ContentLoader> 
       </div> );

      
    }
    }else{
      return (
        <div className="" style={{padding:'60px'}}>
        <div className="container">
           <div className="row">
           <div className="col-md-12">
        <div className="alert alert-light" role="alert">
        <h4 className="alert-heading"> Please Wait </h4>
        <hr/>
        <ContentLoader height={337}width={1200}speed={2}primarycolor="#f3f3f3" secondarycolor="#ecebeb" > <rect x="8" y="0" rx="0" ry="0" width="357" height="233"/> </ContentLoader> 
    </div>
    </div>
    </div>
    </div>
    </div>
      )
     }

    const pageNumbers = [];
    if (this.state.total !== null) {
      for (let i = 1; i <= Math.ceil(this.state.total / this.state.per_page); i++) {
        pageNumbers.push(i);
      }


      renderPageNumbers = pageNumbers.map(number => {
        //console.log('current_page: '+this.state.page+' number : '+number);
        let classes = this.state.current_page != number ? '' : 'active';

        return (
          <span key={number} className={classes} onClick={() => this.makeHttpRequestWithPage(number)}> {number}</span>
        );
      });
    }



    return (
      <>
      <span className="udb-inst">All Classified Listings</span>
     
       <div className="ccc">
       <div className="container">
          <div className="row">
          <div className="col-md-12">
          <div className="ccc2" onClick={this.toggleClass}><Link to="/ClassifiedAdd" className="db-tit-btn"><FontAwesomeIcon icon={['fas','paper-plane']} className="" />  Post Classified Ads </Link></div>
            
          {mainData}
          <div className="col-md-12">
      <div className="pagination">
        <span onClick={() => this.makeHttpRequestWithPage(1)}>&laquo;</span>
        {renderPageNumbers}
        <span onClick={() => this.makeHttpRequestWithPage(1)}>&raquo;</span>
      </div>
      </div>
      </div>

   
    </div>
    </div>
    </div>
    </>
  );
}

}

export default DashboardClassificationAll
